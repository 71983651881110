import React, { FC } from "react";
// import useGetNodesAndEdges from "../../hooks/downstreamDepsModal/useGetNodesAndEdges";
import { Modal, Spin } from "antd";
import { DOWNSTREAM_DEPS_MODAL_GRAPH_HEIGHT } from "../../constants";
import { DefaultNode, GroupNode } from "./CustomGraphNodes";
import useGetNodesAndEdges from "../../hooks/downstreamDepsModal/useGetNodesAndEdges";
import DownstreamGraph from "./DownstreamGraph";

type Props = {
  campaignId: string;
  stepId: string;
  ccItemKey: string;
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const nodeTypes = {
  group: GroupNode,
  default: DefaultNode,
};

const DownstreamDependenciesModal: FC<Props> = ({
  campaignId,
  ccItemKey,
  stepId,
  isOpened,
  setIsOpened,
}: Props) => {
  const {
    nodes,
    initialNodeId,
    isInitialized,
    edges,
    onNodesChange,
    onEdgesChange,
    onNodeClick,
  } = useGetNodesAndEdges({
    campaignId,
    ccItemKey,
    stepId,
  });

  return (
    <Modal
      title="Downstream Dependencies"
      open={isOpened}
      onCancel={() => setIsOpened(false)}
      width={1150}
      centered
      footer={null}
    >
      <Spin spinning={!isInitialized} tip="Loading the data...">
        <div
          className="w-full border-2"
          style={{ height: `${DOWNSTREAM_DEPS_MODAL_GRAPH_HEIGHT}px` }}
        >
          <DownstreamGraph
            nodes={nodes}
            edges={edges}
            initialNodeId={initialNodeId}
            isInitialized={isInitialized}
            nodeTypes={nodeTypes}
            onNodeClick={onNodeClick}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default DownstreamDependenciesModal;
