import React, { FC, useEffect, useState } from "react";
import { SelectOption, TCompanyUserForm } from "../../globalTypes";
import { Drawer, Form } from "antd";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../store/slices/appSlice";
import submitFormWithTrim from "../../utils/submitFormWithTrim";
import handleRequestError from "../../utils/handleRequestError";
import { Button } from "../common/ui/button";
import { createCompanyUserApi } from "../../api/user.api";
import { Icons } from "../common/Icons";
import { useNavigate, useParams } from "react-router-dom";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import CompanyUserForm from "./CompanyUserForm";
import { getCompanyPermissionGroupsApi } from "../../api/permissions-group.api";

type LoadingState = "submit" | "init" | null;

type Props = {
  loadUsers: () => Promise<void>;
};

const AddCompanyUser: FC<Props> = ({ loadUsers }) => {
  const [form] = Form.useForm<TCompanyUserForm>();
  const messageApi = useSelector(selectMessageApi);
  const { companyId } = useParams();
  const { hasUserCreateRole, hasUserReadRole } = useUserHasPermission({
    companyId,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState<LoadingState>(null);
  const [visible, setVisible] = useState(false);
  const [permissionGroupOptions, setPermissionGroupOptions] = useState<
    SelectOption[]
  >([]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  const onClose = () => setVisible(false);

  const submitForm = () => {
    return submitFormWithTrim({
      form,
      onSuccessValidationCb: async (values: TCompanyUserForm) => {
        if (!companyId) {
          messageApi.error("Unable to load required data");
          console.error("Unable to get [companyId]");

          return;
        }

        try {
          setLoading("submit");

          const { permissionGroupId, ...userData } = values;

          const { data } = await createCompanyUserApi({
            companyId,
            userData,
            permissionGroupId,
          });

          onClose();

          if (hasUserReadRole) {
            navigate(`/companies/${companyId}/user/${data.id}`);
          } else {
            await loadUsers();
          }

          messageApi.success("The user has been successfully created");
        } catch (e: any) {
          const customError = handleRequestError(e);

          messageApi.error(customError.message);
          console.error(customError);
        } finally {
          setLoading(null);
        }
      },
    });
  };

  const onDrawerOpen = async () => {
    if (!companyId) {
      messageApi.error("Unable to load required data");
      console.error("Unable to get [companyId]");

      return;
    }

    try {
      setLoading("init");

      const { data } = await getCompanyPermissionGroupsApi({ companyId });
      const options = data.map((group) => ({
        value: group.id,
        label: group.title,
      }));

      setPermissionGroupOptions(options);
      setVisible(true);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(null);
    }
  };

  return (
    <>
      {hasUserCreateRole && (
        <Button
          onClick={onDrawerOpen}
          variant="primary"
          className="rounded-full self-end"
          icon={Icons.Plus}
          loading={loading === "init"}
        >
          Add User
        </Button>
      )}

      <Drawer
        title={
          <div className="font-sans text-white text-base">
            Create Company User
          </div>
        }
        placement="right"
        onClose={onClose}
        open={visible}
        styles={{
          header: { background: "#0D0A37", borderBottom: "0" },
          footer: { borderTop: "1px solid #CBD5E1", paddingLeft: "40px" },
          body: { padding: "40px" },
        }}
        width={533}
        footer={
          <div className="flex justify-start gap-[8px]">
            <Button
              className="rounded-full"
              onClick={submitForm()}
              loading={loading === "submit"}
            >
              Create
            </Button>
            <Button
              className="border border-gray-600 text-gray-600 w-[94px]"
              variant="primaryOutline"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <CompanyUserForm
          permissionGroupOptions={permissionGroupOptions}
          form={form}
        />
      </Drawer>
    </>
  );
};

export default AddCompanyUser;
