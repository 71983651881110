export const DOWNSTREAM_DEPS_MODAL_GRAPH_HEIGHT = window.innerHeight - 200;
export const UPSTREAM_DEPS_GRAPH_HEIGHT = ((window.innerHeight - 200) / 3) * 2;
export const NODE_DIMENSIONS = {
  width: 200,
  height: 40,
};
export const DOWNSTREAM_NODE_DIMENSIONS = {
  width: 300,
  height: 70,
};
export const NODE_PADDING = 30;
export const INIT_NODE_POSITION = { x: 0, y: 0 };

