import { TableCell, TableRow } from "../common/ui/table";

const UsersListItemSkeleton = () => {
  return (
    <TableRow className="relative text-[#475569] font-sans h-[48px]">
      <TableCell className="font-medium text-[#0F172A] w-[200px]">
        <div className="h-4 w-[180px] bg-gray-200 rounded animate-pulse" />
      </TableCell>
      <TableCell className="w-[250px]">
        <div className="h-4 w-[220px] bg-gray-200 rounded animate-pulse" />
      </TableCell>
      <TableCell className="w-[200px]">
        <div className="h-6 w-[180px] bg-gray-200 rounded animate-pulse" />
      </TableCell>
      <TableCell className="w-[180px]">
        <div className="h-4 w-[150px] bg-gray-200 rounded animate-pulse" />
      </TableCell>
      <TableCell className="w-[250px]">
        <div className="h-4 w-[220px] bg-gray-200 rounded animate-pulse" />
      </TableCell>
      <TableCell className="w-[48px]">
        <div className="h-8 w-8 bg-gray-200 rounded-full animate-pulse" />
      </TableCell>
    </TableRow>
  );
};

export default UsersListItemSkeleton;
