import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import { useCallback, useEffect, useState } from "react";
import {
  Edge,
  MarkerType,
  Node,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { getDownstreamDependenciesApi } from "../../../../api/cc-variables.api";
import {
  getLayoutedElementsDownstreamGraph,
  getStepPhaseMetaInfo,
  updateNodesAndEdgesDownstreamDeps,
} from "../../utils";
import {
  DownstreamDepsModalDefaultNodeProps,
  DownstreamDepsModalEdgeProps,
  GraphColors,
} from "../../types";
import {
  DOWNSTREAM_NODE_DIMENSIONS,
  INIT_NODE_POSITION,
} from "../../constants";
import handleRequestError from "../../../../utils/handleRequestError";
import { selectEventMessages } from "../../../../store/slices/computationMessages/slice";

type Props = {
  campaignId: string;
  stepId: string;
  ccItemKey: string;
};

const useGetNodesAndEdges = ({ campaignId, stepId, ccItemKey }: Props) => {
  const { companyId } = useParams();
  const initialNodeId = `${ccItemKey}_${stepId}`;
  const messageApi = useSelector(selectMessageApi);
  const eventMessages = useSelector(selectEventMessages);
  const [isInitialized, setIsInitialized] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { width, height } = DOWNSTREAM_NODE_DIMENSIONS;

  useEffect(() => {
    if (campaignId && stepId && ccItemKey) {
      getNodesAndEdges(campaignId, stepId, ccItemKey);
    }
  }, [campaignId, stepId, ccItemKey]);

  useEffect(() => {
    // TODO update edges override style if override changed from null to value in cc-item
    // TODO (easy to check when cc-item edit pane will be added - save or remove override and change dotted edge to default)
    if (eventMessages.length === 0) return;

    let hasUpdates = false;
    const updatedNodes = nodes.map((node) => {
      const msg = eventMessages.find(
        ({ item }) => `${item?.id.key}_${item?.id.stepId}` === node.id,
      );
      if (!msg?.item) return node;

      hasUpdates = true;

      return {
        ...node,
        data: {
          ...node.data,
          ccItem: msg.item,
        },
      };
    });

    if (hasUpdates) {
      setNodes(updatedNodes);
    }
  }, [eventMessages]);

  const getNodesAndEdges = async (
    campaignId: string,
    stepId: string,
    key: string,
  ) => {
    try {
      const { data } = await getDownstreamDependenciesApi({
        campaignId,
        stepId,
        key,
      });

      const { edges, nodes, metaInfo } = data;
      const stepPhaseMetaInfoData = getStepPhaseMetaInfo(metaInfo);

      const checkHasOverride = (nodeId: string): boolean => {
        const node = nodes.find((node) => node.id === nodeId);

        if (node) {
          return !!node.data.resultOvr;
        } else {
          return false;
        }
      };

      const processedEdges: Edge<DownstreamDepsModalEdgeProps>[] = edges.map(
        ({ id, targetNodeId, sourceNodeId }) => {
          const hasOverride = checkHasOverride(sourceNodeId);

          return {
            id: id,
            data: {
              hasSourceNodeOverride: hasOverride,
            },
            source: targetNodeId,
            target: sourceNodeId,
            type: "simplebezier",
            focusable: false,
            style: {
              pointerEvents: "none",
              strokeWidth: 1,
              strokeDasharray: hasOverride ? "5 5" : undefined,
              stroke: hasOverride ? GraphColors.OVERRIDE : GraphColors.DEFAULT,
            },
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 15,
              height: 15,
              color: hasOverride ? GraphColors.OVERRIDE : GraphColors.DEFAULT,
              strokeWidth: 1,
            },
          };
        },
      );

      const processedNodes: Node<DownstreamDepsModalDefaultNodeProps>[] =
        nodes.map(({ id, data }) => {
          const { stepId } = data.id;
          const { phaseId, stepName, phaseName } =
            stepPhaseMetaInfoData[stepId];

          return {
            id,
            data: {
              ccItem: data,
              phaseId,
              companyId,
              phaseName,
              stepName,
            },
            position: INIT_NODE_POSITION,
            draggable: false,
            selectable: true,
            style: {
              width,
              height,
              padding: 0,
              borderColor: "#475569",
            },
          };
        });

      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElementsDownstreamGraph({
          nodes: processedNodes,
          edges: processedEdges,
          direction: "LR",
        });

      const { updatedNodes, updatedEdges } = updateNodesAndEdgesDownstreamDeps({
        nodeId: initialNodeId,
        nodes: layoutedNodes,
        edges: layoutedEdges,
      });

      setEdges(updatedEdges);
      setNodes(updatedNodes);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsInitialized(true);
    }
  };

  const onNodeClick = useCallback(
    (_: any, node: Node) => {
      const { updatedNodes, updatedEdges } = updateNodesAndEdgesDownstreamDeps({
        nodeId: node.id,
        nodes,
        edges,
      });

      setEdges(updatedEdges);
      setNodes(updatedNodes);
    },
    [edges, nodes, setNodes, setEdges],
  );

  return {
    edges,
    nodes,
    isInitialized,
    initialNodeId,
    onNodeClick,
    onNodesChange,
    onEdgesChange,
  };
};

export default useGetNodesAndEdges;
