import { UIAvailableTemplate } from "src/globalTypes";
import {
  DocumentTemplate,
  DocumentTemplateListItem,
  DocumentTemplateState,
  GetUITemplatesApiParams,
} from "src/types/docTemplates";
import axiosInstance from "src/utils/axios/axiosInstance";

export const getDocumentTemplatesApi = ({
  wordMatch,
  pageNumber,
  pageSize,
  states = "",
  classes = "",
  sortBy,
  orderBy,
}: {
  wordMatch?: string;
  pageSize: number;
  pageNumber: number;
  classes?: string;
  states?: string;
  sortBy?: string;
  orderBy?: string;
}) => {
  return axiosInstance.get<{
    items: DocumentTemplateListItem[];
    totalItems: number;
  }>(`/api/secured/document-template/list`, {
    params: {
      ...(wordMatch && { name: wordMatch }),
      pageNumber,
      pageSize,
      states,
      classes,
      sortBy,
      orderBy,
    },
  });
};

export const generateTemplateApi = ({
  content,
  templateId,
}: {
  content: string;
  templateId: string;
}) => {
  return axiosInstance.post<string>(
    `/api/secured/document-template/figmaHtml2Xsl/${templateId}`,
    content,
    {
      headers: {
        "Content-Type": "text/html",
      },
    },
  );
};

export const updateTemplateClassesApi = ({
  classes,
  templateId,
}: {
  classes: string[];
  templateId: number;
}) => {
  return axiosInstance.put(
    `/api/secured/document-template/${templateId}/classes`,
    classes,
  );
};

export const updateTemplateIncludeCampaignStructureApi = ({
  includeCampaignStructure,
  templateId,
}: {
  includeCampaignStructure: boolean;
  templateId: number;
}) => {
  return axiosInstance.put(
    `/api/secured/document-template/include-campaign-structure/${templateId}`,
    { includeCampaignStructure },
  );
};

export const deleteTemplateApi = ({ templateId }: { templateId: number }) => {
  return axiosInstance.delete(`/api/secured/document-template/${templateId}`);
};

export const createTemplateApi = (payload: {
  name: string;
  state: DocumentTemplateState;
  classes: string[];
  content: string;
}) => {
  return axiosInstance.post<DocumentTemplate>(
    `/api/secured/document-template/`,
    payload,
  );
};

export const updateTemplateApi = ({
  id,
  ...payload
}: {
  id: number;
  name: string;
  state: DocumentTemplateState;
  classes: string[];
  content: string;
  companyId?: number;
  includeCampaignStructure: boolean | null;
}) => {
  return axiosInstance.put<DocumentTemplate>(
    `/api/secured/document-template/${id}`,
    payload,
  );
};

export const getTemplateByIdApi = ({ id }: { id: number }) => {
  return axiosInstance.get<DocumentTemplate>(
    `/api/secured/document-template/${id}`,
  );
};

export const getUiAvailableTemplatesApi = ({
  name,
  states,
  classes,
  stepId,
}: GetUITemplatesApiParams) => {
  return axiosInstance.get<UIAvailableTemplate[]>(
    `/api/secured/document-template/ui-available-json`,
    {
      params: {
        name,
        states: states?.length ? states?.join(",") : "",
        classes: classes?.length ? classes?.join(",") : "",
        stepId,
      },
    },
  );
};

export const uploadPackageTemplateApi = ({
  formData,
  templateId,
}: {
  formData: FormData;
  templateId: number;
}) => {
  return axiosInstance.post<DocumentTemplate>(
    `/api/secured/document-template/import/${templateId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};
