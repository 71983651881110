import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { ClockIcon } from "@heroicons/react/16/solid";
import { Modal, Tooltip, UploadFile, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { ComputedIcon, DeleteIcon, SolidErrorIcon } from "../../common/Icons";
import { Button } from "../ui/button";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { AlertCircle, XIcon } from "lucide-react";
import handleRequestError from "../../../utils/handleRequestError";

type UploadStatus = "on_hold" | "in_progress" | "finished";

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  uploadAsset: (file: File) => Promise<void>;
};

const MAX_FILE_SIZE = 100 * 1024 * 1024;

const UploadAssetsModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  uploadAsset,
}) => {
  const messageApi = useSelector(selectMessageApi);
  const [fileList, setFileList] = useState<Array<UploadFile<File>>>([]);
  const [loadingFileId, setLoadingFileId] = useState<string | null>(null);
  const [loadedFiles, setLoadedFiles] = useState<string[]>([]);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>("on_hold");
  const [loadedWithErrorFiles, setLoadedWithErrorFiles] = useState<
    { id: string; error: string }[]
  >([]);
  const [oversizedFiles, setOversizedFiles] = useState<string[]>([]);
  const isModalClosedRef = useRef(false);
  const isFileListNotEmpty = fileList.length > 0;

  useEffect(() => {
    return () => {
      isModalClosedRef.current = true;
    };
  }, []);

  const uploadProps: UploadProps<File> = {
    name: "file",
    multiple: true,
    showUploadList: false,
    fileList,
    onChange(info) {
      const newFiles = info.fileList.filter(
        (item) => !fileList.find((el) => el.name === item.name),
      );

      const oversizedFileNames = newFiles
        .filter((item) => item.size && item.size > MAX_FILE_SIZE)
        .map((item) => item.name);

      setOversizedFiles(oversizedFileNames);

      const filteredFiles = [
        ...fileList,
        ...newFiles.filter((item) => !(item.size && item.size > MAX_FILE_SIZE)),
      ];

      setFileList(filteredFiles);
    },
    async beforeUpload() {
      return false;
    },
  };

  const onUploadAssets = async () => {
    setUploadStatus("in_progress");

    for (const { name, uid, originFileObj } of fileList) {
      if (isModalClosedRef.current) {
        messageApi.warning("Upload process was cancelled!");
        break;
      }

      setLoadingFileId(uid);

      if (originFileObj) {
        // const formData = new FormData();
        // formData.append("file", originFileObj);

        try {
          await uploadAsset(originFileObj);

          setLoadedFiles((ids) => [...ids, uid]);
          messageApi.success(`${name} successfully uploaded`);
        } catch (e: any) {
          const { message } = handleRequestError(e);

          messageApi.error(`Failed to load ${name}: ${message}`);
          console.error(e);

          setLoadedWithErrorFiles((ids) => [
            ...ids,
            { id: uid, error: message },
          ]);
        } finally {
          setLoadingFileId(null);
        }
      }
    }

    setUploadStatus("finished");
  };

  const handleRemove = (file: UploadFile) => {
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
    messageApi.success(`${file.name} removed`);
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const onClearData = () => {
    setFileList([]);
    setLoadedFiles([]);
    setLoadedWithErrorFiles([]);
    setLoadingFileId(null);
    setUploadStatus("on_hold");
  };

  const fileIcon = (file: UploadFile<File>) => {
    const id = file.uid;

    if (loadingFileId === id) {
      return <LoadingOutlined />;
    }

    if (loadedFiles.includes(id)) {
      return <ComputedIcon className="text-[#16A34A]" />;
    }

    const errorData = loadedWithErrorFiles.find((err) => err.id === id);

    if (errorData) {
      return (
        <Tooltip title={errorData.error}>
          <span>
            <SolidErrorIcon className="text-red-600 cursor-pointer" />
          </span>
        </Tooltip>
      );
    }

    if (uploadStatus === "in_progress") {
      return <ClockIcon className="text-blue-600" />;
    }

    return (
      <DeleteIcon
        className="!text-red-600 cursor-pointer"
        onClick={() => handleRemove(file)}
      />
    );
  };

  return (
    <Modal
      title="Upload Assets"
      open={isModalOpen}
      width={600}
      onCancel={onCancel}
      footer={() => {
        return (
          <div className="flex justify-end items-center gap-[12px]">
            <div className="flex gap-[12px]">
              <Button
                onClick={onCancel}
                variant="primaryOutline"
                className="rounded-full self-start"
                size="sm"
              >
                Cancel
              </Button>

              {uploadStatus !== "finished" && (
                <Button
                  onClick={onUploadAssets}
                  className="rounded-full self-start px-5"
                  loading={uploadStatus === "in_progress"}
                  disabled={
                    !isFileListNotEmpty || uploadStatus === "in_progress"
                  }
                  size="sm"
                >
                  Upload
                </Button>
              )}

              {uploadStatus === "finished" && (
                <Button
                  onClick={onClearData}
                  className="rounded-full self-start px-5"
                  size="sm"
                >
                  Clear and Upload Again
                </Button>
              )}
            </div>
          </div>
        );
      }}
    >
      <div className="min-h-[440px] max-h-[540px] flex flex-col gap-3 overflow-hidden">
        {uploadStatus === "on_hold" && (
          <div className="h-[200px] flex-shrink-0">
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="font-sans font-bold text-[#475569] text-[14px]">
                Click or drag file to this area to upload
              </p>
              <p className="font-sans font-normal text-[#475569] text-[14px]">
                Support for a single or bulk upload.
              </p>
            </Dragger>
          </div>
        )}

        {oversizedFiles.length > 0 && (
          <Alert variant="destructive" className="flex-shrink-0">
            <button
              className="absolute top-2 right-2 hover:bg-muted/50 rounded"
              onClick={() => setOversizedFiles([])}
            >
              <XIcon className="h-4 w-4 text-gray-500" />
            </button>
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              The following files are larger than 100 MB and were not added:
              <ul>
                {oversizedFiles.map((fileName, index) => (
                  <li key={index}>{fileName}</li>
                ))}
              </ul>
            </AlertDescription>
          </Alert>
        )}

        <div className="flex flex-col gap-[8px] min-h-0 flex-1">
          {isFileListNotEmpty && (
            <>
              <div className="flex gap-[8px] font-sans font-semibold text-[#475569] text-[14px] flex-shrink-0">
                {uploadStatus === "on_hold" ? (
                  <>
                    <span>Ready to upload assets:</span>
                    <span>{fileList.length}</span>
                  </>
                ) : (
                  <>
                    <span>Uploaded assets:</span>
                    <span>{`${loadedFiles.length}/${fileList.length}`}</span>
                    {uploadStatus === "in_progress" && <LoadingOutlined />}
                  </>
                )}
              </div>

              <div className="overflow-y-auto flex-1">
                <ul className="list-none p-0">
                  {fileList.map((file, index) => (
                    <li
                      key={file.uid}
                      className="flex items-center justify-between px-[12px] py-[6px] rounded-[6px] mb-[8px] hover:bg-muted/50 transition-colors"
                    >
                      <div className="font-sans font-normal text-[#475569] text-[14px]">
                        <span>{`${index + 1}. ${file.name}`}</span>
                      </div>
                      <div className="size-[20px] flex-shrink-0">
                        {fileIcon(file)}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UploadAssetsModal;
