import React, { FC, useState } from "react";
import { Form, Input, Modal } from "antd";
import customizeFormLabels from "../../../utils/customizeFormLabels";
import { minMax, required } from "../../../utils/validations";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { CloneSecurityGroupFormValues } from "../types";
import { clonePermissionGroupApi } from "../../../api/permissions-group.api";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useNavigate } from "react-router-dom";

type Props = {
  originalTitle: string;
  id: number;
  isModalOpen: boolean;
  closeModal: () => void;
  hasPermGroupUpdateRole: boolean;
};

const CloneSecurityGroupModal: FC<Props> = ({
  originalTitle,
  id,
  isModalOpen,
  closeModal,
  hasPermGroupUpdateRole,
}) => {
  const messageApi = useSelector(selectMessageApi);
  const navigate = useNavigate();
  const [form] = Form.useForm<CloneSecurityGroupFormValues>();
  const [loading, setLoading] = useState(false);

  const onClone = async ({ title }: CloneSecurityGroupFormValues) => {
    try {
      setLoading(true);

      const { data } = await clonePermissionGroupApi({
        permissionGroupId: id,
        title,
      });

      messageApi.success("The security group has been successfully cloned");

      if (hasPermGroupUpdateRole) {
        navigate(`/security-groups/edit/${data.permissionsGroup.id}`);
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: CloneSecurityGroupFormValues) => {
      await onClone(values);
    },
  });

  return (
    <Modal
      title="Clone Security Group"
      open={isModalOpen}
      onOk={submitForm}
      okText="Clone"
      okButtonProps={{ loading }}
      width={500}
      onCancel={closeModal}
    >
      <Form
        requiredMark={customizeFormLabels}
        form={form}
        layout="vertical"
        name="clone_security_group_form"
        initialValues={{
          title: originalTitle,
        }}
      >
        <Form.Item
          name="title"
          label="Title"
          tooltip="Please enter a value between 2 and 255 characters."
          rules={[required(true), minMax({ min: 2, max: 255, text: "Title" })]}
        >
          <Input placeholder="Enter unique title" className="h-[48px]" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CloneSecurityGroupModal;
