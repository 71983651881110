import React from "react";
import { useParams } from "react-router-dom";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { TNavigationItem } from "../../globalTypes";
import Navigation from "../common/Navigation";
import useRedirectToFirstChildOutlet from "../../hooks/navigation/useRedirectToFirstChildOutlet";

const CompanyDetailsTabs = () => {
  const { companyId } = useParams();
  const { hasCompanyUsersReadRole, hasCompanyMicrositeReadRole } =
    useUserHasPermission({ companyId });

  const navigationItems = [
    hasCompanyUsersReadRole && {
      path: "users",
      title: "Users",
    },
    hasCompanyMicrositeReadRole && {
      path: "microsite",
      title: "Customer Site",
    },
  ].filter(Boolean) as TNavigationItem[];

  useRedirectToFirstChildOutlet({ navigationItems });

  if (navigationItems.length === 0) {
    return null;
  }

  return <Navigation items={navigationItems} replace={true} />;
};

export default CompanyDetailsTabs;
