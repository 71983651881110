import { FC } from "react";
import { useDispatch } from "react-redux";
import { ALL_COMPANIES_ID } from "src/pages/campaigns/useCampaignsList";
import {
  setCurrentCompanyId,
  TCompany,
  useCompaniesList,
} from "../../store/slices/companiesSlice";
import { AppDispatch } from "src/store/store";
import { CustomSelect } from "../common/ui/CustomSelect";

export type TChooseCompanySelectOption = {
  value: number | string;
  label: string;
  company: TCompany | null;
};
type TCompanySelectorProps = {
  companyId: number | null;
  onChanged: (companyId: string) => void;
};
const CompanySelector: FC<TCompanySelectorProps> = ({
  companyId,
  onChanged,
}) => {
  const companies = useCompaniesList();
  const dispatch = useDispatch<AppDispatch>();

  const handleChangeCompany = (companyId: string) => {
    if (companyId !== ALL_COMPANIES_ID) {
      dispatch(setCurrentCompanyId(Number(companyId)));
    }
    onChanged(companyId);
  };

  const options: TChooseCompanySelectOption[] = [
    { value: ALL_COMPANIES_ID, label: "All Companies", company: null },
    ...companies.map((company) => ({
      value: company.id,
      label: company.name,
      company,
    })),
  ];

  return (
    <CustomSelect
      options={options.map((option) => ({
        value: String(option.value),
        label: option.label,
      }))}
      value={companyId?.toString() || ALL_COMPANIES_ID}
      onChange={handleChangeCompany}
      label="Select Company"
      className="w-[180px]"
    />
  );
};
export default CompanySelector;
