import { TLabelLanguage, TLabels } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

export const getGlobalLabelsApi = ({ lang }: { lang: TLabelLanguage }) => {
  return axiosInstance.get<TLabels>(`/api/secured/i18n/${lang}/`);
};

export const createGlobalLabelApi = ({
  lang,
  key,
  label,
}: {
  label: string;
  key: string;
  lang: TLabelLanguage;
}) => {
  return axiosInstance.post<string>(`/api/secured/i18n/${lang}/`, {
    id: {
      key,
    },
    label,
  });
};

export const updateGlobalLabelApi = ({
  lang,
  key,
  label,
}: {
  label: string;
  key: string;
  lang: TLabelLanguage;
}) => {
  return axiosInstance.put<string>(`/api/secured/i18n/${lang}/${key}/`, {
    id: {
      key,
    },
    label,
  });
};

export const bulkDeleteGlobalLabelsApi = ({
  keys,
  lang,
}: {
  keys: string[];
  lang: TLabelLanguage;
}) => {
  return axiosInstance.delete<string>(`/api/secured/i18n/${lang}/bulk/`, {
    data: keys,
  });
};
