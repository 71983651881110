import { createMicrositeApi } from "../../../api/microsite.api";
import handleRequestError from "../../../utils/handleRequestError";
import { useState } from "react";
import { AppDispatch } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { setCompanyMicroSiteData } from "../../../store/slices/companyStorageSlice";
import { useParams } from "react-router-dom";
import { MicroSiteFormValues } from "../types";

const useCreateMicroSite = () => {
  const dispatch: AppDispatch = useDispatch();
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);
  const messageApi = useSelector(selectMessageApi);

  const handleCreateMicroSite = async (values: MicroSiteFormValues) => {
    if (!companyId) return;

    try {
      setLoading(true);

      const { subDomain, siteContext, enableHttps } = values;
      const { data } = await createMicrositeApi({
        subDomain,
        siteContext,
        enableHttps,
        companyId,
      });

      dispatch(setCompanyMicroSiteData(data));

      messageApi.success("Micro site is in pending validation state");
    } catch (error: any) {
      const customError = handleRequestError(error);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleCreateMicroSite,
  };
};

export default useCreateMicroSite;
