import React from "react";
import { Table, TableBody } from "../../common/ui/table";
import { TableHeader } from "../../table/Header";
import UserCompanySecurityGroupsListItem from "../UserCompanySecurityGroupsListItem/UserCompanySecurityGroupsListItem";
import useGetData from "./useGetData.hook";
import { Skeleton } from "antd";
import { Outlet, useOutletContext } from "react-router-dom";
import AddCompanySecurityGroup from "../AddCompanySecurityGroup/AddCompanySecurityGroup";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { UpdateUserCompanySecurityGroupList } from "../../../types/securityGroups";

const UserCompanyPermissions = () => {
  const {
    error,
    userCompanySecurityGroups,
    updateGroupsList,
    isInitialized,
    assignedCompanies,
  } = useGetData();
  const { hasUserCompaniesAssignRole } = useUserHasPermission({});

  const tableHeaderItems = [
    {
      title: "Company",
      className: "font-sans w-full text-[#475569]",
    },
    {
      title: "Groups",
      className: "font-sans w-[140px] text-[#475569]",
    },
    {
      title: "Overrides",
      className: "font-sans w-[80px] text-[#475569] text-center",
    },
    null,
  ];

  return (
    <div className="flex justify-between">
      <div className="flex flex-col w-[31%] gap-[12px] mt-[24px]">
        <div className="font-bold text-[16px] text-[#475569]">Companies</div>
        {hasUserCompaniesAssignRole && (
          <AddCompanySecurityGroup
            updateGroupsList={updateGroupsList}
            assignedCompanies={assignedCompanies}
          />
        )}

        {isInitialized ? (
          <>
            {userCompanySecurityGroups.length ? (
              <div className="rounded-md border w-full mt-[6px]">
                <Table>
                  <TableHeader items={tableHeaderItems} />
                  <TableBody className="font-sans">
                    {userCompanySecurityGroups.map((item) => (
                      <UserCompanySecurityGroupsListItem
                        key={item.id}
                        companySecurityGroup={item}
                        updateList={updateGroupsList}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <span>There are no groups yet</span>
            )}
            {error && <span className="text-red-600 text-xs">{error}</span>}
          </>
        ) : (
          <Skeleton active />
        )}
      </div>

      <div className="w-[1px] bg-[#E2E8F0]" />

      <div className="w-[67%] mt-[24px]">
        {/*UserCompanyPermissionData rendered here*/}
        <Outlet context={updateGroupsList} />
      </div>
    </div>
  );
};

export default UserCompanyPermissions;

export function useUpdateGroupsList() {
  return useOutletContext<UpdateUserCompanySecurityGroupList>();
}
