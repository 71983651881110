import { CampaignsListAdditionalParams } from "./useCampaignsList";

import { TCampaign } from "src/store/slices/campaignsSlice";
import { SortingDirection } from "src/types/docTemplates";
import { CampaignsListRequestParams } from "./useCampaignsList";
import { stringifyObjectValues } from "src/utils/stringifyObjectValues";
import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { TCampaignListOptionType } from "src/globalTypes";

export const useCampaignsSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const requestParams = useMemo<CampaignsListRequestParams>(() => {
    const page = searchParams.get("page");
    const pageSize = searchParams.get("pageSize");
    const companyId = searchParams.get("companyId");
    const campaignType = searchParams.get(
      "campaignType",
    ) as TCampaignListOptionType;
    const archive = searchParams.get("archive");
    const sortBy = searchParams.get("sortBy");
    const orderBy = searchParams.get("orderBy");

    return {
      companyId: companyId ? Number(companyId) : null,
      campaignType,
      page: Number(page),
      pageSize: Number(pageSize),
      archive: archive === "true" ? true : archive === "false" ? false : "all",
      sortBy: sortBy as keyof TCampaign,
      orderBy: orderBy as SortingDirection,
    };
  }, [searchParams]);

  const additionalParams = useMemo(() => {
    const backUrl = searchParams.get("backUrl");
    if (!backUrl) {
      return {};
    }
    return {
      backUrl: decodeURIComponent(backUrl),
    };
  }, [searchParams]);


  const updateSearchParams = (
    requestParams: CampaignsListRequestParams,
    additionalParams?: CampaignsListAdditionalParams,
  ) => {
    const stringifiedParams = stringifyObjectValues<
      CampaignsListRequestParams & CampaignsListAdditionalParams
    >({
      ...requestParams,
      ...additionalParams,
    });
    setSearchParams(stringifiedParams);
  };

  return { requestParams, updateSearchParams, additionalParams };
};
