import { Spin, Switch } from "antd";
import { FC, useEffect, useState } from "react";
import {
  getCompanyPublicFileDataApi,
  getCompanyPublicFilesListApi,
} from "../../api/company-storage.api";
import { TStorageFileItem } from "../../globalTypes";
import { getMessageApi } from "../../store/slices/appSlice";
import handleRequestError from "../../utils/handleRequestError";
import SelectStorageItems from "./SelectStorageItems/SelectStorageItems";
import { useParams } from "react-router-dom";
import { getCompanyApi } from "../../api/companies.api";

type SelectAssetProps = {
  filePath: string;
  filePublicUrl: string;
  fileName: string;
};
export type SelectAssetType = (props: SelectAssetProps) => void;
type Props = {
  onSelect: SelectAssetType;
};
const SelectAssetFromPublicCompanyStorage: FC<Props> = ({ onSelect }) => {
  const { companyId } = useParams();
  const messageApi = getMessageApi();
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [contextPathPrefix, setContextPathPrefix] = useState("");

  useEffect(() => {
    if (companyId && isChecked && !contextPathPrefix) {
      getCompanyData(companyId);
    }
  }, [isChecked, companyId]);

  const getCompanyData = async (companyId: string) => {
    try {
      setIsLoading(true);

      const { data } = await getCompanyApi({ companyId });
      setContextPathPrefix(`/${data.name}/`);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const getFolderFiles = async (path?: string): Promise<TStorageFileItem[]> => {
    const { data } = await getCompanyPublicFilesListApi({
      path: path || contextPathPrefix,
    });
    return data;
  };
  const onSelectItem = async (path: string) => {
    try {
      setIsLoading(true);
      const { data: filePublicUrl } = await getCompanyPublicFileDataApi({
        path,
      });

      const fileName = path.split("/").pop() as string;

      onSelect({ filePublicUrl, filePath: path, fileName });
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Spin spinning={isLoading}>
      <div className="h-[36px] w-full flex items-center gap-[16px] mt-[16px]">
        <div className="w-auto flex items-center flex-shrink-0 gap-[8px]">
          <span className="font-sans font-bold text-[#475569] text-[14px]">
            Select asset
          </span>
          <Switch size="small" checked={isChecked} onChange={setIsChecked} />
        </div>
        <div
          className="flex-grow min-w-0"
          style={{ display: isChecked ? "block" : "none" }}
        >
          {contextPathPrefix && (
            <SelectStorageItems
              selectType="FILE"
              pathPrefix={contextPathPrefix}
              getFolderFiles={getFolderFiles}
              onSelect={onSelectItem}
            />
          )}
        </div>
      </div>
    </Spin>
  );
};
export default SelectAssetFromPublicCompanyStorage;
