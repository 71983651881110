import React, { FC } from "react";
import { Button } from "../../common/ui/button";
import { Icons } from "../../common/Icons";
import { useParams } from "react-router-dom";

type Props = {
  handleSubmitForm: () => Promise<void>;
  isFetching: boolean;
  isInitialized: boolean;
  isSuperUserGroup: boolean;
  navigateToGroupList: () => void;
};

const SecurityGroupDataHeader: FC<Props> = ({
  handleSubmitForm,
  isSuperUserGroup,
  isInitialized,
  isFetching,
  navigateToGroupList,
}) => {
  const { securityGroupId } = useParams();
  const headerTitle = securityGroupId
    ? "Edit Security Group"
    : "Create Security Group";

  return (
    <div className="flex items-center justify-between">
      <div className="flex gap-[16px] items-center">
        <Button
          variant={"ghost"}
          icon={Icons.back}
          onClick={navigateToGroupList}
          className="p-[12px] h-auto"
        />
        <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal">
          {headerTitle}
        </h1>
      </div>

      {!isSuperUserGroup && (
        <Button
          className="rounded-full"
          loading={isFetching}
          disabled={isFetching || !isInitialized}
          onClick={handleSubmitForm}
        >
          Save Group
        </Button>
      )}
    </div>
  );
};

export default SecurityGroupDataHeader;
