import React, { FC } from "react";
import { TAsset } from "../../../globalTypes";
import { Table, TableBody } from "../../common/ui/table";
import { TableHeader } from "../../table/Header";
import AssetListItem from "./AssetListItem";
import { EmptyResult } from "../../common/EmptyResult";
import { useParams } from "react-router-dom";
import { TABLE_HEADER_ITEMS } from "../constants";

type Props = {
  assets: TAsset[];
  loadAndSetAssets: () => Promise<void>;
};

const AssetsList: FC<Props> = ({ assets, loadAndSetAssets }) => {
  const { campaignId } = useParams();

  if (!campaignId) {
    return (
      <span className="text-red-600">Unable to get [campaignId] from URL</span>
    );
  }

  if (assets.length === 0) {
    return <EmptyResult message="There are no assets in this step." />;
  }

  return (
    <div className="rounded-md border w-full mx-auto">
      <Table>
        <TableHeader items={TABLE_HEADER_ITEMS} />
        <TableBody>
          {assets.map((asset) => (
            <AssetListItem
              key={asset.id}
              asset={asset}
              loadAndSetAssets={loadAndSetAssets}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AssetsList;
