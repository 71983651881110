import { type ClassValue, clsx } from "clsx";
import { SortingDirection } from "src/types/docTemplates";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getNextOrder = (currentOrder: SortingDirection | undefined) => {
  switch (currentOrder) {
    case SortingDirection.ASC:
      return SortingDirection.DESC;
    case SortingDirection.DESC:
      return undefined;
    default:
      return SortingDirection.ASC;
  }
};

export const isValidInteger = (value: any): boolean => {
  if (typeof value === "number" && Number.isInteger(value)) {
    return true;
  }
  // check if string contains only numbers
  return typeof value === "string" && /^\d+$/.test(value);
};
