import React, { FC } from "react";
import ReactFlow, {
  Background,
  ConnectionLineType,
  ControlButton,
  Controls,
  Edge,
  MiniMap,
  Node,
  NodeTypes,
  useReactFlow,
} from "reactflow";
import { ArrowsPointingInIcon } from "../../../common/Icons";
import CustomMarkers from "../CustomMarkers";

type Props = {
  isInitialized: boolean;
  nodes: Node<any>[];
  edges: Edge<any>[];
  initialNodeId: string;
  nodeTypes: NodeTypes;
  onNodeClick: any;
  onNodesChange: any;
  onEdgesChange: any;
};

const DownstreamGraph: FC<Props> = ({
  isInitialized,
  edges,
  nodes,
  initialNodeId,
  nodeTypes,
  onNodeClick,
  onNodesChange,
  onEdgesChange,
}) => {
  const { fitView } = useReactFlow();

  const onTargetNodeZoom = (nodeId: string) => {
    try {
      fitView({
        nodes: [{ id: nodeId }],
        minZoom: 1,
        maxZoom: 2,
        padding: 0.1,
        duration: 800,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {isInitialized && (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          connectionLineType={ConnectionLineType.SmoothStep}
          fitView
          onInit={() => onTargetNodeZoom(initialNodeId)}
          minZoom={0.1}
          onNodeClick={onNodeClick}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          proOptions={{ hideAttribution: true }}
          onlyRenderVisibleElements={true}
        >
          <CustomMarkers />
          <Controls showInteractive={false}>
            <ControlButton onClick={() => onTargetNodeZoom(initialNodeId)}>
              <ArrowsPointingInIcon />
            </ControlButton>
          </Controls>
          <Background />
          <MiniMap />
        </ReactFlow>
      )}
    </>
  );
};

export default DownstreamGraph;
