import React, { FC } from "react";
import SecurityGroupForm from "./SecurityGroupForm";
import PermissionsTree from "./PermissionsTree";
import SkeletonList from "../../common/SkeletonList";
import { useSelector } from "react-redux";
import { selectSystemPermissions } from "../../../store/slices/appSlice";
import { FormInstance } from "antd";
import { SecurityGroupFormValues, SetCheckedPermissions } from "../types";
import { UserPermissions } from "../../../store/slices/userData/constants";

type Props = {
  isInitialized: boolean;
  isSuperUserGroup: boolean;
  form: FormInstance<SecurityGroupFormValues>;
  initialValues?: SecurityGroupFormValues;
  checkedGlobalPermissions: UserPermissions[];
  checkedCompanyPermissions: UserPermissions[];
  setCheckedGlobalPermissions: SetCheckedPermissions;
  setCheckedCompanyPermissions: SetCheckedPermissions;
};

const SecurityGroupData: FC<Props> = ({
  isInitialized,
  isSuperUserGroup,
  form,
  initialValues,
  checkedGlobalPermissions,
  checkedCompanyPermissions,
  setCheckedGlobalPermissions,
  setCheckedCompanyPermissions,
}) => {
  const permissionsTree = useSelector(selectSystemPermissions);

  if (!isInitialized) {
    return <SkeletonList quantity={3} />;
  }

  if (!permissionsTree) {
    return (
      <span className="text-red-600">Permissions are not initialized</span>
    );
  }

  return (
    <div className="flex gap-[48px]">
      <SecurityGroupForm
        form={form}
        initialValues={initialValues}
        isSuperUserGroup={isSuperUserGroup}
      />
      <div className="flex flex-col gap-[24px]">
        <PermissionsTree
          permissionsTree={permissionsTree.globalLevel}
          setCheckedKeys={setCheckedGlobalPermissions}
          checkedKeys={checkedGlobalPermissions}
          isSuperUserGroup={isSuperUserGroup}
          level="GLOBAL"
        />
        <PermissionsTree
          permissionsTree={permissionsTree.companyLevel}
          setCheckedKeys={setCheckedCompanyPermissions}
          checkedKeys={checkedCompanyPermissions}
          isSuperUserGroup={isSuperUserGroup}
          level="COMPANY"
        />
      </div>
    </div>
  );
};

export default SecurityGroupData;
