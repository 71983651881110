import React from "react";

export enum DocumentTemplateState {
  DRAFT = "DRAFT",
  SYSTEM = "SYSTEM",
  PUBLISHED = "PUBLISHED",
}

export enum XSLTEditorEnum {
  CODE = "Source Code",
  SETTINGS = "Settings",
  PREVIEW = "Preview",
}

export type DocumentTemplate = {
  id: number;
  name: string;
  state: DocumentTemplateState;
  mediaType: string;
  classes: string[];
  includeCampaignStructure: null | boolean;
  keys: string[] | null;
  content: string;
};

export type DocumentTemplateListItem = Omit<DocumentTemplate, "content">;

export type GetUITemplatesApiParams = {
  name?: string;
  states: DocumentTemplateState[];
  classes: string[];
  stepId?: number;
};

export type XSLTEditorTabItem = {
  onSelect: React.Dispatch<React.SetStateAction<XSLTEditorEnum>>;
  title: XSLTEditorEnum;
  active: boolean;
};

export type DocumentTemplateField = keyof DocumentTemplate;

export enum SortingDirection {
  ASC = "asc",
  DESC = "desc",
}
