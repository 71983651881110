import { Tooltip } from "antd";
import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Icons } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import { selectMessageApi } from "src/store/slices/appSlice";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RESULT_CONTENT_MAX_LENGTH } from "../../constants";

type Props = {
  hasOverride: boolean;
  supportOverride: boolean;
  value: string;
  onClickEdit: () => void;
};

const GridItemResult: React.FC<Props> = ({
  value,
  onClickEdit,
  supportOverride,
  hasOverride,
}) => {
  const { companyId } = useParams();
  const [copied, setCopied] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const [animate, setAnimate] = useState(false);
  const { hasCampaignCcOverrideRole } = useUserHasPermission({ companyId });
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // skip highlight animation on first render
    }

    setAnimate(true);
    const timeout = setTimeout(() => setAnimate(false), 2500);
    return () => clearTimeout(timeout);
  }, [value]);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  const content = useMemo(() => {
    const truncatedValue =
      value.length > RESULT_CONTENT_MAX_LENGTH
        ? `${value.slice(0, RESULT_CONTENT_MAX_LENGTH)}...`
        : value;

    return value.length > RESULT_CONTENT_MAX_LENGTH ? (
      <Tooltip
        overlayStyle={{
          maxWidth: "30vW",
          maxHeight: "30vh",
          overflowY: "scroll",
        }}
        title={value}
        overlayInnerStyle={{
          width: "100%",
          height: "100%",
          maxHeight: "30vh",
          overflowY: "scroll",
          whiteSpace: "pre-wrap",
          fontSize: "12px",
        }}
      >
        {truncatedValue}
      </Tooltip>
    ) : (
      value
    );
  }, [value]);

  const handleCopy = useCallback(() => {
    if (!navigator.clipboard) {
      messageApi.error("Clipboard not supported");
      return;
    }
    navigator.clipboard.writeText(value || "").then(() => {
      messageApi.success("Copied to clipboard");
      setCopied(true);
    });
  }, [value, messageApi]);

  return (
    <div className="flex flex-col p-2">
      {hasOverride && (
        <Tooltip title="Override">
          <div className="absolute top-[-5px] right-[-10px] w-[30px] h-[20px] bg-gradient-to-t from-white to-yellow-500 rotate-45" />
        </Tooltip>
      )}
      <span
        className={clsx("whitespace-pre-line", {
          "animate-highlight-cc-updated": animate,
        })}
      >
        {content}
      </span>
      <div className="pt-1">
        {hasCampaignCcOverrideRole && supportOverride && (
          <Button
            onClick={onClickEdit}
            icon={Icons.edit}
            size="sm"
            variant="primaryGhost"
            className="rounded-full"
          >
            Edit
          </Button>
        )}
        <Button
          onClick={handleCopy}
          iconClassName="size-5"
          icon={copied ? Icons.checkmark : Icons.copy}
          size="sm"
          variant="primaryGhost"
          className="rounded-full"
        >
          <span>{copied ? "Copied" : "Copy"}</span>
        </Button>
      </div>
    </div>
  );
};

export default GridItemResult;
