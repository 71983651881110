import React from "react";
import Storage from "../../dynamicStorage/Storage";
import { LoadFolderFiles, LoadRootFiles } from "../../dynamicStorage/types";
import { getGlobalPrivateFilesListApi } from "../../../../api/global-storage.api";
import useDynamicStorage from "../../dynamicStorage/hooks/useDynamicStorage.hook";
import { TFileTreeItem } from "../../../../globalTypes";
import ItemMenu from "./ItemMenu";

const GlobalPrivateStorage = () => {
  const ROOT_PATH = "/";

  const loadRootFiles: LoadRootFiles = async () => {
    const { data } = await getGlobalPrivateFilesListApi({
      path: ROOT_PATH,
    });

    return {
      rootFolderFiles: data,
      rootStoragePath: ROOT_PATH,
    };
  };

  const loadFolderFiles: LoadFolderFiles = async ({ path }) => {
    const { data } = await getGlobalPrivateFilesListApi({
      path,
    });

    return data;
  };

  const {
    setSelectedItem,
    selectedItem,
    treeData,
    setTreeData,
    getFolderFiles,
    isLoading,
    error,
    rootPath,
  } = useDynamicStorage({ loadRootFiles, loadFolderFiles });

  const renderMenu = ({
    isHovering,
    isSelected,
    item,
  }: {
    isHovering: boolean;
    isSelected: boolean;
    item: TFileTreeItem;
  }) => {
    return (
      <ItemMenu
        rootPath={rootPath}
        isHovering={isHovering}
        isSelected={isSelected}
        item={item}
        setTreeData={setTreeData}
        treeData={treeData}
      />
    );
  };

  return (
    <Storage
      setSelectedItem={setSelectedItem}
      getFolderFiles={getFolderFiles}
      isLoading={isLoading}
      error={error}
      treeData={treeData}
      selectedItem={selectedItem}
      renderMenu={renderMenu}
    />
  );
};

export default GlobalPrivateStorage;
