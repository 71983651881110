import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  loadCampaignDataThunk,
  selectCurrentCampaign,
  setCurrentCampaign,
} from "../../../store/slices/campaignsSlice";
import {
  selectCurrentPhase,
  selectPhasesList,
} from "../../../store/slices/phasesSlice";
import { AppDispatch } from "../../../store/store";
import { CampaignViewTypes } from "../../../types";
import { useCampaignParams } from "../components/CampaignEditorPage/useCampaignParams";
import handleRequestError from "../../../utils/handleRequestError";

const useInitCampaignHook = () => {
  const { companyId, campaignId, phaseId, stepId, view, documentTemplateId } =
    useCampaignParams();
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = useSelector(selectMessageApi);
  const navigate = useNavigate();
  const currentCampaign = useSelector(selectCurrentCampaign);
  const currentPhase = useSelector(selectCurrentPhase);
  const phases = useSelector(selectPhasesList);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!campaignId) {
          throw new Error("Company or campaign id is undefined");
        }

        const { loadedPhaseId, loadedStepId, viewToSet } = await dispatch(
          loadCampaignDataThunk({
            campaignId,
            phaseId,
            stepId,
          }),
        ).unwrap();

        let navigatePath = `/campaigns/company/${companyId}/campaign/${campaignId}`;

        if (loadedPhaseId !== undefined) {
          if (loadedStepId !== undefined) {
            let search = `view=${viewToSet || view}`;

            // If the step from the url was not loaded and another was set instead, then ignore documentTemplateId param
            if (
              view === CampaignViewTypes.DOC &&
              stepId === loadedStepId &&
              documentTemplateId !== undefined
            ) {
              search += `&documentTemplateId=${documentTemplateId}`;
            }

            navigatePath += `/phase/${loadedPhaseId}/step/${loadedStepId}?${search}${window.location.hash}`;
          } else {
            navigatePath += `/phase/${loadedPhaseId}`;
          }
        }

        navigate(navigatePath, {
          replace: true,
        });
      } catch (e: any) {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);

        navigate(`/campaigns`);
      } finally {
        setIsInitialized(true);
      }
    };

    getData();

    return () => {
      dispatch(setCurrentCampaign(null));
    };
  }, [companyId, campaignId]);

  return {
    currentCampaign,
    currentPhase,
    phases,
    companyId,
    isInitialized,
  };
};

export default useInitCampaignHook;
