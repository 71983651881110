import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import posthog from "posthog-js";

const usePostHogPageViewTracking = () => {
  const { companyId, campaignId, phaseId, stepId } = useParams();
  const location = useLocation();

  useEffect(() => {
    posthog?.capture("page_view", {
      company_id: companyId,
      campaign_id: campaignId,
      phase_id: phaseId,
      step_id: stepId,
    });
  }, [companyId, campaignId, phaseId, stepId, location.pathname]);
};

export default usePostHogPageViewTracking;
