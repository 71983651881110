import { createSlice } from "@reduxjs/toolkit";
import { ITemplateOption } from "src/globalTypes";
import { RootState } from "../store";

type TemplateOptionsState = {
  options: ITemplateOption[];
};

const initialState: TemplateOptionsState = {
  options: [],
};

export const templateOptionsSlice = createSlice({
  name: "templateOptions",
  initialState,
  reducers: {
    setTemplateOptions: (state, action) => {
      state.options = action.payload;
    },
    clearTemplateOptions: (state) => {
      state.options = [];
    },
  },
});

export const { setTemplateOptions, clearTemplateOptions } =
  templateOptionsSlice.actions;

export const selectTemplateOptions = (state: RootState) =>
  state.templateOptions.options;

export default templateOptionsSlice.reducer;
