import React, { useState } from "react";
import { Alert } from "antd";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import releasesData from "../constants/releasesData.json";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { Button } from "../../common/ui/button";
import { ReleaseInfo } from "../types";
import { RENDERED_ITEMS_NUMBER_STEP } from "../constants";

const data: ReleaseInfo[] = releasesData;

const ChangeLog = () => {
  const [renderedItemsNumber, setRenderedItemsNumber] = useState(
    RENDERED_ITEMS_NUMBER_STEP,
  );
  const [items, setItems] = useState(data.slice(0, RENDERED_ITEMS_NUMBER_STEP));
  const { hasUiShowTasksNumberInReleaseInfoRole } = useUserHasPermission({});
  const showMoreButton = renderedItemsNumber < data.length;

  const handleShowMoreItems = () => {
    const newItemsNumber = renderedItemsNumber + RENDERED_ITEMS_NUMBER_STEP;
    const newItems = data.slice(0, newItemsNumber);

    setRenderedItemsNumber(newItemsNumber);
    setItems(newItems);
  };

  return (
    <div className="flex flex-col gap-2 overflow-y-scroll">
      <h3 className="text-base font-bold text-[#475569] self-center">
        Bug Fixes &amp; Improvements
      </h3>

      <div className="flex flex-col gap-3 overflow-y-scroll">
        {items.map(({ date, changes }) => {
          return (
            <div className="flex gap-4" key={date}>
              <div className="w-[130px]">
                <span className="font-semibold text-[#475569] text-sm">
                  {date}
                </span>
              </div>
              <Alert
                className="w-full"
                type="info"
                message={
                  <ul className="list-disc pl-5">
                    {changes.map(({ jiraTicket, title }) => {
                      return (
                        <li key={title}>
                          <div className="flex items-start gap-2">
                            {jiraTicket &&
                              hasUiShowTasksNumberInReleaseInfoRole && (
                                <a
                                  href={`https://olsysltd.atlassian.net/browse/${jiraTicket}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="inline-block flex-shrink-0 w-[80px]"
                                >
                                  [{jiraTicket}]
                                </a>
                              )}
                            <span>{title}</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                }
              />
            </div>
          );
        })}

        {showMoreButton && (
          <Button variant="text" onClick={handleShowMoreItems}>
            Show more
            <ChevronDownIcon className="size-6" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default ChangeLog;
