import { Form, Input, InputRef, Modal, Select } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import micrositeContextFolderTooltip from "../../../constants/micrositeContextFolderTooltip";
import useSetFocus from "../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  editCampaignThunk,
  TCampaign,
} from "../../../store/slices/campaignsSlice";
import { AppDispatch } from "../../../store/store";
import { getDynFormSelectOptions } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { minMax } from "../../../utils/validations";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../../constants";
import { getConfirmationMessage } from "../../../utils/getConfirmationMessage";
import { getAiModelConfigOptions } from "../../../api/model-configs.api";
import customizeFormLabels from "../../../utils/customizeFormLabels";

type TCampaignFormValues = {
  name: string;
  microSiteContextFolder: string;
  aiModelId: number;
};

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentCampaign: TCampaign;
};

const EditCampaignModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  currentCampaign,
}) => {
  const [form] = Form.useForm<TCampaignFormValues>();
  const inputRef = useRef<InputRef>(null);
  const messageApi = getMessageApi();
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [options, setOptions] = useState<
    { label: string; value: string | number | null }[]
  >([]);

  useSetFocus(inputRef);
  useSubmitFormOnEnter(() => submitForm(), { condition: !isLoading });

  useEffect(() => {
    const getOptions = async () => {
      try {
        setIsLoadingOptions(true);

        const { data } = await getAiModelConfigOptions();
        const options = getDynFormSelectOptions({ data });

        setOptions(options);
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setIsLoadingOptions(false);
      }
    };

    getOptions();
  }, []);

  const onEdit = async (values: TCampaignFormValues) => {
    try {
      setIsLoading(true);

      const newCampaignData: TCampaign = {
        ...currentCampaign,
        ...values,
      };

      const hasChangedMicroSiteContextFolder =
        values.microSiteContextFolder !==
        currentCampaign.microSiteContextFolder;
      const hasChangeCampaignName = values.name !== currentCampaign.name;

      const handleSave = async (rebuild: boolean) => {
        try {
          await dispatch(
            editCampaignThunk({
              campaign: newCampaignData,
              rebuild,
            }),
          ).unwrap();

          onCancel();
        } catch (e: any) {
          messageApi.error(e?.message);
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      };

      const confirmationMessage = getConfirmationMessage(
        hasChangedMicroSiteContextFolder,
        hasChangeCampaignName,
        "Campaign Name",
      );

      if (confirmationMessage) {
        Modal.confirm({
          title: "Confirm campaign update",
          content: confirmationMessage,
          okText: "Confirm",
          cancelText: "Cancel",
          onOk: () => handleSave(true),
        });
      } else {
        await handleSave(false);
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TCampaignFormValues) => {
      await onEdit(values);
    },
  });

  const onCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Edit Campaign"
      open={isModalOpen}
      onOk={submitForm}
      okText="Save"
      width={600}
      destroyOnClose
      okButtonProps={{ loading: isLoading }}
      onCancel={onCancel}
    >
      <Form
        requiredMark={customizeFormLabels}
        form={form}
        layout="vertical"
        name="asset_form"
        initialValues={{
          name: currentCampaign.name,
          microSiteContextFolder: currentCampaign.microSiteContextFolder || "",
          aiModelId: currentCampaign.aiModelId,
        }}
      >
        <Form.Item
          name="name"
          label="Campaign Name"
          tooltip="Please enter a string up to 255 characters long."
          rules={[
            {
              type: "string",
              required: true,
              whitespace: true,
              message: "Required field!",
            },
            {
              max: 255,
              message:
                "Campaign Name must contain no more than 255 characters!",
            },
          ]}
        >
          <Input
            className="h-[48px]"
            placeholder="Enter campaign name"
            ref={inputRef}
          />
        </Form.Item>
        <Form.Item
          name="microSiteContextFolder"
          label="Microsite Context Folder"
          tooltip={micrositeContextFolderTooltip}
          rules={[minMax({ max: 255, text: "Path" })]}
        >
          <Input
            className="h-[48px]"
            placeholder={MICROSITE_INPUT_PLACEHOLDER}
          />
        </Form.Item>
        <Form.Item name="aiModelId" label="AI Model" tooltip="Please select">
          <Select
            className="h-[48px]"
            placeholder="Select the model"
            options={options}
            loading={isLoadingOptions}
            disabled={isLoadingOptions}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditCampaignModal;
