import DocTemplatesList from "src/components/docTemplates/List";
import DocTemplatesHeader from "src/components/docTemplates/TableHeader";

const DocumentTemplatesPage = () => {
  return (
    <div className="w-full flex flex-col gap-4">
      <DocTemplatesHeader />
      <DocTemplatesList />
    </div>
  );
};

export default DocumentTemplatesPage;
