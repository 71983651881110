import {
  getDynamicFormItems,
  getEnvVarTypes,
  TDynamicFormItem,
  TDynamicFormItems,
  TEnvVariable,
  TEnvVariableType,
  TEnvVarsFormValues,
} from "../store/slices/envVariablesSlice";
import localStorageProvider from "../utils/localStorageProvider";

type TReturnHookData = {
  isTypeSelectedDefault: boolean;
  dynamicDataDefault: TDynamicFormItem;
  dynamicFormItems: TDynamicFormItems;
  envVarTypes: Array<TEnvVariableType>;
  initialFormValues: Partial<TEnvVarsFormValues>;
  setEnvVarTypeLS: (item: TEnvVariableType) => void;
};

const useEnvVarData = (envVarData: TEnvVariable | null): TReturnHookData => {
  const dynamicFormItems = getDynamicFormItems();
  const envVarTypes = getEnvVarTypes();

  let dynamicDataDefault: TDynamicFormItem = [];
  let isTypeSelectedDefault: boolean = false;
  let initialFormValues: Partial<TEnvVarsFormValues> = {};

  if (!envVarData) {
    let key = localStorageProvider.getEnvType();

    if (key !== null) {
      const parsedKey: TEnvVariableType = JSON.parse(key);

      if (envVarTypes.includes(parsedKey)) {
        dynamicDataDefault = dynamicFormItems[parsedKey];
        isTypeSelectedDefault = true;

        initialFormValues = {
          type: parsedKey,
        };
      }
    }
  } else {
    dynamicDataDefault = dynamicFormItems[envVarData.type];
    isTypeSelectedDefault = true;

    const { id, type, value, options } = envVarData;

    initialFormValues = {
      key: id.key,
      type,
      value,
      ...options,
    };
  }

  const setEnvVarTypeLS = (item: TEnvVariableType) => {
    localStorageProvider.setEnvType(item);
  };

  return {
    isTypeSelectedDefault,
    dynamicDataDefault,
    dynamicFormItems,
    envVarTypes,
    initialFormValues,
    setEnvVarTypeLS,
  };
};

export default useEnvVarData;
