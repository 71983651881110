import { Flex, Form, Input, InputRef, Modal, Select } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextAreaWithAltEnter from "../../../components/common/TextAreaWithAltEnter";
import useCCVarData from "../../../hooks/useCCVarData";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  loadCcVarsThunk,
  TCcVariable,
  TCcVarsFormValues,
  useCcVariables,
} from "../../../store/slices/ccVariablesSlice";
import { AppDispatch } from "../../../store/store";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { Button } from "../../common/ui/button";
import DynamicCCForm from "../../dynamicForms/DynamicCCForm";
import { TEditModeProps, TitleModal } from "../Grid/CampaignGrid";
import { useParams } from "react-router-dom";
import useCCItemConflictModal from "../../../hooks/useCCItemConflictModal.hook";
import SelectAssetFromPublicCompanyStorage, {
  SelectAssetType,
} from "../../common/SelectAssetFromPublicCompanyStorage";
import saveCCItemWithRepublish from "../../../utils/saveCCItemWithRepublish";
import { createCcVarApi, updateCcVarApi } from "../../../api/cc-variables.api";
import customizeFormLabels from "../../../utils/customizeFormLabels";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";

type Props = {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setGridItemSequence: React.Dispatch<React.SetStateAction<number | null>>;
  setEditModeProps: React.Dispatch<React.SetStateAction<TEditModeProps | null>>;
  //null when create mode, object with var data - when edit mode
  editModeProps: TEditModeProps | null;
  gridItemSequence: number;
  varKeysLowerCase: Array<string>;
};

const GridItemForm: FC<Props> = ({
  isOpened,
  setIsOpened,
  editModeProps,
  gridItemSequence,
  setGridItemSequence,
  varKeysLowerCase,
  setEditModeProps,
}) => {
  const { companyId, campaignId, phaseId, stepId } = useParams();
  const {
    ccVarTypes,
    setFormValues,
    form,
    setDynamicData,
    type,
    resultType,
    dynamicItems,
  } = useCCVarData(editModeProps);
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const ccVars = useCcVariables();
  const inputTagRef = useRef<InputRef>(null);
  const [isFetching, setIsFetching] = useState(false);
  const { openConflictModal, conflictModalContext } = useCCItemConflictModal();
  const { hasCampaignCcRenameRole } = useUserHasPermission({ companyId });

  useSubmitFormOnEnter(() => submitForm(), { condition: !isFetching });

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus({
        cursor: "end",
      });
    }
  }, []);

  if (!campaignId || !phaseId || !stepId) {
    messageApi.error("Failed to load the required data.");
    return null;
  }

  const handleCancel = () => {
    form.resetFields();

    setDynamicData(null);
    setIsOpened(false);
    setGridItemSequence(null);
  };

  const saveCcVar = async ({
    values,
    rebuild = false,
  }: {
    rebuild?: boolean;
    values: TCcVarsFormValues;
  }) => {
    try {
      setIsFetching(true);

      if (editModeProps && editModeProps.actionType !== TitleModal.CLONE) {
        await updateCcVarApi({
          ccItem: editModeProps.variableData,
          values,
          rebuild,
        });
      } else {
        if (gridItemSequence === null || !campaignId || !stepId) {
          messageApi.error("Couldn't create the item");
          return;
        }

        await createCcVarApi({
          campaignId,
          values,
          stepId,
          seq: gridItemSequence,
        });
      }

      await dispatch(loadCcVarsThunk({campaignId, stepId})).unwrap();

      setIsOpened(false);
      setDynamicData(null);
      form.resetFields();
    } catch (e: any) {
      if (e.response && e.response.status === 409 && editModeProps) {
        const actualItem: TCcVariable = e.response.data;
        const onApplyActualCCItem = () => {
          setEditModeProps({
            variableData: actualItem,
            actionType: TitleModal.EDIT,
          });
          setFormValues(actualItem);
        };

        openConflictModal({
          currentItem: editModeProps.variableData,
          actualItem,
          onApplyActualCCItem,
        });
      } else {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const insertToFormField: SelectAssetType = ({ filePublicUrl }) => {
    form.setFieldValue("result", filePublicUrl);
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TCcVarsFormValues) => {
      const postProcessingParams = values.postProcessingParams;
      if (postProcessingParams) {
        values.postProcessingParams = JSON.stringify(postProcessingParams);
      }

      await saveCCItemWithRepublish({
        values,
        saveCCItem: saveCcVar,
        ccItem: editModeProps?.variableData,
      });
    },
  });

  return (
    <Modal
      title={editModeProps?.actionType || TitleModal.ADD}
      open={isOpened}
      onCancel={handleCancel}
      destroyOnClose={true}
      centered={true}
      width={"80vw"}
      footer={() => {
        return (
          <div className="flex justify-end items-center gap-[12px]">
            <div className="flex gap-[12px]">
              <Button
                onClick={handleCancel}
                variant="primaryOutline"
                className="rounded-full self-start"
                size="sm"
              >
                Cancel
              </Button>
              <Button
                onClick={submitForm}
                className="rounded-full self-start px-5"
                loading={isFetching}
                disabled={isFetching}
                size="sm"
              >
                Save
              </Button>
            </div>
          </div>
        );
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="cc_variable_form"
        className="h-[80vh] overflow-y-auto"
        requiredMark={customizeFormLabels}
      >
        <Flex gap="middle" align="center">
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Required field!" }]}
          >
            <Select
              placeholder="Select type"
              style={{ width: 140 }}
              options={ccVarTypes.map((type) => ({
                value: type,
                label: type,
              }))}
              disabled={!!editModeProps}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            hidden={!type}
            name="key"
            label="Key"
            tooltip="Enter a valid JavaScript identifier. It should start with a letter, underscore (_), or dollar sign ($), and can include letters, numbers, underscores, and dollar signs."
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  const trimmed = value ? value.trim() : "";
                  const lowerCaseValue = trimmed.toLowerCase();

                  if (!trimmed.length) {
                    return Promise.reject(new Error("Required field!"));
                  }

                  if (
                    editModeProps?.variableData.id.key.toLowerCase() !==
                      lowerCaseValue &&
                    varKeysLowerCase.includes(lowerCaseValue)
                  ) {
                    return Promise.reject(
                      new Error("The key already exists in this grid!"),
                    );
                  }

                  if (!/^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(trimmed)) {
                    return Promise.reject(
                      new Error("Invalid Javascript variable name!"),
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              ref={inputTagRef}
              disabled={!hasCampaignCcRenameRole}
              placeholder="Enter unique key name"
            />
          </Form.Item>
        </Flex>
        <Form.Item hidden={!type} name="result" label="Result">
          <TextAreaWithAltEnter
            placeholder="Enter result"
            currentForm={form}
            fieldName="result"
            allowClear
            rows={5}
            disabled={resultType === "read_only"}
          />
        </Form.Item>
        {type === "string" && (
          <SelectAssetFromPublicCompanyStorage onSelect={insertToFormField} />
        )}
        <Form.Item name="modifiedTime" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <DynamicCCForm
          hidden={!type}
          form={form}
          items={dynamicItems}
          ccVarData={editModeProps}
          gridItemSequence={gridItemSequence}
          campaignId={+campaignId}
          stepId={+stepId}
          phaseId={+phaseId}
          stepCCVars={ccVars}
        />
      </Form>
      {conflictModalContext}
    </Modal>
  );
};

export default GridItemForm;
