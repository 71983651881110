import CampaignHeader from "src/components/campaignHeader/CampaignHeader/CampaignHeader";
import CampaignVariables from "../../../../components/campaignEnvVariables/CampaignVariables/CampaignVariables";
import CampaignPhases from "../../../../components/campaignPhases/CampaignPhases/CampaignPhases";
import CampaignSteps from "../../../../components/campaignSteps/CampaignSteps/CampaignSteps";
import useInitCampaignHook from "../../hooks/useInitCampaignHook";
import CampaignViewSelector from "../StepData/CampaignView";
import StepData from "../StepData/StepData";
import { Skeleton } from "antd";
import useConnectSocket from "../../../../store/slices/computationMessages/hooks/useConnectSocket";
import usePostHogPageViewTracking from '../../../../hooks/usePostHogPageViewTracking';

const CampaignEditorPage = () => {
  usePostHogPageViewTracking();
  useConnectSocket();

  const { currentCampaign, phases, currentPhase, companyId, isInitialized } =
    useInitCampaignHook();

  if (!isInitialized || !currentCampaign) return <Skeleton active />;

  if (companyId === undefined) {
    console.error("Company id is undefined!");
    return <span className="text-red-600">Unable to load the data</span>;
  }

  return (
    <div className="flex flex-col justify-center">
      <CampaignHeader
        currentCampaign={currentCampaign}
        companyId={+companyId}
      />
      <div className="flex items-center mb-[24px] mt-[12px]">
        <CampaignViewSelector />
        <div style={{ width: "calc(100% - 49px)" }}>
          <CampaignPhases
            currentCampaign={currentCampaign}
            phases={phases}
            currentPhase={currentPhase}
            companyId={companyId}
          />
          <CampaignSteps
            campaignId={currentCampaign.id}
            companyId={companyId}
            currentPhase={currentPhase}
          />
        </div>
      </div>

      <StepData />
      <CampaignVariables />
    </div>
  );
};

export default CampaignEditorPage;
