import { useState } from "react";

type Props = {
  resultOvr: string | null;
  result: string | null;
};

const useManageCCItemOverride = ({ resultOvr, result }: Props) => {
  const [restoreOriginal, setRestoreOriginal] = useState(false);
  const [resetDependencies, setResetDependencies] = useState(false);
  const [overrideValue, setOverrideValue] = useState<string>(
    resultOvr || result || "",
  );

  // If any text is present in Textarea, and it is not equal to "result" cc-item, then the value will be true, otherwise false
  const shouldSaveOverride = overrideValue !== result && !!overrideValue;

  //If cc-item was opened WITH "resultOvr", then compare the "resultOvr" value of cc-item and the value in Textarea
  //If cc-item was opened WITHOUT "resultOvr", then check if any text is present in Textarea, and it is not equal to "result" cc-item
  const hasOverrideChanged = resultOvr
    ? resultOvr !== overrideValue
    : shouldSaveOverride;

  // The button is not blocked if the "Reset downstream dependencies" checkbox was selected, the "Restore Original" button was pressed, or the value in Textarea was changed
  const isSaveButtonDisabled = !(
    resetDependencies ||
    restoreOriginal ||
    hasOverrideChanged
  );

  const handleRestoreOriginalClick = () => {
    setOverrideValue(result || "");
    setRestoreOriginal(true);
  };

  const handleOverrideValueChange = (value: string | undefined) => {
    setOverrideValue(value || "");

    setRestoreOriginal(false);
  };

  const handleResetDependenciesChange = (checked: boolean) => {
    setResetDependencies(checked);
  };

  return {
    handleOverrideValueChange,
    handleRestoreOriginalClick,
    handleResetDependenciesChange,
    setOverrideValue,
    isSaveButtonDisabled,
    shouldSaveOverride,
    overrideValue,
    resetDependencies,
  };
};

export default useManageCCItemOverride;
