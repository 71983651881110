import axiosInstance from "../utils/axios/axiosInstance";
import { UserPermissions } from "../store/slices/userData/constants";

export const createGlobalSecurityPermissionOverrideApi = ({
  userId,
  accessType,
  permission,
}: {
  userId: number | string;
  permission: UserPermissions;
  accessType: "ALLOW" | "DENY";
}) => {
  return axiosInstance.post<string>(
    `/api/secured/user/${userId}/permissions-override/global/${permission}/${accessType}`,
    {},
  );
};

export const deleteGlobalSecurityPermissionOverrideApi = ({
  userId,
  permission,
}: {
  userId: number | string;
  permission: UserPermissions;
}) => {
  return axiosInstance.delete<string>(
    `/api/secured/user/${userId}/permissions-override/global/${permission}`,
  );
};
