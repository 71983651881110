import { Avatar, AvatarImage } from "../common/ui/avatar";
import { ChatBubble, ChatInput, ChatMessageList } from "../chat";
import { motion } from "motion/react";
import { FC, useEffect, useRef, useState } from "react";
import { ChatBubbleMessage } from "../chat/chat-bubble";
import { useBoolean } from "src/hooks/useBoolean";
import {
  getChatMessagesApi,
  postChatMessageApi,
  type ChatMessage,
} from "src/api/chat.api";
import { cn } from "src/utils";
import ChatVEIcon from "src/assets/VEChatIcon.svg";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { Badge } from "../common/ui/badge";
import { Loader2, X } from "lucide-react";
import { handleRequestError } from "src/utils/handleRequestError";
import { getMessageApi } from "src/store/slices/appSlice";

type ChatProps = {
  chatId: number;
  selectedCcVars: TCcVariable[];
  unselectCcVar: (ccVar: TCcVariable) => void;
  messages: ChatMessage[];
  setMessages: (messages: ChatMessage[]) => void;
};

const Chat: FC<ChatProps> = ({
  chatId,
  selectedCcVars,
  unselectCcVar,
  messages,
  setMessages,
}) => {
  const [input, setInput] = useState("");
  const [, startLoading, stopLoading] = useBoolean(false);
  const [inputHeight, setInputHeight] = useState<number>(0);
  const messageApi = getMessageApi();

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const getMessageVariant = (type: string) =>
    type === "REQUEST" ? "sent" : "received";

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages, chatId]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSendMessage(e as unknown as React.FormEvent<HTMLFormElement>);
    }
  };

  const handleSendMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!input) return;

    setInput("");
    formRef.current?.reset();
    startLoading();

    const keysRequestContext: Record<string, string> = selectedCcVars.reduce(
      (acc, ccVar) => {
        if (ccVar.result) {
          acc[ccVar.id.key] = ccVar.resultOvr || ccVar.result;
        }
        return acc;
      },
      {} as Record<string, string>,
    );

    try {
      await postChatMessageApi({
        chatId,
        message: {
          keysRequestContext,
          prompt: input,
        },
      });

      const { data: updatedMessages } = await getChatMessagesApi({
        chatId,
      });

      setMessages(updatedMessages);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopLoading();
    }
  };

  const handleInputResize = (height: number) => {
    setInputHeight(height);
  };

  const isAIReplied =
    messages.length > 0 && messages[messages.length - 1].type === "RESPONSE";

  return (
    <div className="flex h-[calc(100vh-134px)] w-full flex-col">
      <div
        className="flex w-[400px] overflow-y-auto bg-muted/40"
        style={{ height: `calc(100% - ${inputHeight}px)` }}
      >
        <ChatMessageList ref={messagesContainerRef}>
          {messages.map((message, index) => {
            const variant = getMessageVariant(message.type);
            const isAssistant = message.type === "RESPONSE";

            return (
              <motion.div
                key={message.id.timestamp}
                layout
                initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
                exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
                transition={{
                  opacity: { duration: 0.1 },
                  layout: {
                    type: "spring",
                    bounce: 0.3,
                    duration: index * 0.05 + 0.2,
                  },
                }}
                style={{ originX: 0.5, originY: 0.5 }}
                className="flex flex-col gap-2"
              >
                <ChatBubble variant={variant}>
                  {isAssistant ? (
                    <Avatar className="w-[32px] h-[32px] p-[8px] border-[#D4DAE0] border bg-white">
                      <AvatarImage src={ChatVEIcon} alt="Avatar" />
                    </Avatar>
                  ) : null}
                  <ChatBubbleMessage
                    isLoading={false}
                    className={cn(
                      "bg-[#E2E8F0]",
                      isAssistant && "bg-[#F8FAFC] w-[320px]",
                    )}
                  >
                    {message.text}
                  </ChatBubbleMessage>
                </ChatBubble>
              </motion.div>
            );
          })}
          {messages.length > 0 && !isAIReplied && (
            <div className="flex flex-col gap-2">
              <ChatBubble variant="received">
                <ChatBubbleMessage isLoading={true}>
                  <Loader2 className="size-4 animate-spin" />
                </ChatBubbleMessage>
              </ChatBubble>
            </div>
          )}
        </ChatMessageList>
      </div>
      <div className="px-4 pb-4 bg-muted/40 shrink-0">
        <form
          ref={formRef}
          onSubmit={handleSendMessage}
          className="relative rounded-lg border bg-background focus-within:ring-1 focus-within:ring-ring"
        >
          {selectedCcVars.length > 0 && (
            <div className="flex flex-wrap gap-2 p-1 bg-muted/40 border-b max-h-24 overflow-y-auto max-w-[370px]">
              {selectedCcVars.map((ccVar) => (
                <Badge
                  key={ccVar.id.key}
                  variant="secondary"
                  className="text-sm !rounded-sm"
                >
                  {ccVar.id.key}
                  <X
                    className="size-4 cursor-pointer hover:bg-gray-200 rounded ml-1"
                    onClick={() => unselectCcVar(ccVar)}
                  />
                </Badge>
              ))}
            </div>
          )}
          <ChatInput
            ref={inputRef}
            onKeyDown={handleKeyDown}
            onChange={(e) => setInput(e.target.value)}
            onHeightChange={handleInputResize}
            placeholder="Type your message here..."
            className="min-h-12 resize-none rounded-lg bg-background border-0 p-3 shadow-none focus-visible:ring-0"
          />
          <div className="flex items-center p-3 pt-0">
            {/* <Button variant="ghost" size="icon">
              <Paperclip className="size-4" />
              <span className="sr-only">Attach file</span>
            </Button> */}

            {/* <Button
              disabled={!input || isLoading}
              type="submit"
              size="sm"
              className="ml-auto gap-1.5"
            >
              Send Message
              <CornerDownLeft className="size-3.5" />
            </Button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Chat;
