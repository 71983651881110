import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getDocumentTemplatesApi } from "../../../api/document-templates.api";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  DocumentTemplateListItem,
  DocumentTemplateState,
} from "../../../types/docTemplates";
import handleRequestError from "../../../utils/handleRequestError";

const useGenerateTemplateHook = () => {
  const messageApi = useSelector(selectMessageApi);
  const [isFetching, setIsFetching] = useState(false);
  const [transformTemplates, setTransformTemplates] = useState<
    DocumentTemplateListItem[]
  >([]);
  const transformTemplatesOptions = useMemo(
    () =>
      transformTemplates.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [transformTemplates],
  );

  useEffect(() => {
    getTemplates();
  }, []);

  async function getTemplates() {
    try {
      setIsFetching(true);

      const defaultState = DocumentTemplateState.SYSTEM;
      const { data } = await getDocumentTemplatesApi({
        pageNumber: 0,
        pageSize: 10,
        states: defaultState,
      });

      setTransformTemplates(data.items);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  }

  return {
    transformTemplatesOptions,
    isFetching,
  };
};

export default useGenerateTemplateHook;
