import { Select } from "antd";
import { FC } from "react";
import { TCampaign } from "src/store/slices/campaignsSlice";
import { ModelConfig } from "src/types/modelConfigs";

export type Props = {
  campaign: TCampaign;
  modelConfigs: { label: string; value: string | number | null }[];
  onAiModelChange: (campaign: TCampaign) => void;
  defaultModel: ModelConfig | null;
};

export const CampaignChangeModelListItem: FC<Props> = ({
  campaign,
  modelConfigs,
  onAiModelChange,
  defaultModel,
}) => {
  return (
    <div className="py-2 px-3 flex justify-between border items-center rounded-lg my-2 h-[48px]">
      <span className="font-semibold truncate overflow-ellipsis">
        {campaign.name}
      </span>{" "}
      <Select
        placeholder="Enter new model to replace"
        options={modelConfigs}
        onChange={(value) => onAiModelChange({ ...campaign, aiModelId: value })}
        value={defaultModel?.id}
        className="w-[350px]"
      />
    </div>
  );
};
