import { ModelConfig, ModelConfigFormValues } from "src/types/modelConfigs";
import axiosInstance from "src/utils/axios/axiosInstance";

type GetModelConfigListRes = {
  items: ModelConfig[];
  totalItems: number;
};
export const getModelConfigsListApi = ({
  pageNumber,
  pageSize,
  sortBy,
  orderBy = "DESC",
}: {
  pageNumber: number;
  pageSize: number;
  sortBy?: string;
  orderBy?: "DESC" | "ASC";
}) => {
  return axiosInstance.get<GetModelConfigListRes>(
    `/api/secured/ai/model-configuration/`,
    {
      params: {
        pageNumber,
        pageSize,
        sortBy,
        orderBy,
      },
    },
  );
};

export const createModelConfigApi = (body: ModelConfigFormValues) => {
  return axiosInstance.post<ModelConfig>(
    `/api/secured/ai/model-configuration/`,
    body,
  );
};

export const updateModelConfigApi = (body: ModelConfig) => {
  return axiosInstance.put<ModelConfig>(
    `/api/secured/ai/model-configuration/`,
    body,
  );
};

export const getModelConfigApi = (id: number) => {
  return axiosInstance.get<ModelConfig>(
    `/api/secured/ai/model-configuration/${id}`,
  );
};

export const deleteModelConfigApi = (id: number) => {
  return axiosInstance.delete(`/api/secured/ai/model-configuration/${id}`);
};

export const setDefaultSystemModelConfigApi = (id: number) => {
  return axiosInstance.post(
    `/api/secured/ai/model-configuration/${id}/default`,
    {},
  );
};

export const changeToDefaultSystemModelConfigApi = (id: number) => {
  return axiosInstance.put(`/api/secured/campaign/${id}/default-ai-model`, {});
};

export const getAiModelConfigOptions = () => {
  return axiosInstance.get<string[]>(
    `/api/secured/ai/model-configuration/campaign-ui-list`,
  );
};

export const getProviderModelsApi = ({ provider }: { provider: string }) => {
  return axiosInstance.get<string[]>(
    `/api/secured/llm-utils/${provider}/models`,
  );
};
