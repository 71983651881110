import React, { Dispatch, FC, SetStateAction } from "react";
import PaneInfoSection from "./PaneInfoSection";
import PaneExecuteButton from "./PaneExecuteButton";
import clsx from "clsx";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import { useParams } from "react-router-dom";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import PaneResultOverride from "./PaneResultOverride";
import PaneResult from "./PaneResult";
import PaneDynamicForm from "./PaneDynamicForm";
import PaneEditPrompt from "./PaneEditPrompt";
import {
  ActivePaneCCTab,
  OnAfterCCItemUpdate,
  OnAfterOverrideUpdate,
  OnAfterPromptUpdate,
  OnAfterResultUpdate,
  PaneCCNavItem,
  SetActiveComponent,
} from "../types";

type Props = {
  ccItem: TCcVariable;
  navItems: PaneCCNavItem[];
  stepName: string;
  phaseName: string;
  phaseId: number;
  paneLabel: string;
  wrapClassName?: string;
  setActiveComponent: SetActiveComponent;
  setCcItem: Dispatch<SetStateAction<TCcVariable | null>>;
  onAfterResultUpdate?: OnAfterResultUpdate;
  onAfterPromptUpdate?: OnAfterPromptUpdate;
  onAfterOverrideUpdate?: OnAfterOverrideUpdate;
  onAfterCCItemUpdate?: OnAfterCCItemUpdate;
};

const PaneCcItem: FC<Props> = ({
  ccItem,
  navItems,
  stepName,
  phaseName,
  phaseId,
  setActiveComponent,
  paneLabel,
  wrapClassName,
  setCcItem,
  onAfterResultUpdate,
  onAfterPromptUpdate,
  onAfterOverrideUpdate,
  onAfterCCItemUpdate,
}) => {
  const {
    id: { stepId, key, campaignId },
    type,
    state,
    resultOvr,
  } = ccItem;
  const { companyId } = useParams();
  const { hasCampaignCcItemComputationRole } = useUserHasPermission({
    companyId,
  });

  return (
    <div
      className={clsx(
        "relative w-full p-2 flex flex-col h-full",
        wrapClassName,
      )}
    >
      {resultOvr && (
        <div className="absolute top-[-5px] right-[-10px] w-[30px] h-[20px] bg-gradient-to-t from-white to-yellow-500 rotate-45" />
      )}

      <div className="flex items-center justify-between">
        <PaneInfoSection
          stepName={stepName}
          phaseName={phaseName}
          nodeType={paneLabel}
          phaseId={phaseId}
          type={type}
          state={state}
          stepId={stepId}
          ccItemKey={key}
        />

        {hasCampaignCcItemComputationRole && (
          <PaneExecuteButton
            campaignId={campaignId}
            ccItemKey={key}
            stepId={stepId}
          />
        )}
      </div>

      <div>
        {navItems.map(({ label, active }) => (
          <div
            key={label}
            className={clsx(
              "cursor-pointer capitalize inline-flex items-center font-sans font-semibold transition-colors text-[#334155] text-[14px] h-[34px] mr-[24px] border-y-transparent hover:text-vePrimary border-y-[3px]",
              {
                "border-b-vePrimary": active,
              },
            )}
            onClick={() => setActiveComponent(label)}
          >
            <span className={active ? "text-vePrimary" : ""}>{label}</span>
          </div>
        ))}
        <div className="h-[1px] bg-[#E2E8F0]" />
      </div>

      <div className="mt-2 flex-grow overflow-y-auto">
        {navItems.map(({ label, active }) => {
          if (!active) return null;

          return (
            <div key={label} className="h-full">
              {label === ActivePaneCCTab.Override && (
                <PaneResultOverride
                  ccItem={ccItem}
                  setItem={setCcItem}
                  onAfterOverrideUpdate={onAfterOverrideUpdate}
                />
              )}
              {label === ActivePaneCCTab.Result && (
                <PaneResult
                  ccItem={ccItem}
                  setActiveOverrideTab={() =>
                    setActiveComponent(ActivePaneCCTab.Override)
                  }
                  onAfterResultUpdate={onAfterResultUpdate}
                />
              )}
              {label === ActivePaneCCTab.Model && (
                <PaneDynamicForm
                  nodeType={paneLabel}
                  ccItem={ccItem}
                  ccItemPhaseId={phaseId}
                  setItem={setCcItem}
                  onAfterCCItemUpdate={onAfterCCItemUpdate}
                />
              )}
              {label === ActivePaneCCTab.Prompt && (
                <PaneEditPrompt
                  ccItem={ccItem}
                  onAfterPromptUpdate={onAfterPromptUpdate}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaneCcItem;
