import { useNavigate } from "react-router-dom";
import { NavigateToOutletLocationState } from "../../globalTypes";

const useNavigateWithOutletRedirect = () => {
  const navigate = useNavigate();
  const state: NavigateToOutletLocationState = { navigateToOutlet: true };
  const navigateWithOutletRedirect = (path: string) =>
    navigate(path, { state });

  return {
    navigateWithOutletRedirect,
  };
};

export default useNavigateWithOutletRedirect;
