import React, { FC, useRef, useState } from "react";
import { Form, Input, InputRef, Modal } from "antd";
import customizeFormLabels from "../../../utils/customizeFormLabels";
import { minMax, required } from "../../../utils/validations";
import SelectAssetFromPublicCompanyStorage, {
  SelectAssetType,
} from "../SelectAssetFromPublicCompanyStorage";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import useSetFocus from "../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { AssetFormValues } from "../../assets/types";
import { createAssetApi } from "../../../api/assets.api";
import handleRequestError from "../../../utils/handleRequestError";
import { TAsset } from "../../../globalTypes";

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  campaignId: string;
  stepId: string;
  loadAndSetAssets?: () => Promise<void>;
  onAssetCreated?: (asset: TAsset) => void;
};
const CreateAssetModal: FC<Props> = ({
  campaignId,
  stepId,
  onAssetCreated,
  setIsModalOpen,
  isModalOpen,
  loadAndSetAssets,
}) => {
  const inputRef = useRef<InputRef>(null);
  const messageApi = useSelector(selectMessageApi);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<AssetFormValues>();

  useSetFocus(inputRef);
  useSubmitFormOnEnter(() => onFormSubmit(), {
    condition: !isLoading,
  });

  const onCreateAsset = async (values: AssetFormValues) => {
    try {
      setIsLoading(true);
      const { data } = await createAssetApi({
        ...values,
        type: "LINKED",
        campaignId,
        stepId,
      });

      loadAndSetAssets && (await loadAndSetAssets());

      onAssetCreated?.(data);
      setIsModalOpen(false);
      form.resetFields();

      messageApi.success("Asset has been successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const onFormSubmit = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: AssetFormValues) => {
      await onCreateAsset(values);
    },
  });

  const onCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const insertFileData: SelectAssetType = ({
    filePath,
    filePublicUrl,
    fileName,
  }) => {
    form.setFieldsValue({
      ref: filePublicUrl,
      microSiteTargetFolder: filePath,
      title: fileName,
    });
  };

  return (
    <Modal
      title="Add Asset"
      open={isModalOpen}
      onOk={onFormSubmit}
      okText="Save"
      width={600}
      destroyOnClose
      okButtonProps={{ loading: isLoading }}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={customizeFormLabels}
        name="creare_asset_form"
      >
        <Form.Item
          name="title"
          label="Asset Title"
          tooltip="Please enter a string up to 255 characters long."
          rules={[required(), minMax({ max: 255, text: "Asset Title" })]}
        >
          <Input placeholder="Enter asset title" ref={inputRef} />
        </Form.Item>
        <Form.Item label="Asset Link" required>
          <Form.Item
            name="ref"
            noStyle
            rules={[
              {
                type: "string",
                required: true,
                whitespace: true,
                message: "Required field!",
              },
            ]}
          >
            <Input placeholder="Enter asset link" />
          </Form.Item>

          <SelectAssetFromPublicCompanyStorage onSelect={insertFileData} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateAssetModal;
