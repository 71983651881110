import { LockOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Modal, Spin, Tooltip, Typography } from "antd";
import { FC, Fragment, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TabsCarousel from "src/components/common/TabsCarousel";
import { Button } from "src/components/common/ui/button";
import { TMovePhaseStepType, TPhase } from "../../../globalTypes";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  getSteps,
  moveStepThunk,
  saveStepThunk,
  TStep,
  updateStepThunk,
} from "../../../store/slices/stepsSlice";
import { AppDispatch } from "../../../store/store";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import StepModal from "../../common/modals/StepModal/StepModal";
import Step from "../Step/Step";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import handleRequestError from "../../../utils/handleRequestError";
import { CampaignViewTypes } from "../../../types";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { getConfirmationMessage } from "../../../utils/getConfirmationMessage";
import { useCampaignParams } from "src/pages/campaign/components/CampaignEditorPage/useCampaignParams";

export type TStepFormValues = {
  name: string;
  hidden: boolean;
  microSiteContextFolder: null | string;
  classes: string[];
};

type Props = {
  currentPhase: TPhase | null;
  companyId: number;
  campaignId: number;
};

const CampaignSteps: FC<Props> = ({ currentPhase, companyId, campaignId }) => {
  const steps = getSteps();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStepMoving, setIsStepMoving] = useState(false);
  const [editStep, setEditStep] = useState<TStep | null>(null);
  const [form] = Form.useForm<TStepFormValues>();
  const [addBeforeAfterProps, setAddBeforeAfterProps] = useState<{
    targetStepId: number;
    addType: TMovePhaseStepType;
  } | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = useSelector(selectMessageApi);
  const { view: currentCampaignView } = useCampaignParams();
  const navigate = useNavigate();
  const {
    hasStepListRole,
    hasStepShowHiddenRole,
    hasStepCreateRole,
    hasStepMoveRole,
  } = useUserHasPermission({
    companyId,
  });

  async function onCreateStep(values: TStepFormValues) {
    try {
      const { stepId, phaseId } = await dispatch(
        saveStepThunk({
          phaseId: currentPhase!.id,
          stepName: values.name,
          hidden: values.hidden,
          microSiteContextFolder: values.microSiteContextFolder,
          classes: values.classes,
          targetStepId: addBeforeAfterProps
            ? addBeforeAfterProps.targetStepId
            : undefined,
          addType: addBeforeAfterProps ? addBeforeAfterProps.addType : "AFTER",
        }),
      ).unwrap();

      onCancel();

      navigate(
        `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}?view=${currentCampaignView}`,
        { replace: true },
      );
      messageApi.success("The step has been successfully created");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  }

  const onEditStep = async (values: TStepFormValues) => {
    const isMicroSiteContextFolderChanged =
      values.microSiteContextFolder !== editStep?.microSiteContextFolder;
    const isStepNameChanged = values.name !== editStep?.name;
    const confirmationMessage = getConfirmationMessage(
      isMicroSiteContextFolderChanged,
      isStepNameChanged,
      "Step Name",
    );

    const updateStep = async (rebuild: boolean) => {
      try {
        await dispatch(
          updateStepThunk({
            step: {
              ...editStep!,
              ...values,
            },
            rebuild,
          }),
        ).unwrap();

        onCancel();
      } catch (e: any) {
        messageApi.error(e?.message);
        console.error("An error occurred while trying to update the step:", e);
      }
    };

    if (confirmationMessage) {
      Modal.confirm({
        title: "Confirm Step Update",
        content:
          "Microsite target folder will be changed, do you want to republish resources?",
        okText: "Confirm",
        cancelText: "Cancel",
        onOk: () => updateStep(true),
      });
    } else {
      await updateStep(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setEditStep(null);
    setAddBeforeAfterProps(null);
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TStepFormValues) => {
      editStep ? await onEditStep(values) : await onCreateStep(values);
    },
  });

  const onDragEnd: OnDragEndResponder = async (result) => {
    try {
      if (!result.destination) {
        return;
      }
      setIsStepMoving(true);

      const {
        source: { index: sourceIndex },
        destination: { index: destinationIndex },
      } = result;

      if (sourceIndex === destinationIndex) return;

      const moveType = destinationIndex < sourceIndex ? "BEFORE" : "AFTER";
      const targetStepId = steps[destinationIndex].id;
      const { id, phaseId } = steps[sourceIndex];

      await dispatch(
        moveStepThunk({ targetStepId, moveType, stepId: id, phaseId }),
      ).unwrap();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsStepMoving(false);
    }
  };

  const onMove = async ({
    moveType,
    targetStepId,
    phaseId,
    stepId,
  }: {
    moveType: TMovePhaseStepType;
    targetStepId: number;
    stepId: number;
    phaseId: number;
  }) => {
    try {
      setIsStepMoving(true);

      await dispatch(
        moveStepThunk({ targetStepId, moveType, stepId, phaseId }),
      ).unwrap();

      messageApi.success("The step has been successfully moved");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsStepMoving(false);
    }
  };

  const isCampaignViewDoc = currentCampaignView === CampaignViewTypes.DOC;
  const hasStepDocTemplate = (step: TStep) =>
    isCampaignViewDoc ? !!step.documentTemplateId : false;

  return (
    <Spin spinning={isStepMoving}>
      {hasStepListRole && (
        <TabsCarousel
          className="flex clear-start bg-indigo-50 rounded-br-[8px] items-center overflow-auto h-[48px] scrollbar-hide"
          rightButtonClassName="rounded-br-[8px]"
        >
          <div className="flex">
            {currentPhase ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, _snap) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="flex"
                    >
                      {steps.map((step, index) => {
                        if (!hasStepShowHiddenRole && step.hidden) {
                          return <Fragment key={step.id} />;
                        }

                        const prevStep = steps[index - 1];
                        const nextStep = steps[index + 1];

                        const onAddBefore = () => {
                          setAddBeforeAfterProps({
                            targetStepId: step.id,
                            addType: "BEFORE",
                          });
                          setIsModalOpen(true);
                        };

                        const onAddAfter = () => {
                          setAddBeforeAfterProps({
                            targetStepId: step.id,
                            addType: "AFTER",
                          });
                          setIsModalOpen(true);
                        };

                        const onMoveBefore = prevStep
                          ? async () => {
                              await onMove({
                                moveType: "BEFORE",
                                targetStepId: prevStep.id,
                                phaseId: step.phaseId,
                                stepId: step.id,
                              });
                            }
                          : undefined;

                        const onMoveAfter = nextStep
                          ? async () => {
                              await onMove({
                                moveType: "AFTER",
                                targetStepId: nextStep.id,
                                phaseId: step.phaseId,
                                stepId: step.id,
                              });
                            }
                          : undefined;

                        return (
                          <Draggable
                            isDragDisabled={!hasStepMoveRole}
                            key={step.id}
                            draggableId={String(step.id)}
                            index={index}
                          >
                            {(provided, _snap) => (
                              <div
                                style={{ position: "relative" }}
                                className="relative"
                                key={step.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Step
                                  step={step}
                                  form={form}
                                  stepIndex={index}
                                  setIsModalOpen={setIsModalOpen}
                                  setEditStep={setEditStep}
                                  phaseId={currentPhase.id}
                                  companyId={companyId}
                                  campaignId={campaignId}
                                  dragHandleProps={provided.dragHandleProps}
                                  onMoveBefore={onMoveBefore}
                                  onMoveAfter={onMoveAfter}
                                  onAddBefore={onAddBefore}
                                  onAddAfter={onAddAfter}
                                />
                                {step.hidden && (
                                  <Tooltip
                                    title={
                                      <span>
                                        This Step is hidden from users.
                                        <br />
                                        To switch the state, go to the Edit Step
                                        modal.
                                      </span>
                                    }
                                  >
                                    <LockOutlined className="text-slate-600 absolute top-[5px] left-[5px]" />
                                  </Tooltip>
                                )}
                                {hasStepDocTemplate(step) && (
                                  <Tooltip
                                    title={
                                      <span>
                                        This Step has a document template.
                                      </span>
                                    }
                                  >
                                    <DocumentIcon className="text-slate-600 absolute bottom-[6px] left-[6px] size-3" />
                                  </Tooltip>
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Typography.Text>
                There are no steps in this phase yet
              </Typography.Text>
            )}
          </div>
          {hasStepCreateRole && (
            <Button
              disabled={!currentPhase}
              className="border-transparent bg-transparent border-b-2 hover:bg-vePrimary/10 rounded-none h-[48px]"
              onClick={() => setIsModalOpen(true)}
              variant="primaryOutline"
              icon={PlusOutlined}
            >
              Add Step
            </Button>
          )}
        </TabsCarousel>
      )}

      {isModalOpen && (
        <StepModal
          onSubmitForm={submitForm}
          onCancel={onCancel}
          form={form}
          isModalOpen={isModalOpen}
          editStep={editStep}
        />
      )}
    </Spin>
  );
};

export default CampaignSteps;
