import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useEffect, useState } from "react";
import { TCompanyUser } from "../../../globalTypes";
import handleRequestError from "../../../utils/handleRequestError";
import { getCompanyUsersApi } from "../../../api/companies.api";

const useGetCompanyUsers = () => {
  const { companyId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState("");
  const [companyUsers, setCompanyUsers] = useState<TCompanyUser[]>([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    if (companyId === undefined) {
      messageApi.error("Unable to get [companyId] from URL");
      return;
    }

    try {
      const { data } = await getCompanyUsersApi({ companyId });

      setCompanyUsers(data);
      setError("");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    } finally {
      setIsInitialized(true);
    }
  };

  return {
    isInitialized,
    companyUsers,
    error,
    loadUsers,
  };
};

export default useGetCompanyUsers;
