import { getCampaignStructureApi } from "src/api/company-storage.api";
import { TAsset, TFileTreeItem } from "src/globalTypes";
import { CampaignDataAssetsStructure } from "src/store/slices/campaignsSlice";
import { AssetsFileTreeItem } from "./types";

const parsePhaseId = (id: number | string) => `phase_${id}`;
const parseStepId = (id: number | string) => `step_${id}`;

const convertCampaignDataToFileTree = (
  data: CampaignDataAssetsStructure,
): TFileTreeItem[] => {
  const allPhases = data.phases.map((phase) => phase);
  const allSteps = data.phases
    .map((phase) =>
      phase.steps.map((step) => ({ ...step, parentId: phase.id })),
    )
    .flat();
  const allAssets = data.phases
    .map((phase) =>
      phase.steps.map((step) =>
        step.assets.map((asset) => ({
          ...asset,
          parentId: step.id.toString(),
        })),
      ),
    )
    .flat()
    .flat();
  const phasesFolders: TFileTreeItem[] = allPhases.map((phase) => ({
    title: phase.name,
    folder: true,
    fileName: parsePhaseId(phase.id),
    mimeType: "",
    parentId: "campaign",
    level: 1,
    size: 0,
    phaseData: phase,
  }));
  const stepsFolders: TFileTreeItem[] = allSteps.map((step) => ({
    title: step.name,
    folder: true,
    fileName: parseStepId(step.id),
    mimeType: "",
    parentId: parsePhaseId(step.parentId),
    level: 2,
    size: 0,
    stepData: step,
  }));

  const assetsFiles: AssetsFileTreeItem[] = allAssets.map((asset) => {
    return convertAssetToFileTreeItem({ asset, stepId: asset.parentId });
  });

  return [
    {
      title: data.name,
      folder: true,
      fileName: "campaign",
      mimeType: "",
      parentId: null,
      level: 0,
      size: 0,
      isRootDir: true,
      expanded: true,
    },
    ...phasesFolders,
    ...stepsFolders,
    ...assetsFiles,
  ];
};
export const getCampaignViewFileTree = async ({
  campaignId,
}: {
  campaignId: string;
}) => {
  const { data } = await getCampaignStructureApi({
    campaignId,
  });
  return convertCampaignDataToFileTree(data);
};

export const convertAssetToFileTreeItem = ({
  asset,
  stepId,
}: {
  asset: TAsset;
  stepId: number | string;
}): AssetsFileTreeItem => ({
  title: asset.title,
  folder: false,
  fileName: asset.title,
  mimeType: "",
  parentId: parseStepId(stepId),
  level: 3,
  size: 0,
  assetData: asset,
});

export const checkAssetsStorageSelectedItem = (
  currentItem: TFileTreeItem,
  selectedItem: TFileTreeItem | null,
) => {
  return (
    currentItem.fileName === selectedItem?.fileName &&
    currentItem.parentId === selectedItem?.parentId
  );
};
