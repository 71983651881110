import React, { FC, useEffect, useRef, useState } from "react";
import { AppDispatch } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { getMessageApi } from "../../store/slices/appSlice";
import { Form, Input, InputRef, Modal, Select } from "antd";
import { TLabelFormData, TLabelsListData } from "../../globalTypes";
import useSubmitFormOnEnter from "../../hooks/useSubmitFormOnEnter";
import handleRequestError from "../../utils/handleRequestError";
import submitFormWithTrim from "../../utils/submitFormWithTrim";
import customizeFormLabels from "../../utils/customizeFormLabels";
import {
  selectGlobalLabelsList,
  saveGlobalLabelThunk,
} from "../../store/slices/globalLabelsSlice";

type Props = {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  //null when create mode, object with label data - when edit mode
  labelData: TLabelsListData | null;
};

const GlobalLabelsModal: FC<Props> = ({ isOpened, setIsOpened, labelData }) => {
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = getMessageApi();
  const labels = useSelector(selectGlobalLabelsList);
  const labelsKeysLowerCase = labels.map((label) =>
    label.labelKey.toLowerCase(),
  );
  const [form] = Form.useForm<TLabelFormData>();
  const initialFormValues = { lang: "en" } as TLabelFormData;
  const inputTagRef = useRef<InputRef>(null);
  const [loading, setLoading] = useState(false);

  useSubmitFormOnEnter(() => submitForm(), { condition: !loading });

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus({
        cursor: "end",
      });
    }
  }, []);

  if (labelData) {
    initialFormValues.labelKey = labelData.labelKey;
    initialFormValues.value = labelData.value;
  }

  const handleCancel = () => {
    form.resetFields();

    setIsOpened(false);
  };

  const onCreate = async (values: TLabelFormData) => {
    setLoading(true);

    try {
      const { labelKey: key, value: label, lang } = values;
      const action = labelData ? "update" : "create";
      await dispatch(
        saveGlobalLabelThunk({ label, key, lang, action }),
      ).unwrap();

      setIsOpened(false);
      form.resetFields();
      messageApi.success("Successfully created!");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TLabelFormData) =>
      await onCreate(values),
  });

  return (
    <Modal
      title={`${labelData ? "Edit" : "Add"} Label`}
      open={isOpened}
      okText={labelData ? "Save" : "Create"}
      onOk={submitForm}
      confirmLoading={loading}
      onCancel={handleCancel}
      destroyOnClose={true}
      centered={true}
      width={"30vw"}
    >
      <Form
        form={form}
        layout="vertical"
        name="campaign_lables_edit_form"
        requiredMark={customizeFormLabels}
        initialValues={initialFormValues}
      >
        <Form.Item
          name="lang"
          label="Language"
          hidden={true}
          rules={[{ required: true, message: "Required field!" }]}
        >
          <Select
            placeholder="Select language"
            style={{ width: 200 }}
            options={[{ label: "English", value: "en" }]}
          />
        </Form.Item>
        <Form.Item
          name="labelKey"
          label="Key"
          tooltip="Please ensure that the label key does not exceed 26 characters"
          rules={[
            {
              required: true,
              message: "Required field!",
              transform: (value) => value?.trim(),
            },
            {
              max: 26,
              message: "The label key must contain no more than 26 characters!",
              transform: (value) => value?.trim(),
            },
            {
              transform: (value) => value?.trim(),
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve();
                }

                const lowerCaseValue = value.toLowerCase();

                if (
                  labelData?.labelKey.toLowerCase() !== lowerCaseValue &&
                  labelsKeysLowerCase.includes(lowerCaseValue)
                ) {
                  return Promise.reject(
                    new Error("A label with that key already exists!"),
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder="Enter the label key"
            ref={inputTagRef}
            disabled={!!labelData}
          />
        </Form.Item>
        <Form.Item
          name="value"
          label="Value"
          tooltip="Please ensure that the label value does not exceed 255 characters"
          rules={[
            {
              required: true,
              message: "Required field!",
              transform: (value) => value?.trim(),
            },
            {
              max: 255,
              message:
                "The label value must contain no more than 255 characters!",
              transform: (value) => value?.trim(),
            },
          ]}
        >
          <Input placeholder="Enter the label definition" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GlobalLabelsModal;
