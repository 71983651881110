import { useEffect, useState } from "react";
import { TFileTreeItem } from "../../../../globalTypes";
import { getMessageApi as useMessageApi } from "../../../../store/slices/appSlice";
import handleRequestError from "../../../../utils/handleRequestError";
import { GetMenuActions } from "./types";

type Props = {
  getFileTree: () => Promise<TFileTreeItem[]>;
  getItemMenuActions: GetMenuActions;
};
const useStaticStorage = ({ getFileTree }: Props) => {
  const message = useMessageApi();
  const [selectedItem, setSelectedItem] = useState<TFileTreeItem | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [treeData, setTreeData] = useState<Array<TFileTreeItem>>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    getFilesList();
  }, []);

  const getFilesList = async () => {
    try {
      setIsLoading(true);
      const data = await getFileTree();
      setTreeData(data);
      setError(null);
    } catch (e: any) {
      const customError = handleRequestError(e);
      message.error(customError.message);
      console.error(customError);
      setError(customError.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExpandFolder = (folder: TFileTreeItem) => {
    setTreeData((prevData) =>
      prevData.map((fileItem) =>
        fileItem.fileName === folder.fileName
          ? { ...fileItem, expanded: !fileItem.expanded }
          : fileItem,
      ),
    );
  };
  const handleSelectedItem = (item: TFileTreeItem | null) => {
    setSelectedItem(item);
  };
  const handleUnselectItems = () => {
    setSelectedItem(null);
  };
  return {
    isLoading,
    error,
    treeData,
    selectedItem,
    handleTreeDataChange: setTreeData,
    handleExpandFolder,
    handleSelectedItem,
    handleUnselectItems,
  };
};
export default useStaticStorage;
