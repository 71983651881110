import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUiAvailableTemplatesApi } from "src/api/document-templates.api";
import { ITemplateOption } from "src/globalTypes";
import { getMessageApi } from "src/store/slices/appSlice";
import { selectCurrentStep } from "src/store/slices/stepsSlice";
import { FileMimeType } from "src/types";
import handleRequestError from "src/utils/handleRequestError";
import { GetUITemplatesApiParams } from "../types/docTemplates";
import { AppDispatch } from "src/store/store";
import {
  selectTemplateOptions,
  setTemplateOptions,
} from "src/store/slices/templateOptionsSlice";

export const useUiTemplatesOptions = () => {
  const messageApi = getMessageApi();
  const dispatch = useDispatch<AppDispatch>();
  const currentStep = useSelector(selectCurrentStep);
  const [isLoading, setIsLoading] = useState(false);
  const options = useSelector(selectTemplateOptions);

  const load = async (params: GetUITemplatesApiParams) => {
    setIsLoading(true);
    try {
      const { data } = await getUiAvailableTemplatesApi(params);

      const templateOptions: ITemplateOption[] = data.map((template) => {
        return {
          label: template.name,
          value: template.id,
          info: {
            name: template.name,
            foundKeys: template.foundKeys,
            totalKeys: template.totalKeys,
            mediaType: template.mediaType as FileMimeType,
          },
        };
      });

      dispatch(setTemplateOptions(templateOptions || []));
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const selectedOption = useMemo(() => {
    return options?.find((option) => {
      return option.value === Number(currentStep?.documentTemplateId);
    });
  }, [options, currentStep]);

  return { options, load, selectedOption, isLoading };
};
