import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  cloneStepApi,
  createStepApi,
  deleteStepApi,
  getStepsApi,
  moveStepApi,
  updateDocVisibleStepApi,
  updateStepApi,
} from "../../api/steps.api";
import { TCloneEntityFormValues } from "../../components/common/modals/CloneEntityModal/CloneEntityModal";
import { sortBySequence } from "../../utils/cm.utils";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";
import { toggleIsFetching } from "./appSlice";
import { setCcVariables } from "./ccVariablesSlice";
import { TMovePhaseStepType } from "../../globalTypes";

export type TStep = {
  phaseId: number;
  seq: number;
  id: number;
  name: string;
  hidden: boolean;
  microSiteContextFolder: null | string;
  documentTemplateId: number | null;
  hiddenDocView: boolean;
  classes: null | string[];
};

export type TStepPathMap = {
  [key: number]: string;
};

const initialState = {
  current: null as TStep | null,
  list: [] as Array<TStep>,
  stepPathMap: {} as TStepPathMap,
};

type InitialStateType = typeof initialState;

const stepsSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    cleanUpSteps: (state: InitialStateType) => {
      state.current = null;
      state.list = [];
    },
    setStepPathMap: (
      state: InitialStateType,
      action: PayloadAction<TStepPathMap>,
    ) => {
      state.stepPathMap = action.payload;
    },
    setSteps: (
      state: InitialStateType,
      action: PayloadAction<Array<TStep>>,
    ) => {
      state.list = action.payload;
    },
    updateStep: (state: InitialStateType, action: PayloadAction<TStep>) => {
      const editedStep = action.payload;
      const index = state.list.findIndex((step) => step.id === editedStep.id);

      state.list[index] = editedStep;
    },
    setCurrentStep: (
      state: InitialStateType,
      action: PayloadAction<TStep | null>,
    ) => {
      state.current = action.payload;
    },
  },
});

export const {
  cleanUpSteps,
  setSteps,
  updateStep,
  setCurrentStep,
  setStepPathMap,
} = stepsSlice.actions;

export default stepsSlice.reducer;

export const selectStepsList = (state: RootState) => state.steps.list;
export const selectCurrentStep = (state: RootState) => state.steps.current;
export const selectDocVisibleSteps = createSelector(
  selectStepsList,
  (list: RootState["steps"]["list"]) => {
    return list.filter((step) => !step.hiddenDocView);
  },
);

/* eslint-disable*/
export const getSteps = (): Array<TStep> => useSelector(selectStepsList);
export const getCurrentStep = (): TStep | null =>
  useSelector(selectCurrentStep);

type TSaveStepThunkProps = {
  phaseId: number;
  stepName: string;
  hidden: boolean;
  microSiteContextFolder: null | string;
  classes: string[];
  addType: TMovePhaseStepType;
  targetStepId?: number;
};

export const saveStepThunk = createAsyncThunk<
  { phaseId: number; stepId: number },
  TSaveStepThunkProps,
  {
    rejectValue: TCustomError;
  }
>(
  "steps/saveStep",
  async (
    {
      stepName,
      phaseId,
      hidden,
      microSiteContextFolder,
      classes,
      targetStepId,
      addType,
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: step } = await createStepApi({
        phaseId,
        name: stepName,
        hidden: hidden || false,
        microSiteContextFolder: microSiteContextFolder || "",
        classes,
        hiddenDocView: false,
        targetStepId,
        addType,
      });

      const { data: steps } = await getStepsApi({
        phaseId,
      });

      dispatch(setSteps(steps));
      dispatch(setCurrentStep(step));
      dispatch(setCcVariables([]));

      return { phaseId: step.phaseId, stepId: step.id };
    } catch (e: any) {
      return rejectWithValue(handleRequestError(e));
    }
  },
);

export const updateStepThunk = createAsyncThunk<
  string,
  { step: TStep; rebuild?: boolean },
  { rejectValue: TCustomError }
>(
  "steps/updateStep",
  async ({ step, rebuild }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { data: updatedStep } = await updateStepApi({ step, rebuild });

      dispatch(updateStep(updatedStep));
      dispatch(setCurrentStep(updatedStep));

      return "success";
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to update the step:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

export const updateCurrentStepTemplateThunk = createAsyncThunk<
  string,
  { templateId: number | null },
  { state: RootState; rejectValue: TCustomError }
>(
  "steps/updateStepTemplate",
  async ({ templateId }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));
      const { steps } = getState();

      const step = steps.current;

      if (step) {
        const stepWithUpdatedTemplate: TStep = {
          ...step,
          documentTemplateId: templateId,
        };

        await updateStepApi({
          step: stepWithUpdatedTemplate,
        });

        dispatch(updateStep(stepWithUpdatedTemplate));
        dispatch(setCurrentStep(stepWithUpdatedTemplate));
      }

      return "success";
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to update the step template:`,
        e,
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

export const updateHiddenDocViewThunk = createAsyncThunk<
  undefined,
  { enable: boolean; phaseId: number; stepId: number },
  { state: RootState; rejectValue: TCustomError }
>(
  "steps/updateHiddenDocView",
  async ({ enable, stepId, phaseId }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { data: updatedStep } = await updateDocVisibleStepApi({
        stepId,
        phaseId,
        enable,
      });

      dispatch(updateStep(updatedStep));
      dispatch(setCurrentStep(updatedStep));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to update the step hiddenDocView:`,
        e,
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

export const deleteStepThunk = createAsyncThunk<
  { stepId: number | undefined },
  {
    stepId: number;
    phaseId: number;
    stepIndex: number;
    removeFiles: boolean;
  },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "steps/deleteStep",
  async (
    { stepId, phaseId, removeFiles, stepIndex },
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      await deleteStepApi({
        phaseId,
        stepId,
        removeFiles,
      });

      const { steps } = getState();
      const allSteps = steps.list;
      //if it was not the only step - set current step as null and steps empty list
      let updatedSteps: Array<TStep> = [];
      let step: TStep | null = null;

      //if it was not the only step
      if (allSteps.length !== 1) {
        //delete the step from list
        updatedSteps = allSteps.filter((step) => step.id !== stepId);

        //if it was first step - set next as current
        if (stepIndex === 0) {
          step = updatedSteps[0];
        } else {
          //if it was not first step - set previous as current
          step = updatedSteps[stepIndex - 1];
        }
      }

      dispatch(setCurrentStep(step));
      dispatch(setSteps(updatedSteps));

      return {
        stepId: step?.id,
      };
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to delete the step:`, e);

      return rejectWithValue(customError);
    }
  },
);

export const cloneStepThunk = createAsyncThunk<
  undefined,
  { step: TStep; params: TCloneEntityFormValues },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "steps/cloneStepThunk",
  async ({ step, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { phases, steps } = getState();
      const { data: clonedStep } = await cloneStepApi({
        phaseId: phases.current!.id,
        stepId: step.id,
        ...params,
      });

      const index = steps.list.findIndex(
        (stepFromList) => stepFromList.id === step.id,
      );
      let updatedStepsList: Array<TStep> = [];

      if (index !== -1) {
        if (steps.list.length - 1 === index) {
          updatedStepsList = [...steps.list, { ...clonedStep }];
        } else {
          updatedStepsList = [
            ...steps.list.slice(0, index + 1),
            { ...clonedStep },
            ...steps.list.slice(index + 1),
          ];
        }
      }

      dispatch(setSteps(sortBySequence(updatedStepsList)));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to clone the step:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

export const moveStepThunk = createAsyncThunk<
  undefined,
  {
    targetStepId: number;
    stepId: number;
    phaseId: number;
    moveType: TMovePhaseStepType;
  },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "steps/moveStepThunk",
  async (
    { targetStepId, moveType, stepId, phaseId },
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const { data: updatedSteps } = await moveStepApi({
        targetStepId,
        moveType,
        stepId,
        phaseId,
      });

      const { steps } = getState();

      if (steps.current) {
        const currentStep = steps.current;
        const updatedCurrentStep = updatedSteps.find(
          (step) => step.id === currentStep.id,
        );

        dispatch(setCurrentStep(updatedCurrentStep || null));
      } else {
        console.warn("Warning, no current step!");
      }

      dispatch(setSteps(updatedSteps));
    } catch (e: any) {
      return rejectWithValue(handleRequestError(e));
    }
  },
);
