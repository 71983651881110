import { usePostHog } from "posthog-js/react";
import { useDispatch, useSelector } from "react-redux";
import { logoutThunk, selectUserData } from "src/store/slices/userData/slice";
import { AppDispatch } from "src/store/store";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../common/ui/dropdown-menu";
import * as React from "react";

const UserAvatar = () => {
  const dispatch: AppDispatch = useDispatch();
  const posthog = usePostHog();
  const userData = useSelector(selectUserData);

  if (!userData) {
    console.warn('User data not found!')
    return null;
  }

  const { firstName, lastName, email } = userData;
  const userInitials = (firstName[0] + lastName[0]).toUpperCase();
  const userFullName = `${firstName} ${lastName}`;

  const handleLogout = () => {
    posthog?.capture("The user logged out");

    dispatch(logoutThunk({ cleanLogout: true }));
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="size-8 rounded-full flex items-center justify-center bg-vePrimary cursor-pointer">
          <span className="text-[#fff] font-semibold text-xs">
            {userInitials}
          </span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel className="text-lg font-bold">
          My Account
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <div className="px-2 py-1.5 text-sm font-semibold">
          <div className="flex items-center space-x-2 w-[50%]">
            <span className="break-words max-w-full">{userFullName}</span>
          </div>
          <div className="mt-1 text-xs text-gray-600 break-words max-w-full">
            {email}
          </div>
        </div>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="cursor-pointer !text-red-600 font-medium"
            onClick={handleLogout}
          >
            Logout
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserAvatar;
