import { TFileTreeItem, TStorageFileItem } from "../../../../globalTypes";

type Props = {
  data: TStorageFileItem[];
  parentPath?: string;
  level?: number;
  contextFolderName?: string;
};

const processFilesData = ({
  data,
  parentPath,
  level,
  contextFolderName,
}: Props): Array<TFileTreeItem> => {
  return data.map((file) => {
    const { fileName, folder } = file;
    const fileNameSplit = fileName.split("/");
    const title = folder
      ? fileNameSplit[fileNameSplit.length - 2]
      : fileNameSplit[fileNameSplit.length - 1];

    return {
      ...file,
      title: title,
      parentId: parentPath || null,
      ...(folder && { expanded: false }),
      level: level === undefined ? 0 : level + 1,
      isContextDir: contextFolderName === title,
    };
  });
};

export default processFilesData;
