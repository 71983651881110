import { Empty, Select, Spin } from "antd";
import { FC, MutableRefObject, useRef, useState } from "react";
import { getMessageApi } from "../../store/slices/appSlice";
import handleRequestError from "../../utils/handleRequestError";
import { SelectOption } from "../../globalTypes";

type Props = {
  onSelect: (companyId: number, option: SelectOption | SelectOption[]) => void | Promise<void>;
  fetchOptions: (name: string) => Promise<SelectOption[]>;
  className?: string;
  disabled?: boolean;
};

const SearchableSelect: FC<Props> = ({ onSelect, fetchOptions, className, disabled }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const timeout: MutableRefObject<any> = useRef(null);
  const messageApi = getMessageApi();

  const handleSearch = (name: string) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    const getSearchItems = async () => {
      try {
        setIsFetching(true);
        setOptions([]);

        const options = await fetchOptions(name);

        setOptions(options);
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setIsFetching(false);
      }
    };

    if (name) {
      timeout.current = setTimeout(getSearchItems, 400);
    } else {
      setOptions([]);
      setIsFetching(false);
    }
  };

  return (
    <Select
      placeholder="Start typing to search"
      showSearch
      filterOption={false}
      loading={isFetching}
      onSearch={handleSearch}
      disabled={disabled}
      notFoundContent={<EmptySelectDropdown isFetching={isFetching} />}
      options={options}
      onChange={(value, option) => onSelect(value, option)}
      className={className}
    />
  );
};

export default SearchableSelect;

export const EmptySelectDropdown = ({ isFetching }: { isFetching: boolean }) => {
  return (
    <div className="relative">
      <Spin
        spinning={isFetching}
        className="absolute left-1/2 top-1/2 transform -translate-y-1/2"
      />
      <Empty
        style={{
          marginBlock: "12px",
          opacity: isFetching ? 0.2 : 1,
        }}
        imageStyle={{ height: "35px" }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    </div>
  );
};
