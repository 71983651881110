import React from "react";
import UserDetailsMainInfo from "../../../components/users/UserDetailsMainInfo";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import UserCompanyPermissionData from '../../../components/users/UserCompanyPermissionData/UserCompanyPermissionData';

const CompanyUserPage = () => {
  const { companyId } = useParams();
  const { hasUserUpdateRole } = useUserHasPermission({ companyId });

  return (
    <div className="flex flex-col gap-[24px]">
      <UserDetailsMainInfo
        hasUserUpdateRole={hasUserUpdateRole}
        goBackDefaultPath={`/companies/${companyId}/details/users`}
      />
      <div className="h-[1px] bg-[#E2E8F0]" />
      <UserCompanyPermissionData />
    </div>
  );
};

export default CompanyUserPage;
