import { useEffect, useMemo, useState } from "react";
import { MicrositeCompanyAssets, TFileTreeItem } from "../../../../globalTypes";
import { getMessageApi } from "../../../../store/slices/appSlice";
import handleRequestError from "../../../../utils/handleRequestError";
import processFilesData from "../utils/processFilesData";
import { LoadCompanyAssets, LoadFolderFiles, LoadRootFiles } from "../types";
import updateMicrositeStorageItem from "../utils/updateMicrositeStorageItem";
import shouldUpdateCompanyAssets from "../utils/shouldUpdateCompanyAssets";
import { useParams } from "react-router-dom";

type Props = {
  loadRootFiles: LoadRootFiles;
  loadFolderFiles: LoadFolderFiles;
  loadCompanyAssets?: LoadCompanyAssets; //used for microsite storage - loads all campaign assets
  contextFolderName?: string; //used for microsite storage - add label to context folder
};

const useDynamicStorage = ({
  loadRootFiles,
  loadFolderFiles,
  contextFolderName,
  loadCompanyAssets,
}: Props) => {
  const { campaignId } = useParams();
  const messageApi = getMessageApi();
  const [selectedItem, setSelectedItem] = useState<TFileTreeItem | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [treeData, setTreeData] = useState<Array<TFileTreeItem>>([]);
  const [rootPath, setRootPath] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [companyAssets, setCompanyAssets] =
    useState<MicrositeCompanyAssets | null>(null);
  const memoizedTreeData = useMemo(() => treeData, [treeData]);

  useEffect(() => {
    loadRootFolder();
  }, []);

  const loadRootFolder = async () => {
    try {
      setIsLoading(true);

      const rootStorageData = await loadRootFiles();

      if (!rootStorageData) {
        messageApi.error("Unable to get load storage data");
        return null;
      }

      let companyAssets: MicrositeCompanyAssets | null = null;

      if (loadCompanyAssets) {
        companyAssets = await loadCompanyAssets();
      }

      const { rootFolderFiles, rootStoragePath, rootStorageName } =
        rootStorageData;

      const rootDir: TFileTreeItem = {
        folder: true,
        size: 0,
        fileName: rootStoragePath,
        mimeType: "",
        title: rootStorageName || "[Root_Folder]",
        isRootDir: true,
        expanded: true,
        level: 0,
        parentId: null,
      };

      const processedData = processFilesData({
        data: rootFolderFiles,
        parentPath: rootStoragePath,
        level: 0,
        contextFolderName,
      });

      let treeData = [...processedData, rootDir];

      if (companyAssets) {
        treeData = updateMicrositeStorageItem({
          storageFiles: treeData,
          companyAssets,
          campaignId,
        });

        setCompanyAssets(companyAssets);
      }

      if (contextFolderName) {
        const contextFolder = processedData.find((item) => item.isContext);

        if (contextFolder) {
          setSelectedItem(contextFolder);
        }
      }

      setRootPath(rootStoragePath);
      setTreeData(treeData);
      setError(null);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);

      setError(customError.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getFolderFiles = async ({
    fileName,
    level,
    expanded,
    isRootDir,
  }: TFileTreeItem) => {
    //if not expanded - fetch files in dir, add them to state and set expanded to true
    if (!expanded) {
      const folderFiles = await loadFolderFiles({ path: fileName });
      const processedNewFiles = processFilesData({
        data: folderFiles,
        parentPath: fileName,
        level,
        contextFolderName,
      });
      let assetsData = companyAssets;

      if (loadCompanyAssets) {
        const isAssetsUpdateNeeded = shouldUpdateCompanyAssets({
          assetsData,
          loadedFiles: processedNewFiles,
        });

        if (isAssetsUpdateNeeded) {
          assetsData = await loadCompanyAssets();
        }
      }

      setTreeData((origin) => {
        const fileItems = [...origin];

        fileItems.forEach((fileItem) => {
          if (fileItem.fileName === fileName) {
            fileItem.expanded = true;
          }
        });

        let treeData = [...fileItems, ...processedNewFiles];

        if (assetsData) {
          treeData = updateMicrositeStorageItem({
            storageFiles: treeData,
            companyAssets: assetsData,
            campaignId,
          });
        }

        return treeData;
      });
    } else {
      //if expanded - set expanded to false and remove dir files from state
      setTreeData((origin) => {
        const fileItems: TFileTreeItem[] = [];

        origin.forEach((fileItem) => {
          if (fileItem.fileName === fileName) {
            fileItems.push({
              ...fileItem,
              expanded: false,
            });
          } else if (!isRootDir && !fileItem.parentId?.startsWith(fileName)) {
            fileItems.push(fileItem);
          }
        });

        return [...fileItems];
      });
    }
  };

  return {
    isLoading,
    treeData: memoizedTreeData,
    setTreeData,
    error,
    getFolderFiles,
    selectedItem,
    setSelectedItem,
    rootPath,
  };
};

export default useDynamicStorage;
