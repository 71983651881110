import React, { FC, useState } from "react";
import clsx from "clsx";
import { Handle, Position } from "reactflow";
import CCItemStateIcon from "../../../common/CCItemStateIcon";
import {
  DownstreamDepsModalDefaultNodeProps,
  DownstreamDepsModalGroupNodeProps,
  GraphColors,
} from "../../types";
import MenuDropdown, { MenuDropdownItem } from "../../../common/MenuDropdown";
import { Button } from "../../../common/ui/button";
import { CogIcon, Icons, MoveDownIcon } from "../../../common/Icons";
import { CampaignViewTypes } from "../../../../types";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { NavLink } from "react-router-dom";
import ExecuteCcItemModal from "../../../common/modals/ExecuteCCItemModal";

type DefaultNodeProps = {
  //set data props for each node in useGetNodesAndEdges hook
  data: DownstreamDepsModalDefaultNodeProps;
};

export const DefaultNode: FC<DefaultNodeProps> = ({
  data: { ccItem, phaseId, companyId, phaseName, stepName },
}) => {
  const {
    id: { key, stepId, campaignId },
    state,
    resultOvr,
  } = ccItem;
  const hasOverride = !!resultOvr;
  const { hasCampaignCcItemComputationRole } = useUserHasPermission({
    companyId,
  });
  const [isExecuteOpen, setIsExecuteOpen] = useState(false);
  const keyPath = `${phaseName}/${stepName}`;
  const goToKeyPath = `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}?view=${CampaignViewTypes.GRID}#${key}`;

  const onShowExecuteModal = () => {
    setIsExecuteOpen(true);
  };

  const onCloseExecuteModal = () => {
    setIsExecuteOpen(false);
  };

  const nodeMenuItems = [
    {
      key: "goToKeyDefinition",
      label: (
        <NavLink target="_blank" to={goToKeyPath}>
          Go To Key Definition
        </NavLink>
      ),
      disabled: false,
      icon: MoveDownIcon,
      className: "!text-vePrimary",
    },
    hasCampaignCcItemComputationRole && {
      key: "reRunDependencies",
      label: "Re-Run Dependencies",
      disabled: false,
      icon: CogIcon,
      onClick: (e: any) => {
        e.stopPropagation();
        onShowExecuteModal();
      },
      className: "!text-vePrimary",
    },
  ].filter((v) => !!v) as MenuDropdownItem[];

  const isNodeMenuEmpty = nodeMenuItems.length === 0;

  return (
    <div
      className={clsx("w-full h-full p-1", {
        "bg-[#eab308] bg-opacity-10": !!resultOvr,
      })}
    >
      <Handle
        className="!left-[-1px] opacity-0"
        type="target"
        position={Position.Left}
        isConnectable={false}
      />

      <div className="w-full h-full flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 overflow-hidden">
            <div className="flex-shrink-0">
              <CCItemStateIcon state={state} />
            </div>
            <span className="text-xs text-[#9e9e9e] truncate max-w-[230px]">
              {keyPath}
            </span>
          </div>

          {!isNodeMenuEmpty && nodeMenuItems && (
            <MenuDropdown items={nodeMenuItems} align="end">
              <Button
                variant={"ghostPrimary"}
                className="p-[1px] size-[25px]"
                onClick={(e) => e.stopPropagation()}
              >
                <Icons.MoreDots className="size-[20px]" />
              </Button>
            </MenuDropdown>
          )}
        </div>

        <div
          className={clsx(
            "w-full text-[#475569] font-semibold text-lg truncate",
            {
              [`!text-[${GraphColors.OVERRIDE}]`]: hasOverride,
            },
          )}
        >
          {key}
        </div>
      </div>

      <Handle
        className="!right-[-2px] opacity-0"
        type="source"
        position={Position.Right}
        isConnectable={false}
      />

      {isExecuteOpen && (
        <div onClick={(e) => e.stopPropagation()}>
          <ExecuteCcItemModal
            ccItemKey={key}
            closeModal={onCloseExecuteModal}
            isOpen={isExecuteOpen}
            stepId={stepId.toString()}
            campaignId={campaignId.toString()}
            showDownstream={false}
          />
        </div>
      )}
    </div>
  );
};

type GroupNodeProps = {
  data: DownstreamDepsModalGroupNodeProps;
};
export const GroupNode: FC<GroupNodeProps> = ({ data: { label } }) => {
  return (
    <div className="absolute">
      <strong className="relative top-[-5px] text-info">{label}</strong>
    </div>
  );
};
