import { TSocketMsg, TSocketMsgType } from "../types";
import processEventMessages from "./processEventMessages";
import { bulkUpdateCcVariables } from "../../ccVariablesSlice";
import {
  addBulkComputationMessages,
  addSocket,
  removeSocket,
  resetComputationMessages,
  setComputationInfo,
  setSocketShouldReconnect,
} from "../slice";
import parseMessageDataFromWS from "./parseMessageDataFromWS";
import { RootState } from "../../../store";
import { setupPingPong } from "./helpers";

type Props = {
  url: string;
  shouldUpdateCurrentStepCcItems?: boolean;
  campaignId: number;
  dispatch: any;
  getState: () => RootState;
};

export const initializeWebsocket = ({
  url,
  shouldUpdateCurrentStepCcItems,
  dispatch,
  campaignId,
  getState,
}: Props) => {
  const ws = new WebSocket(url);
  const messageQueue: TSocketMsg<TSocketMsgType>[][] = [];
  let isProcessing = false;

  // Start and stop ping-pong mechanism
  const { startPing, stopPing, clearPongTimeout } = setupPingPong(ws);

  const removeSocketWithCleanUp = ({ reconnect }: { reconnect: boolean }) => {
    stopPing();

    dispatch(removeSocket(campaignId));
    dispatch(setSocketShouldReconnect(reconnect));
  };

  const cleanupSocketWhenOffline = () => {
    console.warn("Internet is offline. Forcing socket closure.");
    window.removeEventListener("offline", cleanupSocketWhenOffline);

    ws.onclose = null;
    ws.onopen = null;
    ws.onmessage = null;
    ws.onerror = null;

    removeSocketWithCleanUp({ reconnect: true });
  };

  // Add event listener for offline event
  window.addEventListener("offline", cleanupSocketWhenOffline);

  ws.onopen = async function () {
    startPing();

    dispatch(addSocket({ campaignId, socket: ws }));
    dispatch(setSocketShouldReconnect(false));

    console.log(`Socket connected. campaignId: ${campaignId}`);
  };

  ws.onmessage = async function ({ data }) {
    if (typeof data === "string") {
      try {
        const message = JSON.parse(data);

        if (message?.type === "ACK" && message?.message === "pong") {
          // If a pong message is received, reset the timer
          clearPongTimeout();
        }
      } catch (e) {
        console.warn("Error parsing string to JSON: ", data);
      }
    }

    const messages = await parseMessageDataFromWS(data);

    if (!messages.length) return;

    let currentStepId: number | undefined = undefined;

    if (shouldUpdateCurrentStepCcItems !== undefined) {
      const {
        steps: { current },
      } = getState();
      if (current) {
        currentStepId = current.id;
      }
    }

    const processData = async (newMessages: TSocketMsg<TSocketMsgType>[]) => {
      isProcessing = true;

      const { eventMessagesMap, itemsToUpdate, computationInfo } =
        processEventMessages({
          messages: newMessages,
          currentStepId,
        });

      if (computationInfo) {
        dispatch(setComputationInfo(computationInfo));
      }

      if (itemsToUpdate.length) {
        dispatch(bulkUpdateCcVariables(itemsToUpdate));
      }

      if (eventMessagesMap) {
        dispatch(addBulkComputationMessages(eventMessagesMap));
      }

      isProcessing = false;

      // If there is data in the queue - process the following element
      if (messageQueue.length > 0) {
        const nextMessages = messageQueue.shift(); // Extracting some of the data from the beginning of the queue
        if (nextMessages) {
          processData(nextMessages);
        }
      }
    };

    if (isProcessing) {
      messageQueue.push(messages);
    } else {
      processData(messages);
    }
  };

  ws.onclose = function ({ wasClean, code }) {
    window.removeEventListener("offline", cleanupSocketWhenOffline);

    // if ws.close(4000) - need to reconnect because pong timeout
    if (!wasClean || code === 4000) {
      removeSocketWithCleanUp({ reconnect: true });
    } else {
      removeSocketWithCleanUp({ reconnect: false });

      dispatch(setComputationInfo(null));
      dispatch(resetComputationMessages());

      console.log(`Socket disconnected. campaignId: ${campaignId}`);
    }
  };

  ws.onerror = function () {
    window.removeEventListener("offline", cleanupSocketWhenOffline);

    removeSocketWithCleanUp({ reconnect: true });
  };
};
