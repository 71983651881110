import { Spin } from "antd";
import clsx from "clsx";
import React, { FC, useRef, useState } from "react";
import { TFileTreeItem } from "src/globalTypes";

import useHover from "../../../hooks/useHover";
import { getMessageApi } from "../../../store/slices/appSlice";
import { ChevronRightIcon } from "../../common/Icons";
import FolderIcon from "../FolderIcon";

type Props = {
  isSelected: boolean;
  item: TFileTreeItem;
  getFolderFiles: (selectedDir: TFileTreeItem) => Promise<void>;
  onSelectItem: () => void;
  renderMenu: (props: {
    isHovering: boolean;
    isSelected: boolean;
    item: TFileTreeItem;
  }) => React.ReactElement;
  columns: React.ReactElement | null;
};
const Folder: FC<Props> = ({
  item,
  getFolderFiles,
  onSelectItem,
  isSelected,
  renderMenu,
  columns,
}) => {
  const { expanded, title, isContext } = item;
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = getMessageApi();
  const folderRef = useRef<HTMLDivElement | null>(null);
  const isHovering = useHover(folderRef);

  const toggleExpandDir = async () => {
    try {
      setIsFetching(true);

      await getFolderFiles(item);
    } catch (e) {
      messageApi.error("Error when loading directory");
      console.error("Load dir err:", e);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div
      ref={folderRef}
      onClick={(e) => {
        e.stopPropagation();
        onSelectItem();
      }}
      className={clsx(
        "inline-flex items-center gap-[4px] py-[6px] pr-[8px] transition-all cursor-pointer rounded-md",
        {
          "text-vePrimary bg-[#EEF2FF]": isSelected,
          "hover:bg-[#eee]": !isSelected,
          "opacity-40 pointer-events-none": isFetching,
        },
      )}
    >
      <Spin spinning={isFetching} size="small">
        <div className="ml-[6px]">
          <ChevronRightIcon
            className={clsx("w-[20px] h-[20px]", {
              "rotate-90": expanded,
            })}
            onClick={toggleExpandDir}
          />
        </div>
      </Spin>
      <div className="flex items-center gap-[8px]">
        <div
          className={clsx("size-6", {
            "text-green-500": isContext,
          })}
        >
          <FolderIcon expanded={!!expanded} />
        </div>

        <div className="flex items-center gap-[28px]">
          <div className="flex items-center gap-[8px]">
            <div className="break-all">{title}</div>
          </div>

          <div className="flex-shrink-0">
            {renderMenu({ isHovering, isSelected, item })}
          </div>
        </div>
      </div>

      {columns}
    </div>
  );
};

export default Folder;
