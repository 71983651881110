import { format } from "date-fns";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteCompanyApi,
  disableCompanyApi,
  enableCompanyApi,
  getCompaniesApi,
} from "src/api/companies.api";
import { ArrowRightIcon, DeleteIcon, Icons } from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Button } from "src/components/common/ui/button";
import { TableCell, TableRow } from "src/components/common/ui/table";
import {
  selectCompaniesListState,
  setCompanies,
} from "src/store/slices/companiesListSlice";
import { getMessageApi } from "../../store/slices/appSlice";
import { TCompany } from "../../store/slices/companiesSlice";
import handleRequestError from "../../utils/handleRequestError";
import DeleteWithFilesModal from "../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import {
  ArrowTopRightOnSquareIcon,
  BoltIcon,
  BoltSlashIcon,
  MegaphoneIcon,
} from "@heroicons/react/16/solid";
import clsx from "clsx";
import useConfirm from "../../hooks/useConfirm";
import useNavigateWithOutletRedirect from "../../hooks/navigation/useNavigateWithOutletRedirect";
import { CompanyLogo } from "./CompanyLogo";

type Props = {
  item: TCompany;
};

const CompaniesListItem: FC<Props> = ({ item }) => {
  const { id, website, createdTime, name, logo, disabled } = item;
  const messageApi = getMessageApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const confirm = useConfirm();
  const { pageNumber, pageSize } = useSelector(selectCompaniesListState);
  const {
    hasCompanyReadRole,
    hasCompanyDeleteRole,
    hasCompanyDisableRole,
    hasCompanyEnableRole,
    hasCampaignListRole,
  } = useUserHasPermission({ companyId: id });
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();

  const navigateToDetails = () => {
    navigateWithOutletRedirect(`/companies/${id}/details`);
  };

  const updateCompanies = async () => {
    const { data } = await getCompaniesApi({
      name: "",
      pageSize,
      pageNumber,
    });

    dispatch(setCompanies(data));
  };
  const onDelete = async (removeFiles: boolean) => {
    try {
      await deleteCompanyApi({ companyId: id, removeFiles });
      await updateCompanies();

      messageApi.success("Company deleted successfully");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const handleEnableCompany = async (action: "enable" | "disable") => {
    try {
      let successMessage: string;

      if (action === "enable") {
        await enableCompanyApi({ companyId: id });
        successMessage = "Company enabled successfully";
      } else {
        await disableCompanyApi({ companyId: id });
        successMessage = "Company disabled successfully";
      }

      await updateCompanies();

      messageApi.success(successMessage);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const menuItems = [
    hasCompanyReadRole && {
      key: "details",
      label: "Details",
      onClick: navigateToDetails,
      icon: ArrowRightIcon,
    },
    hasCampaignListRole && {
      key: "campaigns",
      label: "View Campaigns",
      onClick: () =>
        navigate(
          `/campaigns?companyId=${id}&campaignType=ALL&page=1&pageSize=50&backUrl=${encodeURIComponent("/companies")}`,
        ),
      icon: MegaphoneIcon,
    },
    !disabled &&
      hasCompanyDisableRole && {
        key: "disableCompany",
        label: "Disable Company",
        onClick: () =>
          confirm({
            action: () => handleEnableCompany("disable"),
            title: "Disable Company",
          }),
        icon: BoltSlashIcon,
      },
    disabled &&
      hasCompanyEnableRole && {
        key: "enableCompany",
        label: "Enable Company",
        onClick: () =>
          confirm({
            action: () => handleEnableCompany("enable"),
            title: "Enable Company",
          }),
        icon: BoltIcon,
      },
    hasCompanyDeleteRole && {
      key: "delete",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <>
      <TableRow
        key={id}
        onClick={hasCompanyReadRole ? navigateToDetails : undefined}
        className={clsx("relative h-[48px] text-[#475569]", {
          "opacity-30": disabled,
          "cursor-pointer": hasCompanyReadRole,
        })}
      >
        <TableCell className="text-[#0F172A] font-medium">
          <div className="flex items-center gap-[8px]">
            <CompanyLogo url={logo} />
            <span>{name}</span>
          </div>
        </TableCell>
        <TableCell>
          {website ? (
            <Link
              to={website?.startsWith("http") ? website : `http://${website}`}
              target="_blank"
              rel="noopener noreferrer"
              className="font-normal text-[16px] text-vePrimary break-words flex items-center gap-[4px]"
              onClick={(e) => e.stopPropagation()}
            >
              {website?.replace("https://", "").replace("http://", "")}
              <ArrowTopRightOnSquareIcon className="size-[16px]" />
            </Link>
          ) : (
            <span>Website not designated.</span>
          )}
        </TableCell>
        <TableCell>
          {createdTime ? format(createdTime, "MMMM d, yyyy h:mm a zzz") : "-"}
        </TableCell>

        {menuItems.length ? (
          <TableCell onClick={(e) => e.stopPropagation()}>
            <MenuDropdown
              items={menuItems}
              dropdownClassName="w-[190px]"
              align="end"
            >
              <Button
                variant={"ghostPrimary"}
                className="size-[40px] p-2 flex justify-end"
              >
                <Icons.MoreDots className="size-[24px]" />
              </Button>
            </MenuDropdown>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>

      {hasCompanyDeleteRole && isDeleteModalOpen && (
        <DeleteWithFilesModal
          entity="Company"
          onDelete={onDelete}
          title={name}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}
    </>
  );
};

export default CompaniesListItem;
