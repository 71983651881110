import React, { FC } from "react";
import Storage from "../dynamicStorage/Storage";
import { useParams } from "react-router-dom";
import {
  LoadCompanyAssets,
  LoadFolderFiles,
  LoadRootFiles,
} from "../dynamicStorage/types";
import { getMicroSiteStorageFilesApi } from "../../../api/microsite-storage.api";
import useDynamicStorage from "../dynamicStorage/hooks/useDynamicStorage.hook";
import { TFileTreeItem } from "../../../globalTypes";
import ItemMenu from "./ItemMenu";
import { getMicrositeCompanyAssetsApi } from "../../../api/microsite.api";
import { MICRO_SITE_POSTFIX } from "../../../constants";
import { getMessageApi } from "../../../store/slices/appSlice";
import { useSelector } from "react-redux";
import {
  selectCompanyStorageCampaignData,
  selectCompanyStorageMicroSiteData,
} from "../../../store/slices/companyStorageSlice";

const MicrositeStorage: FC = () => {
  const { companyId } = useParams();
  const messageApi = getMessageApi();
  const companyMicroSite = useSelector(selectCompanyStorageMicroSiteData);
  const campaignData = useSelector(selectCompanyStorageCampaignData);
  const ROOT_PATH = "/";

  const loadRootFiles: LoadRootFiles = async () => {
    if (!companyId) {
      return null;
    }

    if (!companyMicroSite) {
      messageApi.error("The company does not have a microsite");
      return null;
    }

    const { subDomain, siteContext } = companyMicroSite;
    const micrositePath = siteContext ? `/${siteContext}` : "";
    const rootStorageName = `[${subDomain}${MICRO_SITE_POSTFIX}${micrositePath}]`;

    const { data } = await getMicroSiteStorageFilesApi({
      companyId,
      path: ROOT_PATH,
    });

    return {
      rootFolderFiles: data,
      rootStoragePath: ROOT_PATH,
      rootStorageName,
    };
  };

  const loadFolderFiles: LoadFolderFiles = async ({ path }) => {
    if (!companyId) {
      console.error("Unable to get [companyId] from URL");
      return [];
    }

    const { data } = await getMicroSiteStorageFilesApi({
      companyId,
      path,
    });

    return data;
  };

  const loadCompanyAssets: LoadCompanyAssets = async () => {
    if (!companyId) {
      console.error("Unable to get [companyId] from URL");
      return null;
    }

    const { data } = await getMicrositeCompanyAssetsApi({ companyId });
    return data;
  };

  const {
    setSelectedItem,
    selectedItem,
    treeData,
    setTreeData,
    getFolderFiles,
    isLoading,
    error,
    rootPath,
  } = useDynamicStorage({
    loadRootFiles,
    loadFolderFiles,
    loadCompanyAssets,
    contextFolderName:
      campaignData && campaignData.microSiteContextFolder
        ? campaignData.microSiteContextFolder.replace(/\//g, "")
        : undefined,
  });

  const renderMenu = ({
    isHovering,
    isSelected,
    item,
  }: {
    isHovering: boolean;
    isSelected: boolean;
    item: TFileTreeItem;
  }) => {
    if (!companyId) {
      return <span className="text-red-500">Error</span>;
    }
    if (!companyMicroSite) {
      return <span className="text-red-500">Microsite not found</span>;
    }
    return (
      <ItemMenu
        isHovering={isHovering}
        isSelected={isSelected}
        item={item}
        setTreeData={setTreeData}
        treeData={treeData}
        contextPath={rootPath}
        companyId={companyId}
        microsite={companyMicroSite}
      />
    );
  };

  return (
    <Storage
      setSelectedItem={setSelectedItem}
      getFolderFiles={getFolderFiles}
      isLoading={isLoading}
      error={error}
      treeData={treeData}
      selectedItem={selectedItem}
      renderMenu={renderMenu}
    />
  );
};

export default MicrositeStorage;
