import {
  GraphColors,
  UpstreamDepsModalDefaultNodeProps,
  UpstreamDepsModalGroupNodeProps,
} from "../../types";
import React, { FC, useState } from "react";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { useNavigate } from "react-router-dom";
import {
  CogIcon,
  ExecuteIcon,
  Icons,
  LabelsIcon,
  MoveDownIcon,
  SolidEditIcon,
} from "../../../common/Icons";
import { CampaignViewTypes } from "../../../../types";
import MenuDropdown, { MenuDropdownItem } from "../../../common/MenuDropdown";
import clsx from "clsx";
import { Handle, Position } from "reactflow";
import CCItemStateIcon from "../../../common/CCItemStateIcon";
import { Button } from "../../../common/ui/button";
import ExecuteCcItemModal from "../../../common/modals/ExecuteCCItemModal";

type DefaultNodeProps = {
  //set data props for each node in useGetNodesAndEdges hook
  data: UpstreamDepsModalDefaultNodeProps;
};

export const DefaultNode: FC<DefaultNodeProps> = ({
  data: {
    ccItem,
    phaseId,
    companyId,
    setInputNode,
    setTargetNode,
    setTargetWithInputNode,
  },
}) => {
  const {
    id: { key, stepId, campaignId },
    state,
    resultOvr,
  } = ccItem;
  const hasOverride = !!resultOvr;
  const { hasCampaignCcItemComputationRole } = useUserHasPermission({
    companyId,
  });
  const navigate = useNavigate();
  const [isExecuteOpen, setIsExecuteOpen] = useState(false);

  const onShowExecuteModal = () => {
    setIsExecuteOpen(true);
  };

  const onCloseExecuteModal = () => {
    setIsExecuteOpen(false);
  };

  const onGoToKey = () => {
    if (phaseId !== undefined && companyId !== undefined) {
      const path = `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}?view=${CampaignViewTypes.GRID}#${key}`;
      navigate(path);
    } else {
      console.error("phaseId/companyId is undefined!");
    }
  };

  const nodeMenuItems = [
    {
      key: "setInput",
      label: "Set Input",
      disabled: false,
      icon: ExecuteIcon,
      onClick: setInputNode,
      className: "!text-vePrimary",
    },
    {
      key: "setTarget",
      label: "Set Target",
      disabled: false,
      icon: LabelsIcon,
      onClick: setTargetNode,
      className: "!text-vePrimary",
    },
    {
      key: "setTargetWithDep",
      label: "Set Target With Dep",
      disabled: false,
      icon: SolidEditIcon,
      onClick: setTargetWithInputNode,
      className: "!text-vePrimary",
    },
    {
      key: "goToKeyDefinition",
      label: "Go To Key Definition",
      disabled: false,
      icon: MoveDownIcon,
      onClick: onGoToKey,
      className: "!text-vePrimary",
    },
    hasCampaignCcItemComputationRole && {
      key: "reRunDependencies",
      label: "Re-Run Dependencies",
      disabled: false,
      icon: CogIcon,
      onClick: onShowExecuteModal,
      className: "!text-vePrimary",
    },
  ].filter((v) => !!v) as MenuDropdownItem[];

  const isNodeMenuEmpty = nodeMenuItems.length === 0;

  return (
    <>
      <div
        className={clsx("w-full h-full pl-[8px]", {
          "bg-[#eab308] bg-opacity-30": !!resultOvr,
        })}
      >
        <Handle
          className="!left-[-1px] opacity-0"
          type="target"
          position={Position.Left}
          isConnectable={false}
        />

        <div className="relative w-full h-full flex justify-between items-center">
          <div className="absolute -top-[9px] -left-[29px]">
            <CCItemStateIcon state={state} />
          </div>
          <div
            className={clsx(
              "w-[85%] text-[#475569] text-xs overflow-hidden text-ellipsis whitespace-nowrap",
              {
                [`!text-[${GraphColors.OVERRIDE}]`]: hasOverride,
                "!w-full": isNodeMenuEmpty,
              },
            )}
          >
            {key}
          </div>

          {!isNodeMenuEmpty && nodeMenuItems && (
            <div className="absolute top-[6px] right-[4px]">
              <MenuDropdown items={nodeMenuItems} align="end">
                <Button
                  variant={"ghostPrimary"}
                  className="p-[1px] size-[25px]"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Icons.MoreDots className="size-[20px]" />
                </Button>
              </MenuDropdown>
            </div>
          )}
        </div>

        <Handle
          className="!right-[-2px] opacity-0"
          type="source"
          position={Position.Right}
          isConnectable={false}
        />
      </div>

      {isExecuteOpen && (
        <ExecuteCcItemModal
          ccItemKey={key}
          closeModal={onCloseExecuteModal}
          isOpen={isExecuteOpen}
          stepId={stepId.toString()}
          campaignId={campaignId.toString()}
          showDownstream={false}
        />
      )}
    </>
  );
};

type GroupNodeProps = {
  data: UpstreamDepsModalGroupNodeProps;
};
export const GroupNode: FC<GroupNodeProps> = ({ data: { label } }) => {
  return (
    <div className="absolute">
      <strong className="relative top-[-5px] text-info">{label}</strong>
    </div>
  );
};
