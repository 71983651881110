import React, { FC, useState } from "react";
import { DataNode } from "antd/lib/tree";
import { Spin, Tree } from "antd";
import handleRequestError from "../../../utils/handleRequestError";
import {
  createGlobalSecurityPermissionOverrideApi,
  deleteGlobalSecurityPermissionOverrideApi,
} from "../../../api/global-permissions-override.api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { TPermissionOverride } from "../../../globalTypes";
import { UserPermissions } from "../../../store/slices/userData/constants";
import {
  TPermissionGroups,
  TPermissionsLevel,
} from "../../../types/securityGroups";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { Icons } from "src/components/common/Icons";

type Props = {
  permissions: UserPermissions[];
  permissionOverrides: TPermissionOverride;
  updatePermissionsList: () => Promise<void>;
  permissionsTree: TPermissionGroups;
  level: TPermissionsLevel;
};

const { denyOverrideIcon, allowOverrideIcon } = Icons;

const UserGlobalPermissionsTree: FC<Props> = ({
  permissions,
  permissionOverrides,
  updatePermissionsList,
  permissionsTree,
  level,
}) => {
  const { userId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [loadingIds, setLoadingIds] = useState<UserPermissions[]>([]);
  const { hasGlobalPermOvrCreateRole, hasGlobalPermOvrDeleteRole } =
    useUserHasPermission({});

  const startLoading = (id: UserPermissions) => {
    setLoadingIds((prev) => [...prev, id]);
  };

  const stopLoading = (id: UserPermissions) => {
    setLoadingIds((prev) => prev.filter((loadingId) => loadingId !== id));
  };

  //if accessType provided - create override, otherwise delete override
  const doOverride = async (
    permission: UserPermissions,
    accessType?: "ALLOW" | "DENY",
  ) => {
    try {
      if (!userId) return;

      startLoading(permission);
      if (accessType) {
        await createGlobalSecurityPermissionOverrideApi({
          accessType,
          permission,
          userId,
        });
      } else {
        await deleteGlobalSecurityPermissionOverrideApi({
          permission,
          userId,
        });
      }
      await updatePermissionsList();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      stopLoading(permission);
    }
  };

  const getIcon = ({
    perm,
    icon,
    action,
  }: {
    perm: UserPermissions;
    action?: "ALLOW" | "DENY";
    icon: React.ReactNode;
  }) => {
    const iconWrapClassName =
      "flex justify-center items-center w-[18px] h-[18px] border-[1px] transition-colors rounded p-[1px] hover:bg-[#eee]";
    return (
      <div
        className={iconWrapClassName}
        onClick={() => doOverride(perm, action)}
      >
        {icon}
      </div>
    );
  };

  const getTitle = ({
    permissions,
    permissionOverrides,
    loadingIds,
    perm,
  }: {
    perm: UserPermissions;
    permissionOverrides: TPermissionOverride;
    permissions: UserPermissions[];
    loadingIds: UserPermissions[];
  }) => {
    const isLoading = loadingIds.includes(perm);

    //by default assume that permission doesn't exist, show allow button if the user hase "hasGlobalPermOvrCreateRole" role
    let iconElement = hasGlobalPermOvrCreateRole
      ? getIcon({
          perm,
          action: "ALLOW",
          icon: allowOverrideIcon,
        })
      : null;
    let textColor = "text-[#CBCACA]";
    let fontStyle = "";

    //if permission exists, show deny button if the user hase "hasGlobalPermOvrCreateRole" role
    if (permissions.includes(perm)) {
      iconElement = hasGlobalPermOvrCreateRole
        ? getIcon({ perm, action: "DENY", icon: denyOverrideIcon })
        : null;
      textColor = "text-vePrimary";
      fontStyle = "font-medium";
    }

    //if permission denied, show delete override button if the user hase "hasGlobalPermOvrDeleteRole" role
    if (permissionOverrides[perm] === "DENY") {
      iconElement = hasGlobalPermOvrDeleteRole
        ? getIcon({ perm, icon: allowOverrideIcon })
        : null;
      textColor = "text-red-600";
      fontStyle = "font-medium";
    }

    //if permission allowed, show delete override button if the user hase "hasGlobalPermOvrDeleteRole" role
    if (permissionOverrides[perm] === "ALLOW") {
      iconElement = hasGlobalPermOvrDeleteRole
        ? getIcon({ perm, icon: denyOverrideIcon })
        : null;
      textColor = "text-green-600";
      fontStyle = "font-medium";
    }

    const titleContent = (
      <>
        {iconElement}
        <span className={`text-[12px] ${textColor} ${fontStyle}`}>{perm}</span>
      </>
    );

    return (
      <Spin spinning={isLoading}>
        <div className="flex gap-[8px] items-center">{titleContent}</div>
      </Spin>
    );
  };

  const getTreeData = (): DataNode[] => {
    return Object.keys(permissionsTree).map((key) => ({
      title: <span className="font-semibold">{key}</span>,
      key: key,
      children: permissionsTree[key].map((perm) => ({
        title: getTitle({ perm, permissionOverrides, permissions, loadingIds }),
        key: perm,
      })),
    }));
  };

  return (
    <div className="flex flex-col gap-[12px]">
      <span className="font-bold text-[16px] text-[#475569]">
        User {level} permissions
      </span>
      <Tree selectable={false} treeData={getTreeData()} defaultExpandAll />
    </div>
  );
};

export default UserGlobalPermissionsTree;
