import { TLabelLanguage, TLabels } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

export const getCampaignLabelsApi = ({
  campaignId,
  lang,
}: {
  campaignId: number;
  lang: TLabelLanguage;
}) => {
  return axiosInstance.get<TLabels>(
    `/api/secured/campaign/${campaignId}/i18n/${lang}/`,
  );
};

export const createCampaignLabelApi = ({
  campaignId,
  lang,
  label,
  key,
}: {
  campaignId: string;
  label: string;
  key: string;
  lang: TLabelLanguage;
}) => {
  return axiosInstance.post<string>(
    `/api/secured/campaign/${campaignId}/i18n/${lang}/`,
    {
      id: {
        key,
      },
      label,
    },
  );
};

export const updateCampaignLabelApi = ({
  campaignId,
  lang,
  label,
  key,
}: {
  campaignId: string;
  label: string;
  key: string;
  lang: TLabelLanguage;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/campaign/${campaignId}/i18n/${lang}/`,
    {
      id: {
        key,
      },
      label,
    },
  );
};

export const bulkDeleteCampaignLabelsApi = ({
  campaignId,
  lang,
  keys,
}: {
  campaignId: string;
  keys: string[];
  lang: TLabelLanguage;
}) => {
  return axiosInstance.delete<string>(
    `/api/secured/campaign/${campaignId}/i18n/${lang}/bulk/`,
    {
      data: keys,
    },
  );
};
