import { FC, useEffect, useState } from "react";
import Chat from "./Chat";
import { Button } from "../common/ui/button";
import { Plus } from "lucide-react";
import {
  ChatMessage,
  ChatOverride,
  createChatApi,
  getChatApi,
  getChatMessagesApi,
  getChatOverridesApi,
  updateChatOverrideApi,
} from "src/api/chat.api";
import { useCampaignChatParams } from "./useCampaignChatParams";
import { getCcVarsApi, saveResultOvrApi } from "src/api/cc-variables.api";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { CCVarsGrid } from "./CCVarsGrid";
import { Chat as ChatType } from "src/api/chat.api";
import { useNavigate } from "react-router-dom";
import { handleRequestError } from "src/utils/handleRequestError";
import { getMessageApi } from "src/store/slices/appSlice";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../common/ui/dialog";

const CampaignChat: FC = () => {
  const { chatId, companyId } = useCampaignChatParams();
  const navigate = useNavigate();
  const messageApi = getMessageApi();
  const [chatOverrides, setChatOverrides] = useState<ChatOverride[]>([]);
  const [chat, setChat] = useState<ChatType | null>(null);
  const [ccVars, setCcVars] = useState<TCcVariable[]>([]);
  const [selectedCcVars, setSelectedCcVars] = useState<TCcVariable[]>([]);
  const [showNewChatDialog, setShowNewChatDialog] = useState(false);

  useEffect(() => {
    if (!chat?.campaignId || !chat?.stepId) return;

    const fetchCcVars = async () => {
      try {
        const response = await getCcVarsApi({
          campaignId: chat.campaignId,
          stepId: chat.stepId,
        });
        setCcVars(response.data);
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      }
    };

    fetchCcVars();
  }, [chatId, chat?.campaignId, chat?.stepId]);

  useEffect(() => {
    try {
      const fetchCurrentChat = async () => {
        if (!chatId) return;

        const response = await getChatApi({
          chatId: Number(chatId),
        });

        setChat(response.data);
      };

      fetchCurrentChat();
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  }, [chatId]);

  useEffect(() => {
    if (!chatId) return;

    try {
      const fetchOverrides = async () => {
        try {
          const response = await getChatOverridesApi({
            chatId: Number(chatId),
          });
          setChatOverrides(
            response.data.filter((override) => override.status === "READY"),
          );
        } catch (e: any) {
          const customError = handleRequestError(e);
          messageApi.error(customError.message);
          console.error(customError);
        }
      };

      const intervalId = setInterval(fetchOverrides, 1000);

      fetchOverrides();
      return () => clearInterval(intervalId);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  }, [chatId]);

  const handleCreateNewChat = async () => {
    if (!chat?.campaignId || !chat?.stepId) return;

    try {
      const response = await createChatApi({
        campaignId: chat.campaignId,
        stepId: chat.stepId,
        chat: {
          title: "New Chat",
          aiModelId: 1,
        },
      });

      navigate(`/campaigns/company/${companyId}/chat/${response.data.id}`);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const rejectOneOverride = async (chatOverride: ChatOverride) => {
    try {
      await updateChatOverrideApi({
        chatOverride: {
          id: {
            chatId: chatId,
            key: chatOverride.id.key,
          },
          text: chatOverride.text,
          status: "APPLIED",
        },
      });
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const applyOneOverride = async (key: string, value: string) => {
    try {
      await saveResultOvrApi({
        campaignId: chat?.campaignId!,
        stepId: chat?.stepId!,
        key,
        value,
      });

      await updateChatOverrideApi({
        chatOverride: {
          id: {
            chatId: chatId,
            key,
          },
          text: value,
          status: "APPLIED",
        },
      });

      setCcVars(
        ccVars.map((ccVar) => {
          if (ccVar.id.key === key) {
            return { ...ccVar, resultOvr: value };
          }
          return ccVar;
        }),
      );

      setChatOverrides(
        chatOverrides.filter((override) => override.id.key !== key),
      );
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const [messages, setMessages] = useState<ChatMessage[]>([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await getChatMessagesApi({
          chatId: chatId,
        });

        setMessages(response.data);
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      }
    };

    fetchMessages();
    const intervalId = setInterval(fetchMessages, 1000);

    return () => clearInterval(intervalId);
  }, [chatId]);

  const unselectCcVar = (ccVar: TCcVariable) => {
    setSelectedCcVars(selectedCcVars.filter((v) => v !== ccVar));
  };

  const handleCreateNewChatClick = () => {
    if (selectedCcVars.length > 0) {
      setShowNewChatDialog(true);
    } else {
      handleCreateNewChat();
    }
  };

  const handleConfirmNewChat = () => {
    setShowNewChatDialog(false);
    setSelectedCcVars([]);
    handleCreateNewChat();
  };

  return (
    <div className="flex h-full">
      <div className="w-[400px] bg-[#F8FAFC]">
        <div className="flex gap-4 h-full">
          <div className="flex flex-col w-[720px] h-[calc(100vh-64px)]">
            <Button
              variant="outline"
              onClick={handleCreateNewChatClick}
              className="m-4"
              disabled={messages.length === 0}
            >
              <Plus className="size-4" />
              New Chat
            </Button>
            {chatId && (
              <Chat
                chatId={chatId}
                messages={messages}
                setMessages={setMessages}
                selectedCcVars={selectedCcVars}
                unselectCcVar={unselectCcVar}
              />
            )}
          </div>
        </div>
      </div>
      <div className="w-full">
        <CCVarsGrid
          ccVars={ccVars}
          selectedCcVars={selectedCcVars}
          setSelectedCcVars={setSelectedCcVars}
          chatOverrides={chatOverrides}
          onApplyOverride={applyOneOverride}
          onRejectOverride={rejectOneOverride}
        />
      </div>

      <Dialog open={showNewChatDialog} onOpenChange={setShowNewChatDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create New Chat</DialogTitle>
            <DialogDescription>
              Creating a new chat will deselect all currently selected CC
              variables. Do you want to continue?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowNewChatDialog(false)}
            >
              Cancel
            </Button>
            <Button onClick={handleConfirmNewChat}>Continue</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CampaignChat;
