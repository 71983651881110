import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TAsset } from "../../../globalTypes";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { getAssetsApi } from "../../../api/assets.api";
import handleRequestError from "../../../utils/handleRequestError";

const useGetAssets = () => {
  const { campaignId, stepId } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [assets, setAssets] = useState<TAsset[]>([]);
  const messageApi = useSelector(selectMessageApi);

  useEffect(() => {
    loadAssets();
  }, [campaignId, stepId]);

  const loadAssets = async () => {
    try {
      if (!campaignId || !stepId) {
        messageApi.error("Unable to get [campaignId] or [stepId] from URL");
        return;
      }

      setIsFetching(true);

      const { data } = await getAssetsApi({ campaignId, stepId });

      setAssets(data);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    assets,
    isFetching,
    loadAssets,
  };
};

export default useGetAssets;
