import React from "react";
import useGetMicroSiteData from "./hooks/useGetMicroSiteData";
import { Alert, Skeleton, Spin } from "antd";
import MicrositeForm from "./MicrositeForm";
import { Badge } from "../common/ui/badge";
import { Link, useParams } from "react-router-dom";
import { MICRO_SITE_POSTFIX } from "../../constants";
import { stateLabels } from "./constants";
import { MicrositeState } from "../../globalTypes";
import { Button } from "../common/ui/button";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import DeleteWithTitleConfirmationModal from "../common/modals/DeleteWithTitleConfirmationModal/DeleteWithTitleConfirmationModal";
import useDeleteMicroSite from "./hooks/useDeleteMicroSite";

const MicroSiteData = () => {
  const { companyId } = useParams();
  const { initialized, microSite } = useGetMicroSiteData();
  const {
    loading,
    isDeleteModalOpen,
    handleRemoveMicroSite,
    setIsDeleteModalOpen,
  } = useDeleteMicroSite();
  const { hasCompanyMicrositeDeleteRole, hasCompanyMicrositeCreateRole } =
    useUserHasPermission({ companyId });

  if (!initialized) {
    return <Skeleton active />;
  }

  if (!microSite) {
    return (
      <div>
        <div className="flex flex-col gap-[2px]">
          <div className="font-bold text-[#0F172A] text-[20px]">Microsite</div>
          <div className="font-sans text-[#475569] text-[14px]">
            {hasCompanyMicrositeCreateRole
              ? "Create a customer subdomain"
              : "The Microsite is not configured"}
          </div>
        </div>
        {hasCompanyMicrositeCreateRole && <MicrositeForm />}
      </div>
    );
  }

  const { state, enableHttps, subDomain, siteContext, metaInfo } = microSite;
  const stateProps = stateLabels[state as MicrositeState] || {
    label: "Unknown state",
    className: "!bg-red-100 !text-red-900",
  };
  const isAlertVisible =
    metaInfo &&
    ["ERROR", "PENDING_VALIDATION"].includes(state as MicrositeState);
  const alertType = state === "ERROR" ? "error" : "info";

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="w-[400px]">
          <div className="flex flex-col items-start">
            <div className="font-bold text-[#475569] text-[14px] inline-flex gap-2">
              <span>Microsite</span>
              <Badge className={`${stateProps.className}`}>
                {state === "PENDING_VALIDATION" && (
                  <Spin size="small" className="mr-1" />
                )}
                {stateProps.label}
              </Badge>
              <Badge
                className={`${enableHttps ? "!text-green-500 !bg-green-100" : "!text-red-900 !bg-red-100"}`}
              >
                HTTPS {enableHttps ? "Enabled" : "Disabled"}
              </Badge>
            </div>
            <Link
              className="flex justify-center items-center gap-2 text-[16px] text-vePrimary"
              to={`${enableHttps ? "https" : "http"}://${subDomain}${MICRO_SITE_POSTFIX}/${siteContext}`}
              target="_blank"
              rel="noreferrer"
            >
              Visit {subDomain}
              {MICRO_SITE_POSTFIX}/{siteContext}
            </Link>
          </div>
        </div>

        {hasCompanyMicrositeDeleteRole && (
          <Button
            variant="destructive"
            className="rounded-full px-6"
            onClick={() => setIsDeleteModalOpen(true)}
            loading={loading}
            disabled={state === "PENDING_VALIDATION"}
          >
            Delete
          </Button>
        )}

        {hasCompanyMicrositeDeleteRole && isDeleteModalOpen && (
          <DeleteWithTitleConfirmationModal
            entity="microsite"
            onDelete={handleRemoveMicroSite}
            title={subDomain}
            setIsModalOpen={setIsDeleteModalOpen}
            isModalOpen={isDeleteModalOpen}
          />
        )}
      </div>

      {isAlertVisible && <Alert message={metaInfo} type={alertType} />}
    </div>
  );
};

export default MicroSiteData;
