import { TStorageFileItem } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

export const getMicroSiteStorageFilesApi = ({
  path,
  companyId,
}: {
  path: string;
  companyId: string;
}) => {
  return axiosInstance.get<TStorageFileItem[]>(
    `/api/secured/company/${companyId}/microsite/fs/list`,
    {
      params: {
        path,
      },
    },
  );
};

export const getMicroSiteStorageFileApi = ({
  path,
  companyId,
}: {
  path: string;
  companyId: string;
}) => {
  return axiosInstance.get<ArrayBuffer>(
    `/api/secured/company/${companyId}/microsite/fs/file`,
    {
      responseType: "arraybuffer",
      params: {
        path,
      },
    },
  );
};

export const downloadMicroSiteStoragePDFFileApi = ({
  path,
  companyId,
}: {
  path: string;
  companyId: string;
}) => {
  return axiosInstance.get<ArrayBuffer>(
    `/api/secured/company/${companyId}/microsite/fs/html2Pdf`,
    {
      responseType: "arraybuffer",
      params: {
        path,
      },
    },
  );
};

export const deleteMicroSiteStorageFileApi = ({
  path,
  companyId,
}: {
  path: string;
  companyId: string;
}) => {
  return axiosInstance.delete<string>(
    `/api/secured/company/${companyId}/microsite/fs/file`,
    {
      params: {
        path,
      },
    },
  );
};

export const uploadMicroSiteStorageFileApi = ({
  formData,
  companyId,
}: {
  formData: FormData;
  companyId: string;
}) => {
  return axiosInstance.post<TStorageFileItem>(
    `/api/secured/company/${companyId}/microsite/fs/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};

export const copyMicroSiteStorageFileApi = ({
  sourcePath,
  targetPath,
  companyId,
}: {
  sourcePath: string;
  targetPath: string;
  companyId: string;
}) => {
  return axiosInstance.post<TStorageFileItem>(
    `/api/secured/company/${companyId}/microsite/fs/copy`,
    {},
    {
      params: {
        companyId,
        sourcePath,
        targetPath,
      },
    },
  );
};

export const getMicroSiteStorageFileLinkApi = ({
  path,
  companyId,
}: {
  path: string;
  companyId: string;
}) => {
  return axiosInstance.get<string>(
    `/api/secured/company/${companyId}/microsite/fs/link`,
    {
      params: {
        path,
      },
    },
  );
};
