import React, { FC, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { getUserDataApi } from "../../api/user.api";
import handleRequestError from "../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../store/slices/appSlice";
import { Popover, Spin } from "antd";
import { TUser } from "../../globalTypes";

type Props = {
  modifiedBy: null | number;
  modifiedTime: string;
  className?: string;
};

const ModifiedBy: FC<Props> = ({ modifiedBy, modifiedTime, className }) => {
  const { companyId } = useParams();
  const { hasUserReadRole } = useUserHasPermission({ companyId });
  const [isFetching, setIsFetching] = useState(false);
  const [modifierUserData, setModifierUserData] = useState<TUser | null>(null);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const formattedDate = dayjs(modifiedTime).format("MMM D, YYYY h:mm:ss A");

  const onClick = async () => {
    try {
      if (!modifiedBy) return;

      // if already loaded
      if (modifierUserData) {
        setIsUserInfoOpen(true);
        return;
      }

      setIsFetching(true);

      const { data } = await getUserDataApi({ userId: modifiedBy });

      setModifierUserData(data);
      setIsUserInfoOpen(true);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={className}>
      <Spin spinning={isFetching} size="small">
        <Popover
          content={
            <div className="flex flex-col">
              <span className="font-semibold">Modified by:</span>
              <span className="text-vePrimary">
                {modifierUserData?.firstName} {modifierUserData?.lastName}{" "}
                <span className="text-xs">({modifierUserData?.position})</span>
              </span>
              <span className="text-xs text-gray-600 break-words max-w-full">
                {modifierUserData?.email}
              </span>
            </div>
          }
          // trigger="click"
          open={isUserInfoOpen}
          onOpenChange={(val) => {
            if (!val) setIsUserInfoOpen(val);
          }}
        >
          <div
            onClick={hasUserReadRole ? onClick : undefined}
            className="p-[4px] border-vePrimary/15 border-[1px] rounded bg-vePrimary/10 cursor-pointer opacity-30 hover:opacity-100 transition-opacity"
          >
            <div className="flex gap-1 text-slate-500 text-[10px] font-semibold">
              <span>Last Modified:</span>
              <span>{formattedDate}</span>
            </div>
          </div>
        </Popover>
      </Spin>
    </div>
  );
};

export default ModifiedBy;
