import { Checkbox, Typography } from "antd";
import { FC } from "react";
import { TAsset } from "../../../globalTypes";
import { TableCell, TableRow } from "../../common/ui/table";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../../constants";
import clsx from "clsx";
import AssetListItemMenu from "./AssetListItemMenu";
import {
  toggleApproveAssetApi,
  toggleApprovePublishApi,
} from "../../../api/assets.api";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import useConfirm from "../../../hooks/useConfirm";

type Props = {
  asset: TAsset;
  loadAndSetAssets: () => Promise<void>;
};

const AssetListItem: FC<Props> = ({ asset, loadAndSetAssets }) => {
  const messageApi = useSelector(selectMessageApi);
  const { companyId } = useParams();
  const confirm = useConfirm();
  const { hasAssetApproveRole, hasAssetApprovePubRole } = useUserHasPermission({
    companyId,
  });

  const {
    title,
    ref,
    id,
    approved,
    publishToMicroSite,
    publishedDate,
    microSiteTargetFolder,
    campaignId,
  } = asset;
  const publishedDateValidated = publishedDate
    ? `Published: ${new Date(publishedDate).toLocaleString()}`
    : "Not published yet";

  const onApproveAsset = async (approved: boolean) => {
    try {
      await toggleApproveAssetApi({
        campaignId,
        assetId: id,
        approved,
      });

      await loadAndSetAssets();

      messageApi.success("The status has been changed");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onApprovePublish = async (publishToMicroSite: boolean) => {
    try {
      if (!microSiteTargetFolder && publishToMicroSite) {
        messageApi.error(`Publishing path not defined`);
        return;
      }

      await toggleApprovePublishApi({
        campaignId,
        assetId: id,
        publishToMicroSite,
      });

      await loadAndSetAssets();

      messageApi.success("The publish status has been changed");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  return (
    <TableRow
      key={id}
      className="cursor-pointer relative text-[#475569] font-sans h-10"
    >
      <TableCell className="w-[400px]">
        {ref ? (
          <Typography.Link href={ref} target="_blank" className="text-[18px]">
            {title}
          </Typography.Link>
        ) : (
          <div className="text-orange-600">
            <span className="text-[18px] mr-[6px]">{title}</span>
            <span>(the link is lost, update it manually)</span>
          </div>
        )}
      </TableCell>

      <TableCell
        className={clsx("w-[400px]", {
          "opacity-30": !microSiteTargetFolder,
        })}
      >
        {microSiteTargetFolder || MICROSITE_INPUT_PLACEHOLDER}
      </TableCell>

      <TableCell className="w-[150px]">
        <Checkbox
          disabled={!ref || !hasAssetApproveRole}
          checked={approved}
          onClick={() =>
            confirm({
              action: () => onApproveAsset(!approved),
              title: "Approve the asset",
            })
          }
        />
      </TableCell>

      <TableCell className="w-[150px]">
        <Checkbox
          disabled={!ref || !approved || !hasAssetApprovePubRole}
          checked={publishToMicroSite}
          onClick={() =>
            confirm({
              action: () => onApprovePublish(!publishToMicroSite),
              title: "Approve the publication",
            })
          }
        />
      </TableCell>

      <TableCell className="w-[125px]">
        <span className="text-[10px]">{publishedDateValidated}</span>
      </TableCell>

      <AssetListItemMenu asset={asset} loadAndSetAssets={loadAndSetAssets} />
    </TableRow>
  );
};

export default AssetListItem;
