import { FC } from "react";
import { useSelector } from "react-redux";
import { selectCurrentStep } from "src/store/slices/stepsSlice";

import { useCampaignParams } from "../CampaignEditorPage/useCampaignParams";
import { useUserHasPermission } from "src/store/slices/userData/hooks/useUserHasPermission";
import { Button } from "src/components/common/ui/button";
import { Icons } from "src/components/common/Icons";
import { createChatApi } from "src/api/chat.api";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "src/hooks/useBoolean";
import { Loader2 } from "lucide-react";
import { handleRequestError } from "src/utils/handleRequestError";
import { getMessageApi } from "src/store/slices/appSlice";

type Props = {};

const CampaignClasses: FC<Props> = () => {
  const navigate = useNavigate();
  const messageApi = getMessageApi();
  const { campaignId, stepId, companyId } = useCampaignParams();
  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const step = useSelector(selectCurrentStep);
  const { hasChatListRole } = useUserHasPermission({
    companyId,
  });

  if (!step) return null;

  const { classes } = step;

  const handleStartChat = async () => {
    startLoading();
    try {
      const response = await createChatApi({
        campaignId: campaignId!,
        stepId: stepId!,
        chat: {
          stepId: stepId!,
          title: "New Chat",
          aiModelId: 1,
        },
      });

      navigate(`/campaigns/company/${companyId}/chat/${response.data.id}`);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
    stopLoading();
  };

  return (
    <div className="flex font-sans gap-[8px] items-center justify-between">
      <div className="flex gap-[8px] items-center">
        <div className="text-[#475569] font-bold text-[14px]">Classes:</div>
        <div className="flex gap-[4px] text-[#0F172A]">
          {classes && classes.length ? (
            classes.map((stepClass, index) => (
              <span
                key={stepClass}
              >{`[${stepClass}]${index !== classes.length - 1 ? "," : ""}`}</span>
            ))
          ) : (
            <span>empty</span>
          )}
        </div>
      </div>
      {hasChatListRole && (
        <div className="flex gap-[4px]">
          <Button
            variant="primary"
            className="rounded-full"
            onClick={handleStartChat}
            disabled={isLoading}
            icon={Icons.ChatIcon}
            loading={isLoading}
          >
            Start a Chat
          </Button>
        </div>
      )}
    </div>
  );
};

export default CampaignClasses;
