import { Form, Modal } from "antd";
import React, { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { changeCampaignCompanyApi } from "src/api/campaigns.api";
import useSubmitFormOnEnter from "src/hooks/useSubmitFormOnEnter";
import { getMessageApi } from "src/store/slices/appSlice";
import { fetchCompaniesOptions } from "src/utils/apiUtils";
import handleRequestError from "src/utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import SelectWithHighlightSearch from "../SelectWithHighlightSearch";
import { Button } from "../ui/button";
import customizeFormLabels from "../../../utils/customizeFormLabels";

type TChangeCampaignCompanyFormValues = {
  companyId: number;
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  campaignId: number;
};

export const ChangeCampaignsCompanyModal: FC<Props> = ({
  open,
  setOpen,
  campaignId,
}) => {
  const messageApi = getMessageApi();
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm<TChangeCampaignCompanyFormValues>();
  const { phaseId, stepId } = useParams();

  useSubmitFormOnEnter(() => submitForm(), { condition: !isFetching });

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TChangeCampaignCompanyFormValues) => {
      await handleUpdate(values);
    },
  });

  const handleUpdate = async ({
    companyId,
  }: TChangeCampaignCompanyFormValues) => {
    try {
      setIsFetching(true);

      await changeCampaignCompanyApi({
        campaignId,
        companyId,
      });

      messageApi.success("Campaign updated successfully");
      navigate(
        `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}`,
      );
      setOpen(false);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal
      title="Change Company"
      open={open}
      className="w-[700px]"
      onCancel={() => setOpen(false)}
      footer={() => {
        return (
          <div className="flex gap-3 justify-end">
            <Button variant="outline" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              loading={isFetching}
              disabled={isFetching}
              onClick={submitForm}
            >
              Change Company
            </Button>
          </div>
        );
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="change_campaign_company_form"
        requiredMark={customizeFormLabels}
      >
        <Form.Item
          name="companyId"
          label="Choose Company"
          rules={[{ required: true, message: "Required field!" }]}
        >
          <SelectWithHighlightSearch
            onSelect={(companyId: number | undefined, _) => {
              form.setFieldValue("companyId", companyId);
              form.validateFields(["companyId"]);
            }}
            fetchOptions={fetchCompaniesOptions}
            className="h-[48px]"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
