import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../common/ui/button";
import { Icons } from "../../common/Icons";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";

const SecurityGroupListHeader = () => {
  const { hasPermGroupCreateRole } = useUserHasPermission({});

  return (
    <div className="flex justify-between items-center h-[48px]">
      <h1 className=" text-slate-900 text-2xl font-semibold font-sans leading-normal">
        Security Groups
      </h1>

      {hasPermGroupCreateRole && (
        <Link to="/security-groups/create">
          <Button icon={Icons.Plus} className="rounded-full">
            Add Group
          </Button>
        </Link>
      )}
    </div>
  );
};

export default SecurityGroupListHeader;
