import { FC } from "react";
import { Icons } from "./Icons";
import { Button } from "./ui/button";

type Props = {
  children?: React.ReactNode;
  title: string;
  clickBack: () => void;
};

export const ItemHeader: FC<Props> = ({ title, children, clickBack }) => {
  return (
    <div className="flex items-center justify-between mb-3">
      <div className="flex gap-[16px] items-center">
        <Button
          variant={"ghost"}
          icon={Icons.back}
          onClick={clickBack}
          className="p-[12px] h-auto"
        />
        <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal">
          {title}
        </h1>
      </div>
      {children}
    </div>
  );
};
