import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoginPage from "../pages/login/LoginPage";
import useTrackUrlChanges from "../hooks/useTrackUrlChanges";
import MainRoutes from "./MainRoutes";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../store/slices/userData/slice";
import { useEffect } from "react";
import { selectSockets } from "../store/slices/computationMessages/slice";

const ApplicationRoutes = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const location = useLocation();
  const sockets = useSelector(selectSockets);

  // TODO remove after testing
  useEffect(() => {
    console.log("Connected CM sockets", sockets);
  }, [sockets]);

  useTrackUrlChanges();

  return (
    <Routes location={location}>
      {isAuthenticated ? (
        <Route path="/*" element={<MainRoutes />} />
      ) : (
        <>
          <Route path="/sign-in" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </>
      )}
    </Routes>
  );
};

export default ApplicationRoutes;
