import { FormInstance, Input } from "antd";
import { TextAreaProps } from "antd/es/input";
import { TextAreaRef } from "antd/es/input/TextArea";
import React, { Ref, forwardRef } from "react";

type PropsType = TextAreaProps &
  React.RefAttributes<TextAreaRef> & {
    currentForm: FormInstance<any>;
    fieldName: string;
  };

const TextAreaWithAltEnter = forwardRef(function TextAreaWithAltEnter(
  props: PropsType,
  ref: Ref<TextAreaRef> | undefined
) {
  const { currentForm, fieldName, ...textAreaProps } = props;

  const onPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.altKey) {
      e.preventDefault();

      const { selectionStart, selectionEnd } = e.currentTarget;
      const value = e.currentTarget.value;
      const newValue =
        value.substring(0, selectionStart) +
        "\n" +
        value.substring(selectionEnd);

      currentForm.setFieldValue(fieldName, newValue);
      e.currentTarget.value = newValue;

      const newCursorPosition = selectionStart + 1;
      e.currentTarget.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  };

  return (
    <Input.TextArea {...textAreaProps} ref={ref} onPressEnter={onPressEnter} />
  );
});

export default TextAreaWithAltEnter;
