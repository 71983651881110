import React, { FC, useState } from "react";
import { DeleteIcon, GetLinkIcon, Icons } from "../../common/Icons";
import MenuDropdown, { MenuDropdownItem } from "../../common/MenuDropdown";
import { TAsset } from "../../../globalTypes";
import { Button } from "../../common/ui/button";
import DeleteWithFilesModal from "../../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import {
  deleteAssetApi,
  getAssetApi,
  publishAssetApi,
  unpublishAssetApi,
} from "../../../api/assets.api";
import handleRequestError from "../../../utils/handleRequestError";
import {
  BoltIcon,
  BoltSlashIcon,
  GlobeAsiaAustraliaIcon,
} from "@heroicons/react/16/solid";
import EditAssetModal from "./EditAssetModal";
import { TableCell } from "../../common/ui/table";
import useConfirm from "../../../hooks/useConfirm";

type Props = {
  asset: TAsset;
  loadAndSetAssets: () => Promise<void>;
};

const AssetListItemMenu: FC<Props> = ({ asset, loadAndSetAssets }) => {
  const { companyId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const confirm = useConfirm();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editAssetData, setEditAssetData] = useState<TAsset | null>(null);
  const {
    hasAssetDeployRole,
    hasAssetDeleteRole,
    hasAssetUpdateRole,
    hasAssetUIGetPubAWSLink,
  } = useUserHasPermission({ companyId });

  const {
    ref,
    id,
    publishToMicroSite,
    publishedDate,
    type,
    deployedUrl,
    campaignId,
  } = asset;
  const deployLabel = publishedDate ? "Republish" : "Publish";

  const onLink = async (ref: string) => {
    try {
      await navigator.clipboard.writeText(ref);

      messageApi.success("The link has been copied to the clipboard!");
    } catch (e) {
      messageApi.error("Failed to copy the link");
      console.error("Copy link ERROR:", e);
    }
  };

  const onDeleteAsset = async (assetId: number, removeFiles?: boolean) => {
    try {
      await deleteAssetApi({
        campaignId,
        assetId,
        removeFiles,
      });

      messageApi.success("The asset has been deleted");

      await loadAndSetAssets();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onEditAsset = async (asset: TAsset) => {
    try {
      const { data } = await getAssetApi({
        campaignId,
        assetId: asset.id,
      });

      setIsEditModalOpen(true);
      setEditAssetData(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onPublishAsset = async (assetId: number) => {
    try {
      await publishAssetApi({
        campaignId,
        assetId,
      });

      messageApi.success("The asset has been published");

      await loadAndSetAssets();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onUnPublishAsset = async (assetId: number) => {
    try {
      await unpublishAssetApi({
        campaignId,
        assetId,
      });

      messageApi.success("The asset has been unpublished");

      await loadAndSetAssets();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const menuItems = [
    hasAssetUIGetPubAWSLink &&
      deployedUrl && {
        key: "get_link",
        label: "Get Link",
        onClick: () => onLink(deployedUrl),
        icon: GetLinkIcon,
      },
    hasAssetUIGetPubAWSLink &&
      ref && {
        key: "get_aws_link",
        label: "Get Public AWS Link",
        onClick: () => onLink(ref),
        icon: GlobeAsiaAustraliaIcon,
      },
    hasAssetDeployRole && {
      key: "publish",
      label: deployLabel,
      onClick: () =>
        confirm({
          action: () => onPublishAsset(id),
          title: `${deployLabel} the asset`,
        }),
      icon: BoltIcon,
      disabled: !publishToMicroSite,
    },
    hasAssetDeployRole && {
      key: "unpublish",
      label: "Unpublish",
      onClick: () =>
        confirm({
          action: () => onUnPublishAsset(id),
          title: "Unpublish the asset",
        }),
      icon: BoltSlashIcon,
      disabled: !publishedDate,
    },
    hasAssetUpdateRole &&
      type !== "GENERATED" && {
        key: "edit",
        label: "Edit",
        onClick: () => onEditAsset(asset),
        icon: Icons.edit,
      },
    hasAssetDeleteRole && {
      key: "delete",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  if (menuItems.length === 0) return <TableCell />;

  return (
    <>
      <TableCell className="w-[25px]" onClick={(e) => e.stopPropagation()}>
        <MenuDropdown
          items={menuItems}
          dropdownClassName="w-[190px]"
          align="end"
        >
          <Button variant="ghostPrimary" className="size-[40px] p-2">
            <Icons.MoreDots className="size-[24px]" />
          </Button>
        </MenuDropdown>
      </TableCell>

      {hasAssetDeleteRole && isDeleteModalOpen && (
        <DeleteWithFilesModal
          isModalOpen={isDeleteModalOpen}
          entity="Asset"
          title={asset.title}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={(removeFiles) => onDeleteAsset(id, removeFiles)}
        />
      )}

      {hasAssetUpdateRole && isEditModalOpen && editAssetData && (
        <EditAssetModal
          loadAndSetAssets={loadAndSetAssets}
          isModalOpen={isEditModalOpen}
          setIsModalOpen={setIsEditModalOpen}
          campaignId={campaignId.toString()}
          assetData={editAssetData}
        />
      )}
    </>
  );
};

export default AssetListItemMenu;
