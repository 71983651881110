import React from "react";
import GlobalLabelsHeader from "../../components/globalLabels/GlobalLabelsHeader";
import GlobalLabelsList from "../../components/globalLabels/GlobalLabelsList";

const GlobalLabelsPage = () => {
  return (
    <div className="flex flex-col gap-[24px]">
      <GlobalLabelsHeader />
      <GlobalLabelsList />
    </div>
  );
};

export default GlobalLabelsPage;
