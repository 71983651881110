import { Tooltip, Typography } from "antd";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowUpTrayIcon,
  ArrowUturnLeftIcon,
  CloneIcon,
  DeleteIcon,
  Icons,
} from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Badge } from "src/components/common/ui/badge";
import { Button } from "src/components/common/ui/button";
import { TableCell, TableRow } from "src/components/common/ui/table";
import { campaignBadgeSettings } from "src/constants";
import useConfirm from "../../hooks/useConfirm";
import { getMessageApi } from "../../store/slices/appSlice";
import CloneEntityModal, {
  TCloneEntityFormValues,
} from "../common/modals/CloneEntityModal/CloneEntityModal";
import DeleteWithFilesModal from "../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import handleRequestError from "../../utils/handleRequestError";
import {
  archiveCampaignApi,
  cloneCampaignApi,
  deleteCampaignApi,
  publishCampaignApi,
  unArchiveCampaignApi,
  unPublishCampaignApi,
} from "../../api/campaigns.api";
import { format } from "date-fns";
import { CompanyLogo } from "../companies/CompanyLogo";
import ClonedWithErrorsMessage from "../common/ClonedWithErrorsMessage";
import { ArchiveIcon } from "lucide-react";
import { TCampaign } from "src/store/slices/campaignsSlice";
import { NavLink } from "react-router-dom";

type PropsType = {
  item: TCampaign;
  onReloadCurrentList: () => void;
};

export const CampaignListItem: FC<PropsType> = ({
  item,
  onReloadCurrentList,
}) => {
  const {
    id,
    companyName,
    name: title,
    type: campaignType,
    companyId,
    companyLogo,
    modifiedTime,
    archive,
  } = item;
  const navigate = useNavigate();
  const messageApi = getMessageApi();
  const confirm = useConfirm();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const {
    hasCampaignDeleteRole,
    hasCampaignCloneRole,
    hasCampaignPublishRole,
    hasCampaignUnpublishRole,
    hasCampaignReadRole,
    hasCampaignArchiveRole,
  } = useUserHasPermission({
    companyId,
  });

  const campaignUrl = `/campaigns/company/${companyId}/campaign/${id}`;

  const handleDelete = async (removeFiles: boolean) => {
    try {
      await deleteCampaignApi({ campaignId: id, removeFiles });

      onReloadCurrentList();
      setIsDeleteModalOpen(false);
      messageApi.success("The campaign has been successfully removed");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onEditCampaign = async () => {
    navigate(`/campaigns/company/${companyId}/campaign/${id}`);
  };

  const onCloneCampaign = async (values: TCloneEntityFormValues) => {
    try {
      const params = {
        ...values,
        name: values.name.replace(/ +/g, " "),
      };

      const { data } = await cloneCampaignApi({
        campaignId: id,
        ...params,
      });

      navigate(`/campaigns/company/${companyId}/campaign/${data.campaign.id}`);

      if (data.errorMessages.length) {
        messageApi.warning(
          <ClonedWithErrorsMessage errorMessages={data.errorMessages} />,
        );
      } else {
        messageApi.success("The campaign has been successfully cloned");
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onPublishCampaign = async () => {
    try {
      if (campaignType === "DRAFT_TEMPLATE") {
        await publishCampaignApi({ campaignId: id });
      } else {
        await unPublishCampaignApi({ campaignId: id });
      }

      onReloadCurrentList();

      messageApi.success(
        `The campaign has been successfully ${
          campaignType === "PUBLISHED_TEMPLATE" ? "unpublished" : "published"
        }`,
      );
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onArchiveCampaign = async () => {
    try {
      if (archive) {
        await unArchiveCampaignApi({ campaignId: id });
      } else {
        await archiveCampaignApi({ campaignId: id });
      }

      onReloadCurrentList();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const menuItems = [
    hasCampaignArchiveRole &&
      !archive && {
        key: "archive",
        label: "Archive",
        icon: ArchiveIcon,
        onClick: () =>
          confirm({
            action: onArchiveCampaign,
            title: "Archive Campaign",
            content: "Are you sure you want to archive this campaign?",
            okText: "Archive",
          }),
      },
    hasCampaignArchiveRole &&
      archive && {
        key: "unarchive",
        label: "Unarchive",
        icon: ArrowUturnLeftIcon,
        onClick: () =>
          confirm({
            action: onArchiveCampaign,
            title: "Unarchive Campaign",
            content: "Are you sure you want to unarchive this campaign?",
            okText: "Unarchive",
          }),
      },
    hasCampaignPublishRole &&
      campaignType === "DRAFT_TEMPLATE" && {
        key: "publish",
        label: "Publish",
        icon: ArrowUpTrayIcon,
        onClick: () =>
          confirm({ action: onPublishCampaign, title: "Publish Template" }),
        className: "!text-vePrimary",
      },
    hasCampaignUnpublishRole &&
      campaignType === "PUBLISHED_TEMPLATE" && {
        key: "unpublish",
        label: "Unpublish",
        icon: ArrowUturnLeftIcon,
        onClick: () =>
          confirm({ action: onPublishCampaign, title: "Unpublish Template" }),
      },
    hasCampaignCloneRole && {
      key: "clone",
      label: "Clone",
      icon: CloneIcon,
      onClick: () => setIsCloneModalOpen(true),
    },
    hasCampaignDeleteRole && {
      key: "delete",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const formattedModifiedTime = modifiedTime ? (
    <div className="text-[#475569] text-[11px]">
      {format(new Date(modifiedTime), "MMM d, yyyy")}
      <br />
      {format(new Date(modifiedTime), "h:mm a zzz")}
    </div>
  ) : (
    "-"
  );

  return (
    <>
      <TableRow
        key={id}
        onClick={hasCampaignReadRole ? onEditCampaign : undefined}
        className="cursor-pointer relative h-[40px]"
      >
        <TableCell className="p-0">
          {hasCampaignReadRole ? (
            <NavLink
              className="flex items-center gap-2 text-[14px] text-black hover:text-black w-full h-full px-2"
              to={campaignUrl}
              onClick={(e) => e.preventDefault()}
            >
              {title}
              {hasCampaignArchiveRole && archive && (
                <Tooltip title="Campaign is archived">
                  <ArchiveIcon className="size-[16px] text-gray-500" />
                </Tooltip>
              )}
            </NavLink>
          ) : (
            <div className="flex items-center gap-2 text-[14px] text-black hover:text-black w-full h-full px-2">
              {title}
              {hasCampaignArchiveRole && archive && (
                <Tooltip title="Campaign is archived">
                  <ArchiveIcon className="size-[16px] text-gray-500" />
                </Tooltip>
              )}
            </div>
          )}
        </TableCell>
        <TableCell>
          {companyName ? (
            <div className="flex items-center gap-2">
              <CompanyLogo url={companyLogo} />
              <span>{companyName}</span>
            </div>
          ) : (
            <Typography.Text code style={{ color: "orange", opacity: 0.7 }}>
              Global
            </Typography.Text>
          )}
        </TableCell>
        <TableCell>
          <Badge
            className={campaignBadgeSettings[campaignType].className}
            variant={"table"}
          >
            {campaignBadgeSettings[campaignType].label}
          </Badge>
        </TableCell>
        <TableCell>
          <div className="text-[14px] text-[#475569]">
            {formattedModifiedTime}
          </div>
        </TableCell>
        {menuItems.length ? (
          <TableCell
            className="flex items-center justify-center"
            onClick={(e) => e.stopPropagation()}
          >
            <MenuDropdown
              items={menuItems}
              dropdownClassName="w-[190px]"
              align="end"
            >
              <Button variant={"ghostPrimary"} className="size-[40px] p-2">
                <Icons.MoreDots className="size-[24px]" />
              </Button>
            </MenuDropdown>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>

      {hasCampaignDeleteRole && isDeleteModalOpen && (
        <DeleteWithFilesModal
          entity="Campaign"
          onDelete={handleDelete}
          title={title}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}

      {hasCampaignCloneRole && isCloneModalOpen && (
        <CloneEntityModal
          entity="Campaign"
          entityTitle={title}
          entityTitleTooltip="Please enter a string between 4 and 255 characters."
          onSubmitForm={onCloneCampaign}
          isModalOpen={isCloneModalOpen}
          onCancel={() => setIsCloneModalOpen(false)}
        />
      )}
    </>
  );
};

export default CampaignListItem;
