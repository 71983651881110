import React, { FC } from "react";
import { StorageDirClosedIcon, StorageDirOpenedIcon } from "../common/Icons";

type Props = {
  expanded: boolean;
};

const FolderIcon: FC<Props> = ({ expanded }) => {
  return expanded ? (
    <StorageDirOpenedIcon className="size-6" />
  ) : (
    <StorageDirClosedIcon className="size-6" />
  );
};

export default FolderIcon;
