import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { AppDispatch } from "../../../store/store";
import {
  selectUsersListState,
  setUsers,
} from "../../../store/slices/usersListSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { createUserApi, getUsersListApi } from "../../../api/user.api";
import { TUserForm } from "../../../globalTypes";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useUsersSearchParams } from "./useUsersSearchParams";

const useUsersList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = useSelector(selectMessageApi);
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();
  const { hasUserReadRole } = useUserHasPermission({});
  const [isLoading, setIsLoading] = useState(true);
  const { users, total } = useSelector(selectUsersListState);
  const { pageNumber, pageSize, search, updateSearchParams } = useUsersSearchParams();

  useEffect(() => {
    loadUsers({ pageNumber, pageSize, search });
  }, [pageNumber, pageSize, search]);

  const loadUsers = async ({
    pageNumber,
    pageSize,
    search,
  }: {
    pageNumber: number;
    pageSize: number;
    search: string;
  }) => {
    try {
      setIsLoading(true);

      const { data } = await getUsersListApi({
        pageSize,
        pageNumber,
        search,
      });

      const { items, totalItems } = data;
      dispatch(setUsers({ users: items, total: totalItems }));
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePage = (pageNumber: number) => {
    updateSearchParams({ pageNumber });
  };

  const updatePageSize = (pageSize: number) => {
    updateSearchParams({ pageNumber: 1, pageSize });
  };

  const updateSearch = (search: string) => {
    updateSearchParams({ pageNumber: 1, search });
  };

  const onCreateUser = async ({ values }: { values: TUserForm }) => {
    const { data: newUser } = await createUserApi({ userData: values });

    if (hasUserReadRole) {
      navigateWithOutletRedirect(`/users/${newUser.id}/details`);
    } else {
      await loadUsers({ pageNumber, pageSize, search });
    }
  };

  return {
    users,
    isLoading,
    total,
    pageSize,
    pageNumber,
    search,
    updatePage,
    updatePageSize,
    updateSearch,
    onCreateUser,
  };
};
export default useUsersList;

