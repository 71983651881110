import { NavLink } from "react-router-dom";
import UserAvatar from "./UserAvatar";
import ReleasesInfo from "./ReleasesInfo";
import React from "react";
import Navigation from "./Navigation";

const Header = () => {
  return (
    <div className="w-full h-16 flex items-center border-b border-[#E2E8F0] justify-between px-12">
      <div className="w-full flex gap-2 items-center">
        <NavLink to="/home">
          <img src={"/logo.svg"} alt="App logo" className="h-6" />
        </NavLink>

        <Navigation />
      </div>

      <div className="flex gap-6 items-center">
        <UserAvatar />
        <ReleasesInfo />
      </div>
    </div>
  );
};

export default Header;
