import { FC } from "react";
import { createCampaignThunk } from "../../store/slices/campaignsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { selectMessageApi } from "../../store/slices/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import { ArchiveStatus, TCampaignListOptionType } from "../../globalTypes";
import { CampaignsListRequestParams } from "../../pages/campaigns/useCampaignsList";
import { TCompany } from "../../store/slices/companiesSlice";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import { PageHeader } from "../common/PageHeader";
import CampaignTypeSelector from "./CampaignTypeSelector";
import CompanySelector from "./CompanySelector";
import CampaignsPagination from "./CampaignsPagination";
import { TemplatesPageTabs } from "../docTemplates/Tabs";
import ArchiveFilter from "./ArchiveFilter";
import { useUserHasPermission } from "src/store/slices/userData/hooks/useUserHasPermission";

type Props = {
  currentCompany: TCompany | null;
  backUrl: string;
  enabledTypes?: TCampaignListOptionType[];
  requestParams: CampaignsListRequestParams;
  onCompanyChange: (companyId: string) => void;
  onCampaignTypeChange: (campaignType: TCampaignListOptionType) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onArchiveChange?: (archive: ArchiveStatus) => void;
};

const CampaignListHeader: FC<Props> = ({
  requestParams,
  backUrl,
  enabledTypes,
  onCompanyChange,
  onCampaignTypeChange,
  onPageChange,
  onPageSizeChange,
  onArchiveChange,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = useSelector(selectMessageApi);
  const { hasCampaignArchiveRole } = useUserHasPermission({
    companyId: requestParams.companyId || undefined,
  });
  const isTemplatesPage = useLocation().pathname.includes("/templates");
  const navigate = useNavigate();

  const onCampaignCreate = async () => {
    try {
      if (requestParams.companyId === null) {
        messageApi.warning("Please select a company.");
        return;
      }

      const { companyId, id } = await dispatch(
        createCampaignThunk({ companyId: requestParams.companyId }),
      ).unwrap();

      navigate(`/campaigns/company/${companyId}/campaign/${id}`);
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };
  const handleChangeCampaignType = (campaignType: string) => {
    onCampaignTypeChange(campaignType as TCampaignListOptionType);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <div className="flex justify-between min-h-[40px]">
          <PageHeader
            title="Campaigns"
            showBackButton={!!backUrl}
            backUrl={backUrl}
          />
        </div>
        {isTemplatesPage && <TemplatesPageTabs />}
      </div>
      <div className="flex flex-wrap items-start gap-3">
        <div className="flex-1 flex flex-wrap gap-3">
          <CompanySelector
            companyId={requestParams.companyId || null}
            onChanged={onCompanyChange}
          />
          {onArchiveChange && hasCampaignArchiveRole && (
            <ArchiveFilter
              archiveStatus={requestParams.archive}
              onChanged={onArchiveChange}
            />
          )}
          {isTemplatesPage && (
            <CampaignTypeSelector
              campaignType={requestParams.campaignType}
              onChanged={handleChangeCampaignType}
              enabledTypes={enabledTypes}
            />
          )}
          <SettingsMenu
            companyId={requestParams.companyId || undefined}
            campaignType={requestParams.campaignType}
            onCampaignCreate={onCampaignCreate}
          />
        </div>
        <div className="ml-auto">
          <CampaignsPagination
            page={requestParams.page}
            pageSize={requestParams.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};
export default CampaignListHeader;
