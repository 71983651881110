import { usePostHog } from "posthog-js/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { Alert } from "src/components/alerts/Alert";
import { loginWithGoogleThunk } from "../../store/slices/userData/slice";
import { AppDispatch } from "../../store/store";
import localStorageProvider from "../../utils/localStorageProvider";
import { Spin } from "antd";
import { getDefaultRedirectUrlOnLogin } from "../../store/slices/userData/utils";

const GoogleAuth: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const posthog = usePostHog();
  const [error, setError] = useState<any>();
  const [isFetching, setIsFetching] = useState(false);

  const handleGoogleSuccess = async (
    credentialResponse: CredentialResponse,
  ) => {
    setError(undefined);
    try {
      if (!credentialResponse.credential) {
        throw new Error("No credential found");
      }
      setIsFetching(true);
      const { userEmail, userPermissions } = await dispatch(
        loginWithGoogleThunk(credentialResponse.credential),
      ).unwrap();

      posthog?.identify(userEmail);
      posthog?.capture("The user has logged in");

      const lastUrl = localStorageProvider.getLastUrl();
      const redirectPath =
        lastUrl || getDefaultRedirectUrlOnLogin(userPermissions);

      navigate(redirectPath);
    } catch (err) {
      setError(err);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="auth-wrapper">
      <Spin spinning={isFetching} tip="Logging in...">
        <GoogleLogin onSuccess={handleGoogleSuccess} />
        {/* <button
          type="button"
          className="w-[416px] h-14 px-6 py-4 bg-white rounded-md border border-[#d4dae0] flex-col justify-start items-start gap-2.5 inline-flex cursor-pointer hover:bg-gray-50 transition-colors"
          onClick={() => login()}
        >
          <div className="justify-start items-center gap-[78px] inline-flex">
            <img
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
              alt="google logo"
              className="!w-6 !h-6 relative overflow-hidden"
            />
            <div className="text-[#0a1c31] text-base font-semibold font-['Instrument Sans'] leading-none">
              Continue with Google
            </div>
          </div>
        </button> */}
      </Spin>

      {error?.status === 404 && (
        <Alert title="User not found">
          Sorry, you don't have a registered account with Velocity Engine. Click
          here to{" "}
          <Link
            to="mailto:support@velocityengine.co?subject=Login Help"
            rel="noreferrer nofollower"
            target="_blank"
            className="text-blue-500 hover:underline"
          >
            send us a note
          </Link>
        </Alert>
      )}
    </div>
  );
};

export default GoogleAuth;
