import { Form } from "antd";
import { FC, useEffect, useState } from "react";
import { createSFTPUserApi } from "src/api/sftp-users.api";
import { Button } from "src/components/common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/common/ui/dialog";
import { Input } from "src/components/common/ui/input";
import { getMessageApi } from "src/store/slices/appSlice";
import handleRequestError from "src/utils/handleRequestError";
import { SftpUser } from "../../globalTypes";
import { Icons } from "../common/Icons";
import submitFormWithTrim from "../../utils/submitFormWithTrim";
import { minMax, required } from "../../utils/validations";
import customizeFormLabels from "../../utils/customizeFormLabels";
import { SftpUserFormValues } from "./types";

type Props = {
  micrositeId: number;
  onCreated: (sftpUser: SftpUser) => void;
};

export const CreateSftpUserDialog: FC<Props> = ({ micrositeId, onCreated }) => {
  const messageApi = getMessageApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm<SftpUserFormValues>();

  const handleCreate = async (values: SftpUserFormValues) => {
    try {
      setLoading(true);
      const { data } = await createSFTPUserApi({
        micrositeId,
        username: values.username,
      });

      onCreated(data);
      messageApi.success("SFTP User created successfully");
    } catch (error: any) {
      const customError = handleRequestError(error);

      if (
        customError.message.includes("already exists") &&
        customError.message.includes("secret")
      ) {
        messageApi.error("SFTP User with the same name already exists");
      } else {
        messageApi.error(customError.message);
        console.error(customError);
      }
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    return () => {
      form.setFieldValue("username", "");
    };
  }, [open]);

  const onFormSubmit = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: SftpUserFormValues) => {
      await handleCreate(values);
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          icon={Icons.Plus}
          className="rounded-full"
          onClick={() => setOpen(true)}
        >
          Add SFTP User
        </Button>
      </DialogTrigger>

      <DialogContent aria-describedby="">
        <DialogHeader>
          <DialogTitle className="font-sans">Create new SFTP User</DialogTitle>
        </DialogHeader>

        <Form form={form} layout="vertical" requiredMark={customizeFormLabels}>
          <Form.Item
            name="username"
            label="Username"
            required
            tooltip="Please enter a string between 2 and 150 characters long"
            rules={[required(), minMax({ text: "Username", min: 2, max: 150 })]}
          >
            <Input />
          </Form.Item>
        </Form>

        <DialogFooter>
          <Button disabled={loading} onClick={onFormSubmit} loading={loading}>
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
