import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Edge, Node } from "reactflow";
import { getUpstreamDependenciesApi } from "../../../../api/cc-variables.api";
import { TStepPhaseMetaInfo } from "../../../../globalTypes";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import {
  loadDynamicItemsThunk,
  TCcVariable,
} from "../../../../store/slices/ccVariablesSlice";
import { AppDispatch } from "../../../../store/store";
import handleRequestError from "../../../../utils/handleRequestError";
import useNodeMenuActions from "./useNodeMenuActions.hook";
import { INIT_NODE_POSITION, NODE_DIMENSIONS } from "../../constants";
import {
  getLayoutedElements,
  getStepPhaseMetaInfo,
  getTargetWithInputCCItem,
  getUpdatedEdgeStyles,
  getUpdatedNodeStyles,
} from "../../utils";
import {
  ChangeNodeProps,
  UpstreamDepsModalDefaultNodeProps,
  UpstreamDepsModalGroupNodeProps,
} from "../../types";

const useGetNodesAndEdges = () => {
  const { companyId, campaignId, stepId, key } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [targetNode, setTargetNode] = useState<TCcVariable | null>(null);
  const [inputNode, setInputNode] = useState<TCcVariable | null>(null);
  const [isTargetLoading, setIsTargetLoading] = useState(false);
  const [isInputLoading, setIsInputLoading] = useState(false);
  const [stepPhaseMetaInfo, setStepPhaseMetaInfo] =
    useState<TStepPhaseMetaInfo | null>(null);
  const { setTarget, setTargetWithInput, setInput } = useNodeMenuActions({
    setTargetNode,
    setInputNode,
    setNodes,
    inputNode,
    targetNode,
    setEdges,
    setIsTargetLoading,
    setIsInputLoading,
  });
  const dispatch: AppDispatch = useDispatch();
  const initialNodeId = `${key}_${stepId}`;

  useEffect(() => {
    if (campaignId && stepId && key) {
      getNodesAndEdges(campaignId, stepId, key);
    }
  }, [campaignId, stepId, key]);

  const getNodesAndEdges = async (
    campaignId: string,
    stepId: string,
    key: string,
  ) => {
    try {
      setIsTargetLoading(true);
      setIsInputLoading(true);

      await dispatch(loadDynamicItemsThunk()).unwrap();

      const { data } = await getUpstreamDependenciesApi({
        campaignId,
        stepId,
        key,
      });

      const { edges, nodes, metaInfo } = data;
      const stepPhaseMetaInfoData = getStepPhaseMetaInfo(metaInfo);
      const itemStepMetaInfo = stepPhaseMetaInfoData[+stepId];
      const path = itemStepMetaInfo
        ? `${itemStepMetaInfo.phaseName}/${itemStepMetaInfo.stepName}/`
        : "";

      const { targetCCItem, inputCCItem } = await getTargetWithInputCCItem({
        campaignId,
        stepId,
        key: path + key,
      });

      const processedEdges: Edge[] = edges.map(
        ({ id, targetNodeId: target, sourceNodeId }) => ({
          id: id,
          source: sourceNodeId,
          target,
          type: "simplebezier",
          focusable: false,
          ...getUpdatedEdgeStyles({
            nodeId: target,
            targetNodeId: `${targetCCItem.id.key}_${targetCCItem.id.stepId}`,
            inputNodeId: inputCCItem
              ? `${inputCCItem.id.key}_${inputCCItem.id.stepId}`
              : "",
          }),
        }),
      );

      const processedNodes: Node<UpstreamDepsModalDefaultNodeProps>[] =
        nodes.map(({ id, data }) => {
          const { stepId, key } = data.id;
          const nodeStepMetaInfo = stepPhaseMetaInfoData[stepId];
          const { phaseId } = nodeStepMetaInfo;
          const path = nodeStepMetaInfo
            ? `${nodeStepMetaInfo.phaseName}/${nodeStepMetaInfo.stepName}/`
            : "";
          const itemKeyWithFullPath = path + key;

          return {
            id,
            data: {
              ccItem: data,
              phaseId,
              companyId,
              setInputNode: () => setInput(itemKeyWithFullPath),
              setTargetNode: () => setTarget(itemKeyWithFullPath),
              setTargetWithInputNode: () =>
                setTargetWithInput(itemKeyWithFullPath),
            },
            position: INIT_NODE_POSITION,
            parentId: stepId.toString(),
            draggable: false,
            selectable: false,
            zIndex: 2,
            style: getUpdatedNodeStyles({
              nodeId: id,
              targetNodeId: `${targetCCItem.id.key}_${targetCCItem.id.stepId}`,
              inputNodeId: inputCCItem
                ? `${inputCCItem.id.key}_${inputCCItem.id.stepId}`
                : "",
            }),
          };
        });

      const groupNodes: Node<UpstreamDepsModalGroupNodeProps>[] =
        metaInfo.steps.map(({ name, id }) => {
          let label = name;
          const { phaseName } = stepPhaseMetaInfoData[id];

          if (phaseName) {
            label = `${phaseName} / ${name}`;
          } else {
            console.warn("Couldn't find phase");
          }

          return {
            id: id.toString(),
            type: "group",
            data: { label },
            position: INIT_NODE_POSITION,
            style: NODE_DIMENSIONS,
            draggable: false,
            selectable: false,
            zIndex: 2,
          };
        });

      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements({
          nodes: [...groupNodes, ...processedNodes],
          edges: processedEdges,
          direction: "LR",
        });

      setEdges(layoutedEdges);
      setNodes(layoutedNodes);
      setTargetNode(targetCCItem);
      setInputNode(inputCCItem);
      setStepPhaseMetaInfo(getStepPhaseMetaInfo(metaInfo));
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsInitialized(true);
      setIsTargetLoading(false);
      setIsInputLoading(false);
    }
  };

  const changeNodeProps: ChangeNodeProps = useCallback(({ nodeId, props }) => {
    setNodes((prevState) => {
      return prevState.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: { ...node.data, ...props },
          };
        } else {
          return node;
        }
      });
    });
  }, []);

  return {
    edges,
    nodes,
    isInitialized,
    initialNodeId,
    isTargetLoading,
    isInputLoading,
    targetNode: useMemo(() => targetNode, [targetNode]),
    inputNode: useMemo(() => inputNode, [inputNode]),
    stepPhaseMetaInfo: useMemo(() => stepPhaseMetaInfo, [stepPhaseMetaInfo]),
    changeNodeProps,
    setTargetNode,
    setInputNode,
  };
};

export default useGetNodesAndEdges;
