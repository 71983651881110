import { useEffect, useState } from "react";
import handleRequestError from "../../../utils/handleRequestError";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSystemPermissions,
  selectMessageApi,
} from "../../../store/slices/appSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  createPermissionGroupApi,
  getPermissionGroupByIdApi,
  updatePermissionGroupApi,
} from "../../../api/permissions-group.api";
import { UserPermissions } from "../../../store/slices/userData/constants";
import { useForm } from "antd/es/form/Form";
import { AppDispatch } from "../../../store/store";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { SecurityGroupFormValues } from "../types";
import { DEFAULT_FORM_VALUES } from "../constants";

const useGetSecurityGroupData = () => {
  const { securityGroupId } = useParams();
  const navigate = useNavigate();
  const [form] = useForm<SecurityGroupFormValues>();
  const messageApi = useSelector(selectMessageApi);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [initialValues, setInitialValues] =
    useState<SecurityGroupFormValues>(DEFAULT_FORM_VALUES);
  const [isSuperUserGroup, setIsSuperUserGroup] = useState(false);
  const [checkedGlobalPermissions, setCheckedGlobalPermissions] = useState<
    UserPermissions[]
  >([]);
  const [checkedCompanyPermissions, setCheckedCompanyPermissions] = useState<
    UserPermissions[]
  >([]);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    getSystemPermissions();
  }, []);

  const navigateToGroupList = () => navigate("/security-groups");

  const getSystemPermissions = async () => {
    try {
      const permissionsTree = await dispatch(loadSystemPermissions()).unwrap();

      if (securityGroupId) {
        const { data } = await getPermissionGroupByIdApi({
          permissionGroupId: securityGroupId,
        });
        const { title, superUser, level } = data.permissionsGroup;

        setInitialValues({ title, level });

        if (superUser) {
          setIsSuperUserGroup(superUser);
          //set all permissions if superuser
          setCheckedCompanyPermissions(
            Object.values(permissionsTree.companyLevel).flat(),
          );
          setCheckedGlobalPermissions(
            Object.values(permissionsTree.globalLevel).flat(),
          );
        } else {
          // Separate permissions based on their presence in permissionsTree
          const globalPermissions = data.permissions.filter((permission) =>
            Object.values(permissionsTree.globalLevel)
              .flat()
              .includes(permission),
          );

          const companyPermissions = data.permissions.filter((permission) =>
            Object.values(permissionsTree.companyLevel)
              .flat()
              .includes(permission),
          );

          setCheckedCompanyPermissions(companyPermissions);
          setCheckedGlobalPermissions(globalPermissions);
        }
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);

      navigateToGroupList();
    } finally {
      setIsInitialized(true);
    }
  };

  const onSaveData = async (values: SecurityGroupFormValues) => {
    try {
      setIsFetching(true);
      const allPermissions = [
        ...checkedGlobalPermissions,
        ...checkedCompanyPermissions,
      ];

      // remove unnecessary strings (permission group title) when clicked check the entire group
      const filteredPermissions = allPermissions.filter((permission) =>
        permission.startsWith("ROLE_"),
      );

      if (securityGroupId) {
        await updatePermissionGroupApi({
          permissions: filteredPermissions,
          permissionsGroup: {
            id: securityGroupId,
            title: values.title,
            level: values.level,
          },
        });
      } else {
        await createPermissionGroupApi({
          permissions: filteredPermissions,
          permissionsGroup: {
            title: values.title,
            level: values.level,
          },
        });
      }

      if (!securityGroupId) {
        navigateToGroupList();
      }
      messageApi.success("The group was successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const handleSubmitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: SecurityGroupFormValues) => {
      await onSaveData(values);
    },
  });

  return {
    isInitialized,
    initialValues,
    checkedGlobalPermissions,
    checkedCompanyPermissions,
    setCheckedCompanyPermissions,
    setCheckedGlobalPermissions,
    isSuperUserGroup,
    isFetching,
    form,
    navigateToGroupList,
    handleSubmitForm,
  };
};

export default useGetSecurityGroupData;
