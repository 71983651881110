import React, { FC, useState } from "react";
import {
  copyGlobalPrivateFileApi,
  deleteGlobalPrivateFileApi,
  downloadGlobalPrivateFileApi,
  getGlobalPrivateFileDataApi,
  getGlobalPrivateFilesListApi,
  uploadGlobalPrivateFileApi,
} from "../../../../api/global-storage.api";
import { TFileTreeItem, TStorageFileItem } from "../../../../globalTypes";
import useConfirm from "../../../../hooks/useConfirm";
import { CopyFileParams } from "../../../../types";
import { MenuDropdownItem } from "../../../common/MenuDropdown";
import { CopyFileModal } from "../../../common/modals/CopyFileModal";
import EditFileDrawer from "../../dynamicStorage/EditFileDrawer";
import useCopyFile from "../../dynamicStorage/hooks/useCopyFile.hook";
import useDelete from "../../dynamicStorage/hooks/useDelete.hook";
import useDownload from "../../dynamicStorage/hooks/useDownload.hook";
import useEditFile from "../../dynamicStorage/hooks/useEditFile.hook";
import useSaveFile from "../../dynamicStorage/hooks/useSaveFile.hook";
import useUpload from "../../dynamicStorage/hooks/useUpload.hook";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import {
  allowedFilesToEdit,
  menuOptions,
} from "../../dynamicStorage/constants";
import ItemActions from "../../dynamicStorage/ItemActions";
import UploadAssetsModal from "../../../common/modals/UploadAssetsModal";

type Props = {
  treeData: TFileTreeItem[];
  setTreeData: React.Dispatch<React.SetStateAction<TFileTreeItem[]>>;
  item: TFileTreeItem;
  isHovering: boolean;
  isSelected: boolean;
  rootPath: string;
};
const ItemMenu: FC<Props> = ({
  setTreeData,
  treeData,
  item,
  rootPath,
  isHovering,
  isSelected,
}) => {
  const [isOpenCopyFile, setIsOpenCopyFile] = useState(false);
  const [isOpenUploadFiles, setIsOpenUploadFiles] = useState(false);
  const [isEditFileOpen, setIsEditFileOpen] = useState(false);
  const [fileData, setFileData] = useState<string | undefined>();
  const confirm = useConfirm();
  const { fileName, folder, isRootDir, mimeType } = item;
  // const isHTMLFile = mimeType === "text/html";
  const isEditFileAllowed = allowedFilesToEdit.includes(mimeType);
  const {
    hasPrivateRootFsUploadRole,
    hasPrivateRootFsCopyRole,
    hasPrivateRootFsReadRole,
    hasPrivateRootFsDeleteRole,
    hasPrivateRootFsEditRole,
  } = useUserHasPermission({});

  const onDownloadFile = useDownload({
    download: async (path) => {
      return await downloadGlobalPrivateFileApi({
        path,
      });
    },
  });
  // const onDownloadPDFFile = useDownload({
  //   download: async (path) => {
  //     return await downloadGlobalPrivatePDFFileApi({
  //       path,
  //     });
  //   },
  // });
  const onUploadFile = useUpload({
    upload: async (formData: FormData) => {
      const { data } = await uploadGlobalPrivateFileApi({
        formData,
      });
      return data;
    },
    setTreeData,
    selectedItem: item,
  });
  const onSaveFile = useSaveFile({
    saveFile: async (formData: FormData) => {
      const { data } = await uploadGlobalPrivateFileApi({
        formData,
      });
      return data;
    },
    closeModal: () => setIsEditFileOpen(false),
    setTreeData,
    selectedItem: item,
  });
  const onDelete = useDelete({
    deleteFile: async (path: string) => {
      await deleteGlobalPrivateFileApi({
        path,
      });
    },
    setTreeData,
  });
  const handleCopyFile = useCopyFile({
    copyFile: async (params: CopyFileParams) => {
      const { data } = await copyGlobalPrivateFileApi(params);
      return data;
    },
    setTreeData,
    treeData,
  });
  const onEditFile = useEditFile({
    getFileData: async (path: string) => {
      const { data } = await getGlobalPrivateFileDataApi({
        path,
      });
      return data;
    },
    setFileData,
    openEditDrawer: () => setIsEditFileOpen(true),
  });
  const getFolderFiles = async (path?: string): Promise<TStorageFileItem[]> => {
    const { data } = await getGlobalPrivateFilesListApi({
      path: path || rootPath,
    });
    return data;
  };
  const menuItems = [
    !folder &&
      hasPrivateRootFsEditRole &&
      isEditFileAllowed && {
        ...menuOptions.editFile,
        onClick: () => onEditFile(fileName),
      },
    !folder &&
      hasPrivateRootFsCopyRole && {
        ...menuOptions.copyFile,
        onClick: () => setIsOpenCopyFile(true),
      },
    hasPrivateRootFsReadRole && {
      ...menuOptions.download,
      onClick: () => onDownloadFile(fileName),
    },
    // hasPrivateRootFsReadRole &&
    //   isHTMLFile && {
    //     ...menuOptions.downloadPDF,
    //     onClick: () => onDownloadPDFFile(fileName),
    //   },
    folder &&
      hasPrivateRootFsUploadRole && {
        ...menuOptions.upload,
        onClick: () => setIsOpenUploadFiles(true),
      },
    hasPrivateRootFsDeleteRole &&
      !isRootDir && {
        ...menuOptions.delete,
        onClick: () =>
          confirm({
            action: () => onDelete(fileName),
            title: `Delete ${folder ? "directory" : "file"}`,
          }),
        className: "!text-red-600",
      },
  ].filter(Boolean) as MenuDropdownItem[];
  return (
    <>
      <ItemActions
        isSelected={isSelected}
        isHovering={isHovering}
        menuItems={menuItems}
      />
      <EditFileDrawer
        fileData={fileData}
        setIsEditFileOpen={setIsEditFileOpen}
        isEditFileOpen={isEditFileOpen}
        onSaveFile={onSaveFile}
        setFileData={setFileData}
        mimeType={mimeType}
      />
      {isOpenCopyFile && (
        <CopyFileModal
          getFolderFiles={getFolderFiles}
          open={isOpenCopyFile}
          setOpen={setIsOpenCopyFile}
          selectedPath={fileName}
          onCopy={handleCopyFile}
          pathPrefix={rootPath}
        />
      )}
      {isOpenUploadFiles && (
        <UploadAssetsModal
          uploadAsset={onUploadFile}
          setIsModalOpen={(open) => setIsOpenUploadFiles(open)}
          isModalOpen={isOpenUploadFiles}
        />
      )}
    </>
  );
};
export default ItemMenu;
