import axiosInstance from "../utils/axios/axiosInstance";

export interface Chat {
  id: number;
  campaignId: number;
  stepId: number;
  userId: number;
  title: string;
  aiModelId: number | null;
  modifiedBy: number;
  modifiedTime: string;
}

export interface ChatMessage {
  id: {
    chatId: number;
    timestamp: string;
  };
  type: "REQUEST" | "RESPONSE";
  text: string;
}

export interface ChatMessageRequest {
  keysRequestContext: Record<string, string>;
  prompt: string;
}

export interface ChatOverride {
  id: {
    chatId: number;
    key: string;
  };
  text: string;
  status: "APPLIED" | "CANCELLED" | "READY";
}

// Get chat by ID
export const getChatApi = ({ chatId }: { chatId: number }) => {
  return axiosInstance.get<Chat>(`/api/secured/chat/${chatId}`);
};

// Get list of chats for campaign step
export const getChatListApi = ({
  campaignId,
  stepId,
}: {
  campaignId: number;
  stepId: number;
}) => {
  return axiosInstance.get<Chat[]>(`/api/secured/chat/${campaignId}/${stepId}`);
};

// Create new chat
export const createChatApi = ({
  campaignId,
  stepId,
  chat,
}: {
  campaignId: number;
  stepId: number;
  chat: Partial<Chat>;
}) => {
  return axiosInstance.post<Chat>(
    `/api/secured/chat/${campaignId}/${stepId}`,
    chat,
  );
};

// Update chat title
export const updateChatTitleApi = ({
  chatId,
  title,
}: {
  chatId: number;
  title: string;
}) => {
  return axiosInstance.put<void>(
    `/api/secured/chat/${chatId}/update-title`,
    title,
  );
};

// Delete chat
export const deleteChatApi = ({ chatId }: { chatId: number }) => {
  return axiosInstance.delete<void>(`/api/secured/chat/${chatId}`);
};

// Get chat messages
export const getChatMessagesApi = ({
  chatId,
  lastTimestamp,
}: {
  chatId: number;
  lastTimestamp?: string;
}) => {
  return axiosInstance.get<ChatMessage[]>(
    `/api/secured/chat/${chatId}/messages`,
    {
      params: {
        lastTimestamp,
      },
    },
  );
};

// Post new message
export const postChatMessageApi = ({
  chatId,
  message,
}: {
  chatId: number;
  message: ChatMessageRequest;
}) => {
  return axiosInstance.post<void>(
    `/api/secured/chat/${chatId}/message`,
    message,
  );
};

// Update message
export const updateChatMessageApi = ({
  chatId,
  message,
}: {
  chatId: number;
  message: ChatMessage;
}) => {
  return axiosInstance.put<ChatMessage>(
    `/api/secured/chat/${chatId}/message`,
    message,
  );
};

// Get chat overrides
export const getChatOverridesApi = ({ chatId }: { chatId: number }) => {
  return axiosInstance.get<ChatOverride[]>(
    `/api/secured/chat/override/${chatId}`,
  );
};

// Update chat override
export const updateChatOverrideApi = ({
  chatOverride,
}: {
  chatOverride: ChatOverride;
}) => {
  return axiosInstance.put<ChatOverride>(
    `/api/secured/chat/override`,
    chatOverride,
  );
};
