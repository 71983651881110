import { Button } from "antd";
import React, { FC, useState } from "react";
import ExecuteCcItemModal from "../../common/modals/ExecuteCCItemModal";

type Props = {
  campaignId: number;
  stepId: number;
  ccItemKey: string;
};

const PaneExecuteButton: FC<Props> = ({ campaignId, ccItemKey, stepId }) => {
  const [isExecuteOpen, setIsExecuteOpen] = useState(false);

  const onShowExecuteModal = () => {
    setIsExecuteOpen(true);
  };

  const onCloseExecuteModal = () => {
    setIsExecuteOpen(false);
  };

  return (
    <>
      <Button size="small" onClick={onShowExecuteModal}>
        Execute
      </Button>

      <ExecuteCcItemModal
        ccItemKey={ccItemKey}
        closeModal={onCloseExecuteModal}
        isOpen={isExecuteOpen}
        campaignId={campaignId.toString()}
        stepId={stepId.toString()}
      />
    </>
  );
};

export default PaneExecuteButton;
