import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import ErrorBoundary from "./components/common/ErrorBoundary";
import InitializeApp from "./components/common/InitializeApp";
import ApplicationRoutes from "./routes/ApplicationRoutes";
import { store } from "./store/store";
import axiosInterceptor from "./utils/axios/axiosInterceptor";

function App() {
  axiosInterceptor(store);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Provider store={store}>
          <InitializeApp>
            <ApplicationRoutes />
          </InitializeApp>
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
