import { FC } from "react";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { cn } from "src/utils";
import { Button } from "../common/ui/button";
import { ChatOverride } from "src/api/chat.api";
import { Table } from "antd";

type CCVarsGridProps = {
  ccVars: TCcVariable[];
  selectedCcVars: TCcVariable[];
  setSelectedCcVars: (ccVars: TCcVariable[]) => void;
  chatOverrides?: ChatOverride[];
  onApplyOverride?: (key: string, value: string) => void;
  onRejectOverride?: (chatOverride: ChatOverride) => void;
};

export const CCVarsGrid: FC<CCVarsGridProps> = ({
  ccVars,
  selectedCcVars,
  setSelectedCcVars,
  chatOverrides,
  onApplyOverride,
  onRejectOverride,
}) => {
  const handleToggleCcVar = (ccVar: TCcVariable) => {
    if (selectedCcVars.includes(ccVar)) {
      setSelectedCcVars(selectedCcVars.filter((v) => v !== ccVar));
    } else {
      setSelectedCcVars([...selectedCcVars, ccVar]);
    }
  };

  const handleApplyAllOverrides = async () => {
    for (const v of chatOverrides || []) {
      await onApplyOverride?.(v.id.key, v.text);
    }
  };

  const handleRejectAllOverrides = async () => {
    for (const override of chatOverrides || []) {
      await onRejectOverride?.(override);
    }
  };

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      width: "30%",
      render: (text: string, record: any) => (
        <div
          className={cn(
            "text-gray-600 cursor-pointer font-semibold font-geistMono leading-[13px] py-2",
          )}
          onClick={() =>
            handleToggleCcVar(ccVars.find((v) => v.id.key === record.key)!)
          }
        >
          <span
            className={cn(
              "leading-[28px] py-1 px-2 rounded-lg",
              selectedCcVars.some((v) => v.id.key === record.key) &&
                "bg-[#EDE9FE]",
            )}
          >
            {text}
          </span>
        </div>
      ),
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      width: "70%",
      render: (text: string, record: any) => {
        const ccVar = ccVars.find((v) => v.id.key === record.key)!;
        const ccVarChatOverride = chatOverrides?.find(
          (override) => override.id.key === record.key,
        );
        const hasOverride = !!ccVarChatOverride;

        if (hasOverride) {
          return (
            <div className="flex flex-col gap-2">
              <span className="text-gray-800 font-bold text-sm">Previous</span>
              <div className="text-gray-500 min-h-[24px]">
                {ccVar.resultOvr || ccVar.result}
              </div>
              <span className="text-gray-800 font-bold text-sm">New</span>
              <div className="text-[#4F46E5]">{ccVarChatOverride?.text}</div>
              <div className="flex gap-2">
                <Button
                  size="sm"
                  variant="primary"
                  className="rounded-full"
                  onClick={() =>
                    onApplyOverride?.(record.key, ccVarChatOverride?.text || "")
                  }
                >
                  Apply
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  className="rounded-full"
                  onClick={() => onRejectOverride?.(ccVarChatOverride)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          );
        }

        return (
          <div
            className={cn(
              "cursor-pointer py-1 px-2 text-gray-600 font-instrument leading-[13px] !text-base rounded-lg min-h-[32px]",
              selectedCcVars.some((v) => v.id.key === record.key) &&
                "bg-[#EDE9FE]",
            )}
            onClick={() => handleToggleCcVar(ccVar)}
          >
            {ccVar.resultOvr || ccVar.result}
          </div>
        );
      },
    },
  ];

  const tableData = ccVars.map((variable) => ({
    key: variable.id.key,
    result: variable.resultOvr || variable.result,
  }));

  const tableScrollY = window.innerHeight - 84;

  return (
    <div className="w-full h-[calc(100vh-64px)] flex flex-col p-[12px] gap-[32px] relative">
      <Table
        className="max-w-[1120px] [&_.ant-table]:bg-transparent [&_.ant-table-thead]:hidden [&_.ant-table-cell]:!bg-transparent [&_.ant-table-cell]:!border-none [&_.ant-table-cell]:!p-[4px_8px] [&_.ant-table-wrapper]:border-none [&_.ant-table-tbody>tr:hover>td]:!bg-transparent"
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{ y: tableScrollY }}
        virtual
      />
      {chatOverrides && chatOverrides.length > 0 && (
        <div className="sticky bottom-2 flex justify-center" id="apply-changed">
          <div className="flex items-center gap-2 justify-between w-[864px] h-[60px] bg-white rounded-[100px] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.12)] shadow-[0px_4px_12px_0px_rgba(0,0,0,0.08)] border border-[#d4dae0] px-4 py-3">
            <div className="text-sm text-gray-500">
              {Object.keys(chatOverrides || {}).length} remaining overrides
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="lg"
                className="rounded-full"
                onClick={handleRejectAllOverrides}
              >
                Cancel All Overrides
              </Button>
              <Button
                variant="primary"
                size="lg"
                className="rounded-full"
                onClick={handleApplyAllOverrides}
              >
                Apply All Overrides
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
