import dagre from 'dagre';
import { Edge, Node } from 'reactflow';
import { GraphDirection } from '../../types';
import { DOWNSTREAM_NODE_DIMENSIONS } from '../../constants';

type Props = {
  nodes: Node<any>[];
  edges: Edge<any>[];
  direction: GraphDirection;
};
const getLayoutedElementsDownstreamGraph = ({nodes, edges, direction}: Props) => {
  const dagreGraph = new dagre.graphlib.Graph();
  const { width, height } = DOWNSTREAM_NODE_DIMENSIONS;

  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction, ranksep: 150 });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width, height });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {

    const nodeWithPosition = dagreGraph.node(node.id);

    node.position = {
      x: nodeWithPosition.x - width / 2,
      y: nodeWithPosition.y - height / 2,
    };
    return node;
  });

  return { nodes: layoutedNodes, edges };
};

export default getLayoutedElementsDownstreamGraph;