import { Empty } from "antd";
import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useModelConfigsList } from "src/hooks/useModelConfigsList";
import { Table, TableBody } from "../common/ui/table";
import { TableHeader } from "../table/Header";
import ModelConfigListItem from "./ListItem";
import SkeletonList from "../common/SkeletonList";
import Pagination from "../common/Pagination";

type Props = {};

const tableHeaders = [
  {
    title: "Title",
    name: "title",
    className: "font-sans w-1/8 text-black",
    isSortable: false,
  },
  {
    title: "Service Provider",
    name: "serviceProvider",
    className: "font-sans w-1/8 text-black",
    isSortable: false,
  },
  {
    title: "Model Name",
    name: "modelName",
    className: "font-sans w-1/8 text-black",
    isSortable: false,
  },
  {
    title: "Read Timeout",
    className: "font-sans w-1/8 text-black",
    name: "readTimoutSec",
    isSortable: false,
  },
  {
    title: "Max Tokens",
    className: "font-sans w-1/8 text-black",
    name: "maxTokens",
    isSortable: false,
  },
  {
    title: "Top P",
    className: "font-sans w-1/8 text-black",
    name: "topP",
    isSortable: false,
  },
  {
    title: "Temperature",
    className: "font-sans w-1/8 text-black",
    name: "temperature",
    isSortable: false,
  },
  {
    title: "Retries",
    className: "font-sans w-1/8 text-black",
    name: "retriesOnErrorResponse",
    isSortable: false,
  },
  {
    title: "Default",
    className: "font-sans w-1/8 text-black",
    name: "systemDefault",
    isSortable: false,
  },
  null,
];

const ModelConfigsList: FC<Props> = () => {
  const {
    loading,
    load,
    pageNumber,
    pageSize,
    list,
    updatePageSize,
    updatePage,
    total,
    hasAiConfigurationCreateRole,
  } = useModelConfigsList();

  useEffect(() => {
    load();
  }, [pageNumber, pageSize]);

  const renderPagination = () => {
    return (
      <Pagination
        updatePageSize={updatePageSize}
        updatePage={updatePage}
        total={total}
        pageSize={pageSize}
        pageNumber={pageNumber}
      />
    );
  };

  return (
    <>
      {list.length ? (
        <>
          {renderPagination()}

          {loading ? (
            <SkeletonList quantity={Math.ceil(total / 3)} />
          ) : (
            <div className="rounded-md border w-full mx-auto">
              <Table>
                <TableHeader items={tableHeaders} />
                <TableBody className="font-sans">
                  {list.map((item) => (
                    <ModelConfigListItem key={item.id} item={item} />
                  ))}
                </TableBody>
              </Table>
            </div>
          )}

          {renderPagination()}
        </>
      ) : (
        <>
          {loading ? (
            <SkeletonList quantity={3} />
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <Empty
                imageStyle={{ height: "30vh" }}
                description={
                  <div className="font-sans font-bold text-[#0F172A]">
                    <span>There are no model configs created.</span>
                    {hasAiConfigurationCreateRole && (
                      <span>
                        <Link to="/model-configs/create"> Create</Link> a
                        new model config.
                      </span>
                    )}
                  </div>
                }
                />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ModelConfigsList;
