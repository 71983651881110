import {
  TDynamicFormItemsFunction,
  TDynamicFormItemsImportVars,
  TDynamicFormItemsPrompt,
  TDynamicFormItemsString,
  TEnvVariable,
  TEnvVarsFormValues,
  TImportEnvVarsFormValues,
} from "../store/slices/envVariablesSlice";
import axiosInstance from "../utils/axios/axiosInstance";

export const getPromptTemplateApi = ({
  campaignId,
  stepId,
  key,
}: {
  campaignId: number;
  stepId: number;
  key: string;
}) => {
  return axiosInstance.get<string>(
    `/api/secured/${campaignId}/computation/prompt/${stepId}/${key}/template`,
  );
};

export const getEnvVarsApi = ({ campaignId }: { campaignId: number }) => {
  return axiosInstance.get<TEnvVariable[]>(`/api/secured/${campaignId}/env/`);
};

export type TGetEnvVarTypesResponse = {
  function: { items: TDynamicFormItemsFunction };
  prompt: { items: TDynamicFormItemsPrompt };
  string: { items: TDynamicFormItemsString };
};

export const getEnvVarTypesApi = () => {
  return axiosInstance.get<TGetEnvVarTypesResponse>(
    `/api/secured/global/env-types/`,
  );
};

export const getImportVarTypesApi = () => {
  return axiosInstance.get<TDynamicFormItemsImportVars>(
    `/api/secured/global/import-types/`,
  );
};

export const importVarsApi = ({
  campaignId,
  values,
}: {
  campaignId: number;
  values: TImportEnvVarsFormValues;
}) => {
  const { type, value, ...options } = values;
  return axiosInstance.post<string>(
    `/api/secured/${campaignId}/env/${type}/import`,
    {
      rawContent: value,
      options,
    },
  );
};

export const createEnvVarApi = ({
  campaignId,
  values,
}: {
  campaignId: number;
  values: TEnvVarsFormValues;
}) => {
  const { type, key, value, ...options } = values;

  return axiosInstance.post<void>(`/api/secured/${campaignId}/env/${type}`, {
    id: { key },
    value,
    options,
  });
};

export const updateEnvVarApi = ({
  campaignId,
  values,
}: {
  campaignId: number;
  values: TEnvVarsFormValues;
}) => {
  const { type, key, value, ...options } = values;

  return axiosInstance.put<void>(`/api/secured/${campaignId}/env/${type}`, {
    id: { key },
    value,
    options,
  });
};

export const bulkDeleteEnvVarApi = ({
  campaignId,
  keys,
}: {
  campaignId: number;
  keys: string[];
}) => {
  return axiosInstance.delete<string>(`/api/secured/${campaignId}/env/bulk`, {
    data: keys,
  });
};

export const executeFunctionVarApi = ({
  campaignId,
  scriptContent,
}: {
  campaignId: number;
  scriptContent: string;
}) => {
  return axiosInstance.post<string>(
    `/api/secured/${campaignId}/computation/env-item/script/`,
    scriptContent,
    {
      headers: {
        "Content-Type": "text/plain",
      },
    },
  );
};

export const editEnvVarKeyApi = ({
  campaignId,
  currentKey,
  newKey,
}: {
  campaignId: number;
  currentKey: string;
  newKey: string;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/${campaignId}/env/change-key/${currentKey}/${newKey}`,
    {},
  );
};

export const exportEnvVarsToCSVApi = ({
  campaignId,
  type,
}: {
  campaignId: number;
  type: string;
}) => {
  return axiosInstance.get<Blob>(
    `/api/secured/${campaignId}/env/${type}/export-csv`,
    {
      responseType: "blob",
    },
  );
};
