import { getCCItemApi } from "../../../../api/cc-variables.api";
import { TCcVariable } from "../../../../store/slices/ccVariablesSlice";

const getTargetWithInputCCItem = async ({
  campaignId,
  stepId,
  key,
}: {
  campaignId: string;
  stepId: string;
  key: string;
}) => {
  const { data: targetCCItem } = await getCCItemApi({
    campaignId,
    stepId,
    key,
  });
  const targetCCItemArguments = targetCCItem.options?.arguments;
  let inputCCItem: TCcVariable | null = null;

  if (!!targetCCItemArguments?.length) {
    //if there is more than one arg - set second arg as input
    const keyArg =
      targetCCItemArguments.length > 1
        ? targetCCItemArguments[1]
        : targetCCItemArguments[0];

    const { data } = await getCCItemApi({
      campaignId,
      stepId,
      key: keyArg,
    });

    inputCCItem = data;
  }

  return {
    targetCCItem,
    inputCCItem,
  };
};

export default getTargetWithInputCCItem;
