import { useState } from "react";

export const useBoolean = (
  initialValue: boolean,
): [boolean, () => void, () => void, () => void] => {
  const [value, setValue] = useState(initialValue);
  return [
    value,
    () => setValue(true),
    () => setValue(false),
    () => setValue(!value),
  ];
};
