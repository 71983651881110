import { Spin } from "antd";
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { TStepPhaseMetaInfo } from "../../../../globalTypes";
import { TCcVariable } from "../../../../store/slices/ccVariablesSlice";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import { PaneCcItem } from "../../../paneCCItem";
import { ActivePaneCCTab, PaneCCNavItem } from "../../../paneCCItem/types";
import { ChangeNodeProps, GraphColors, UpstreamDepsNodeType } from "../../types";

type Props = {
  item: TCcVariable | null;
  nodeType: UpstreamDepsNodeType;
  stepPhaseMetaInfo: TStepPhaseMetaInfo | null;
  isItemLoading: boolean;
  changeNodeProps?: ChangeNodeProps;
  setItem: Dispatch<SetStateAction<TCcVariable | null>>;
};

const PaneSection: FC<Props> = ({
  item,
  nodeType,
  stepPhaseMetaInfo,
  isItemLoading,
  changeNodeProps,
  setItem,
}) => {
  const { companyId } = useParams();
  const [activeComponent, setActiveComponent] =
    useState<ActivePaneCCTab | null>(null);
  const color = GraphColors[nodeType];
  const { hasCampaignCcUpdateRole, hasCampaignEnvItemUpdateRole } =
    useUserHasPermission({ companyId });

  useEffect(() => {
    if (!activeComponent && item) {
      setActiveComponent(
        item.resultOvr ? ActivePaneCCTab.Override : ActivePaneCCTab.Result,
      );
    }
  }, [item]);

  if (!item) {
    return (
      <PaneSectionWrap color={color} loading={isItemLoading}>
        <span>Please select the node</span>
      </PaneSectionWrap>
    );
  }

  const stepMetaInfo = stepPhaseMetaInfo && stepPhaseMetaInfo[item.id.stepId];

  if (!stepMetaInfo)
    return (
      <PaneSectionWrap color={color} loading={isItemLoading}>
        <span className="text-red-600">
          Error: Unable to get step by ID {item.id.stepId} from meta info:
        </span>
        <pre className="overflow-y-scroll text-[10px]">
          {JSON.stringify(stepPhaseMetaInfo, null, 2)}
        </pre>
      </PaneSectionWrap>
    );

  const { phaseId, stepName, phaseName } = stepMetaInfo;
  const allowedTypesForModel =
    item.type === "prompt" ||
    item.type === "asset" ||
    item.type === "web_scraper" ||
    item.type === "function";

  const isString = item.type === "string";

  const onAfterResultUpdate = async (updatedCCVariable: TCcVariable) => {
    setItem((item) => {
      return item ? updatedCCVariable : null;
    });

    changeNodeProps &&
      changeNodeProps({
        nodeId: `${updatedCCVariable.id.key}_${updatedCCVariable.id.stepId}`,
        props: { touched: true },
      });
  };

  const onAfterPromptUpdate = ({ id }: TCcVariable) => {
    changeNodeProps &&
      changeNodeProps({
        nodeId: `${id.key}_${id.stepId}`,
        props: { touched: true },
      });
  };

  const onAfterOverrideUpdate = ({ id }: TCcVariable) => {
    changeNodeProps &&
      changeNodeProps({
        nodeId: `${id.key}_${id.stepId}`,
        props: { touched: true },
      });
  };

  const onAfterCCItemUpdate = ({ id, state }: TCcVariable) => {
    changeNodeProps &&
      changeNodeProps({
        nodeId: `${id.key}_${id.stepId}`,
        props: { touched: true, state },
      });
  };

  const navDataList = [
    !isString && {
      label: ActivePaneCCTab.Override,
      active: activeComponent === ActivePaneCCTab.Override,
    },
    {
      label: ActivePaneCCTab.Result,
      active: activeComponent === ActivePaneCCTab.Result,
    },
    hasCampaignCcUpdateRole &&
      allowedTypesForModel && {
        label: ActivePaneCCTab.Model,
        active: activeComponent === ActivePaneCCTab.Model,
      },
    hasCampaignEnvItemUpdateRole &&
      item.type === "prompt" && {
        label: ActivePaneCCTab.Prompt,
        active: activeComponent === ActivePaneCCTab.Prompt,
      },
  ].filter(Boolean) as PaneCCNavItem[];

  return (
    <PaneSectionWrap color={color} loading={isItemLoading}>
      <PaneCcItem
        ccItem={item}
        stepName={stepName}
        phaseName={phaseName!}
        phaseId={phaseId!}
        navItems={navDataList}
        paneLabel={nodeType}
        setActiveComponent={(value) => setActiveComponent(value)}
        onAfterPromptUpdate={onAfterPromptUpdate}
        onAfterCCItemUpdate={onAfterCCItemUpdate}
        onAfterOverrideUpdate={onAfterOverrideUpdate}
        onAfterResultUpdate={onAfterResultUpdate}
        setCcItem={setItem}
      />
    </PaneSectionWrap>
  );
};

const PaneSectionWrap: FC<{
  color: GraphColors;
  children: ReactNode;
  loading: boolean;
}> = ({ color, children, loading }) => {
  return (
    <div
      className="relative w-full h-1/2 border-2 rounded-sm overflow-hidden"
      style={{
        borderColor: color,
        opacity: loading ? 0.3 : 1,
        pointerEvents: loading ? "none" : "auto",
      }}
    >
      <Spin
        spinning={loading}
        style={{ opacity: 1 }}
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
      {children}
    </div>
  );
};

export default PaneSection;
