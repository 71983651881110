import { Node } from "reactflow";
import {
  TCcVariable,
  TComputationState,
} from "../../store/slices/ccVariablesSlice";

export type GraphDirection = "LR" | "RL";

export type GroupedNodes = {
  [key: string]: Node<any>[];
};

export type MaxGroupPoints = Record<
  string,
  {
    maxX: number;
    maxY: number;
  }
>;

export type MinGroupPoints = Record<
  string,
  {
    minX: number;
    minY: number;
  }
>;

export type TUpdateNodeProps = { touched?: boolean; state?: TComputationState };

export type ChangeNodeProps = ({
  nodeId,
  props,
}: {
  nodeId: string;
  props: TUpdateNodeProps;
}) => void;

export type DownstreamDepsModalDefaultNodeProps = {
  ccItem: TCcVariable;
  phaseId: number | undefined;
  companyId: string | undefined;
  phaseName: string | undefined;
  stepName: string | undefined;
};

export type DownstreamDepsModalGroupNodeProps = {
  label: string;
};

export type DownstreamDepsModalEdgeProps = {
  hasSourceNodeOverride: boolean;
};

export type UpstreamDepsModalDefaultNodeProps = {
  ccItem: TCcVariable;
  phaseId: number | undefined;
  companyId: string | undefined;
  setInputNode: () => Promise<void>;
  setTargetNode: () => Promise<void>;
  setTargetWithInputNode: () => Promise<void>;
};

export type UpstreamDepsModalGroupNodeProps = {
  label: string;
};

export enum GraphColors {
  TARGET = "#623CEA",
  INPUT = "#3cbd77",
  DEFAULT = "#dedede",
  OVERRIDE = "#967206",
}

export enum UpstreamDepsNodeType {
  TARGET = "TARGET",
  INPUT = "INPUT",
}
