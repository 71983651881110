import React, { useMemo } from "react";
import { Icons } from "../../components/common/Icons";
import Pagination from "../../components/common/Pagination";
import { Button } from "../../components/common/ui/button";
import useUsersList from "./hooks/useUsersList";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { Table, TableBody } from "../../components/common/ui/table";
import { TableHeader } from "../../components/table/Header";
import UsersListItem from "../../components/users/UsersListItem";
import UsersListItemSkeleton from "../../components/users/UsersListItemSkeleton";
import UserDrawer from "../../components/users/UserDrawer/UserDrawer";
import { useBoolean } from "src/hooks/useBoolean";
import { Input } from "src/components/common/ui/input";
import { EmptyResult } from "src/components/common/EmptyResult";
import SkeletonList from "src/components/common/SkeletonList";
import { debounce } from "throttle-debounce";

const headerClassName = "font-sans font-bold text-[#0F172A]";
const tableHeaderItems = [
  {
    title: "User",
    className: headerClassName,
  },
  {
    title: "Email",
    className: headerClassName,
  },
  {
    title: "Company",
    className: headerClassName,
  },
  {
    title: "Department",
    className: headerClassName,
  },
  {
    title: "Last Login Time",
    className: headerClassName,
  },
  null,
];

const UsersPage: React.FC = () => {
  const {
    users,
    isLoading,
    updatePageSize,
    updatePage,
    updateSearch,
    search,
    total,
    pageSize,
    pageNumber,
    onCreateUser,
  } = useUsersList();
  const [drawerVisible, openDrawer, closeDrawer] = useBoolean(false);
  const { hasUserCreateRole } = useUserHasPermission({});

  const renderPagination = () => (
    <Pagination
      updatePageSize={updatePageSize}
      updatePage={updatePage}
      total={total}
      pageSize={pageSize}
      pageNumber={pageNumber}
    />
  );

  const debouncedSearch = useMemo(
    () => debounce(500, (value: string) => updateSearch(value)),
    [updateSearch],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    e.target.value = value;
    debouncedSearch(value);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal">
          Users
        </h1>

        {hasUserCreateRole && (
          <Button
            icon={Icons.Plus}
            className="rounded-full"
            disabled={isLoading}
            onClick={openDrawer}
          >
            Add New User
          </Button>
        )}
      </div>

      <div className="flex justify-between items-center gap-4">
        <Input
          placeholder="Search by name or email"
          defaultValue={search}
          onChange={handleSearchChange}
          className="max-w-sm"
        />
        {renderPagination()}
      </div>

      {users.length ? (
        <>
          <div className="rounded-md border w-full mx-auto">
            <Table>
              <TableHeader items={tableHeaderItems} />
              <TableBody className="font-sans">
                {isLoading ? (
                  Array.from({ length: pageSize }).map((_, index) => (
                    <UsersListItemSkeleton key={index} />
                  ))
                ) : (
                  <>
                    {users.map((item) => (
                      <UsersListItem key={item.user.id} item={item} />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="flex justify-end items-center gap-4">
            {renderPagination()}
          </div>
        </>
      ) : (
        <>
          {isLoading ? (
            <SkeletonList quantity={3} />
          ) : (
            <EmptyResult message={"No users found."} />
          )}
        </>
      )}

      {hasUserCreateRole && (
        <UserDrawer
          visible={drawerVisible}
          onClose={closeDrawer}
          initialFormValues={null}
          onCreateUser={onCreateUser}
        />
      )}
    </div>
  );
};

export default UsersPage;
