import React from "react";
import useGetSecurityGroupData from "../../components/securityGroups/hooks/useGetSecurityGroupData.hook";
import {
  SecurityGroupData,
  SecurityGroupDataHeader,
} from "../../components/securityGroups";

const SecurityGroupDataPage = () => {
  const {
    isInitialized,
    initialValues,
    isSuperUserGroup,
    setCheckedCompanyPermissions,
    checkedGlobalPermissions,
    setCheckedGlobalPermissions,
    checkedCompanyPermissions,
    isFetching,
    form,
    navigateToGroupList,
    handleSubmitForm,
  } = useGetSecurityGroupData();

  return (
    <div className="w-full flex flex-col gap-4">
      <SecurityGroupDataHeader
        isSuperUserGroup={isSuperUserGroup}
        isInitialized={isInitialized}
        isFetching={isFetching}
        handleSubmitForm={handleSubmitForm}
        navigateToGroupList={navigateToGroupList}
      />

      <SecurityGroupData
        isSuperUserGroup={isSuperUserGroup}
        isInitialized={isInitialized}
        checkedGlobalPermissions={checkedGlobalPermissions}
        checkedCompanyPermissions={checkedCompanyPermissions}
        setCheckedGlobalPermissions={setCheckedGlobalPermissions}
        setCheckedCompanyPermissions={setCheckedCompanyPermissions}
        initialValues={initialValues}
        form={form}
      />
    </div>
  );
};

export default SecurityGroupDataPage;
