import {
  TPermissionGroup,
  TSystemPermissions,
  TUserPermissionGroupWithPermissions,
} from "../types/securityGroups";
import axiosInstance from "../utils/axios/axiosInstance";
import { Key } from "react";

export const getSystemPermissionsApi = () => {
  return axiosInstance.get<TSystemPermissions>("/api/secured/sys-permissions/");
};

export const getPermissionsGroupApi = () => {
  return axiosInstance.get<TPermissionGroup[]>(
    "/api/secured/permissions-group/",
  );
};

export const getGlobalPermissionGroupsApi = () => {
  return axiosInstance.get<TPermissionGroup[]>(
    "/api/secured/permissions-group/global",
  );
};

export const getCompanyPermissionGroupsApi = ({
  companyId,
}: {
  companyId: string | number;
}) => {
  return axiosInstance.get<TPermissionGroup[]>(
    `/api/secured/permissions-group/company/${companyId}`,
  );
};

export const getPermissionGroupByIdApi = ({
  permissionGroupId,
}: {
  permissionGroupId: string | number;
}) => {
  return axiosInstance.get<TUserPermissionGroupWithPermissions>(
    `/api/secured/permissions-group/${permissionGroupId}`,
  );
};

export const createPermissionGroupApi = (payload: {
  permissionsGroup: {
    title: string;
    level: number;
  };
  permissions: Key[];
}) => {
  return axiosInstance.post<TSystemPermissions>(
    `/api/secured/permissions-group/`,
    payload,
  );
};

export const updatePermissionGroupApi = (payload: {
  permissionsGroup: {
    id: number | string;
    title: string;
    level: number;
  };
  permissions: Key[];
}) => {
  return axiosInstance.put<TSystemPermissions>(
    `/api/secured/permissions-group/`,
    payload,
  );
};

export const deletePermissionGroupByIdApi = ({
  permissionGroupId,
}: {
  permissionGroupId: string | number;
}) => {
  return axiosInstance.delete<TUserPermissionGroupWithPermissions>(
    `/api/secured/permissions-group/${permissionGroupId}`,
  );
};

export const clonePermissionGroupApi = ({
  permissionGroupId,
  title,
}: {
  permissionGroupId: string | number;
  title: string;
}) => {
  return axiosInstance.post<TUserPermissionGroupWithPermissions>(
    `/api/secured/permissions-group/${permissionGroupId}/clone`,
    {},
    { params: { title } },
  );
};
