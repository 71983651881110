import { TableHeaderItem } from "../../../globalTypes";
import { SecurityGroupFormValues } from "../types";

export const TABLE_HEADER_ITEMS: TableHeaderItem[] = [
  {
    title: "Group",
    className: "font-sans w-1/3 text-black",
  },
  {
    title: "Super User",
    className: "font-sans w-1/3 text-black",
  },
  {
    title: "Level",
    className: "font-sans w-1/3 text-black",
  },
  null,
];

export const DEFAULT_FORM_VALUES: SecurityGroupFormValues = {
  title: "",
  level: 10,
};
