import { Drawer } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCcVarsThunk,
  TCcVariable,
} from "src/store/slices/ccVariablesSlice";
import { selectCurrentPhase } from "src/store/slices/phasesSlice";
import { selectCurrentStep } from "src/store/slices/stepsSlice";
import { AppDispatch } from "src/store/store";
import { KeyPanelMode } from "src/types";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import { PaneCcItem } from "../../paneCCItem";
import { ActivePaneCCTab, PaneCCNavItem } from "../../paneCCItem/types";

type Props = {
  variableData?: TCcVariable | undefined;
  onClose: () => void;
  onSaved: () => void;
  mode?: KeyPanelMode;
  ccVariables: TCcVariable[];
  isOpen?: boolean;
};

export const VarKeyPanel: FC<Props> = ({
  variableData,
  onClose,
  onSaved,
  isOpen,
}) => {
  const { companyId, campaignId, stepId } = useParams();
  const [item, setItem] = useState<TCcVariable | null>(variableData || null);
  const currentStep = useSelector(selectCurrentStep);
  const currentPhase = useSelector(selectCurrentPhase);
  const [activeComponent, setActiveComponent] = useState(
    ActivePaneCCTab.Result,
  );
  const { hasCampaignCcUpdateStateRole, hasCampaignEnvItemUpdateRole } =
    useUserHasPermission({ companyId });
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    setActiveComponent(
      item?.resultOvr ? ActivePaneCCTab.Override : ActivePaneCCTab.Result,
    );
  }, [item]);

  if (!item) {
    return (
      <div className="text-red-600">Error: Unable to get computation item</div>
    );
  }

  const allowedTypesForModel =
    item.type === "prompt" ||
    item.type === "asset" ||
    item.type === "web_scraper" ||
    item.type === "function";

  const isString = item.type === "string";

  const onAfterResultUpdate = async () => {
    await dispatch(loadCcVarsThunk({ campaignId, stepId })).unwrap();

    onSaved();
  };

  const onAfterOverrideUpdate = async () => {
    onSaved();
  };

  const navDataList = [
    !isString && {
      label: ActivePaneCCTab.Override,
      active: activeComponent === ActivePaneCCTab.Override,
    },
    {
      label: ActivePaneCCTab.Result,
      active: activeComponent === ActivePaneCCTab.Result,
    },
    hasCampaignCcUpdateStateRole &&
      allowedTypesForModel && {
        label: ActivePaneCCTab.Model,
        active: activeComponent === ActivePaneCCTab.Model,
      },
    hasCampaignEnvItemUpdateRole &&
      item.type === "prompt" && {
        label: ActivePaneCCTab.Prompt,
        active: activeComponent === ActivePaneCCTab.Prompt,
      },
  ].filter(Boolean) as PaneCCNavItem[];

  return (
    <Drawer
      placement={"bottom"}
      closable={false}
      open={isOpen}
      onClose={onClose}
      height={340}
      classNames={{
        header: "h-0",
        body: "!p-0 !h-full",
      }}
    >
      <PaneCcItem
        ccItem={item}
        stepName={currentStep?.name!}
        phaseName={currentPhase?.name!}
        phaseId={currentPhase?.id!}
        navItems={navDataList}
        paneLabel={"EDIT"}
        setCcItem={setItem}
        setActiveComponent={(value) => setActiveComponent(value)}
        wrapClassName="!p-6"
        onAfterOverrideUpdate={onAfterOverrideUpdate}
        onAfterResultUpdate={onAfterResultUpdate}
      />
    </Drawer>
  );
};
