import React, { FC } from "react";

type Props = {
  errorMessages: string[];
};
const ClonedWithErrorsMessage: FC<Props> = ({ errorMessages }) => {
  return (
    <div>
      <div className="font-sans font-bold text-[#475569] text-[14px]">
        Cloned with errors:
      </div>

      <div className="flex flex-col items-start">
        {errorMessages.map((msg, index) => (
          <div key={index} className="text-red-900 text-[12px]">
            {msg}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClonedWithErrorsMessage;
