import dayjs from "dayjs";
import {
  MicrositeCompanyAssets,
  MicrositeItemSource,
  TFileTreeItem,
} from "../../../../globalTypes";

const removeFileExtension = (fileName: string) => {
  if (fileName.includes(".")) {
    return fileName.split(".").slice(0, -1).join(".");
  }
  return fileName;
};

type Props = {
  storageFiles: TFileTreeItem[];
  companyAssets: MicrositeCompanyAssets;
  campaignId: string | undefined;
};

const updateMicrositeStorageItem = ({
  storageFiles,
  companyAssets,
  campaignId,
}: Props) => {
  const updatedFiles = [...storageFiles];

  return updatedFiles.map((file) => {
    const updatedFile = { ...file };
    const { folder, isRootDir, fileName } = updatedFile;
    const { assets, campaigns } = companyAssets;
    const itemSources: MicrositeItemSource[] = [];
    const publicationDates: string[] = [];
    let isContext = false;

    if (folder) {
      if (isRootDir) {
        itemSources.push({
          campaignName: "Source",
          campaignId: "root",
          stepId: "root",
          phaseId: "root",
        });
        publicationDates.push("Modified Date");
      } else {
        for (const itemKey in assets) {
          if (itemKey.startsWith(fileName)) {
            const assetData = assets[itemKey];

            for (let i = 0; i < assetData.length; i++) {
              const item = assetData[i];
              const campaignName = campaigns[item.cid];
              const isContextFolder = item.cid.toString() === campaignId;

              if (
                !itemSources.some(
                  (source) => source.campaignName === campaignName,
                )
              ) {
                itemSources.push({
                  campaignName,
                  campaignId: item.cid.toString(),
                  stepId: item.sid.toString(),
                  phaseId: item.pid.toString(),
                });
              }

              if (isContextFolder) {
                isContext = isContextFolder;
              }
            }
          }
        }
      }
    } else {
      const assetData =
        assets[removeFileExtension(fileName)] || assets[fileName];

      if (file?.lastModified && !assetData) {
        publicationDates.push(
          dayjs(file.lastModified).format("DD MMM YYYY, h:mm:ss a"),
        );
      }

      if (assetData) {
        assetData.forEach((item) => {
          const campaignName = campaigns[item.cid];
          const isContextFile = item.cid.toString() === campaignId;
          const date = dayjs(item.publishedDate).format(
            "DD MMM YYYY, h:mm:ss a",
          );

          if (
            !itemSources.some((source) => source.campaignName === campaignName)
          ) {
            itemSources.push({
              campaignName,
              campaignId: item.cid.toString(),
              stepId: item.sid.toString(),
              phaseId: item.pid.toString(),
            });
          }

          if (!publicationDates.some((d) => d.includes(date))) {
            publicationDates.push(`${campaignName}: ${date}`);
          }

          if (isContextFile) {
            isContext = isContextFile;
          }
        });
      } else {
        itemSources.push({
          campaignName: "File Uploaded",
          campaignId: "uploaded",
          stepId: "uploaded",
          phaseId: "uploaded",
        });
      }
    }

    updatedFile.campaigns = itemSources;
    updatedFile.publicationDates = publicationDates;
    updatedFile.isContext = isContext;

    return updatedFile;
  });
};

export default updateMicrositeStorageItem;
