import React, { FC, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, InputRef, Modal } from "antd";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import useSetFocus from "../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { unpublishAssetApi, updateAssetApi } from "../../../api/assets.api";
import handleRequestError from "../../../utils/handleRequestError";
import { getConfirmationMessage } from "../../../utils/getConfirmationMessage";
import SelectMicrositeStorageFolder, {
  SelectMicrositeFolderType,
} from "../../common/SelectMicrositeStorageFolder";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import customizeFormLabels from "../../../utils/customizeFormLabels";
import {
  minMax,
  required,
  validateMicrositeAssetPath,
} from "../../../utils/validations";
import micrositeContextFolderTooltip from "../../../constants/micrositeContextFolderTooltip";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../../constants";
import { TAsset } from "../../../globalTypes";
import { AssetFormValues } from "../types";

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  campaignId: string;
  loadAndSetAssets?: () => Promise<void>;
  onAssetUpdated?: (asset: TAsset) => void;
  assetData: TAsset;
};

const EditAssetModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  onAssetUpdated,
  loadAndSetAssets,
  campaignId,
  assetData,
}) => {
  const { companyId } = useParams();
  const inputRef = useRef<InputRef>(null);
  const messageApi = useSelector(selectMessageApi);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<AssetFormValues>();
  const initialFormValues: AssetFormValues = {
    title: assetData.title,
    microSiteTargetFolder: assetData.microSiteTargetFolder || "",
    ref: assetData.ref || "",
  };
  const formValues = Form.useWatch([], form);
  const isFormChanged =
    JSON.stringify(formValues) !== JSON.stringify(initialFormValues);

  useSetFocus(inputRef);
  useSubmitFormOnEnter(() => onFormSubmit(), {
    condition: !isLoading && isFormChanged,
  });

  const onUpdateAsset = async (values: AssetFormValues) => {
    try {
      setIsLoading(true);

      const isFolderContextChanged =
        values.microSiteTargetFolder !== assetData.microSiteTargetFolder;
      const isAssetTitleChanged = values.title !== assetData.title;
      const isAssetPublished =
        assetData.approved &&
        assetData.publishToMicroSite &&
        assetData.publishedDate !== null;

      const updateAsset = async (unpublish?: boolean) => {
        try {
          if (unpublish) {
            await unpublishAssetApi({
              campaignId,
              assetId: assetData.id,
            });
          }
          const updatedAsset = {
            ...assetData,
            ...values,
          };

          const { data } = await updateAssetApi({
            ...updatedAsset,
          });

          loadAndSetAssets && (await loadAndSetAssets());

          onAssetUpdated?.(data);
          setIsModalOpen(false);
          form.resetFields();
          messageApi.success("Asset has been successfully updated");
        } catch (e: any) {
          const customError = handleRequestError(e);
          messageApi.error(customError.message);
          console.error(customError);
        } finally {
          setIsLoading(false);
        }
      };

      const confirmationMessage = getConfirmationMessage(
        isFolderContextChanged,
        isAssetTitleChanged,
        "Asset Title",
      );
      if (confirmationMessage && isAssetPublished) {
        Modal.confirm({
          title: "Confirm asset change",
          content: `${confirmationMessage} The published asset will be deleted.`,
          okText: "Yes",
          cancelText: "Cancel",
          onOk: () => updateAsset(true),
        });
      } else {
        await updateAsset();
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const insertFolderData: SelectMicrositeFolderType = (path: string) => {
    form.setFieldsValue({
      microSiteTargetFolder: path,
    });
  };

  const onFormSubmit = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: AssetFormValues) => {
      await onUpdateAsset(values);
    },
  });

  return (
    <Modal
      title="Edit Asset"
      open={isModalOpen}
      onOk={onFormSubmit}
      okText="Update"
      width={600}
      destroyOnClose
      okButtonProps={{ loading: isLoading, disabled: !isFormChanged }}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={customizeFormLabels}
        name="edit_asset_form"
        initialValues={initialFormValues}
      >
        <Form.Item
          name="title"
          label="Asset Title"
          tooltip="Please enter a string up to 255 characters long."
          rules={[required(), minMax({ max: 255, text: "Asset Title" })]}
        >
          <Input
            placeholder="Enter asset title"
            ref={inputRef}
            disabled={true}
          />
        </Form.Item>

        <Form.Item
          label="Microsite Context Folder"
          tooltip={micrositeContextFolderTooltip}
        >
          <Form.Item
            name="microSiteTargetFolder"
            rules={[
              minMax({ max: 255, text: "Path" }),
              validateMicrositeAssetPath,
            ]}
            noStyle
          >
            <Input placeholder={MICROSITE_INPUT_PLACEHOLDER} />
          </Form.Item>
          {companyId && (
            <SelectMicrositeStorageFolder
              companyId={companyId}
              onSelect={insertFolderData}
            />
          )}
        </Form.Item>

        <Form.Item label="Asset Link" required>
          <Form.Item
            name="ref"
            noStyle
            rules={[
              {
                type: "string",
                required: true,
                whitespace: true,
                message: "Required field!",
              },
            ]}
          >
            <Input placeholder="Enter asset link" disabled={true} />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAssetModal;
