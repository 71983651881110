import { Flex, Form, Input, InputRef, Modal, Select } from "antd";
import React, { FocusEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useEnvVarData from "../../../hooks/useEnvVarData";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  createEnvVarThunk,
  editEnvVarKeyThunk,
  TDynamicFormItem,
  TEnvVariable,
  TEnvVariableType,
  TEnvVarsFormValues,
} from "../../../store/slices/envVariablesSlice";
import { AppDispatch } from "../../../store/store";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import TextAreaWithAltEnter from "../../common/TextAreaWithAltEnter";
import DynamicForms from "../DynamicForms/DynamicForms";
import { Button } from "../../common/ui/button";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import customizeFormLabels from "../../../utils/customizeFormLabels";

type Props = {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setEditModeProps: React.Dispatch<React.SetStateAction<TEnvVariable | null>>;
  //null when create mode, object with var data - when edit mode
  envVarData: TEnvVariable | null;
  varKeysLowerCase: Array<string>;
};

const AddVariableModal = (props: Props) => {
  const { companyId } = useParams();
  const {
    isOpened,
    setIsOpened,
    envVarData,
    varKeysLowerCase,
    setEditModeProps,
  } = props;
  const {
    isTypeSelectedDefault,
    dynamicDataDefault,
    dynamicFormItems,
    envVarTypes,
    initialFormValues,
    setEnvVarTypeLS,
  } = useEnvVarData(envVarData);
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const [isTypeSelected, setIsTypeSelected] = useState(isTypeSelectedDefault);
  const [dynamicData, setDynamicData] =
    useState<TDynamicFormItem>(dynamicDataDefault);
  const [form] = Form.useForm<TEnvVarsFormValues>();
  const inputTagRef = useRef<InputRef>(null);
  const [isFetching, setIsFetching] = useState(false);
  const { hasCampaignEnvItemRenameRole } = useUserHasPermission({ companyId });

  useSubmitFormOnEnter(() => submitForm(), { condition: !isFetching });

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus({
        cursor: "end",
      });
    }

    if (!envVarData) {
      const defaultFieldsValue: any = {};

      dynamicData.forEach((item) => {
        //don't set default value in checkbox
        if (item.type !== "checkbox") {
          defaultFieldsValue[item.name] = item.defaultValue;
        }
      });

      form.setFieldsValue(defaultFieldsValue);
    }
  }, []);

  const handleCancel = () => {
    form.resetFields();

    setIsTypeSelected(false);
    setDynamicData([]);
    setIsOpened(false);
  };

  const onTypeSelect = async (value: TEnvVariableType) => {
    const keyValue = form.getFieldValue("key");

    form.resetFields();
    form.setFieldsValue({ type: value, key: keyValue });
    setIsTypeSelected(true);
    setDynamicData(dynamicFormItems[value]);

    setEnvVarTypeLS(value);
  };

  const onCreate = async (values: TEnvVarsFormValues) => {
    try {
      setIsFetching(true);
      const action = envVarData ? "update" : "create";
      const res = await dispatch(createEnvVarThunk({ values, action }));

      if ("error" in res) {
        messageApi.error(
          `An error occurred while trying to create the env variable: ${res.payload?.message}`,
        );
      } else {
        setIsOpened(false);
        setIsTypeSelected(false);
        setDynamicData([]);
        form.resetFields();
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onKeyNameChange = async (e: FocusEvent<HTMLInputElement>) => {
    form
      .validateFields(["key"])
      .then(async () => {
        const newKey = e.target.value;
        const currentKey = envVarData!.id.key;

        if (currentKey === newKey) return;
        messageApi.open({
          key: "rename_env_var_key",
          type: "loading",
          content: "Renaming the key...",
        });

        try {
          const res = await dispatch(
            editEnvVarKeyThunk({ currentKey, newKey }),
          );

          if ("error" in res) {
            messageApi.open({
              key: "rename_env_var_key",
              type: "error",
              content: `An error occurred while trying to import env variables: ${res.payload?.message}`,
            });
          } else {
            const newVarData = { ...envVarData! };

            newVarData.id = {
              ...newVarData.id,
              key: newKey,
            };

            setEditModeProps(newVarData);

            messageApi.open({
              key: "rename_env_var_key",
              type: "success",
              content: "Successfully renamed!",
            });
          }
        } catch (e: any) {
          messageApi.open({
            key: "rename_env_var_key",
            type: "error",
            content: `An error occurred while trying to import env variables: ${e?.message}`,
          });
          console.error(
            "An error occurred while trying to import env variables:",
            e,
          );
        }
      })
      .catch((e) => {
        console.warn("Can't update invalid env var key", e.errorFields);
      });
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TEnvVarsFormValues) => {
      await onCreate(values);
    },
  });

  return (
    <Modal
      title={envVarData ? "Edit Variable" : "Add Variable"}
      open={isOpened}
      okText="Save"
      onOk={submitForm}
      okButtonProps={{ disabled: !isTypeSelected }}
      onCancel={handleCancel}
      destroyOnClose={true}
      centered={true}
      width={"70vw"}
      footer={() => {
        return (
          <div className="flex justify-end gap-[12px]">
            <Button
              onClick={handleCancel}
              variant="primaryOutline"
              className="rounded-full self-start"
              size="sm"
            >
              Cancel
            </Button>
            <Button
              onClick={submitForm}
              className="rounded-full self-start px-5"
              loading={isFetching}
              disabled={isFetching}
              size="sm"
            >
              Save
            </Button>
          </div>
        );
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="env_variable_form"
        style={{ height: "70vh", overflowY: "scroll" }}
        initialValues={initialFormValues}
        requiredMark={customizeFormLabels}
      >
        <Flex gap="middle" align="center">
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Required field!" }]}
          >
            <Select
              placeholder="Select type"
              style={{ width: 120 }}
              onChange={onTypeSelect}
              options={envVarTypes.map((type) => ({
                value: type,
                label: type,
              }))}
              disabled={!!envVarData}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            hidden={!isTypeSelected}
            name="key"
            label="Key"
            tooltip="Enter a valid variable identifier. It should start with a letter, underscore (_), or dollar sign ($), and can include letters, numbers, hyphens (-), underscores, and dollar signs."
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value || !value.length) {
                    return Promise.reject(new Error("Required field!"));
                  }

                  const lowerCaseValue = value.toLowerCase();

                  if (
                    envVarData?.id.key.toLowerCase() !== lowerCaseValue &&
                    varKeysLowerCase.includes(lowerCaseValue)
                  ) {
                    return Promise.reject(
                      new Error("A variable with such a key already exists!"),
                    );
                  }

                  if (!/^[a-zA-Z_$][a-zA-Z0-9_$-]*$/.test(value)) {
                    return Promise.reject(new Error("Invalid variable name!"));
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              ref={inputTagRef}
              placeholder="Enter unique key name"
              onBlur={
                hasCampaignEnvItemRenameRole && envVarData
                  ? onKeyNameChange
                  : undefined
              }
            />
          </Form.Item>
        </Flex>

        <Form.Item
          hidden={!isTypeSelected}
          name="value"
          label="Value"
          rules={[
            {
              required: form.getFieldValue("type") !== "function",
              message: "Required field!",
            },
          ]}
        >
          <TextAreaWithAltEnter
            placeholder="Enter value"
            rows={5}
            currentForm={form}
            fieldName="value"
          />
        </Form.Item>
        <DynamicForms
          isTypeSelected={isTypeSelected}
          form={form}
          items={dynamicData}
        />
      </Form>
    </Modal>
  );
};

export default AddVariableModal;
