import React from "react";
import { Spin } from "antd";
import Graph from "./Graph";
import EditorPane from "./EditorPane";
import useGetNodesAndEdges from "../../hooks/upstreamDeps/useGetNodesAndEdges";
import { UPSTREAM_DEPS_GRAPH_HEIGHT } from "../../constants";
import useComputationEvents from "../../hooks/upstreamDeps/useUpdateGraphWithComputationEvents";
import { DefaultNode, GroupNode } from './CustomGraphNodes';

const nodeTypes = {
  group: GroupNode,
  default: DefaultNode,
};

const UpstreamDependencies = () => {
  const {
    edges,
    nodes,
    isInitialized,
    initialNodeId,
    inputNode,
    targetNode,
    stepPhaseMetaInfo,
    isTargetLoading,
    isInputLoading,
    changeNodeProps,
    setInputNode,
    setTargetNode,
  } = useGetNodesAndEdges();

  useComputationEvents({
    changeNodeProps,
    setInputNode,
    setTargetNode,
    inputNodeId: inputNode
      ? `${inputNode.id.key}_${inputNode.id.stepId}`
      : null,
    targetNodeId: targetNode
      ? `${targetNode.id.key}_${targetNode.id.stepId}`
      : null,
  });

  return (
    <Spin spinning={!isInitialized} tip="Loading the data...">
      <div
        className="w-full border-2"
        style={{ height: `${UPSTREAM_DEPS_GRAPH_HEIGHT}px` }}
      >
        <Graph
          nodes={nodes}
          edges={edges}
          initialNodeId={initialNodeId}
          isInitialized={isInitialized}
          nodeTypes={nodeTypes}
        />
      </div>
      <EditorPane
        isTargetLoading={isTargetLoading}
        isInputLoading={isInputLoading}
        inputNode={inputNode}
        targetNode={targetNode}
        stepPhaseMetaInfo={stepPhaseMetaInfo}
        changeNodeProps={changeNodeProps}
        setInputNode={setInputNode}
        setTargetNode={setTargetNode}
      />
    </Spin>
  );
};

export default UpstreamDependencies;
