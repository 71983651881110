import { Dispatch, SetStateAction, useEffect } from "react";
import { TCcVariable } from "../../../../store/slices/ccVariablesSlice";
import { selectEventMessages } from "../../../../store/slices/computationMessages/slice";
import { useSelector } from "react-redux";
import { ChangeNodeProps } from "../../types";

type Props = {
  changeNodeProps: ChangeNodeProps;
  setInputNode: Dispatch<SetStateAction<TCcVariable | null>>;
  setTargetNode: Dispatch<SetStateAction<TCcVariable | null>>;
  inputNodeId: string | null;
  targetNodeId: string | null;
};

const useComputationEvents = ({
  changeNodeProps,
  setTargetNode,
  setInputNode,
  inputNodeId,
  targetNodeId,
}: Props) => {
  const messages = useSelector(selectEventMessages);

  useEffect(() => {
    messages.forEach(({ item }) => {
      if (item) {
        const updatedNodeId = `${item.id.key}_${item.id.stepId}`;

        // Update target and input nodes in section
        if (updatedNodeId === targetNodeId) {
          setTargetNode(item);
        }

        if (updatedNodeId === inputNodeId) {
          setInputNode(item);
        }

        // Update node in graph
        changeNodeProps({
          nodeId: updatedNodeId,
          props: { touched: false, state: item.state },
        });
      }
    });
  }, [
    messages,
    targetNodeId,
    inputNodeId,
    setTargetNode,
    setInputNode,
    changeNodeProps,
  ]);
};

export default useComputationEvents;
