import React, { FC } from "react";
import { Tree } from "antd";
import {
  TPermissionGroups,
  TPermissionsLevel,
} from "../../../types/securityGroups";
import { DataNode } from "antd/lib/tree";
import { UserPermissions } from "../../../store/slices/userData/constants";
import { SetCheckedPermissions } from "../types";

type Props = {
  permissionsTree: TPermissionGroups;
  setCheckedKeys: SetCheckedPermissions;
  checkedKeys: UserPermissions[];
  isSuperUserGroup: boolean;
  level: TPermissionsLevel;
};

const PermissionsTree: FC<Props> = ({
  permissionsTree,
  setCheckedKeys,
  checkedKeys,
  isSuperUserGroup,
  level,
}) => {
  const getTreeData = (permissions: TPermissionGroups): DataNode[] => {
    return Object.keys(permissions).map((key) => ({
      title: <span className="font-semibold">{key}</span>,
      key: key,
      children: permissions[key].map((perm) => ({
        title: <span className="text-[12px]">{perm}</span>,
        key: `${perm}`,
      })),
    }));
  };

  return (
    <div className="flex flex-col gap-[12px]">
      <span className="text-[#475569] font-sans font-semibold text-[16px]">
        {level} permissions
      </span>
      <Tree
        checkable
        selectable={false}
        disabled={isSuperUserGroup}
        onCheck={(keys) => setCheckedKeys(keys as UserPermissions[])}
        checkedKeys={checkedKeys}
        treeData={getTreeData(permissionsTree)}
      />
    </div>
  );
};

export default PermissionsTree;
