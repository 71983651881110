import { FC } from "react";
import { useCampaignListTotalCampaigns } from "src/store/slices/campaignsListSlice";
import { CustomSelect } from "../common/ui/CustomSelect";

type CampaignsPaginationProps = {
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  pageSizeOptions?: string[];
};
const CampaignsPagination: FC<CampaignsPaginationProps> = ({
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  pageSizeOptions = ["10", "20", "50", "100"],
}) => {
  const totalCampaigns = useCampaignListTotalCampaigns();
  if (!totalCampaigns || !pageSize) return null;
  const handlePageSizeChange = (pageSize: string) => {
    onPageSizeChange(Number(pageSize));
  };
  const handlePageChange = async (page: string) => {
    onPageChange(Number(page));
  };
  const pagesNumber = Math.ceil(totalCampaigns / pageSize);
  const pages = Array.from({ length: pagesNumber }, (_, i) => i + 1);
  return (
    <div className="flex justify-end">
      <div className=" h-10 justify-start items-center gap-6 inline-flex">
        <div className="text-slate-600 text-sm font-medium font-sans leading-[14px]">
          {totalCampaigns} items
        </div>
        <div className="justify-start items-center gap-2 flex">
          <CustomSelect
            options={pages.map((option) => ({
              value: String(option),
              label: String(option),
            }))}
            value={String(page)}
            onChange={handlePageChange}
            label="Select a page"
            className="w-[80px]"
          />
          <div className="text-slate-600 text-sm font-medium font-sans leading-[14px]">
            of {pagesNumber}
          </div>
        </div>
        <div className="w-px h-10 bg-slate-300" />
        <CustomSelect
          options={pageSizeOptions.map((option) => ({
            value: option,
            label: `${option} per page`,
          }))}
          value={String(pageSize)}
          onChange={handlePageSizeChange}
          label="Select a page size"
          className="w-[180px]"
        />
      </div>
    </div>
  );
};
export default CampaignsPagination;
