import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import handleRequestError from "../../../../utils/handleRequestError";
import { TNavigationItem } from "../../../../globalTypes";
import { AppDispatch } from "../../../../store/store";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import {
  cleanUpCompanyStorageData,
  loadCompanyStorageDataThunk,
  selectCompanyStorageCampaignData,
  selectCompanyStorageMicroSiteData,
} from "../../../../store/slices/companyStorageSlice";
import { useGoBack } from "../../../../hooks/useGoBack";

const useCampaignStorageData = () => {
  const { companyId, campaignId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const campaignData = useSelector(selectCompanyStorageCampaignData);
  const companyMicroSite = useSelector(selectCompanyStorageMicroSiteData);
  const onGoBack = useGoBack(
    `/campaigns/company/${companyId}/campaign/${campaignId}/`,
  );
  const {
    hasPublicFsFileListRole,
    hasPrivateFsFileListRole,
    hasCampaignReadRole,
    hasMsFsFileListRole,
  } = useUserHasPermission({ companyId });

  const navigationItems = useMemo(() => {
    return [
      hasPublicFsFileListRole && {
        path: "public",
        title: "Public",
      },
      hasPrivateFsFileListRole && {
        path: "private",
        title: "Private",
      },
      hasCampaignReadRole && {
        path: "assets",
        title: "Assets",
      },
      companyMicroSite &&
        hasMsFsFileListRole && {
          path: "microsite",
          title: "Microsite",
        },
    ].filter(Boolean) as TNavigationItem[];
  }, [
    hasPublicFsFileListRole,
    hasPrivateFsFileListRole,
    hasCampaignReadRole,
    companyMicroSite,
    hasMsFsFileListRole,
  ]);

  useEffect(() => {
    getCampaignData();

    return () => {
      dispatch(cleanUpCompanyStorageData());
    };
  }, []);

  const getCampaignData = async () => {
    if (!campaignId || !companyId) {
      messageApi.error("Unable to load the campaign view");
      console.error("Unable to get [companyId] or [campaignId]");
      setIsLoading(false);
      return;
    }

    try {
      await dispatch(
        loadCompanyStorageDataThunk({ companyId, campaignId }),
      ).unwrap();

      setError(null);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
      setError(customError.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    onGoBack,
    navigationItems,
    campaignName: campaignData?.name,
  };
};
export default useCampaignStorageData;
