import React from "react";
import { Table, TableBody } from "../../common/ui/table";
import { TableHeader } from "../../table/Header";
import SecurityGroupListItem from "./SecurityGroupListItem";
import { Empty } from "antd";
import useGetPermissionsGroupList from "../hooks/useGetPermissionsGroupList.hook";
import SkeletonList from "../../common/SkeletonList";
import { TABLE_HEADER_ITEMS } from "../constants";

const SecurityGroupList = () => {
  const { isFetching, permissionsGroupList, error, getUserPermissions } =
    useGetPermissionsGroupList();

  if (isFetching) {
    return <SkeletonList quantity={3} />;
  }

  if (error) {
    return <span className="text-red-600">{error}</span>;
  }

  if (!permissionsGroupList.length)
    return (
      <Empty
        description={
          <div>
            <span className="font-sans font-medium text-[#0F172A]">
              There are no permission groups.
            </span>
          </div>
        }
      />
    );

  return (
    <div className="rounded-md border w-full mx-auto">
      <Table>
        <TableHeader items={TABLE_HEADER_ITEMS} />
        <TableBody className="font-sans">
          {permissionsGroupList.map((item) => (
            <SecurityGroupListItem
              key={item.id}
              updateList={getUserPermissions}
              userPermissionGroup={item}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default SecurityGroupList;
