import clsx from "clsx";
import React, { useCallback, useMemo, useRef } from "react";
import { TFileTreeItem } from "src/globalTypes";
import useHover from "../../../hooks/useHover";
import { ChevronRightIcon, Icons } from "../../common/Icons";
import StaticStorageItemActions from "./ItemActions";
import { GetMenuActions } from "./hooks/types";
import FolderIcon from "../FolderIcon";

type Props = {
  isSelected: boolean;
  hasChildren: boolean;
  item: TFileTreeItem;
  onExpandFolder: (folder: TFileTreeItem) => void;
  onSelectItem: () => void;
  selectedItem: TFileTreeItem | null;
  getItemMenuActions: GetMenuActions;
  setTreeData: (tree: TFileTreeItem[]) => void;
};

const StaticStorageFolder: React.FC<Props> = ({
  item,
  onExpandFolder,
  onSelectItem,
  isSelected,
  selectedItem,
  getItemMenuActions,
  setTreeData,
  hasChildren,
}) => {
  const { expanded, title } = item;
  const folderRef = useRef<HTMLDivElement | null>(null);
  const isHovering = useHover(folderRef);

  const handleExpandDir = useCallback(
    () => onExpandFolder(item),
    [onExpandFolder, item],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onSelectItem();
    },
    [onSelectItem],
  );

  const hasMenuItems = useMemo(
    () => getItemMenuActions({ item, setTree: setTreeData }).length > 0,
    [getItemMenuActions, item, setTreeData],
  );

  const menuIcon =
    isSelected && selectedItem ? (
      <StaticStorageItemActions
        item={selectedItem}
        getItemMenuActions={getItemMenuActions}
        setTree={setTreeData}
      />
    ) : hasMenuItems ? (
      <Icons.MoreDots
        className={clsx("size-[20px]", {
          "opacity-100": isHovering,
          "opacity-0": !isHovering,
        })}
      />
    ) : (
      <div className="size-[20px]" />
    );

  return (
    <div
      ref={folderRef}
      onClick={handleClick}
      className={clsx(
        "inline-flex items-center gap-[4px] h-[32px] py-[6px] pr-[8px] transition-all cursor-pointer rounded-md",
        {
          "text-vePrimary bg-[#EEF2FF]": isSelected,
          "hover:bg-[#eee]": !isSelected,
        },
      )}
    >
      <div className="ml-[6px] size-5">
        {hasChildren && (
          <ChevronRightIcon
            className={clsx("", { "rotate-90": expanded })}
            onClick={handleExpandDir}
          />
        )}
      </div>
      <div className="flex items-center gap-[8px]">
        <div className="size-6">
          <FolderIcon expanded={!!expanded} />
        </div>
        <div className="flex items-center gap-[28px]">
          <div>{title}</div>
          {menuIcon}
        </div>
      </div>
    </div>
  );
};

export default React.memo(StaticStorageFolder);
