import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "antd";
import { Button } from "src/components/common/ui/button";
import { DownloadIcon } from "../../common/Icons";
import { FC, useState } from "react";

type Props = {
  onReloadClick: () => void;
  onTabPreviewClick: () => void;
  disabled: boolean;
  isBlob: boolean;
  handleDownloadPDF?: () => Promise<void>;
};
export const DocViewActions: FC<Props> = ({
  onReloadClick,
  onTabPreviewClick,
  disabled,
  isBlob,
  handleDownloadPDF,
}) => {
  const [isDownloadPDFLoading, setIsDownloadPDFLoading] = useState(false);

  return (
    <div>
      {handleDownloadPDF && (
        <Button
          onClick={async () => {
            setIsDownloadPDFLoading(true);
            await handleDownloadPDF();
            setIsDownloadPDFLoading(false);
          }}
          variant="ghost"
          icon={DownloadIcon}
          size="sm"
          disabled={disabled}
          iconClassName="size-4"
          loading={isDownloadPDFLoading}
        >
          Download PDF
        </Button>
      )}


      <Button
        onClick={onReloadClick}
        variant="ghost"
        icon={ArrowPathIcon}
        size="sm"
        disabled={disabled}
        iconClassName="size-4"
      >
        Reload
      </Button>

      <Tooltip
        title={
          <p className="text-center">
            {isBlob
              ? "Click to preview template in separate tab"
              : "Please, select template to preview template in separate tab"}
          </p>
        }
      >
        <Button
          onClick={onTabPreviewClick}
          variant="ghost"
          icon={ArrowTopRightOnSquareIcon}
          size="sm"
          disabled={disabled}
          iconClassName="size-4"
        >
          Tab Preview
        </Button>
      </Tooltip>
    </div>
  );
};
