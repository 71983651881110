import { Edge, EdgeMarker, Node } from "reactflow";
import {
  DownstreamDepsModalDefaultNodeProps,
  DownstreamDepsModalEdgeProps,
  GraphColors,
} from "../../types";

type Props = {
  nodeId: string;
  edges: Edge<DownstreamDepsModalEdgeProps>[];
  nodes: Node<DownstreamDepsModalDefaultNodeProps>[];
};

const updateNodesAndEdges = ({ nodeId, nodes, edges }: Props) => {
  // Find the neighbors of the selected node
  const sourceIds = edges
    .filter((edge) => edge.target === nodeId) // Find edges where the selected node is the target
    .map((edge) => edge.source); // Get the source nodes of those edges
  const targetIds = edges
    .filter((edge) => edge.source === nodeId) // Find edges where the selected node is the source
    .map((edge) => edge.target); // Get the target nodes of those edges

  // Update the styles of nodes
  const updatedNodes: Node<DownstreamDepsModalDefaultNodeProps>[] = nodes.map(
    (n) => {
      if (n.id === nodeId) {
        // Highlight the selected node with a specific border color
        return {
          ...n,
          style: {
            ...n.style,
            boxShadow: `inset 0 0 0 3px ${GraphColors.TARGET}`,
          },
        };
      } else if (sourceIds.includes(n.id) || targetIds.includes(n.id)) {
        // Highlight neighboring nodes (both sources and targets) with a different border color
        return {
          ...n,
          style: {
            ...n.style,
            boxShadow: `inset 0 0 0 3px ${GraphColors.INPUT}`,
          },
        };
      } else {
        // Reset the style of non-neighboring nodes to default
        return { ...n, style: { ...n.style, boxShadow: "none" } };
      }
    },
  );

  // Update the styles of edges
  const updatedEdges: Edge<DownstreamDepsModalEdgeProps>[] = edges.map(
    (edge) => {
      const hasOverride = edge.data?.hasSourceNodeOverride;

      if (edge.source === nodeId || edge.target === nodeId) {
        // Highlight edges connected to the selected node with a specific stroke color and width
        return {
          ...edge,
          style: { ...edge.style, stroke: GraphColors.INPUT, strokeWidth: 3 },
          markerEnd: {
            ...(edge.markerEnd as EdgeMarker),
            color: GraphColors.INPUT,
            strokeWidth: 1,
          },
        };
      } else {
        // Reset the style of non-connected edges to default
        return {
          ...edge,
          style: {
            ...edge.style,
            stroke: hasOverride ? GraphColors.OVERRIDE : GraphColors.DEFAULT,
            strokeWidth: 1,
          },
          markerEnd: {
            ...(edge.markerEnd as EdgeMarker),
            color: hasOverride ? GraphColors.OVERRIDE : GraphColors.DEFAULT,
          },
        };
      }
    },
  );

  return {
    updatedEdges,
    updatedNodes,
  };
};

export default updateNodesAndEdges;
