import { useSelector } from "react-redux";
import { selectUserData } from "../store/slices/userData/slice";
import Intercom, { shutdown } from "@intercom/messenger-js-sdk";
import { useEffect } from "react";
import { getUserIntercomHmacApi } from "../api/user.api";
import { TUser } from "../globalTypes";
import { useUserHasPermission } from "../store/slices/userData/hooks/useUserHasPermission";

const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

const useInitializeIntercomChat = () => {
  const userData = useSelector(selectUserData);
  const { hasIntercomChatRole } = useUserHasPermission({});

  useEffect(() => {
    if (APP_ID && userData && hasIntercomChatRole) {
      initializeChat({ app_id: APP_ID, user: userData });
    }

    return () => {
      shutdown();
    };
  }, []);

  const initializeChat = async ({
    app_id,
    user,
  }: {
    user: TUser;
    app_id: string;
  }) => {
    try {
      const { firstName, lastName, lastLoginTime, email, id } = user;

      const { data } = await getUserIntercomHmacApi();

      Intercom({
        app_id,
        user_id: id.toString(),
        name: `${firstName} ${lastName}`,
        email,
        created_at: new Date(lastLoginTime).getTime(),
        vertical_padding: 80,
        user_hash: data,
      });
    } catch (e) {
      console.error("Unable to get intercom hmac", e);
    }
  };
};

export default useInitializeIntercomChat;
