import { useNavigate } from "react-router-dom";
import { ItemHeader } from "src/components/common/ItemHeader";
import ModelConfigForm from "src/components/modelConfigs/ModelConfigForm";

const CreateModelConfigPage = () => {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate("/model-configs");
  };

  return (
    <>
      <ItemHeader title="Create Model Config" clickBack={navigateBack} />
      <ModelConfigForm />
    </>
  );
};

export default CreateModelConfigPage;
