import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  CloneIcon,
  CogIcon,
  DeleteIcon,
  ExecuteIcon,
  GetLinkIcon,
  Icons,
  InsertAfterIcon,
  InsertBeforeIcon,
  MoveDownIcon,
  MoveUpIcon,
  SolidEditIcon,
} from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Button } from "src/components/common/ui/button";
import useConfirm from "../../../hooks/useConfirm";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  deleteCcVarThunk,
  swapCcItemSequenceThunk,
  TCcVariable,
  useCcVariables,
} from "../../../store/slices/ccVariablesSlice";
import { AppDispatch } from "../../../store/store";
import { CC_VAR_SEQUENCE_STEP } from "../../../utils/campaigns.constant";
import { getLastSequence } from "../../../utils/cm.utils";
import { TEditModeProps, TitleModal } from "../Grid/CampaignGrid";
import { getCurrentStep } from "../../../store/slices/stepsSlice";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { getCurrentPhase } from "../../../store/slices/phasesSlice";
import { CodeBracketIcon } from "@heroicons/react/16/solid";
import sanitizeVariableName from "../../../utils/sanitizeVariableName";
import ExecuteCcItemModal from "../../common/modals/ExecuteCCItemModal";

type Props = {
  variable: TCcVariable;
  prevVariable: TCcVariable | undefined;
  nextVariable: TCcVariable | undefined;
  setIsAddRowModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setEditModeProps: React.Dispatch<React.SetStateAction<TEditModeProps | null>>;
  setGridItemSequence: React.Dispatch<React.SetStateAction<number | null>>;
};

const GridItemActions: FC<Props> = ({
  variable,
  prevVariable,
  nextVariable,
  setIsAddRowModalOpened,
  setEditModeProps,
  setGridItemSequence,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { companyId, campaignId, phaseId, stepId } = useParams();
  const currentStep = getCurrentStep();
  const currentPhase = getCurrentPhase();
  const confirm = useConfirm();
  const ccVariables = useCcVariables();
  const navigate = useNavigate();
  const messageApi = useSelector(selectMessageApi);
  const {
    hasCampaignCcItemComputationRole,
    hasCampaignCcCreateRole,
    hasCampaignCcUpdateRole,
    hasCampaignCcDeleteRole,
    hasCampaignCcSwapRole,
    hasCampaignCcGraphRole,
    hasCampaignCcCloneRole,
    hasCampaignUICCGetReferenceRole,
  } = useUserHasPermission({ companyId });
  const [isExecuteOpen, setIsExecuteOpen] = useState(false);

  const onShowExecuteModal = () => {
    setIsExecuteOpen(true);
  };

  const onCloseExecuteModal = () => {
    setIsExecuteOpen(false);
  };

  const onDeleteCcVar = async () => {
    try {
      await dispatch(
        deleteCcVarThunk({
          key: variable.id.key,
        }),
      ).unwrap();
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const onEdit = () => {
    setEditModeProps({
      variableData: variable,
      actionType: TitleModal.EDIT,
    });
    setGridItemSequence(variable.seq);
    setIsAddRowModalOpened(true);
  };

  const onClone = () => {
    const sequence = nextVariable
      ? variable.seq + Math.floor((nextVariable.seq - variable.seq) / 2)
      : getLastSequence(ccVariables, CC_VAR_SEQUENCE_STEP);

    const varClone = {
      ...variable,
      id: {
        ...variable.id,
        key: `${variable.id.key}_copy`,
      },
    };

    setEditModeProps({
      variableData: varClone,
      actionType: TitleModal.CLONE,
    });
    setGridItemSequence(sequence);
    setIsAddRowModalOpened(true);
  };

  const onInsertBefore = () => {
    const sequence = prevVariable
      ? prevVariable.seq + Math.floor((variable.seq - prevVariable.seq) / 2)
      : variable.seq - CC_VAR_SEQUENCE_STEP;

    setEditModeProps(null);
    setGridItemSequence(sequence);
    setIsAddRowModalOpened(true);
  };

  const onInsertAfter = () => {
    const sequence = nextVariable
      ? variable.seq + Math.floor((nextVariable.seq - variable.seq) / 2)
      : getLastSequence(ccVariables, CC_VAR_SEQUENCE_STEP);

    setEditModeProps(null);
    setGridItemSequence(sequence);
    setIsAddRowModalOpened(true);
  };

  const onLink = (itemKey: string) => {
    const baseUrl = window.location.href.split("#")[0];
    const url = `${baseUrl}#${itemKey}`;

    navigator.clipboard.writeText(url).then(
      () => {
        messageApi.success("Link copied to clipboard!");
      },
      (err) => {
        messageApi.error("Failed to copy link");
        console.error("Could not copy text: ", err);
      },
    );
  };

  const onGetReference = (itemKey: string) => {
    if (currentStep && currentPhase) {
      const ref = `${sanitizeVariableName(currentPhase.name)}$${sanitizeVariableName(currentStep.name)}$${itemKey}`;

      navigator.clipboard.writeText(ref).then(
        () => {
          messageApi.success("Reference copied to clipboard!");
        },
        (err) => {
          messageApi.error("Failed to copy reference");
          console.error("Could not copy text: ", err);
        },
      );
    } else {
      messageApi.error("Unable to get [currentStep] or [currentPhase]!");
    }
  };

  const onMove = async (seqToSwap: number | undefined) => {
    if (seqToSwap === undefined) return;

    try {
      await dispatch(
        swapCcItemSequenceThunk({
          currentVar: variable,
          seqToSwap,
        }),
      ).unwrap();
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const items = [
    hasCampaignCcItemComputationRole && {
      key: "execute",
      label: "Execute",
      disabled: !variable.executable,
      icon: ExecuteIcon,
      onClick: onShowExecuteModal,
      className: "!text-vePrimary",
    },
    hasCampaignCcUpdateRole && {
      key: "edit",
      label: "Edit",
      onClick: onEdit,
      icon: SolidEditIcon,
    },
    hasCampaignCcCloneRole && {
      key: "clone",
      label: "Clone",
      icon: CloneIcon,
      onClick: onClone,
    },
    hasCampaignCcGraphRole &&
      variable.type === "prompt" && {
        key: "promptEngineering",
        label: "Prompt Engineering",
        icon: CogIcon,
        onClick: () =>
          navigate(
            `/graph/${companyId}/${campaignId}/${phaseId}/${stepId}/${variable.id.key}`,
          ),
      },
    hasCampaignCcDeleteRole && {
      key: "delete",
      label: "Delete",
      danger: true,
      icon: DeleteIcon,
      onClick: () =>
        confirm({
          action: onDeleteCcVar,
          title: "Delete Item",
        }),
      className: "!text-red-600",
    },
  ].filter((v) => !!v) as MenuDropdownItem[];

  const moreItems = [
    {
      key: "getLink",
      label: "Get Link",
      onClick: () => onLink(variable.id.key),
      icon: GetLinkIcon,
    },
    hasCampaignUICCGetReferenceRole && {
      key: "getReference",
      label: "Get Reference",
      onClick: () => onGetReference(variable.id.key),
      icon: CodeBracketIcon,
    },
    hasCampaignCcSwapRole && {
      key: "moveUp",
      label: "Move Up",
      onClick: () => onMove(prevVariable?.seq),
      disabled: prevVariable === undefined,
      icon: MoveUpIcon,
    },
    hasCampaignCcSwapRole && {
      key: "moveDown",
      label: "Move Down",
      onClick: () => onMove(nextVariable?.seq),
      disabled: nextVariable === undefined,
      icon: MoveDownIcon,
    },
    hasCampaignCcCreateRole && {
      key: "insertBefore",
      label: "Insert Before",
      onClick: onInsertBefore,
      icon: InsertBeforeIcon,
    },
    hasCampaignCcCreateRole && {
      key: "insertAfter",
      label: "Insert After",
      onClick: onInsertAfter,
      icon: InsertAfterIcon,
    },
  ].filter((v) => !!v) as MenuDropdownItem[];

  return (
    <>
      <MenuDropdown items={items} moreItems={moreItems} align="end">
        <Button
          variant={"ghostPrimary"}
          className="p-[6px] size-[40px]"
          onClick={(e) => e.stopPropagation()}
        >
          <Icons.MoreDots className="size-6" />
        </Button>
      </MenuDropdown>

      {campaignId && stepId && isExecuteOpen && (
        <ExecuteCcItemModal
          ccItemKey={variable.id.key}
          closeModal={onCloseExecuteModal}
          isOpen={isExecuteOpen}
          stepId={stepId}
          campaignId={campaignId}
        />
      )}
    </>
  );
};

export default GridItemActions;
