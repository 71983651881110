import React, { FC, useState } from "react";
import { Button } from "../../common/ui/button";
import { Icons } from "../../common/Icons";
import { uploadAssetApi } from "../../../api/assets.api";
import UploadAssetsModal from "../../common/modals/UploadAssetsModal";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import CreateAssetModal from "../../common/modals/CreateAssetModal";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";

type Props = {
  loadAndSetAssets: () => Promise<void>;
};
const ActionButtons: FC<Props> = ({ loadAndSetAssets }) => {
  const { companyId, campaignId, stepId } = useParams();
  const [isUploadAssetsModalOpen, setIsUploadAssetsModalOpen] = useState(false);
  const [isCreateAssetModalOpen, setIsCreateAssetModalOpen] = useState(false);
  const { hasAssetCreateRole } = useUserHasPermission({ companyId });
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = useSelector(selectMessageApi);

  if (!stepId || !campaignId) {
    return (
      <span className="text-red-600">
        Unable to get [stepId] or [campaignId] from URL
      </span>
    );
  }

  const onAddAsset = () => {
    setIsCreateAssetModalOpen(true);
  };

  const onUploadAssets = () => {
    setIsUploadAssetsModalOpen(true);
  };

  const onUploadAsset = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    await uploadAssetApi({
      campaignId,
      stepId,
      formData,
    });

    await loadAndSetAssets();
  };

  const onReloadAssets = async () => {
    try {
      setIsFetching(true);

      await loadAndSetAssets();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      <div className="flex gap-[6px]">
        {hasAssetCreateRole && (
          <>
            <Button
              onClick={onAddAsset}
              variant="primaryOutline"
              className="rounded-full self-start"
              size="sm"
              icon={Icons.Plus}
            >
              Add Asset
            </Button>

            <Button
              onClick={onUploadAssets}
              variant="primaryOutline"
              className="rounded-full self-start"
              size="sm"
              icon={Icons.UploadIcon}
            >
              Upload Assets
            </Button>
          </>
        )}

        <Button
          onClick={onReloadAssets}
          className="rounded-full"
          variant="primaryOutline"
          icon={Icons.rerun}
          size="sm"
          iconClassName="size-4"
          loading={isFetching}
        >
          Reload Assets
        </Button>
      </div>

      {isUploadAssetsModalOpen && (
        <UploadAssetsModal
          uploadAsset={onUploadAsset}
          setIsModalOpen={(open) => setIsUploadAssetsModalOpen(open)}
          isModalOpen={isUploadAssetsModalOpen}
        />
      )}

      {hasAssetCreateRole && isCreateAssetModalOpen && (
        <CreateAssetModal
          loadAndSetAssets={loadAndSetAssets}
          isModalOpen={isCreateAssetModalOpen}
          setIsModalOpen={setIsCreateAssetModalOpen}
          campaignId={campaignId}
          stepId={stepId}
        />
      )}
    </>
  );
};

export default ActionButtons;
