import { Empty, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getModelConfigApi } from "src/api/model-configs.api";
import { ItemHeader } from "src/components/common/ItemHeader";
import ModelConfigForm from "src/components/modelConfigs/ModelConfigForm";
import { ModelConfigFormValues } from "src/types/modelConfigs";
import { getMessageApi } from "../../store/slices/appSlice";
import handleRequestError from "../../utils/handleRequestError";

const EditModelConfigPage = () => {
  const { modelConfigId } = useParams();
  const navigate = useNavigate();
  const messageApi = getMessageApi();
  const [modelConfigValues, setModelConfig] = useState<
    ModelConfigFormValues | undefined
  >();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getModelConfig(modelConfigId);
  }, [modelConfigId]);

  const getModelConfig = async (modelConfigId: string | undefined) => {
    if (modelConfigId === undefined) {
      messageApi.error("Model Config ID not found");
      return;
    }

    try {
      setLoading(true);

      const {
        data: { id, ...values },
      } = await getModelConfigApi(Number(modelConfigId));

      setModelConfig(values);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
  };

  const navigateBack = () => {
    navigate("/model-configs");
  };

  if (loading) return <Skeleton active />;

  if (!modelConfigValues)
    return (
      <Empty
        imageStyle={{ height: "30vh" }}
        description={
          <span className="font-sans font-medium text-[#0F172A]">
            We couldn't retrieve the data. Please try again later or contact
            support if the issue persists.
          </span>
        }
      />
    );

  return (
    <>
      <ItemHeader title={modelConfigValues.title} clickBack={navigateBack} />

      <ModelConfigForm initialValues={modelConfigValues} />
    </>
  );
};

export default EditModelConfigPage;
