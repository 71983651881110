import React, { useState } from "react";
import {
  resetComputationMessages,
  selectErrorMessagesCount,
  selectEventMessages,
  selectSocketByCampaignId,
  setComputationInfo,
} from "../../store/slices/computationMessages/slice";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Tooltip } from "antd";
import { Button } from "../common/ui/button";
import { Icons } from "../common/Icons";
import { useParams } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/16/solid";
import EventsLogModal from "./EventsLogModal";
import { SocketConnectionState, socketConnectionStateMap } from "./constants";

const EventsLog = () => {
  const { campaignId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const messages = useSelector(selectEventMessages);
  const socket = useSelector((state: RootState) =>
    selectSocketByCampaignId(state, campaignId ? +campaignId : null),
  );
  const errorMessagesCount = useSelector(selectErrorMessagesCount);
  const [isListOfMessagesOpened, setIsListOfMessagesOpened] = useState(false);

  const connectionState =
    socketConnectionStateMap[
      socket
        ? SocketConnectionState.CONNECTED
        : SocketConnectionState.DISCONNECTED
    ];

  if (!campaignId) {
    return (
      <Tooltip title="An error occurred while attempting to load the Event Log. Please try again later or contact support if the issue persists.">
        <span className="flex items-center text-red-500">
          <ExclamationCircleIcon className="h-5 w-5 mr-1" />
          Error
        </span>
      </Tooltip>
    );
  }

  const onCloseAndClear = () => {
    setIsListOfMessagesOpened(false);

    dispatch(resetComputationMessages());
    dispatch(setComputationInfo(null));
  };

  return (
    <>
      <Tooltip
        title={connectionState.tooltipTitle}
        color={connectionState.tooltipColor}
      >
        <div>
          <Badge status={connectionState.badgeStatus} />
        </div>
      </Tooltip>

      <Badge
        count={errorMessagesCount}
        offset={[0, 30]}
        size="small"
        overflowCount={9999}
        title="Errors"
      >
        <Badge
          count={messages.length}
          color="#623CEA"
          title="Events"
          overflowCount={9999}
        >
          <Button
            size="sm"
            onClick={() => setIsListOfMessagesOpened(true)}
            className="rounded-full"
            variant={"ghost"}
            icon={Icons.docs}
          >
            Event Log
          </Button>
        </Badge>
      </Badge>
      {isListOfMessagesOpened && (
        <EventsLogModal
          campaignId={+campaignId}
          onCloseAndClear={onCloseAndClear}
          setIsListOfMessagesOpened={setIsListOfMessagesOpened}
          isListOfMessagesOpened={isListOfMessagesOpened}
        />
      )}
    </>
  );
};

export default EventsLog;
