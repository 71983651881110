import { Link } from "react-router-dom";

type TTooltipTitleProps = {
  display: boolean;
  hasDocumentTemplateListRole: boolean;
};
export const tooltipTitle = ({
  display,
  hasDocumentTemplateListRole,
}: TTooltipTitleProps) => {
  return display ? (
    <div className="text-black text-sm">
      There are no templates for this class. Please ask an admin to add a class
      template from the template library{" "}
      {hasDocumentTemplateListRole ? (
        <Link to="/templates/documents">VelocityEngine</Link>
      ) : (
        <span>VelocityEngine</span>
      )}
    </div>
  ) : null;
};
