import { Spin, Tooltip } from "antd";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { getMessageApi } from "../../../../store/slices/appSlice";
import {
  deleteCampaignLabelsThunk,
  saveCampaignLabelThunk,
} from "../../../../store/slices/campaignLabelsSlice";
import { AppDispatch } from "../../../../store/store";
import { TGridItemTitle } from "../../Grid/CampaignGrid";
import TextArea from "antd/es/input/TextArea";
import { GRID_TITLE_MAX_LENGTH } from "../../../../constants";
import { useParams } from "react-router-dom";
import useSaveOnBlurWithEscape from "../../../../hooks/useSaveOnBlurWithEscape";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { TCcVariable } from "../../../../store/slices/ccVariablesSlice";

type Props = {
  ccItem: TCcVariable;
  title: TGridItemTitle;
};

const GridItemTitle: FC<Props> = ({ ccItem, title }) => {
  const { value: titleValue, scope } = title;
  const { key, campaignId } = ccItem.id;
  const { companyId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const messageApi = getMessageApi();
  const [isValidationTooltipOpen, setIsValidationTooltipOpen] = useState(false);
  const { hasCampaignI18nCreateRole } = useUserHasPermission({ companyId });
  const { value, setValue, isLoading, handleBlur, handleKeyDown } =
    useSaveOnBlurWithEscape({
      initialValue: titleValue,
      onSave: () => onSave(value),
      onCancel: () => setIsValidationTooltipOpen(false),
    });

  const onSave = async (value: string) => {
    const lang = "en";

    if (!value) {
      await dispatch(
        deleteCampaignLabelsThunk({
          keys: [key],
          lang,
          campaignId: campaignId.toString(),
        }),
      ).unwrap();
    } else {
      //if global label and !titleValue.length - need POST request, otherwise - PUT
      const action =
        scope === "global" ? "create" : titleValue.length ? "update" : "create";

      await dispatch(
        saveCampaignLabelThunk({
          label: value,
          key,
          lang,
          action,
          campaignId: campaignId.toString(),
        }),
      ).unwrap();
    }

    messageApi.success("Successfully saved");
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value.substring(0, GRID_TITLE_MAX_LENGTH));
    setIsValidationTooltipOpen(e.target.value.length > GRID_TITLE_MAX_LENGTH);
  };

  return (
    <div className="p-[3px]">
      {hasCampaignI18nCreateRole ? (
        <Spin size="small" spinning={isLoading}>
          <Tooltip
            title={`The maximum allowed number of characters is ${GRID_TITLE_MAX_LENGTH}.`}
            color="red"
            open={isValidationTooltipOpen}
          >
            <TextArea
              autoSize
              value={value || undefined}
              placeholder="not defined"
              onBlur={handleBlur}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              disabled={isLoading}
              className="hover:border-indigo-600 border-transparent"
            />
          </Tooltip>
        </Spin>
      ) : (
        <div>{titleValue}</div>
      )}
    </div>
  );
};

export default GridItemTitle;
