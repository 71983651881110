import React from "react";

/**
 * Splits the input text by placeholders "<|" and "|>" into React elements.
 * Text inside "<| ... |>" is treated as editable content, while outside as non-editable.
 * Handles nested placeholders properly.
 *
 * @param text The input text to split
 * @returns An array of React elements representing the text and placeholders
 */
const splitTextByTags = (text: string): React.ReactElement[] => {
  const result: React.ReactElement[] = [];
  const openPlaceholder = "<|";
  const closePlaceholder = "|>";
  let currentText = "";
  let inTag = false;
  let nestedLevel = 0;

  for (let i = 0; i < text.length; i++) {
    // Check for the beginning of a placeholder
    if (text.substring(i, i + openPlaceholder.length) === openPlaceholder) {
      // If not already inside a placeholder, start a new one
      if (!inTag) {
        // Push any accumulated text as a contentEditable span
        if (currentText !== "") {
          result.push(
            <span
              key={result.length}
              className="hover:text-vePrimary transition-colors duration-200 cursor-pointer"
              contentEditable={true}
              suppressContentEditableWarning={true}
            >
              {currentText}
            </span>
          );
          currentText = "";
        }
        inTag = true;
        // Skip the length of the opening placeholder
        i += openPlaceholder.length - 1;
      }
      currentText += openPlaceholder;
      nestedLevel++;
    }
    // Check for the end of a placeholder
    else if (
      text.substring(i, i + closePlaceholder.length) === closePlaceholder
    ) {
      if (inTag) {
        currentText += closePlaceholder;
        nestedLevel--;
        // If we're at the outermost level, push the accumulated text as a non-editable span
        if (nestedLevel === 0) {
          result.push(
            <span key={result.length} className="text-orange-600">
              {currentText}
            </span>
          );
          currentText = "";
          inTag = false;
          // Skip the length of the closing placeholder
          i += closePlaceholder.length - 1;
        }
      } else {
        currentText += closePlaceholder;
      }
    }
    // Accumulate text outside of placeholders
    else {
      currentText += text[i];
    }
  }

  // Push any remaining text as a contentEditable span
  if (currentText !== "") {
    result.push(
      <span
        key={result.length}
        contentEditable={true}
        className="hover:text-vePrimary transition-colors duration-200 cursor-pointer"
        suppressContentEditableWarning={true}
      >
        {currentText}
      </span>
    );
  }

  return result;
};

export default splitTextByTags;
