import { InputRef } from "antd";
import React, { useEffect } from "react";

const useSetFocus = (ref: React.RefObject<InputRef>) => {
  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      //need timeout because of issue inside antD modal
      setTimeout(() => {
        element.focus({ cursor: "end" });
      }, 300);
    }
  }, [ref]);
};

export default useSetFocus;
