import { GraphEdge, GraphMetaInfo, GraphNode } from "../globalTypes";
import {
  TCcDynamicFormItemsAsset,
  TCcDynamicFormItemsFunction,
  TCcDynamicFormItemsPrompt,
  TCcDynamicFormItemsString,
  TCcDynamicFormItemsWebScrapper,
  TCcVariable,
  TCcVariableType,
  TCcVarsFormValues,
  TComputationState,
  TPromptCcVarServicePovider,
} from "../store/slices/ccVariablesSlice";
import axiosInstance from "../utils/axios/axiosInstance";

export const getCcVarsApi = ({
  campaignId,
  stepId,
}: {
  campaignId: number | string;
  stepId: number | string;
}) => {
  return axiosInstance.get<TCcVariable[]>(
    `/api/secured/${campaignId}/cc/step/${stepId}`,
  );
};

export type TGetCcVarTypesResponse = {
  function: { items: TCcDynamicFormItemsFunction };
  prompt: { items: TCcDynamicFormItemsPrompt };
  string: { items: TCcDynamicFormItemsString };
  asset: { items: TCcDynamicFormItemsAsset };
  web_scrapper: { items: TCcDynamicFormItemsWebScrapper };
};

export const getCcVarTypesApi = () => {
  return axiosInstance.get<TGetCcVarTypesResponse>(
    `/api/secured/global/cc-types/`,
  );
};

export const updateCcVarApi = ({
  ccItem,
  values,
  rebuild,
}: {
  ccItem: TCcVariable;
  values: TCcVarsFormValues;
  rebuild?: boolean;
}) => {
  const { type, key, result, modifiedTime, ...options } = values;
  const ccItemToUpdate: TCcVariable = {
    ...ccItem,
    id: {
      ...ccItem.id,
      key,
    },
    type,
    result,
    modifiedTime,
    options,
  };

  return axiosInstance.put<TCcVariable>(
    `/api/secured/${ccItem.id.campaignId}/cc/${ccItem.type}/${ccItem.id.key}`,
    ccItemToUpdate,
    { params: { rebuild } },
  );
};

export const createCcVarApi = ({
  campaignId,
  stepId,
  seq,
  values,
}: {
  campaignId: number | string;
  values: TCcVarsFormValues;
  stepId: number | string;
  seq: number;
}) => {
  const { type, key, result, ...options } = values;

  return axiosInstance.post<TCcVariable>(
    `/api/secured/${campaignId}/cc/${type}`,
    {
      id: { key, stepId },
      seq,
      result,
      options,
    },
  );
};

export const editCcVarKeyApi = ({
  campaignId,
  currentKey,
  newKey,
  stepId,
}: {
  campaignId: number;
  stepId: number;
  currentKey: string;
  newKey: string;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/${campaignId}/cc/change-key/${stepId}/${currentKey}/${newKey}`,
    {},
  );
};

export const deleteCcVarApi = ({
  campaignId,
  stepId,
  key,
}: {
  campaignId: number;
  stepId: number;
  key: string;
}) => {
  return axiosInstance.delete<string>(
    `/api/secured/${campaignId}/cc/${stepId}/${key}`,
  );
};

export const executeCcVarApi = ({
  campaignId,
  stepId,
  key,
  updateDownstreamDependencies = false,
}: {
  campaignId: number | string;
  key: string;
  stepId: number | string;
  updateDownstreamDependencies?: boolean;
}) => {
  return axiosInstance.post<string>(
    `/api/secured/${campaignId}/computation/${stepId}/cc-item/${key}`,
    {},
    { params: { updateDownstreamDependencies } },
  );
};

export const saveResultOvrApi = ({
  campaignId,
  stepId,
  key,
  value,
  recomputeDownStream,
}: {
  campaignId: number;
  key: string;
  stepId: number;
  value: string;
  recomputeDownStream?: boolean;
}) => {
  return axiosInstance.put<TCcVariable>(
    `/api/secured/${campaignId}/cc/${stepId}/${key}/content`,
    value,
    {
      headers: {
        "Content-Type": "text/plain",
      },
      params: {
        recomputeDownStream,
      },
    },
  );
};

export type TPromptTemplate = {
  key: string;
  label: string;
  template: string;
  options: {
    serviceProvider: TPromptCcVarServicePovider;
    modelName: string;
  };
};

export const getPromptTemplatesApi = ({
  campaignId,
  type,
  searchPart,
}: {
  campaignId: number | string;
  type: TCcVariableType;
  searchPart: string;
}) => {
  return axiosInstance.get<TPromptTemplate[]>(
    `/api/secured/${campaignId}/env/${type}/lookup`,
    {
      params: {
        searchPart,
      },
    },
  );
};

export interface PromptTemplateByKey {
  id: {
    campaignId: number;
    key: string;
  };
  type: string;
  options: {
    aiModelId: any;
  };
  value: string;
}

export const getPromptTemplateByKey = ({
  campaignId,
  key,
}: {
  campaignId: number;
  key: string;
}) => {
  return axiosInstance.get<PromptTemplateByKey>(
    `/api/secured/${campaignId}/env/${key}`,
  );
};

export interface TemplateByPath {
  id: {
    campaignId: number;
    stepId: number;
    key: string;
  };
  seq: number;
  type: string;
  result: string;
  state: string;
  resultOvr: any;
  mimeType: string;
  executable: boolean;
  supportOverride: boolean;
}

export const getCcTemplateByPath = ({
  campaignId,
  path,
}: {
  campaignId: number;
  path: string;
}) => {
  return axiosInstance.get<TemplateByPath>(
    `/api/secured/${campaignId}/cc/findPath/`,
    {
      params: {
        path,
      },
    },
  );
};

export const executeFunctionCcVarApi = ({
  campaignId,
  stepId,
  seq,
  scriptContent,
}: {
  campaignId: number;
  stepId: number;
  seq: number;
  scriptContent: string;
}) => {
  return axiosInstance.post<string>(
    `/api/secured/${campaignId}/computation/cc-item/${stepId}/${seq}/script/`,
    scriptContent,
    {
      headers: {
        "Content-Type": "text/plain",
      },
    },
  );
};

export const updateCcVarStateApi = ({
  campaignId,
  stepId,
  state,
  key,
}: {
  campaignId: number;
  key: string;
  stepId: number;
  state: TComputationState;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/${campaignId}/cc/${stepId}/${key}/state/${state}`,
    {},
  );
};

export const swapCcVarSequenceApi = ({
  campaignId,
  stepId,
  cc1Seq,
  cc2Seq,
}: {
  campaignId: number;
  stepId: number;
  cc1Seq: number;
  cc2Seq: number;
}) => {
  return axiosInstance.put<TCcVariable[]>(
    `/api/secured/${campaignId}/cc/${stepId}/${cc1Seq}/${cc2Seq}/swap`,
    {},
  );
};

export const getProviderOptionsApi = ({ url }: { url: string }) => {
  return axiosInstance.get<string[]>(url);
};

type GetCCDependenciesRes = {
  nodes: GraphNode[];
  edges: GraphEdge[];
  metaInfo: GraphMetaInfo;
};

export const getUpstreamDependenciesApi = ({
  campaignId,
  stepId,
  key,
}: {
  campaignId: number | string;
  stepId: number | string;
  key: string;
}) => {
  return axiosInstance.get<GetCCDependenciesRes>(
    `/api/secured/${campaignId}/cc/${stepId}/${key}/upstream-dependencies`,
  );
};

export const getDownstreamDependenciesApi = ({
  campaignId,
  stepId,
  key,
}: {
  campaignId: number | string;
  stepId: number | string;
  key: string;
}) => {
  return axiosInstance.get<GetCCDependenciesRes>(
    `/api/secured/${campaignId}/cc/${stepId}/${key}/downstream-dependencies`,
  );
};

export const getCCItemApi = ({
  campaignId,
  stepId,
  key,
}: {
  campaignId: number | string;
  stepId: number | string;
  key: string;
}) => {
  return axiosInstance.get<TCcVariable>(
    `/api/secured/${campaignId}/cc/${stepId}/find`,
    { params: { key } },
  );
};

export const validateCcVarApi = ({
  campaignId,
}: {
  campaignId: number | string;
}) => {
  return axiosInstance.get<boolean>(
    `/api/secured/${campaignId}/cc/execute/validate/`,
  );
};

export const getCcVarApi = ({
  campaignId,
  stepId,
  key,
}: {
  campaignId: number | string;
  stepId: number | string;
  key: string;
}) => {
  return axiosInstance.get<TCcVariable>(
    `/api/secured/${campaignId}/cc/${stepId}/${key}/get`,
  );
};
