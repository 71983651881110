import { Skeleton } from "antd";
import { Outlet } from "react-router-dom";
import { Icons } from "../../../components/common/Icons";
import Navigation from "../../../components/common/Navigation";
import { Button } from "../../../components/common/ui/button";
import useCampaignStorageData from "./hooks/useCampaignStorageData.hook";
import useRedirectToFirstChildOutlet from "../../../hooks/navigation/useRedirectToFirstChildOutlet";
import usePostHogPageViewTracking from "../../../hooks/usePostHogPageViewTracking";

const CampaignStoragePage = () => {
  const { isLoading, error, onGoBack, navigationItems, campaignName } =
    useCampaignStorageData();

  useRedirectToFirstChildOutlet({ navigationItems });
  usePostHogPageViewTracking();

  if (isLoading) {
    return <Skeleton active />;
  }

  return (
    <div>
      <div className="flex gap-[12px] items-center">
        <Button
          variant={"ghost"}
          icon={Icons.back}
          onClick={onGoBack}
          className="p-[12px] h-auto"
        />
        <div className="flex items-end gap-[8px]">
          <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal capitalize">
            Campaign Storage:{" "}
            {campaignName ? (
              <span className="font-normal">{campaignName}</span>
            ) : (
              <span className="text-red-500 text-sm">
                Error: Failed to load campaign information.
              </span>
            )}
          </h1>
        </div>
      </div>

      {error ? (
        <span className="text-red-500">Error: {error}</span>
      ) : (
        <div className="flex flex-col gap-4">
          <Navigation items={navigationItems} replace={true} />
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default CampaignStoragePage;
