import React, { FC, useState } from "react";
import { Modal } from "antd";
import ApiVersion from "./ApiVersion";
import ChangeLog from "./ChangeLog";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import packageJson from "../../../../package.json";

const ReleasesInfo: FC = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <div>
      <div
        className="py-[5px] px-3 bg-[#F1F5F9] text-[#64748B] rounded-full cursor-pointer flex items-center hover:bg-gray-200 transition-colors"
        onClick={() => setIsModalOpened(true)}
      >
        <span className="font-semibold text-xs text-[#64748B] mr-1">
          {`v${packageJson.version}`}
        </span>
        <ChevronDownIcon className="size-4" />
      </div>

      <Modal
        title="Releases Info"
        open={isModalOpened}
        onCancel={() => setIsModalOpened(false)}
        footer={null}
        centered
        destroyOnClose
        width="80vw"
      >
        <div className="flex flex-col gap-6 h-[80vh]">
          <ApiVersion />
          <ChangeLog />
        </div>
      </Modal>
    </div>
  );
};

export default ReleasesInfo;
