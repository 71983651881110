import React, { FC, useState } from "react";
import { Icons } from "../common/Icons";
import { Button } from "../common/ui/button";
import { useGoBack } from "../../hooks/useGoBack";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import CampaignLabelModal from "./CampaignLabelModal";

const CampaignLabelsHeader: FC = () => {
  const { companyId, campaignId } = useParams();
  const { hasCampaignI18nCreateRole } = useUserHasPermission({ companyId });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const goBack = useGoBack(
    `/campaigns/company/${companyId}/campaign/${campaignId}`,
  );

  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-[12px] items-center">
        <Button
          variant="ghost"
          icon={Icons.back}
          onClick={goBack}
          className="p-[12px] h-auto"
        />
        <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal capitalize">
          Campaign Labels
        </h1>
      </div>

      {hasCampaignI18nCreateRole && (
        <Button
          icon={Icons.Plus}
          className="rounded-full"
          onClick={() => setIsEditModalOpen(true)}
        >
          Add Label
        </Button>
      )}

      {hasCampaignI18nCreateRole && isEditModalOpen && (
        <CampaignLabelModal
          isOpened={isEditModalOpen}
          setIsOpened={setIsEditModalOpen}
          labelData={null}
        />
      )}
    </div>
  );
};

export default CampaignLabelsHeader;
