import { TCompany } from "../store/slices/companiesSlice";
import axiosInstance from "../utils/axios/axiosInstance";
import {
  TCompanyShortInfo,
  TCompanyStatistics,
  TCompanyUser,
} from "../globalTypes";
import { TCompanyForm } from "../components/companies/CompanyDrawer/CompanyDrawer";
import { SortingDirection } from "src/types/docTemplates";

export const getCompanyStatisticsApi = ({
  companyId,
}: {
  companyId: number;
}) => {
  return axiosInstance.get<TCompanyStatistics>(
    `/api/secured/company/${companyId}/statistics`,
  );
};

export const deleteCompanyApi = ({
  companyId,
  removeFiles,
}: {
  companyId: number;
  removeFiles: boolean;
}) => {
  return axiosInstance.delete<string>(`/api/secured/company/${companyId}`, {
    params: {
      removeFiles,
    },
  });
};

export const createCompanyApi = ({ payload }: { payload: TCompanyForm }) => {
  return axiosInstance.post<TCompany>(`/api/secured/company/`, payload);
};

export const updateCompanyApi = ({
  payload,
  companyId,
  rebuild,
}: {
  payload: TCompanyForm;
  companyId: number;
  rebuild: boolean;
}) => {
  return axiosInstance.put<TCompany>(
    `/api/secured/company/${companyId}`,
    {
      ...payload,
    },
    { params: { rebuild } },
  );
};

export const getCompanyApi = ({
  companyId,
}: {
  companyId: number | string;
}) => {
  return axiosInstance.get<TCompany>(`/api/secured/company/${companyId}`);
};

export type CompaniesListRequestParams = {
  name?: string;
  pageNumber: number;
  pageSize: number;
  sortBy?: keyof TCompany;
  orderBy?: SortingDirection;
};

type SearchCompaniesRes = {
  totalItems: number;
  items: TCompany[];
};

export const getCompaniesApi = (params: CompaniesListRequestParams) => {
  return axiosInstance.get<SearchCompaniesRes>(`/api/secured/company/`, {
    params,
  });
};

export const getCompanyUsersApi = ({
  companyId,
}: {
  companyId: number | string;
}) => {
  return axiosInstance.get<TCompanyUser[]>(
    `/api/secured/company/${companyId}/assigned-users`,
  );
};

export const uploadCompanyLogoApi = ({
  companyId,
  formData,
}: {
  companyId: number | string;
  formData: FormData;
}) => {
  return axiosInstance.post<TCompany>(
    `/api/secured/company/${companyId}/uploadLogo`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    },
  );
};

export const getCompanyDataApi = ({ companyId }: { companyId: string }) => {
  return axiosInstance.get<TCompanyShortInfo>(
    `/api/secured/company/${companyId}`,
  );
};

export const enableCompanyApi = ({
  companyId,
}: {
  companyId: string | number;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/company/${companyId}/enable`,
    {},
  );
};
export const disableCompanyApi = ({
  companyId,
}: {
  companyId: string | number;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/company/${companyId}/disable`,
    {},
  );
};
