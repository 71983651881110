import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TUsersListItem } from "../../globalTypes";

type CMUsersState = {
  users: TUsersListItem[];
  total: number;
};

const initialState: CMUsersState = {
  users: [],
  total: 0,
};

const usersListSlice = createSlice({
  name: "usersList",
  initialState,
  reducers: {
    setUsers: (
      state: CMUsersState,
      action: PayloadAction<{ users: TUsersListItem[]; total: number }>,
    ) => {
      state.users = action.payload.users;
      state.total = action.payload.total;
    },
    removeUser(state, action: PayloadAction<number>) {
      state.users = state.users.filter(
        (item) => item.user.id !== action.payload,
      );
    },
  },
});

export const { setUsers, removeUser } = usersListSlice.actions;

export default usersListSlice.reducer;

export const selectUsersListState = (state: RootState) => state.usersList;
