import { LoadingOutlined } from "@ant-design/icons";
import { TComputationState } from "../../store/slices/ccVariablesSlice";
import { ComputedIcon, CreatedIcon, SolidErrorIcon } from "./Icons";

const CCItemStateIcon = ({ state }: { state: TComputationState | undefined }) => {
  switch (state) {
    case "computed":
      return <ComputedIcon className="text-[#16A34A]" />;
    case "created":
      return <CreatedIcon className="text-[#D97706]" />;
    case "error":
      return <SolidErrorIcon className="mx-auto text-red-600" />;
    case "processing":
      return <LoadingOutlined style={{ color: "brown", fontSize: "20px" }} />;
    default:
      return null;
  }
};

export default CCItemStateIcon;
