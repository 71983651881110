import { Form, Input, InputRef, Modal } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import useSubmitFormOnEnter from "src/hooks/useSubmitFormOnEnter";
import submitFormWithTrim from "src/utils/submitFormWithTrim";
import { folderName, required } from "../../../utils/validations";
import customizeFormLabels from "../../../utils/customizeFormLabels";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedPath: string;
  onCreate: (fullPath: string) => Promise<void>;
};

type CreateFolderProps = {
  fileName: string;
};

export const CreateFolderModal: FC<Props> = ({
  open,
  setOpen,
  selectedPath,
  onCreate,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [form] = Form.useForm<CreateFolderProps>();
  const inputTagRef = useRef<InputRef>(null);

  useSubmitFormOnEnter(() => handleClose(), { condition: !isFetching });
  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus({
        cursor: "end",
      });
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const path = selectedPath || "/";

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: CreateFolderProps) => {
      setIsFetching(true);
      await onCreate(path + values.fileName + "/");
      setIsFetching(false);
      setOpen(false);
    },
  });

  return (
    <Modal
      title="Create Folder"
      open={open}
      onOk={submitForm}
      okButtonProps={{ disabled: isFetching, loading: isFetching }}
      onCancel={handleClose}
      className="w-[700px]"
    >
      <Form form={form} layout="vertical" requiredMark={customizeFormLabels}>
        <Form.Item
          name="fileName"
          label="Folder Name"
          tooltip="Only letters, numbers, minus signs and underscores are allowed"
          rules={[required(), folderName]}
        >
          <Input ref={inputTagRef} placeholder="Enter the folder name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
