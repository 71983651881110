import { FC, useEffect } from "react";
import CampaignDocView from "src/components/CampaignDocView";
import CampaignGrid from "../../../../components/CampaignGrid/Grid/CampaignGrid";
import { CampaignViewTypes } from "../../../../types";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { useCampaignParams } from "../CampaignEditorPage/useCampaignParams";
import { cn } from "src/utils";
import {
  selectCurrentStep,
  selectStepsList,
  setCurrentStep,
} from "src/store/slices/stepsSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store/store";
import { DocumentTemplateState } from "src/types/docTemplates";
import { useUiTemplatesOptions } from "src/hooks/useTemplatesOptions";
import { EmptyResult } from "../../../../components/common/EmptyResult";
import { Assets } from "../../../../components/assets";

type Props = {};

const StepData: FC<Props> = () => {
  const { companyId, stepId, view, campaignId, phaseId } = useCampaignParams();
  const { hasAssetListRole } = useUserHasPermission({ companyId });
  const currentStep = useSelector(selectCurrentStep);
  const stepsList = useSelector(selectStepsList);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { hasStepTemplatePreviewRole } = useUserHasPermission({ companyId });
  const { load: requestUiTemplateOptions } = useUiTemplatesOptions();

  useEffect(() => {
    if (view === CampaignViewTypes.DOC && currentStep?.hiddenDocView) {
      const closestStepWithShowDocView = stepsList.find(
        (step) => !step.hiddenDocView,
      );

      if (!closestStepWithShowDocView) return;

      dispatch(setCurrentStep(closestStepWithShowDocView)); // this is needed to update the current step in the store, that's BAD: we should be able to use just URL params

      navigate(
        `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${closestStepWithShowDocView?.id}?view=${CampaignViewTypes.DOC}&documentTemplateId=${closestStepWithShowDocView?.documentTemplateId}`,
        {
          replace: true,
        },
      );
    }
  }, [view, currentStep?.hiddenDocView]);

  useEffect(() => {
    if (currentStep?.id && hasStepTemplatePreviewRole) {
      requestUiTemplateOptions({
        states: [DocumentTemplateState.PUBLISHED],
        classes: currentStep?.classes || [],
        stepId: currentStep?.id,
      });
    }
  }, [currentStep?.id, currentStep?.classes, hasStepTemplatePreviewRole]);

  if (!stepId) {
    return (
      <EmptyResult
        message={
          stepsList.length
            ? "To display the content, please select a step."
            : "You need at least one step to display content."
        }
      />
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className={cn(view === CampaignViewTypes.GRID ? "block" : "hidden")}>
        <CampaignGrid />
      </div>

      <div className={cn(view === CampaignViewTypes.DOC ? "block" : "hidden")}>
        <CampaignDocView />
      </div>

      {hasAssetListRole && (
        <>
          <div className="h-[1px] bg-[#E2E8F0]" />
          <Assets />
        </>
      )}
    </div>
  );
};

export default StepData;
