import * as React from "react";
import { Textarea } from "../common/ui/textarea";
import { cn } from "src/utils";

interface ChatInputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onHeightChange?: (height: number) => void;
}

const ChatInput = React.forwardRef<HTMLTextAreaElement, ChatInputProps>(
  ({ className, onHeightChange, ...props }, ref) => {
    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      if (containerRef.current && onHeightChange) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            onHeightChange(entry.contentRect.height);
          }
        });

        resizeObserver.observe(containerRef.current);

        return () => {
          resizeObserver.disconnect();
        };
      }
    }, [onHeightChange]);

    return (
      <div ref={containerRef}>
        <Textarea
          autoComplete="off"
          ref={ref}
          name="message"
          className={cn(
            "max-h-48 px-4 py-3 bg-background text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 w-full rounded-md flex items-center h-16 resize-none",
            className,
          )}
          {...props}
        />
      </div>
    );
  },
);
ChatInput.displayName = "ChatInput";

export { ChatInput };
