import { Switch } from "antd";
import { FC, useState } from "react";
import { TStorageFileItem } from "../../globalTypes";
import SelectStorageItems from "./SelectStorageItems/SelectStorageItems";
import { getMicroSiteStorageFilesApi } from "../../api/microsite-storage.api";

export type SelectMicrositeFolderType = (path: string) => void;
type Props = {
  onSelect: SelectMicrositeFolderType;
  companyId: string;
};
const SelectMicrositeStorageFolder: FC<Props> = ({ onSelect, companyId }) => {
  const [isChecked, setIsChecked] = useState(false);
  const ROOT_PATH = "/";
  const getFolderFiles = async (path?: string): Promise<TStorageFileItem[]> => {
    const { data } = await getMicroSiteStorageFilesApi({
      companyId,
      path: path || ROOT_PATH,
    });
    return data;
  };
  return (
    <div className="h-[36px] w-full flex items-center gap-[16px] mt-[16px]">
      <div className="w-auto flex items-center flex-shrink-0 gap-[8px]">
        <span className="font-sans font-bold text-[#475569] text-[14px]">
          Select microsite folder
        </span>
        <Switch size="small" checked={isChecked} onChange={setIsChecked} />
      </div>
      <div
        className="flex-grow min-w-0"
        style={{ display: isChecked ? "block" : "none" }}
      >
        <SelectStorageItems
          selectType="FOLDER"
          pathPrefix={ROOT_PATH}
          getFolderFiles={getFolderFiles}
          onSelect={onSelect}
        />
      </div>
    </div>
  );
};
export default SelectMicrositeStorageFolder;
