import { Checkbox, Modal, Select } from "antd";
import { FC, useState } from "react";
import { ComputationType, recalculateOptions } from "src/constants";
import useSubmitFormOnEnter from "src/hooks/useSubmitFormOnEnter";
import { getMessageApi } from "src/store/slices/appSlice";
import handleRequestError from "src/utils/handleRequestError";
import { TExecutionType } from '../../../../store/slices/computationMessages/types';

type Props = {
  title: string;
  executionType: TExecutionType;
  onCancel: () => void;
  onRequestExecute: (
    computationType: ComputationType,
    republish: boolean,
    updateDownstreamDependencies: boolean,
  ) => Promise<void>;
};

const ExecuteModal: FC<Props> = ({
  title,
  executionType,
  onRequestExecute,
  onCancel,
}) => {
  const [computationType, setComputationType] = useState<ComputationType>(
    ComputationType.NOT_COMPUTED,
  );
  const [republish, setRepublish] = useState(true);
  const [updateDownstreamDependencies, setUpdateDownstreamDependencies] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const messageApi = getMessageApi();

  const isCampaignExecution = executionType === "EXECUTION_CAMPAIGN";

  useSubmitFormOnEnter(() => onExecute(), { condition: !isLoading });

  const onExecute = async () => {
    try {
      setIsLoading(true);
      await onRequestExecute(
        computationType,
        republish,
        updateDownstreamDependencies,
      );
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      onCancel();
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={title}
      open={true}
      okText="Execute"
      cancelText="No"
      confirmLoading={isLoading}
      onOk={onExecute}
      onCancel={onCancel}
    >
      <div className="flex flex-col gap-3">
        <Select
          onChange={setComputationType}
          className="w-full"
          defaultValue={ComputationType.NOT_COMPUTED}
        >
          {recalculateOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
        <Checkbox
          onChange={(e) => setRepublish(e.target.checked)}
          checked={republish}
        >
          Republish
        </Checkbox>
        {!isCampaignExecution && (
          <Checkbox
            onChange={(e) => setUpdateDownstreamDependencies(e.target.checked)}
            checked={updateDownstreamDependencies}
          >
            Update downstream dependencies
          </Checkbox>
        )}
      </div>
    </Modal>
  );
};

export default ExecuteModal;
