import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  FormInstance,
  Input,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import React, { FC, useState } from "react";
import { TImportCampaignFormValues } from "../../../globalTypes";
import customizeFormLabels from "../../../utils/customizeFormLabels";

type PropsType = {
  form: FormInstance<TImportCampaignFormValues>;
  companyId: number;
};

const ImportCampaignForm: FC<PropsType> = ({ form, companyId }) => {
  const [fileList, setFileList] = useState<Array<UploadFile<any>>>([]);

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "application/zip",
    fileList,
    onChange(info) {
      setFileList(info.fileList);
    },
    onRemove(_) {
      //need timeout to reset form, research how to improve
      setTimeout(() => {
        form.resetFields(["file"]);
        setFileList([]);
      }, 0);
    },
    beforeUpload(file) {
      form.setFieldValue("file", file);
      return false;
    },
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="campaign_import_form"
      requiredMark={customizeFormLabels}
    >
      <Form.Item
        name="file"
        tooltip={
          <span>
            Supported formats: <strong>.zip</strong>.<br />
            To export a campaign in .zip format, go to the campaign and select{" "}
            <strong>Actions &gt; Export Campaign</strong>
          </span>
        }
        label="File"
        rules={[{ required: true, message: "Required field!" }]}
      >
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item name="companyId" noStyle initialValue={companyId}>
        <Input type="hidden" />
      </Form.Item>
    </Form>
  );
};

export default ImportCampaignForm;
