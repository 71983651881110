import { CaretRightFilled } from "@ant-design/icons";
import { Alert, Form, FormInstance, Select, Spin, Typography } from "antd";
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  getPromptTemplatesApi,
  TPromptTemplate,
} from "../../../api/cc-variables.api";
import {
  TCcVariable,
  TCcVariableType,
} from "../../../store/slices/ccVariablesSlice";
import { getNumOfVarsInPrompt } from "../../../utils/cm.utils";
import TemplatePrompt from "./TemplatePrompt";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useParams } from "react-router-dom";

type TSelectTemplateOption = {
  value: string;
  label: string;
  envData: TPromptTemplate;
};

type TArgumentsConflict = {
  numberKeysUsed: number;
  numberKeysRequired: number;
  usedKeys: Array<string | null>;
};

const ArgumentsConflictAlert: React.FC<{
  argumentsConflict: TArgumentsConflict | null;
  onClose: () => void;
}> = ({ argumentsConflict, onClose }) => {
  if (!argumentsConflict) return null;

  return (
    <Alert
      message="The prompt template used has been changed!"
      showIcon
      description={
        <div>
          <div>
            The current required number of keys -{" "}
            <Typography.Text type="success" strong>
              {argumentsConflict.numberKeysRequired}
            </Typography.Text>
          </div>
          <div style={{ marginBottom: "12px" }}>
            The initial number of keys used -{" "}
            <Typography.Text type="danger" strong>
              {argumentsConflict.numberKeysUsed}
            </Typography.Text>
            {argumentsConflict.usedKeys.map((key, index) => (
              <div key={`${key}__${index}`} style={{ marginLeft: "12px" }}>
                - <Typography.Text code>{key}</Typography.Text>
              </div>
            ))}
          </div>
          <Typography.Text strong>
            Please select all variables again according to the changes!
          </Typography.Text>
        </div>
      }
      type="error"
      closable
      onClose={onClose}
    />
  );
};

type Props = {
  dynElemName: string;
  required: boolean;
  hidden: boolean;
  form: FormInstance<any>;
  gridItemSequence: number;
  localKeys: TCcVariable[];
  isEditMode: boolean;
};

const ListKeySearch: FC<Props> = ({
  dynElemName,
  required,
  hidden,
  form,
  gridItemSequence,
  localKeys,
  isEditMode,
}) => {
  const { companyId, campaignId } = useParams();
  const [templateOptions, setTemplateOptions] = useState<
    Array<TSelectTemplateOption>
  >([]);
  const [isFetching, setIsFetching] = useState(false);
  const [promptTemplate, setPromptTemplate] = useState("");
  const messageApi = useSelector(selectMessageApi);
  const timeout: MutableRefObject<any> = useRef(null);
  const currentValue: MutableRefObject<string> = useRef("");
  const [argumentsConflict, setArgumentsConflict] =
    useState<TArgumentsConflict | null>(null);
  const promptKey = Form.useWatch<string | undefined | null>("promptKey", form);
  const envType: TCcVariableType = form.getFieldValue("type");
  const promptKeyArgs: (string | null)[] = form.getFieldValue(dynElemName);
  const { hasCampaignUICCPromptModificationRole } = useUserHasPermission({
    companyId,
  });

  useEffect(() => {
    if (isEditMode && promptKey) {
      const getTemplate = async () => {
        const templateOptions = await getTemplateOptions();

        if (!templateOptions) return;

        const promptItem = templateOptions.find((el) => el.value === promptKey);

        //check if prompt has changed. Add message and hard reset form.arguments with empty values
        if (promptItem) {
          const template = promptItem.envData.template;
          const count = getNumOfVarsInPrompt(template);
          let keys = promptKeyArgs;

          if (keys.length !== count) {
            const newKeys = Array(count).fill(null);

            setArgumentsConflict({
              numberKeysUsed: keys.length,
              numberKeysRequired: count,
              usedKeys: keys,
            });

            form.setFieldsValue({
              arguments: newKeys,
            });
          }

          setPromptTemplate(template);
        }
      };
      getTemplate();
    }
  }, [promptKey]);

  const getTemplateOptions = async (
    searchPart: string = "",
  ): Promise<TSelectTemplateOption[] | undefined> => {
    if (campaignId === undefined) {
      messageApi.error("Unable to get [campaignId] from URL");
      return;
    }

    try {
      setIsFetching(true);

      const { data } = await getPromptTemplatesApi({
        campaignId,
        type: envType,
        searchPart,
      });

      return data.map((item) => ({
        value: item.key,
        label: item.key,
        envData: item,
      }));
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const handleSearch = (searchPart: string) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    currentValue.current = searchPart;

    const getAndSetTemplateOptions = async () => {
      const templateOptions = await getTemplateOptions(searchPart);

      if (!templateOptions) return;

      if (currentValue.current === searchPart) {
        setTemplateOptions(templateOptions);
      }
    };

    if (searchPart) {
      timeout.current = setTimeout(getAndSetTemplateOptions, 400);
    } else {
      setTemplateOptions([]);
    }
  };

  const handleSelect = (value: string, option: TSelectTemplateOption) => {
    const template = option.envData.template;
    const count = getNumOfVarsInPrompt(template);
    const varKeysArr = Array(count).fill(null);

    form.setFieldsValue({
      promptKey: value,
      arguments: varKeysArr,
    });

    setPromptTemplate(template);
  };

  return (
    <Spin spinning={isFetching}>
      <Form.Item
        key="promptKey"
        hidden={hidden}
        name="promptKey"
        label="Search Prompt Keys"
        rules={[{ required, message: "Required field!" }]}
      >
        <Select
          disabled={isEditMode && !hasCampaignUICCPromptModificationRole}
          showSearch
          placeholder="Type to search keys"
          defaultActiveFirstOption={false}
          optionRender={(option) => {
            const optionData = option.data as TSelectTemplateOption;

            return (
              <div>
                <span style={{ color: "#003784" }}>{optionData.label}</span>
                <CaretRightFilled style={{ color: "#003784" }} />
                <span style={{ opacity: 0.5, fontStyle: "italic" }}>
                  {optionData.envData.template}
                </span>
              </div>
            );
          }}
          suffixIcon={null}
          filterOption={false}
          onSearch={handleSearch}
          onSelect={handleSelect}
          notFoundContent={null}
          options={templateOptions}
        />
      </Form.Item>
      <Form.Item
        key={dynElemName}
        name={dynElemName}
        hidden={!promptKey}
        rules={[
          {
            validator: (_, value: (string | null)[]) => {
              const isKeysArrValid = value.every((key) => key !== null);
              return isKeysArrValid
                ? Promise.resolve()
                : Promise.reject(new Error("Choose all variables!"));
            },
          },
        ]}
      >
        <TemplatePrompt
          promptTemplate={promptTemplate}
          form={form}
          formPromptArgsKey={dynElemName}
          gridItemSequence={gridItemSequence}
          localKeys={localKeys}
        />
      </Form.Item>
      <ArgumentsConflictAlert
        argumentsConflict={argumentsConflict}
        onClose={() => setArgumentsConflict(null)}
      />
    </Spin>
  );
};

export default ListKeySearch;
