const headerClassName = "font-sans font-bold text-[#0F172A]";
export const TABLE_HEADER_ITEMS = [
  {
    title: "Name",
    className: headerClassName,
  },
  {
    title: "Microsite Context Folder",
    className: headerClassName,
  },
  {
    title: "Asset approved",
    className: headerClassName,
  },
  {
    title: "Publish approved",
    className: headerClassName,
  },
  {
    title: "Publish date",
    className: headerClassName,
  },
  null,
];

export const APPROVE_ASSET_MESSAGE_KEY = "approve_asset";
export const APPROVE_PUBLISH_ASSET_MESSAGE_KEY = "approve_publish_asset";
