import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useEffect, useState } from "react";
import { TPermissionGroup } from "../../../types/securityGroups";
import { getPermissionsGroupApi } from "../../../api/permissions-group.api";
import handleRequestError from "../../../utils/handleRequestError";

const useGetPermissionsGroupList = () => {
  const messageApi = useSelector(selectMessageApi);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState("");
  const [permissionsGroupList, setPermissionsGroupList] = useState<
    TPermissionGroup[]
  >([]);

  useEffect(() => {
    getUserPermissions();
  }, []);

  const getUserPermissions = async () => {
    try {
      const { data } = await getPermissionsGroupApi();

      setPermissionsGroupList(data);
      setError("");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);

      setError(customError.message);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    permissionsGroupList,
    error,
    getUserPermissions,
  };
};

export default useGetPermissionsGroupList;
