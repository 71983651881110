import React from "react";
import { Checkbox, Form, Input } from "antd";
import customizeFormLabels from "../../utils/customizeFormLabels";
import {
  minMax,
  required,
  siteContextValidation,
  subDomainRule,
} from "../../utils/validations";
import { MICRO_SITE_MAX_LENGTH, MICRO_SITE_POSTFIX } from "../../constants";
import { Button } from "../common/ui/button";
import submitFormWithTrim from "../../utils/submitFormWithTrim";
import { MicroSiteFormValues } from "./types";
import useCreateMicroSite from "./hooks/useCreateMicroSite";
import { getCurrentCompany } from "../../store/slices/companiesSlice";

const MicroSiteForm = () => {
  const [form] = Form.useForm<MicroSiteFormValues>();
  const { loading, handleCreateMicroSite } = useCreateMicroSite();
  const currentCompany = getCurrentCompany();

  const validLength = MICRO_SITE_MAX_LENGTH - MICRO_SITE_POSTFIX.length;
  const defaultSubDomain = currentCompany
    ? currentCompany.name.toLowerCase()
    : "";

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: MicroSiteFormValues) => {
      await handleCreateMicroSite(values);
    },
  });

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        className="w-[373px] mt-[20px]"
        initialValues={{ subDomain: defaultSubDomain, enableHttps: false }}
        requiredMark={customizeFormLabels}
        disabled={loading}
      >
        <Form.Item
          name="subDomain"
          label="Subdomain"
          rules={[
            required(),
            subDomainRule,
            minMax({ text: "Subdomain", max: validLength }),
          ]}
        >
          <Input
            size="large"
            addonAfter={<span>{MICRO_SITE_POSTFIX}</span>}
            classNames={{
              input: "h-[48px] px-5 py-2",
            }}
          />
        </Form.Item>
        <Form.Item
          name="siteContext"
          tooltip="String must be 255 characters or less"
          label="Site Context"
          rules={[
            minMax({ text: "Site Context", max: 255 }),
            siteContextValidation,
          ]}
        >
          <Input
            size="large"
            placeholder="path/to/your-site"
            className="h-[48px] px-5 py-2"
          />
        </Form.Item>
        <Form.Item name="enableHttps" valuePropName="checked">
          <Checkbox className="font-sans font-bold text-[#475569] text-[14px]">
            Enable HTTPS
          </Checkbox>
        </Form.Item>
      </Form>

      <Button
        className="rounded-full px-6"
        onClick={submitForm}
        loading={loading}
      >
        Create
      </Button>
    </div>
  );
};

export default MicroSiteForm;
