import React from "react";
import { format } from "date-fns";
import { Alert, Skeleton } from "antd";
import useGetApiData from "../hooks/useGetApiData";

const ApiVersion = () => {
  const { apiData, initialized } = useGetApiData();

  if (!initialized) {
    return <Skeleton active />;
  }

  if (!apiData) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-base font-bold text-[#475569] self-center">
        API Version
      </h3>

      <div className="flex gap-4">
        <div className="w-[130px] flex flex-col">
          <span className="font-semibold text-[#475569] text-sm">
            {format(apiData.time, "MMMM d")}
          </span>
          <span className="text-xs">{format(apiData.time, "h:mm a zzz")}</span>
        </div>

        <Alert
          className="w-full"
          type="warning"
          message={
            <ul className="version-details">
              <li>
                <span className="font-semibold text-[#475569]">Name:</span>{" "}
                {apiData.name}
              </li>
              <li>
                <span className="font-semibold text-[#475569]">Version:</span>{" "}
                {apiData.version}
              </li>
            </ul>
          }
        />
      </div>
    </div>
  );
};

export default ApiVersion;
