import { PING_INTERVAL, PONG_TIMEOUT } from "../constants";

export const buildWebSocketUrl = (campaignId: number): string => {
  const protocol = window.location.protocol === "https:" ? "wss" : "ws";
  const host =
    window.location.hostname === "localhost" && window.location.port === "3000"
      ? "localhost:8080"
      : window.location.host;
  return `${protocol}://${host}/secured/ws-integration?campaignId=${campaignId}`;
};

export const getProgressNumber = (
  total: number,
  toComplete: number,
): number => {
  if (total <= 0 || toComplete < 0 || toComplete > total) {
    return 0;
  }

  const progress = (1 - toComplete / total) * 100;
  const roundedProgress = Math.min(Math.max(progress, 0), 100).toFixed(2);

  return parseFloat(roundedProgress);
};

export const setupPingPong = (ws: WebSocket) => {
  let pingInterval: number | null = null;
  let pongTimeout: number | null = null;

  const startPing = () => {
    pingInterval = window.setInterval(() => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: "ACK", message: "ping" }));

        if (pongTimeout) clearTimeout(pongTimeout);

        pongTimeout = window.setTimeout(() => {
          console.warn("Pong not received. Closing socket.");
          if (
            ws.readyState === WebSocket.OPEN ||
            ws.readyState === WebSocket.CLOSING
          ) {
            ws.close(4000, "No pong response within timeout");
          }
        }, PONG_TIMEOUT);
      }
    }, PING_INTERVAL);
  };

  const stopPing = () => {
    if (pingInterval) clearInterval(pingInterval);
    if (pongTimeout) clearTimeout(pongTimeout);
  };

  const clearPongTimeout = () => {
    if (pongTimeout) {
      clearTimeout(pongTimeout);
    }
  };

  return { startPing, stopPing, clearPongTimeout };
};
