import { Form, Input, InputNumber, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createModelConfigApi,
  getProviderModelsApi,
  updateModelConfigApi,
} from "src/api/model-configs.api";
import { serviceProviders } from "src/constants";
import { getMessageApi } from "src/store/slices/appSlice";
import { ModelConfigFormValues } from "src/types/modelConfigs";
import { getDynFormSelectOptions } from "src/utils/cm.utils";
import { minMax, required } from "src/utils/validations";
import DeleteModelConfigModal from "../common/modals/DeleteModelConfigModal/DeleteModelConfigModal";
import { Button } from "../common/ui/button";
import handleRequestError from "../../utils/handleRequestError";
import customizeFormLabels from "../../utils/customizeFormLabels";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";

type Props = {
  initialValues?: ModelConfigFormValues;
};

const serviceProviderOptions = Object.entries(serviceProviders).map(
  ([key, value]) => ({
    label: value.label,
    value: key,
  }),
);

const ModelConfigForm: FC<Props> = ({ initialValues }) => {
  const { modelConfigId } = useParams();
  const [form] = useForm<ModelConfigFormValues>();
  const [modelNameOptions, setModelNameOptions] = useState<string[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const messageApi = getMessageApi();
  const navigate = useNavigate();
  const { hasAiConfigurationUpdateRole, hasAiConfigurationDeleteRole } =
    useUserHasPermission({});

  useEffect(() => {
    if (initialValues?.serviceProvider) {
      getServiceProvidersModels(initialValues.serviceProvider);
    }
  }, [initialValues?.serviceProvider]);

  const getServiceProvidersModels = async (selectedServiceProvider: string) => {
    try {
      const { data } = await getProviderModelsApi({
        provider: selectedServiceProvider,
      });

      setModelNameOptions(data);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const memoizedModelNameOptions = useMemo(() => {
    return getDynFormSelectOptions({ data: modelNameOptions });
  }, [modelNameOptions]);

  const onSubmit = async (values: ModelConfigFormValues) => {
    setLoading(true);
    try {
      if (!modelConfigId) {
        const { data } = await createModelConfigApi(values);

        messageApi.success("Model config created successfully");
        navigate(`/model-configs/${data.id}`);
      } else {
        await updateModelConfigApi({
          id: Number(modelConfigId),
          ...initialValues,
          ...values,
        });
        messageApi.success("Model config updated successfully");
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form
        name="modelConfigForm"
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        disabled={!hasAiConfigurationUpdateRole && !!modelConfigId}
        requiredMark={customizeFormLabels}
        initialValues={modelConfigId ? initialValues : undefined}
      >
        <Form.Item
          name="title"
          label="Title"
          tooltip="Please enter a value between 2 and 255 characters."
          rules={[required(), minMax({ min: 2, max: 255, text: "Title" })]}
        >
          <Input placeholder="Enter unique title" className="h-[48px]" />
        </Form.Item>
        <Form.Item
          name="serviceProvider"
          label="Service Provider"
          rules={[required()]}
        >
          <Select
            className="h-[48px]"
            options={serviceProviderOptions}
            placeholder="Choose service provider"
            onChange={(value) => {
              if (value !== initialValues?.serviceProvider) {
                form.setFieldsValue({ modelName: undefined });
              }
              getServiceProvidersModels(value);
            }}
          />
        </Form.Item>
        <Form.Item name="modelName" label="Model Name" rules={[required(true)]}>
          <Select
            className="h-[48px]"
            options={memoizedModelNameOptions}
            placeholder="Select service provider to view models"
          />
        </Form.Item>

        <div className="flex flex-col xl:flex-row xl:gap-6">
          <Form.Item
            name="readTimoutSec"
            tooltip="Please enter the value between 0 and 5000 in seconds(sec)."
            label="Read Timeout"
            rules={[required()]}
            className="flex-1"
          >
            <InputNumber
              placeholder="Enter read timeout"
              className="h-[48px] w-full py-[8px]"
              step={100}
              max={5000}
              min={0}
            />
          </Form.Item>
          <Form.Item
            name="maxTokens"
            tooltip="Please enter the value between 100 and 10000."
            label="Max Tokens"
            rules={[required()]}
            className="flex-1"
          >
            <InputNumber
              placeholder="Enter max tokens"
              className="h-[48px] w-full py-[8px]"
              step={100}
              max={10000}
              min={100}
            />
          </Form.Item>
          <Form.Item
            name="topP"
            tooltip="Please enter the value between 0 and 1"
            label="Top P"
            rules={[required()]}
            className="flex-1"
          >
            <InputNumber
              placeholder="Enter topP"
              className="h-[48px] w-full py-[8px]"
              step={0.1}
              max={1}
              min={0}
            />
          </Form.Item>
          <Form.Item
            name="temperature"
            tooltip="Please enter the value between 0 and 1"
            label="Temperature"
            rules={[required()]}
            className="flex-1"
          >
            <InputNumber
              placeholder="Enter temperature"
              className="h-[48px] w-full py-[8px]"
              step={0.1}
              max={1}
              min={0}
            />
          </Form.Item>
          <Form.Item
            name="retriesOnErrorResponse"
            tooltip="Please enter the value between 0 and 5"
            label="Retries on Error Response"
            rules={[required()]}
            className="flex-1"
          >
            <InputNumber
              placeholder="Enter retries on error response"
              className="h-[48px] w-full py-[8px]"
              step={1}
              max={5}
              min={0}
            />
          </Form.Item>
        </div>
        <Form.Item>
          <div className="flex gap-6">
            {hasAiConfigurationUpdateRole && modelConfigId && (
              <Button loading={isLoading} type="submit">
                Update
              </Button>
            )}

            {!modelConfigId && (
              <Button loading={isLoading} type="submit">
                Create
              </Button>
            )}

            {hasAiConfigurationDeleteRole && modelConfigId && (
              <Button
                disabled={initialValues?.systemDefault || isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  setIsDeleteModalOpen(true);
                }}
                variant={"destructive"}
              >
                Delete
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>

      {isDeleteModalOpen && (
        <DeleteModelConfigModal
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
          aiModelId={Number(modelConfigId)}
        />
      )}
    </>
  );
};

export default ModelConfigForm;
