import { Form, FormInstance } from "antd";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import TextAreaWithAltEnter from "../../common/TextAreaWithAltEnter";
import { Button } from "../../common/ui/button";

type Props = {
  hidden: boolean;
  name: string;
  label: string;
  required: boolean;
  form: FormInstance<any>;
  executeScriptContent: (scriptContent: string) => Promise<void>;
  rows: number;
};
const ExecuteScriptContent: FC<Props> = ({
  hidden,
  name,
  label,
  required,
  rows,
  form,
  executeScriptContent,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const scriptContent = Form.useWatch(name, form);

  const onExecuteScript = async () => {
    try {
      setIsFetching(true);

      await executeScriptContent(scriptContent);

      messageApi.success(
        "The calculated value was recorded in the Result field"
      );
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      <Form.Item
        hidden={hidden}
        name={name}
        label={label}
        className="mb-[10px]"
        rules={[
          {
            required: required,
            message: "Required field!",
          },
        ]}
      >
        <TextAreaWithAltEnter
          placeholder="Insert the javascript code to execute"
          currentForm={form}
          fieldName={name}
          rows={rows}
        />
      </Form.Item>
      <Button
        onClick={onExecuteScript}
        loading={isFetching}
        disabled={!scriptContent || isFetching}
        variant="primaryOutline"
        className="rounded-full self-start"
        size="s"
      >
        Execute script
      </Button>
    </>
  );
};

export default ExecuteScriptContent;
