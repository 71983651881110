import { AssetType, TAsset } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

export const getAssetsApi = ({
  campaignId,
  stepId,
}: {
  campaignId: number | string;
  stepId: number | string;
}) => {
  return axiosInstance.get<TAsset[]>(
    `/api/secured/${campaignId}/asset/step/${stepId}`,
  );
};

export const getAssetApi = ({
  campaignId,
  assetId,
}: {
  campaignId: number | string;
  assetId: number;
}) => {
  return axiosInstance.get<TAsset>(
    `/api/secured/${campaignId}/asset/${assetId}`,
  );
};

export const createAssetApi = ({
  campaignId,
  stepId,
  title,
  ref,
  microSiteTargetFolder,
  type,
}: {
  campaignId: number | string;
  stepId: number | string;
  title: string;
  ref: string;
  microSiteTargetFolder: string;
  type: AssetType;
}) => {
  return axiosInstance.post<TAsset>(
    `/api/secured/${campaignId}/asset/step/${stepId}`,
    { title, ref, microSiteTargetFolder, type },
  );
};

export const updateAssetApi = ({ campaignId, stepId, ...asset }: TAsset) => {
  return axiosInstance.put<TAsset>(
    `/api/secured/${campaignId}/asset/step/${stepId}`,
    asset,
  );
};

export const deleteAssetApi = ({
  campaignId,
  assetId,
  removeFiles = false,
}: {
  campaignId: number | string;
  assetId: number;
  removeFiles?: boolean;
}) => {
  return axiosInstance.delete<string>(
    `/api/secured/${campaignId}/asset/${assetId}`,
    {
      params: {
        removeFiles,
      },
    },
  );
};

export const toggleApproveAssetApi = ({
  campaignId,
  assetId,
  approved,
}: {
  campaignId: number | string;
  assetId: number;
  approved: boolean;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/${campaignId}/asset/item/${assetId}/${approved}`,
    {},
  );
};

export const toggleApprovePublishApi = ({
  campaignId,
  assetId,
  publishToMicroSite,
}: {
  campaignId: number | string;
  assetId: number;
  publishToMicroSite: boolean;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/${campaignId}/asset/deploy/${assetId}/${publishToMicroSite}`,
    {},
  );
};

export const publishAssetApi = ({
  campaignId,
  assetId,
}: {
  campaignId: number | string;
  assetId: number;
}) => {
  return axiosInstance.put<TAsset>(
    `/api/secured/${campaignId}/asset/deploy/${assetId}`,
    {},
  );
};

export const uploadAssetApi = ({
  campaignId,
  stepId,
  formData,
}: {
  formData: FormData;
  campaignId: string | number;
  stepId: string | number;
}) => {
  return axiosInstance.post<TAsset>(
    `/api/secured/${campaignId}/asset/step/${stepId}/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};

export const unpublishAssetApi = ({
  campaignId,
  assetId,
}: {
  campaignId: number | string;
  assetId: number | string;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/${campaignId}/asset/unpublish/${assetId}`,
    {},
  );
};
