import React, { FC, useState } from "react";
import { TableCell, TableRow } from "../../common/ui/table";
import MenuDropdown, { MenuDropdownItem } from "../../common/MenuDropdown";
import { Button } from "../../common/ui/button";
import {
  CheckmarkIcon,
  CloneIcon,
  DeleteIcon,
  Icons,
} from "../../common/Icons";
import { TPermissionGroup } from "../../../types/securityGroups";
import { useNavigate } from "react-router-dom";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import DeleteWithTitleConfirmationModal from "../../common/modals/DeleteWithTitleConfirmationModal/DeleteWithTitleConfirmationModal";
import { deletePermissionGroupByIdApi } from "../../../api/permissions-group.api";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import CloneSecurityGroupModal from "./CloneSecurityGroupModal";

type Props = {
  userPermissionGroup: TPermissionGroup;
  updateList: () => Promise<void>;
};

const SecurityGroupListItem: FC<Props> = ({
  userPermissionGroup,
  updateList,
}) => {
  const { id, superUser, title, level } = userPermissionGroup;
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const {
    hasPermGroupUpdateRole,
    hasPermGroupDeleteRole,
    hasPermGroupCloneRole,
  } = useUserHasPermission({});

  const navigateToEdit = () => navigate(`/security-groups/edit/${id}`);

  const menuItems = [
    hasPermGroupUpdateRole && {
      key: "edit",
      label: "Edit",
      onClick: navigateToEdit,
      icon: Icons.edit,
    },
    hasPermGroupCloneRole &&
      !superUser && {
        key: "clone",
        label: "Clone",
        onClick: () => setIsCloneModalOpen(true),
        icon: CloneIcon,
      },
    hasPermGroupDeleteRole &&
      !superUser && {
        key: "delete",
        label: "Delete",
        onClick: () => setIsDeleteModalOpen(true),
        icon: DeleteIcon,
        className: "!text-red-600",
      },
  ].filter(Boolean) as MenuDropdownItem[];

  const onDelete = async () => {
    try {
      await deletePermissionGroupByIdApi({ permissionGroupId: id });
      await updateList();

      messageApi.success("Company deleted successfully");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  return (
    <>
      <TableRow
        key={id}
        onClick={hasPermGroupUpdateRole ? navigateToEdit : undefined}
        className="cursor-pointer relative h-[48px]"
      >
        <TableCell>{title}</TableCell>
        <TableCell>
          {superUser && <CheckmarkIcon className="size-6" />}
        </TableCell>
        <TableCell>{level}</TableCell>

        {menuItems.length ? (
          <TableCell onClick={(e) => e.stopPropagation()}>
            <MenuDropdown
              items={menuItems}
              dropdownClassName="w-[190px]"
              align="end"
            >
              <Button
                variant={"ghostPrimary"}
                className="size-[40px] p-2 flex justify-end"
              >
                <Icons.MoreDots className="size-[24px]" />
              </Button>
            </MenuDropdown>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>

      {hasPermGroupDeleteRole && isDeleteModalOpen && (
        <DeleteWithTitleConfirmationModal
          entity="Security Group"
          onDelete={onDelete}
          title={title}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}

      {hasPermGroupCloneRole && isCloneModalOpen && (
        <CloneSecurityGroupModal
          closeModal={() => setIsCloneModalOpen(false)}
          isModalOpen={isCloneModalOpen}
          id={id}
          originalTitle={title}
          hasPermGroupUpdateRole={hasPermGroupUpdateRole}
        />
      )}
    </>
  );
};

export default SecurityGroupListItem;
