import { FC } from "react";
import { TableCell, TableRow } from "src/components/common/ui/table";

const CompanySkeletonRow: FC = () => {
  return (
    <TableRow className="h-[48px]">
      <TableCell>
        <div className="flex items-center gap-[8px]">
          <div className="size-[32px] rounded-full bg-gray-200 animate-pulse" />
          <div className="h-[20px] w-[150px] bg-gray-200 rounded animate-pulse" />
        </div>
      </TableCell>
      <TableCell>
        <div className="h-[20px] w-[200px] bg-gray-200 rounded animate-pulse" />
      </TableCell>
      <TableCell>
        <div className="h-[20px] w-[180px] bg-gray-200 rounded animate-pulse" />
      </TableCell>
      <TableCell>
        <div className="size-[37px] bg-gray-200 animate-pulse ml-auto rounded-full" />
      </TableCell>
    </TableRow>
  );
};

export default CompanySkeletonRow;
