import { Empty, Skeleton } from "antd";
import React, { FC } from "react";
import { TFileTreeItem } from "../../../globalTypes";
import FileTree from "./FileTree";

type Props = {
  isLoading: boolean;
  error: string | null;
  treeData: TFileTreeItem[];
  getFolderFiles: (selectedDir: TFileTreeItem) => Promise<void>;
  selectedItem: TFileTreeItem | null;
  setSelectedItem: React.Dispatch<React.SetStateAction<TFileTreeItem | null>>;
  renderMenu: (props: {
    isHovering: boolean;
    isSelected: boolean;
    item: TFileTreeItem;
  }) => React.ReactElement;
};
const Storage: FC<Props> = ({
  isLoading,
  error,
  renderMenu,
  selectedItem,
  setSelectedItem,
  treeData,
  getFolderFiles,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Skeleton active />;
    }
    if (error) {
      return <span className={"text-red-500"}>Error: {error}</span>;
    }
    if (!treeData.length) {
      return (
        <Empty
          imageStyle={{ height: "30vh" }}
          description={
            <span className="font-sans font-semibold text-[14px]">
              There are no files in the storage
            </span>
          }
        />
      );
    }
    return (
      <FileTree
        renderMenu={renderMenu}
        treeData={treeData}
        getFolderFiles={getFolderFiles}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    );
  };
  return (
    <div
      className="w-full max-h-[600px] overflow-y-scroll"
      onClick={() => setSelectedItem(null)}
    >
      {renderContent()}
    </div>
  );
};
export default Storage;
