import { FC } from "react";
import { Icons } from "src/components/common/Icons";
import { TFileTreeItem } from "../../../globalTypes";
import MenuDropdown from "../../common/MenuDropdown";
import { GetMenuActions } from "./hooks/types";

type Props = {
  item: TFileTreeItem;
  getItemMenuActions: GetMenuActions;
  setTree: (tree: TFileTreeItem[]) => void;
};
const StaticStorageItemActions: FC<Props> = ({
  item,
  getItemMenuActions,
  setTree,
}) => {
  const menuItems = getItemMenuActions({ item, setTree });
  if (menuItems.length === 0) {
    return <div className="size-[20px]" />;
  }
  return (
    <MenuDropdown items={menuItems} align="start">
      <div className="hover:bg-vePrimary/10 rounded-full hover:text-vePrimary text-slate-600 transition-colors">
        <Icons.MoreDots className="size-[20px]" />
      </div>
    </MenuDropdown>
  );
};
export default StaticStorageItemActions;
