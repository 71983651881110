import React from "react";
import useGetGlobalPermissions from "./useGetGlobalPermissions.hook";
import { Button, Empty, Skeleton, Spin } from "antd";
import UserGlobalPermissionsTree from "./UserGlobalPermissionsTree";
import AddSecurityGroup from "../AddSecurityGroup/AddSecurityGroup";
import { useSelector } from "react-redux";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { selectSystemPermissions } from "../../../store/slices/appSlice";
import useConfirm from "../../../hooks/useConfirm";

const UserGlobalPermissions = () => {
  const {
    isInitialized,
    globalPermissions,
    error,
    updatePermissionsList,
    deleteGroup,
    isFetching,
    addGroupAndUpdateList,
    loadGlobalPermissionGroups,
  } = useGetGlobalPermissions();
  const permissionsTree = useSelector(selectSystemPermissions);
  const {
    hasUserGlobalPermCreateRole,
    hasUserGlobalPermDeleteRole,
    hasUiShowDetailedPermissionsListRole,
  } = useUserHasPermission({});
  const confirm = useConfirm();

  if (!isInitialized) return <Skeleton active className="mt-[24px]" />;

  const globalPermissionGroups = globalPermissions?.permissionGroups || [];

  return (
    <div className="flex justify-between mt-[24px]">
      <div className="w-[25%] flex flex-col gap-[12px]">
        <div className="font-bold text-[16px] text-[#475569]">
          Permission Groups
        </div>
        {hasUserGlobalPermCreateRole && (
          <AddSecurityGroup
            addGroupAndUpdateList={addGroupAndUpdateList}
            loadPermissionGroups={loadGlobalPermissionGroups}
          />
        )}
        {error && <div className="text-red-600">{error}</div>}
        <Spin spinning={isFetching}>
          {globalPermissionGroups.length ? (
            <div className="flex flex-col w-full gap-[6px]">
              {globalPermissionGroups.map(({ id, title }) => {
                return (
                  <div key={id}>
                    <div key={id} className="flex justify-between gap-[12px]">
                      <div>{title}</div>

                      {hasUserGlobalPermDeleteRole && (
                        <Button
                          danger
                          size="small"
                          onClick={() =>
                            confirm({
                              action: () =>
                                deleteGroup({
                                  permissionsGroupId: id,
                                }),
                              title: "Delete security group",
                            })
                          }
                          className="w-[60px]"
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                    <div className="h-[1px] bg-[#E2E8F0] w-[calc(100%-80px)] mt-[6px]" />
                  </div>
                );
              })}
            </div>
          ) : (
            <Empty
              description={
                <div>
                  <span className="font-sans font-medium text-[#0F172A]">
                    There are no security groups.
                  </span>
                </div>
              }
            />
          )}
        </Spin>
      </div>

      <div className="w-[1px] bg-[#E2E8F0]" />

      {hasUiShowDetailedPermissionsListRole &&
        globalPermissions &&
        permissionsTree && (
          <div className="w-[73%] flex justify-between">
            <UserGlobalPermissionsTree
              permissions={globalPermissions.permissions}
              permissionOverrides={globalPermissions.permissionOverrides}
              updatePermissionsList={updatePermissionsList}
              permissionsTree={permissionsTree.globalLevel}
              level="GLOBAL"
            />
            <div className="w-[1px] bg-[#E2E8F0] shrink-0" />
            <UserGlobalPermissionsTree
              permissions={globalPermissions.permissions}
              permissionOverrides={globalPermissions.permissionOverrides}
              updatePermissionsList={updatePermissionsList}
              permissionsTree={permissionsTree.companyLevel}
              level="COMPANY"
            />
          </div>
        )}
    </div>
  );
};

export default UserGlobalPermissions;
