import { AxiosResponse } from "axios";
import { ExportCampaignParamKeys, ExportCampaignParams, TLabels, TLabelsListData, TPhase } from '../globalTypes';
import { TCcVariable } from "../store/slices/ccVariablesSlice";
import { TStep, TStepPathMap } from "../store/slices/stepsSlice";
import { VAR_IN_PROMPT_PLACEHOLDER } from "./campaigns.constant";
import { TPhasesData } from "./transformCampaignData";

export const getLastSequence = <T extends TCcVariable | TPhase | TStep>(
  arr: Array<T>,
  sequenceStep: number,
): number => {
  if (!arr.length) return 0;

  return arr[arr.length - 1].seq + sequenceStep;
};

export const getNumOfVarsInPrompt = (string: string): number =>
  string ? string.split(VAR_IN_PROMPT_PLACEHOLDER).length - 1 : 0;
export const sortBySequence = <T extends TCcVariable | TPhase | TStep>(
  arr: Array<T>,
): Array<T> => arr.sort((a, b) => a.seq - b.seq);

export const downloadTextFile = ({
  data,
  type,
  fileName,
}: {
  data: string;
  type: string;
  fileName: string;
}) => {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(url);
};

export const getExportSettingQueryParam = (
  settings: ExportCampaignParams,
): string => {
  let param = "";

  for (const setting in settings) {
    if (settings[setting as ExportCampaignParamKeys]) {
      param += `${setting},`;
    }
  }

  if (!param) return "";

  //remove last comma
  return param.slice(0, -1);
};

//TODO: check for places where to use this func
export const downloadFileUtil = (
  response: AxiosResponse<ArrayBuffer | Blob | string, any>,
  fileName?: string,
) => {
  const contentDisposition = response.headers["content-disposition"];
  let suggestedFileName = "download";

  if (
    !fileName &&
    contentDisposition &&
    contentDisposition.includes("attachment")
  ) {
    const fileNameMatch = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
    );
    if (fileNameMatch && fileNameMatch[1]) {
      suggestedFileName = fileNameMatch[1].replace(/['"]/g, "");
    }
  }

  const blob = new Blob([response.data], {
    type: response.headers["content-type"] || "text/plain",
  });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName || suggestedFileName;
  a.click();

  window.URL.revokeObjectURL(url);
};

export const getDynFormSelectOptions = ({
  data,
  delimiter = "~",
}: {
  data: string[];
  delimiter?: string;
}) => {
  return data.map((option) => {
    let value: string | number | null = option;
    let label = option;

    if (option.includes(delimiter)) {
      const parts = option.split("~");
      const splitValue = parts[0];
      const splitLabel = parts.slice(1).join("~");

      let integerValue = parseInt(splitValue);

      if (!isNaN(integerValue)) {
        value = integerValue;
      } else if (splitValue === "null") {
        value = null;
      } else {
        value = splitValue;
      }

      label = splitLabel;
    }

    return {
      label,
      value,
    };
  });
};

export const convertLabelsToArray = (
  labels: TLabels,
): Array<TLabelsListData> => {
  const labelsList: Array<TLabelsListData> = [];

  for (const key in labels) {
    const value = labels[key];

    labelsList.push({
      labelKey: key,
      value,
      key,
    });
  }

  return labelsList;
};

export const getStepPathMap = (phases: Array<TPhasesData>): TStepPathMap => {
  const map: TStepPathMap = {};

  phases.forEach((phase) => {
    phase.steps.forEach((step) => {
      map[step.id] = `${phase.name}/${step.name}/`;
    });
  });

  return map;
};

export const camelCaseToWords = (str: string) => {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

export const replaceBrackets = (path: string) =>
  path.replace(/\[/g, "%5B").replace(/]/g, "%5D");

export const stringToUrlName = (value: string): string => {
  // The function duplicates the backend function for replacing special characters
  let result = "";
  let lastDash = false;

  for (const char of value) {
    if (
      (char >= "a" && char <= "z") ||
      (char >= "A" && char <= "Z") ||
      (char >= "0" && char <= "9")
    ) {
      result += char;
      lastDash = false;
    } else {
      if (!lastDash) {
        result += "-";
      }
      lastDash = true;
    }
  }

  return result;
};

type NameEntry = {
  originalName: string;
  transformedName: string;
};

export const validateUniqueNamesWithReplace = ({
  names,
  editName,
  value,
}: {
  names: NameEntry[];
  value: string;
  editName: string | undefined;
}): string | null => {
  const trimmedValue = value.trim();
  const transformedValue = stringToUrlName(trimmedValue).toLowerCase();

  if (editName) {
    names = names.filter(({ originalName }) => originalName !== editName);
  }

  const existingEntry = names.find(
    ({ originalName, transformedName }) =>
      originalName.toLowerCase() === trimmedValue.toLowerCase() ||
      transformedName === transformedValue,
  );

  if (existingEntry) {
    const isOriginalName =
      existingEntry.originalName.toLowerCase() === trimmedValue.toLowerCase();
    return isOriginalName
      ? "The name is already in use."
      : `The name is converted to the matching name of the existing item "${existingEntry.originalName}". Please choose a different name.`;
  }

  return null;
};
