import { ListIcon, UploadCloudIcon } from "lucide-react";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Button } from "src/components/common/ui/button";
import { Icons } from "../../common/Icons";
import { UploadTemplatePackageModal } from "../../common/modals/UploadTemplatePackage";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import ClassesListModal from "../ClassesListModal";

const SettingsMenu: FC = () => {
  const navigate = useNavigate();
  const [uploadOpen, setUploadOpen] = useState(false);
  const [classesOpen, setClassesOpen] = useState(false);
  const {
    hasDocumentTemplateHtml2XslRole,
    hasDocumentTemplateImportRole,
    hasDictionarySearchRole,
  } = useUserHasPermission({});

  const actionsItems = [
    hasDocumentTemplateImportRole && {
      key: "upload",
      label: "Upload template package",
      icon: UploadCloudIcon,
      onClick: () => setUploadOpen(true),
    },
    hasDocumentTemplateHtml2XslRole && {
      key: "addXsltFromHtml",
      label: "Add XSLT from HTML",
      icon: Icons.Plus,
      onClick: () => navigate("/templates/documents/generate-figma"),
    },
    hasDictionarySearchRole && {
      key: "classes",
      label: "Classes",
      icon: ListIcon,
      onClick: () => setClassesOpen(true),
    },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <>
      {!!actionsItems.length && (
        <>
          <div className="flex gap-3">
            <MenuDropdown items={actionsItems} dropdownClassName="w-[230px]">
              <Button
                variant={"primaryOutline"}
                className="text-sm font-medium"
              >
                Actions
                <Icons.More className="size-4 ml-2" />
              </Button>
            </MenuDropdown>
          </div>
          {uploadOpen && (
            <UploadTemplatePackageModal
              open={uploadOpen}
              setOpen={setUploadOpen}
            />
          )}
          {classesOpen && (
            <ClassesListModal open={classesOpen} setOpen={setClassesOpen} />
          )}
        </>
      )}
    </>
  );
};

export default SettingsMenu;
