import { FC } from "react";
import { CustomSelect } from "../common/ui/CustomSelect";
import { ArchiveStatus } from "src/globalTypes";

type Props = {
  archiveStatus: ArchiveStatus;
  onChanged: (archive: ArchiveStatus) => void;
};

const ArchiveFilter: FC<Props> = ({ archiveStatus, onChanged }) => {
  const stringValue = archiveStatus === undefined ? "all" : String(archiveStatus);
  
  const handleChange = (value: string) => {
    if (value === "all") {
      onChanged("all");
    } else {
      onChanged(value === "true");
    }
  };

  return (
    <div className="flex items-center gap-2">
      <CustomSelect
        value={stringValue}
        onChange={handleChange}
        label="Archive Status"
        className="w-[180px]"
        options={[
          { value: "all", label: "All Statuses" },
          { value: "false", label: "Active" },
          { value: "true", label: "Archived" },
        ]}
      />
    </div>
  );
};

export default ArchiveFilter;
