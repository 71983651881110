import { useEffect } from "react";

const useSubmitFormOnEnter = (
  callback: () => void,
  opts: { condition: boolean } = { condition: true }
) => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter" && !event.shiftKey && !event.altKey) {
        event.preventDefault();
        callback();
      }
    };

    if (opts.condition) {
      document.addEventListener("keydown", keyDownHandler);
    } else {
      document.removeEventListener("keydown", keyDownHandler);
    }

    return () => {
      if (opts.condition) {
        document.removeEventListener("keydown", keyDownHandler);
      }
    };
  }, [opts.condition]);
};

export default useSubmitFormOnEnter;
