import { Spin, Tooltip } from "antd";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editEnvVarKeyThunk,
  getEnvVariables,
} from "../../../store/slices/envVariablesSlice";
import { AppDispatch } from "../../../store/store";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import ModifiedBy from "../../common/ModifiedBy";
import { selectMessageApi } from "../../../store/slices/appSlice";
import TextArea from "antd/es/input/TextArea";
import useSaveOnBlurWithEscape from "../../../hooks/useSaveOnBlurWithEscape";

type Props = {
  itemKey: string;
  modifiedBy: null | number;
  modifiedTime: string;
};

const VariableItemKey: FC<Props> = ({ itemKey, modifiedTime, modifiedBy }) => {
  const { companyId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const envVars = getEnvVariables();
  const [tooltipTitle, setTooltipTitle] = useState("");
  const messageApi = useSelector(selectMessageApi);
  const { hasCampaignEnvItemRenameRole } = useUserHasPermission({ companyId });
  const { value, setValue, isLoading, handleBlur, handleKeyDown } =
    useSaveOnBlurWithEscape({
      initialValue: itemKey,
      onSave: () => onSave(),
      onCancel: () => setTooltipTitle(""),
      validate: () => !tooltipTitle,
    });

  const onSave = async () => {
    await dispatch(
      editEnvVarKeyThunk({ currentKey: itemKey, newKey: value }),
    ).unwrap();

    messageApi.success("The key was successfully renamed");
  };

  const getValidateErrorMessage = (value: string): string => {
    const lowerCaseValue = value.toLowerCase();
    const validationWarning = "The changes will not be saved!";

    if (!value.length) {
      return `The value should not be empty. ${validationWarning}`;
    }

    if (!/^[a-zA-Z_$][a-zA-Z0-9_$-]*$/.test(value)) {
      return `Invalid Javascript variable name. ${validationWarning}`;
    }

    for (let i = 0; i < envVars.length; i++) {
      const existingCCItemKey = envVars[i].id.key;

      if (existingCCItemKey === itemKey) continue;

      if (existingCCItemKey.toLowerCase() === lowerCaseValue) {
        return `The key already exists in this grid. ${validationWarning}`;
      }
    }

    return "";
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const validationErrorMessage = getValidateErrorMessage(value);

    setValue(value);
    setTooltipTitle(validationErrorMessage);
  };

  return (
    <div>
      {hasCampaignEnvItemRenameRole ? (
        <Spin spinning={isLoading} size="small">
          <Tooltip title={tooltipTitle} color="red" open={!!tooltipTitle}>
            <TextArea
              autoSize
              value={value || undefined}
              placeholder="not defined"
              onBlur={handleBlur}
              onChange={onChange}
              disabled={isLoading}
              onKeyDown={(e) => {
                e.stopPropagation();
                handleKeyDown(e);
              }}
              className="hover:border-indigo-600 border-transparent"
            />
          </Tooltip>
        </Spin>
      ) : (
        <span className="p-2">{value}</span>
      )}

      <ModifiedBy
        className="mt-3"
        modifiedBy={modifiedBy}
        modifiedTime={modifiedTime}
      />
    </div>
  );
};

export default VariableItemKey;
