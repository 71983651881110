import { useParams } from "react-router-dom";

export type CampaignChatParams = {
  companyId: number;
  chatId: number;
};

export const useCampaignChatParams = (): CampaignChatParams => {
  const { companyId, chatId } = useParams<{
    companyId: string;
    chatId: string;
  }>();

  return {
    companyId: +companyId!,
    chatId: +chatId!,
  };
};
