import { Checkbox, Form, FormInstance, Input, InputNumber, Select } from "antd";
import React from "react";
import { executeFunctionCcVarApi } from "../../api/cc-variables.api";
import {
  TDynamicItem,
  TDynamicSelectProps,
  TDynamicTextareaProps,
  TDynamicTextInputProps,
} from "../../globalTypes";
import {
  TCcVariable,
  TCcVariableType,
} from "../../store/slices/ccVariablesSlice";
import { camelCaseToWords } from "../../utils/cm.utils";
import { TEditModeProps } from "../CampaignGrid/Grid/CampaignGrid";
import TextAreaWithAltEnter from "../common/TextAreaWithAltEnter";
import ExecuteScriptContent from "../dynamicFormItems/ExecuteScriptContent/ExecuteScriptContent";
import ListKeySearch from "../dynamicFormItems/ListKeySearch/ListKeySearch";
import PostProcessingItems from "../dynamicFormItems/PostProcessing/PostProcessingItems";
import SelectWithOptionsProvider from "../dynamicFormItems/SelectWithDependsOn/SelectWithOptionsProvider";

type PropsType = {
  hidden: boolean;
  form: FormInstance<any>;
  items: TDynamicItem[];
  gridItemSequence: number;
  ccVarData: TEditModeProps | null;
  campaignId: number;
  phaseId: number;
  stepId: number;
  stepCCVars: TCcVariable[];
};

const ALLOWED_LOCAL_KEYS_TYPES: TCcVariableType[] = [
  "string",
  "function",
  "prompt",
  "web_scraper",
  "file_content",
];

const DynamicCCForm = ({
  hidden,
  form,
  items,
  gridItemSequence,
  ccVarData,
  campaignId,
  stepId,
  phaseId,
  stepCCVars,
}: PropsType) => {
  if (!items.length) return null;

  const executeScriptContent = async (scriptContent: string) => {
    const { data } = await executeFunctionCcVarApi({
      campaignId,
      stepId,
      seq: gridItemSequence,
      scriptContent,
    });

    form.setFieldsValue({
      result: data,
    });
  };

  const localKeys = stepCCVars.filter(
    (variable) =>
      variable.seq < gridItemSequence &&
      ALLOWED_LOCAL_KEYS_TYPES.includes(variable.type),
  );

  return (
    <>
      {items.map((item) => {
        const { type, name, required } = item;
        const validatedLabel = camelCaseToWords(name);
        let formItem: React.ReactElement = <></>;

        switch (type) {
          case "list_key_search":
            formItem = (
              <div key={name}>
                <ListKeySearch
                  dynElemName={name}
                  required={required}
                  hidden={hidden}
                  form={form}
                  gridItemSequence={gridItemSequence}
                  localKeys={localKeys}
                  isEditMode={!!ccVarData}
                />
              </div>
            );
            break;

          case "json_post_processing":
            formItem = (
              <div key={name}>
                <PostProcessingItems
                  dynElemName={name}
                  required={required}
                  hidden={hidden}
                  form={form}
                  gridItemSequence={gridItemSequence}
                  ccVarData={ccVarData}
                  campaignId={campaignId}
                  stepId={stepId}
                  localKeys={localKeys}
                />
              </div>
            );
            break;

          case "checkbox":
            formItem = (
              <div key={name}>
                <Form.Item
                  hidden={hidden}
                  valuePropName="checked"
                  name={name}
                  noStyle
                >
                  <Checkbox className="font-sans font-bold text-[#475569] text-[14px] mb-[24px]">
                    {validatedLabel}
                  </Checkbox>
                </Form.Item>
              </div>
            );
            break;
          case "textarea":
            {
              const textareaProps = item as TDynamicTextareaProps;
              const { properties } = textareaProps;
              const rows = +properties.rows || 5;

              if (name === "scriptContent") {
                formItem = (
                  <ExecuteScriptContent
                    key={name}
                    rows={rows}
                    label={validatedLabel}
                    hidden={hidden}
                    required={required}
                    executeScriptContent={executeScriptContent}
                    name={name}
                    form={form}
                  />
                );
              } else {
                formItem = (
                  <Form.Item
                    key={name}
                    hidden={hidden}
                    name={name}
                    label={validatedLabel}
                    rules={[
                      {
                        required: required,
                        message: "Required field!",
                      },
                    ]}
                  >
                    <TextAreaWithAltEnter
                      placeholder={`Enter ${validatedLabel}`}
                      currentForm={form}
                      fieldName={name}
                      rows={rows}
                    />
                  </Form.Item>
                );
              }
            }
            break;
          case "text_input":
            {
              const { properties } = item as TDynamicTextInputProps;
              const regex = new RegExp(properties.pattern);

              formItem = (
                <Form.Item
                  key={name}
                  hidden={hidden}
                  name={name}
                  label={validatedLabel}
                  tooltip={properties.tooltip}
                  rules={[
                    {
                      required: required,
                      message: "Required field!",
                    },
                    {
                      pattern: regex,
                      message: `${validatedLabel} contains invalid characters!`,
                    },
                  ]}
                >
                  <Input placeholder={`Enter ${validatedLabel}`} />
                </Form.Item>
              );
            }

            break;
          case "number_input":
            formItem = (
              <Form.Item
                key={name}
                hidden={hidden}
                name={name}
                label={validatedLabel}
              >
                <InputNumber
                  placeholder={`Enter ${validatedLabel}`}
                  className="w-full"
                />
              </Form.Item>
            );
            break;
          case "select":
            {
              const selectProps = item as TDynamicSelectProps;
              const { values, properties } = selectProps;
              const restValuesProvider = properties.restValuesProvider;

              if (restValuesProvider) {
                formItem = (
                  <SelectWithOptionsProvider
                    key={name}
                    selectProps={selectProps}
                    hidden={hidden}
                    form={form}
                    restValuesProvider={restValuesProvider}
                    campaignId={campaignId}
                    stepId={stepId}
                    phaseId={phaseId}
                  />
                );
              } else {
                formItem = (
                  <Form.Item
                    key={name}
                    hidden={hidden}
                    name={name}
                    label={validatedLabel}
                    rules={[
                      {
                        required,
                        message: "Required field!",
                      },
                    ]}
                  >
                    <Select
                      placeholder={`Choose ${validatedLabel}`}
                      options={values.map((value) => ({
                        value,
                        label: value,
                      }))}
                    />
                  </Form.Item>
                );
              }
            }
            break;
        }
        return formItem;
      })}
    </>
  );
};

export default DynamicCCForm;
