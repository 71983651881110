import {
  CheckCircleIcon,
  CommandLineIcon,
  PencilSquareIcon,
} from "src/components/common/Icons";
import { TCampaignType } from "src/store/slices/campaignsSlice";
import { DocumentTemplateState } from "src/types/docTemplates";
import {
  TCcVariableType,
  TModelServiceProvider,
} from "../store/slices/ccVariablesSlice";
import React from "react";

type HeroIcon = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
>;

type BadgeSetting = {
  label: string;
  className: string;
  icon?: HeroIcon | any;
};

export const campaignBadgeSettings: Record<TCampaignType, BadgeSetting> = {
  CAMPAIGN: {
    label: "Campaign",
    className: "bg-indigo-100 text-indigo-900",
  },
  DRAFT_TEMPLATE: {
    label: "Draft",
    className: "bg-slate-100 text-slate-500",
  },
  PUBLISHED_TEMPLATE: {
    label: "Published",
    className: "bg-green-100 text-green-950",
  },
};

export const campaignStatusBadgeSettings: Record<
  TCcVariableType,
  { className: string }
> = {
  function: {
    className: "bg-indigo-100 text-indigo-600 border border-indigo-200",
  },
  prompt: {
    className: "bg-gray-100 text-gray-600 border border-gray-200",
  },
  string: {
    className: "bg-green-100 text-green-600 border border-green-200",
  },
  web_scraper: {
    className: "bg-blue-100 text-blue-600 border border-blue-200",
  },
  asset: {
    className: "bg-orange-100 text-orange-600 border border-orange-200",
  },
  json: {
    className: "bg-yellow-100 text-yellow-700 border border-yellow-300",
  },
  xml: {
    className: "bg-purple-100 text-purple-600 border border-purple-200",
  },
  file_content: {
    className: "bg-orange-100 text-orange-500 border border-orange-200",
  },
};

export enum ComputationType {
  FULL = "FULL",
  NOT_COMPUTED_AND_ASSETS = "NOT_COMPUTED_AND_ASSETS",
  NOT_COMPUTED = "NOT_COMPUTED",
}

export const recalculateOptions = [
  {
    label: "Re-compute all",
    value: ComputationType.FULL,
  },
  {
    label: "Re-compute assets and not computed",
    value: ComputationType.NOT_COMPUTED_AND_ASSETS,
  },
  {
    label: "Run not computed",
    value: ComputationType.NOT_COMPUTED,
  },
];

export const docTemplateStateBadgeSettings: Record<
  DocumentTemplateState,
  BadgeSetting
> = {
  [DocumentTemplateState.DRAFT]: {
    className: "bg-slate-100 text-slate-500 border-none",
    label: "Draft",
    icon: PencilSquareIcon,
  },
  [DocumentTemplateState.PUBLISHED]: {
    className: "bg-green-100 text-green-950 border-none",
    label: "Published",
    icon: CheckCircleIcon,
  },
  [DocumentTemplateState.SYSTEM]: {
    className: "bg-indigo-100 text-indigo-900 border-none",
    label: "System",
    icon: CommandLineIcon,
  },
};

export const DROPDOWN_MENU_COLLAPSE_LENGTH = 6;

export const serviceProviders: Record<
  TModelServiceProvider,
  { label: string }
> = {
  OPENAI: {
    label: "OpenAI",
  },
  VERTEX_AI: {
    label: "Vertex AI",
  },
  BEDROCK: {
    label: "Bedrock",
  },
};

export const MICROSITE_INPUT_PLACEHOLDER = "<Not Used>";
export const MICRO_SITE_POSTFIX = ".velocityengine.co";
export const MICRO_SITE_MAX_LENGTH = 63;
export const GRID_TITLE_MAX_LENGTH = 50;
export const START_PUBLISHING_TEXT_MESSAGE =
  "The publishing procedure has started. See the current status in the Event Log";
export const DEFAULT_LISTING_PAGE_SIZE = 50;
export const ALLOWED_LOGO_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/svg+xml",
  "image/webp",
];
