import { Form } from "antd";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "src/components/common/Icons";
import { Button } from "../../components/common/ui/button";
import HtmlCodeEditor from "../../components/docTemplates/HTMLCodeEditor/HTMLCodeEditor";
import SaveTemplateButton from "../../components/docTemplates/SaveTemplateButton";
import { TemplateSettingForm } from "../../components/docTemplates/TemplateSettingsForm";
import XSLTCodeEditor from "../../components/docTemplates/XSLTCodeEditor/XSLTCodeEditor";
import { XSLTEditorEnum } from "../../types/docTemplates";
import useGenerateTemplateHook from "./hooks/useGenerateTemplate.hook";

const GenerateTemplatePage = () => {
  const { transformTemplatesOptions, isFetching } = useGenerateTemplateHook();
  const [form] = Form.useForm<TemplateSettingForm>();
  const navigate = useNavigate();
  const [leftWidth, setLeftWidth] = useState(50);
  const [isResizing, setIsResizing] = useState(false);
  const [XSLTCode, setXSLTCode] = useState<string | undefined>(undefined);
  const [tab, setTab] = useState<XSLTEditorEnum>(XSLTEditorEnum.CODE);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (e: any) => {
    const resizer = containerRef.current;

    if (isResizing && resizer) {
      const containerRect = resizer.getBoundingClientRect();
      const newLeftWidth = Math.round(
        ((e.clientX - containerRect.left) / containerRect.width) * 100
      );

      if (newLeftWidth > 10 && newLeftWidth < 90) {
        setLeftWidth(newLeftWidth);
      }
    }
  };

  return (
    <div className="flex flex-col gap-[12px]">
      <div className="mb-[12px] flex justify-between">
        <div className="flex gap-[16px] items-center">
          <Button
            variant={"ghost"}
            icon={Icons.back}
            onClick={() => navigate(`/templates/documents`)}
            className="p-[12px] h-auto"
          />
          <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal">
            Templates Editor
          </h1>
        </div>
        <SaveTemplateButton
          form={form}
          content={XSLTCode || ""}
          setTab={setTab}
        />
      </div>
      <div
        ref={containerRef}
        className="flex"
        onMouseMove={handleMouseMove}
        onMouseUp={() => setIsResizing(false)}
        onMouseLeave={() => setIsResizing(false)}
      >
        <div style={{ width: `${leftWidth - 1}%` }}>
          <HtmlCodeEditor
            transformTemplatesOptions={transformTemplatesOptions}
            isFetchingTemplatesOptions={isFetching}
            setXSLTCode={setXSLTCode}
            className="w-full"
          />
        </div>
        <div
          className="w-[2%] cursor-col-resize select-none"
          onMouseDown={() => setIsResizing(true)}
        />
        <div style={{ width: `${100 - leftWidth - 1}%` }}>
          <XSLTCodeEditor
            setTab={setTab}
            tab={tab}
            setXSLTCode={setXSLTCode}
            XSLTCode={XSLTCode}
            form={form}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default GenerateTemplatePage;
