import { PermissionsBuilder } from "../types";

export const buildPermissions = ({
  globalPermissions,
  companyPermissions,
  companyPermissionsDeny,
  companyId,
}: PermissionsBuilder) => {
  let permissionsSet = new Set(globalPermissions);

  if (companyId) {
    const currentCompanyId =
      typeof companyId === "string" ? Number(companyId) : companyId;
    const currentCompanyPermissions = companyPermissions[currentCompanyId];
    const currentCompanyPermissionsDeny =
      companyPermissionsDeny[currentCompanyId];

    if (currentCompanyPermissions) {
      //if there are company permissions - join global with company ones
      permissionsSet = new Set([
        ...globalPermissions,
        ...currentCompanyPermissions,
      ]);

      if (currentCompanyPermissionsDeny) {
        //if there are company permissions deny - delete permissions from joined global with company permission set
        currentCompanyPermissionsDeny.forEach((permissionDeny) => {
          if (permissionsSet.has(permissionDeny)) {
            permissionsSet.delete(permissionDeny);
          }
        });
      }
    }
  }

  return permissionsSet;
};
