import { TEnvVariableType } from "../store/slices/envVariablesSlice";
import { TCcVariableType } from "../store/slices/ccVariablesSlice";
import { FilterOption } from "../components/EventsLog/constants";

const IGNORE_REDIRECTS_TO = ["/sign-in", "/", "/home"];
const ENV_VAR_TYPE_LOCAL_STORAGE_KEY = "envVarType";
const CC_VAR_TYPE_LOCAL_STORAGE_KEY = "ccVarType";
const LAST_URL_LOCAL_STORAGE_KEY = "lastUrl";
const UPDATE_DOWNSTREAM_DEPENDENCIES_LOCAL_STORAGE_KEY =
  "updateDownstreamDependencies";
const EVENT_LOG_FILTER = "eventLogFilter";

const localStorageProvider = {
  getEnvType: () => localStorage.getItem(ENV_VAR_TYPE_LOCAL_STORAGE_KEY),
  setEnvType: (type: TEnvVariableType) =>
    localStorage.setItem(ENV_VAR_TYPE_LOCAL_STORAGE_KEY, JSON.stringify(type)),

  getCCType: () => localStorage.getItem(CC_VAR_TYPE_LOCAL_STORAGE_KEY),
  setCCType: (type: TCcVariableType) =>
    localStorage.setItem(CC_VAR_TYPE_LOCAL_STORAGE_KEY, JSON.stringify(type)),

  getLastUrl: () => localStorage.getItem(LAST_URL_LOCAL_STORAGE_KEY),
  setLastUrl: (url: string) => {
    const shouldIgnorePath = IGNORE_REDIRECTS_TO.includes(url);
    if (!shouldIgnorePath) {
      localStorage.setItem(LAST_URL_LOCAL_STORAGE_KEY, url);
    }
  },

  getUpdateDownstreamDependencies: () => {
    const value = localStorage.getItem(
      UPDATE_DOWNSTREAM_DEPENDENCIES_LOCAL_STORAGE_KEY,
    );
    return value === "true";
  },
  setUpdateDownstreamDependencies: (value: boolean) =>
    localStorage.setItem(
      UPDATE_DOWNSTREAM_DEPENDENCIES_LOCAL_STORAGE_KEY,
      JSON.stringify(value),
    ),
  getEventLogFilter: () =>
    localStorage.getItem(EVENT_LOG_FILTER) as FilterOption | null,
  setEventLogFilter: (filter: FilterOption) =>
    localStorage.setItem(EVENT_LOG_FILTER, filter),
};
export default localStorageProvider;
