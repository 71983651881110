import { FormInstance } from "antd";

const submitFormWithTrim = ({
  form,
  onSuccessValidationCb,
  onErrorValidationCb,
  ignoreFields,
}: {
  form: FormInstance<any>;
  onSuccessValidationCb: (values: any) => Promise<void>;
  onErrorValidationCb?: (error: any) => Promise<void> | void;
  ignoreFields?: string[];
}) => {
  return async () => {
    try {
      const values = await form.validateFields({});
      const trimmedValues: Record<string, any> = {};

      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const value = values[key];

          if (ignoreFields?.includes(key)) {
            trimmedValues[key] = value;
          } else {
            trimmedValues[key] =
              typeof value === "string" ? value.trim() : value;
          }
        }
      }

      await onSuccessValidationCb(trimmedValues);
    } catch (e: any) {
      onErrorValidationCb && (onErrorValidationCb(e));
      console.log("Validation Err:", e);
    }
  };
};

export default submitFormWithTrim;
