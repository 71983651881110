import { TStep } from "../store/slices/stepsSlice";
import { CampaignViewTypes } from "../types";

type Props = {
  steps: TStep[];
  currentStep: TStep | null;
};

const determineCurrentStepAndView = ({ steps, currentStep }: Props) => {
  const isDocView = window.location.href.includes(CampaignViewTypes.DOC);

  let validatedCurrentStep: TStep | null = currentStep;
  let viewToSet: CampaignViewTypes | undefined;

  //If there is a DOC parameter in the URL, need to check the hiddenDocView at the step.
  if (isDocView) {
    if (currentStep && currentStep.hiddenDocView) {
      //If hiddenDocView, try to find a step that has hiddenDocView false.
      const firstNotHiddenDocStep = steps.find((step) => !step.hiddenDocView);

      if (firstNotHiddenDocStep) {
        validatedCurrentStep = firstNotHiddenDocStep;
      } else {
        // If no such step is found, then switch the view to DOC mode
        viewToSet = CampaignViewTypes.GRID;
      }
    }
  }

  return {
    validatedCurrentStep,
    viewToSet,
  };
};

export default determineCurrentStepAndView;
