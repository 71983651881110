import { TCcVariable } from "../../store/slices/ccVariablesSlice";

export type OnAfterResultUpdate = (ccItem: TCcVariable) => void | Promise<void>;
export type OnAfterPromptUpdate = (ccItem: TCcVariable) => void | Promise<void>;
export type OnAfterOverrideUpdate = (
  ccItem: TCcVariable,
) => void | Promise<void>;
export type OnAfterCCItemUpdate = (ccItem: TCcVariable) => void | Promise<void>;
export type SetActiveComponent = (value: ActivePaneCCTab) => void;

export enum ActivePaneCCTab {
  Result = "Result",
  Override = "Override",
  Model = "Model",
  Prompt = "Prompt",
}

export type PaneCCNavItem = {
  label: ActivePaneCCTab;
  active: boolean;
};
