import { FC } from "react";
import { SelectOption } from "src/globalTypes";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../common/ui/select";

type Props = {
  onValueChange: (value: string) => void;
  options: SelectOption[];
  value: string;
  placeholder: string;
};

export const Selector: FC<Props> = ({
  onValueChange,
  options,
  value,
  placeholder,
}) => {
  return (
    <Select onValueChange={onValueChange} value={value}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>{placeholder}</SelectLabel>
          {options.map(({ label, value }) => (
            <SelectItem key={value} value={String(value)}>
              {label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
