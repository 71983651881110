import { Button } from "../components/common/ui/button";
import React from "react";
import { Modal } from "antd";
import { TCcVariable } from "../store/slices/ccVariablesSlice";
import { TableHeader } from "../components/table/Header";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../components/common/ui/table";
import dayjs from "dayjs";

const camelCaseToSentence = (camelCaseStr: string): string => {
  const result = camelCaseStr
    .replace(/([A-Z])/g, " $1") // find each capital letter and add a space in front of it
    .replace(/^./, (str) => str.toUpperCase()); // make the first letter uppercase

  return result.trim();
};

const deepDiff = ({
  current,
  actual,
}: {
  current: any;
  actual: any;
}): Record<string, { current: any; actual: any }> => {
  let differences: Record<string, { current: any; actual: any }> = {};

  const isObject = (obj: any) =>
    obj && typeof obj === "object" && !Array.isArray(obj);
  const isArray = (obj: any) => Array.isArray(obj);

  // go through all the keys in both objects
  const keys = new Set([
    ...Object.keys(current || {}),
    ...Object.keys(actual || {}),
  ]);

  keys.forEach((key) => {
    const value1 = current?.[key];
    const value2 = actual?.[key];

    // If both values are objects - compare recursively
    if (isObject(value1) && isObject(value2)) {
      const nestedDiff = deepDiff({ current: value1, actual: value2 });
      if (Object.keys(nestedDiff).length > 0) {
        differences = { ...differences, ...nestedDiff };
      }
    } else if (isArray(value1) && isArray(value2)) {
      // If both values are arrays and they are not equal
      if (JSON.stringify(value1) !== JSON.stringify(value2)) {
        differences[key] = { current: value1, actual: value2 };
      }
    } else if (value1 !== value2) {
      differences[key] = { current: value1, actual: value2 };
    }
  });

  return differences;
};

const tableHeaderItems = [
  {
    title: "Property",
    className: "font-sans !w-1/5 font-bold text-[#0F172A]",
  },
  {
    title: "Current Item",
    className: "font-sans !w-2/5 font-bold text-[#0F172A]",
  },
  {
    title: "Actual Item",
    className: "font-sans !w-2/5 font-bold text-[#0F172A]",
  },
];

const useCCItemConflictModal = () => {
  const [modal, contextHolder] = Modal.useModal();

  const openConflictModal = ({
    currentItem,
    actualItem,
    onApplyActualCCItem,
  }: {
    currentItem: TCcVariable;
    actualItem: TCcVariable;
    onApplyActualCCItem: () => void;
  }) => {
    const differences = deepDiff({ current: currentItem, actual: actualItem });

    const { destroy } = modal.warning({
      title: "The content was changed by another user!",
      className: "conflict-cc-item-modal",
      content: (
        <Table className="my-3">
          <TableHeader items={tableHeaderItems} />
          <TableBody className="mb-2">
            {Object.entries(differences).map((diff, index) => {
              const [propName, diffObject] = diff;
              const { current, actual } = diffObject;
              let currentContent = current
                ? JSON.stringify(current, null, 2)
                : "";
              let actualContent = actual ? JSON.stringify(actual, null, 2) : "";

              if (propName === "modifiedTime") {
                currentContent = dayjs(current).format("MMM D, YYYY h:mm:ss A");
                actualContent = dayjs(actual).format("MMM D, YYYY h:mm:ss A");
              }

              return (
                <TableRow
                  key={index}
                  className="cursor-pointer h-[48px] relative text-[#475569]"
                >
                  <TableCell className="font-semibold">
                    {camelCaseToSentence(propName)}
                  </TableCell>
                  <TableCell>{currentContent}</TableCell>
                  <TableCell>{actualContent}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ),
      footer: (
        <div className="flex justify-end items-center">
          <div className="flex gap-[12px]">
            <Button
              onClick={() => destroy()}
              variant="primaryOutline"
              className="rounded-full self-start"
              size="sm"
            >
              Close
            </Button>
            <Button
              onClick={() => {
                onApplyActualCCItem();
                destroy();
              }}
              className="rounded-full self-start px-5"
              size="sm"
            >
              Apply actual content
            </Button>
          </div>
        </div>
      ),
    });
  };

  return {
    openConflictModal,
    conflictModalContext: contextHolder,
  };
};

export default useCCItemConflictModal;
