import { Drawer } from "antd";
import { Dispatch, FC, memo, SetStateAction, useState } from "react";
import { TStepPhaseMetaInfo } from "../../../../globalTypes";
import { TCcVariable } from "../../../../store/slices/ccVariablesSlice";
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from "../../../common/Icons";
import { Button } from "../../../common/ui/button";
import PaneSection from "./PaneSection";
import { ChangeNodeProps, UpstreamDepsNodeType } from "../../types";

type Props = {
  inputNode: TCcVariable | null;
  targetNode: TCcVariable | null;
  stepPhaseMetaInfo: TStepPhaseMetaInfo | null;
  isInputLoading: boolean;
  isTargetLoading: boolean;
  changeNodeProps: ChangeNodeProps;
  setTargetNode: Dispatch<SetStateAction<TCcVariable | null>>;
  setInputNode: Dispatch<SetStateAction<TCcVariable | null>>;
};

const EditorPane: FC<Props> = ({
  inputNode,
  targetNode,
  stepPhaseMetaInfo,
  isInputLoading,
  isTargetLoading,
  changeNodeProps,
  setTargetNode,
  setInputNode,
}) => {
  const [drawerHeight, setDrawerHeight] = useState("30%");

  const toggleDrawerSize = () => {
    setDrawerHeight((prevHeight) => (prevHeight === "30%" ? "60%" : "30%"));
  };

  return (
    <Drawer
      title={null}
      placement="bottom"
      closable={false}
      open={true}
      mask={false}
      classNames={{
        header: "h-0",
        body: "!p-0 !h-full",
      }}
      height={drawerHeight}
    >
      <div className="flex py-[10px] pr-[48px] h-full">
        <div className="px-[9px]">
          <Button
            variant={"ghostPrimary"}
            className="p-[1px] size-[30px]"
            onClick={toggleDrawerSize}
          >
            {drawerHeight === "30%" ? (
              <ChevronDoubleUpIcon className="size-[20px]" />
            ) : (
              <ChevronDoubleDownIcon className="size-[20px]" />
            )}
          </Button>
        </div>
        <div className="w-full h-full flex flex-col gap-[12px]">
          <PaneSection
            isItemLoading={isInputLoading}
            item={inputNode}
            setItem={setInputNode}
            nodeType={UpstreamDepsNodeType.INPUT}
            stepPhaseMetaInfo={stepPhaseMetaInfo}
            changeNodeProps={changeNodeProps}
          />
          <PaneSection
            isItemLoading={isTargetLoading}
            item={targetNode}
            setItem={setTargetNode}
            nodeType={UpstreamDepsNodeType.TARGET}
            stepPhaseMetaInfo={stepPhaseMetaInfo}
            changeNodeProps={changeNodeProps}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default memo(EditorPane);
