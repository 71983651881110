import { TComputationState } from "../../store/slices/ccVariablesSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { ComputedIcon, CreatedIcon, SolidErrorIcon } from "../common/Icons";
import React, { ReactNode } from "react";
import { eventMessageTypeClassMap, FilterOption } from "./constants";
import {
  TComputationMessage,
  TComputationStatus,
  TSocketMsgType,
} from "../../store/slices/computationMessages/types";
import { isValidInteger } from "../../utils";
import { CampaignViewTypes } from "../../types";
import { NavLink } from "react-router-dom";

export const getStateConfig = (
  state: TComputationState | TComputationStatus,
): { icon: ReactNode; className: string } => {
  switch (state) {
    case "processing":
    case "in_progress":
      return { icon: <LoadingOutlined />, className: "" };
    case "computed":
      return { icon: <ComputedIcon />, className: "text-[#16A34A]" };
    case "finished":
      return { icon: <ComputedIcon />, className: "text-[#1c72d5]" };
    case "interrupted":
      return { icon: <SolidErrorIcon />, className: "text-orange-600" };
    case "error":
      return { icon: <SolidErrorIcon />, className: "text-red-600" };
    case "created":
      return { icon: <CreatedIcon />, className: "text-[#D97706]" };
    default:
      return { icon: <span>{state}</span>, className: "" };
  }
};

export const getProgressInfo = ({
  progress,
  computationStatus,
}: {
  progress: number;
  computationStatus: TComputationStatus | undefined;
}): {
  status: "success" | "normal" | "exception" | "active";
  className: string;
} => {
  const baseClass = "rounded-full px-2.5 py-0.5 font-semibold text-xs";

  // Define status
  const status =
    progress === 100
      ? "success"
      : computationStatus === "interrupted"
        ? "normal"
        : computationStatus === "finished" && progress !== 100
          ? "exception"
          : "active";

  // Define class name based on status
  const statusClasses: Record<typeof status, string> = {
    success: `${baseClass} bg-green-100 text-green-900`,
    normal: `${baseClass} bg-orange-100 text-orange-900`,
    exception: `${baseClass} bg-red-100 text-red-900`,
    active:
      computationStatus === "in_progress"
        ? `${baseClass} bg-blue-100 text-blue-900`
        : `${baseClass} bg-gray-100 text-gray-900`,
  };

  return { status, className: statusClasses[status] };
};

export const getClassNameForTypeCol = (type: TSocketMsgType): string => {
  const baseClass = "inline rounded-full px-2.5 py-0.5 font-semibold text-xs";
  const defaultClass = "bg-orange-100 text-orange-900";

  return `${baseClass} ${eventMessageTypeClassMap[type] || defaultClass}`;
};

export const getPathNode = ({
  companyId,
  campaignId,
  phaseId,
  stepId,
  ccItemKey,
  path,
}: {
  companyId: any;
  campaignId: any;
  phaseId: any;
  stepId: any;
  ccItemKey: string | undefined;
  path: string | null;
}): ReactNode => {
  const pathToStep = _generatePathToStep({
    companyId,
    campaignId,
    phaseId,
    stepId,
    ccItemKey,
  });

  if (!path) {
    return _getLinkOrSpanNode("No path provided!", pathToStep, true);
  }

  const splittedPath = path.split("/");
  const keyName = splittedPath.pop()!;
  const firstPathPart =
    splittedPath.length > 0 ? `${splittedPath.join("/")}/` : null;

  return (
    <div>
      {firstPathPart && <span className="text-slate-300">{firstPathPart}</span>}
      {_getLinkOrSpanNode(keyName, pathToStep)}
    </div>
  );
};

export const getFilteredMessages = ({
  filter,
  messages,
}: {
  filter: FilterOption;
  messages: TComputationMessage[];
}): TComputationMessage[] => {
  switch (filter) {
    case FilterOption.ERROR:
      return messages.filter((item) =>
        item.type.toLowerCase().includes("error"),
      );
    case FilterOption.COMPUTED:
      return messages.filter((item) => item.item?.state === "computed");
    case FilterOption.IN_PROGRESS:
      return messages.filter((item) => item.item?.state === "processing");
    case FilterOption.CREATED:
      return messages.filter((item) => item.item?.state === "created");
    case FilterOption.ALL:
    default:
      return Object.values(messages);
  }
};

export const _generatePathToStep = ({
  companyId,
  campaignId,
  phaseId,
  stepId,
  ccItemKey,
}: {
  companyId: any;
  campaignId: any;
  phaseId: any;
  stepId: any;
  ccItemKey: string | undefined;
}) => {
  // validate params
  if (![companyId, campaignId, phaseId, stepId].every(isValidInteger)) {
    return "";
  }

  // build base path to step
  const pathToStep = `/campaigns/company/${companyId}/campaign/${campaignId}/phase/${phaseId}/step/${stepId}?view=${CampaignViewTypes.GRID}`;

  // add hash if ccItemKey exists
  return ccItemKey ? `${pathToStep}#${ccItemKey}` : pathToStep;
};

const _getLinkOrSpanNode = (
  content: string,
  pathToKey: string,
  isError = false,
) =>
  pathToKey ? (
    <NavLink
      className="font-semibold text-veSecondary"
      to={pathToKey}
      target="_blank"
    >
      {content}
    </NavLink>
  ) : (
    <span
      className={`font-semibold ${isError ? "text-red-800" : "text-[#475569]"}`}
    >
      {content}
    </span>
  );
