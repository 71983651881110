import {
  Button,
  Checkbox,
  Form,
  Input,
  InputRef,
  Modal,
  Select,
  Spin,
} from "antd";
import { FC, useRef, useState } from "react";
import { createPhaseApi, getPhasesApi } from "../../../api/phases.api";
import { getStepsApi } from "../../../api/steps.api";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { getMessageApi } from "../../../store/slices/appSlice";
import { TCampaignResponseData } from "../../../store/slices/campaignsSlice";
import { fetchCampaignsOptions } from "../../../utils/apiUtils";
import { STEP_SEQUENCE_STEP } from "../../../utils/campaigns.constant";
import { sortBySequence } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";
import SelectWithHighlightSearch, {
  TSelectOptionWithData,
} from "../../common/SelectWithHighlightSearch";

export type TCloneToStepFormValues = {
  name: string;
  resetOvr: boolean;
  resetResult: boolean;
  companyId: number;
  campaignId: number;
  phaseId: number;
  targetStepSeq: number;
};

type TSelectOption = { label: string; value: number };

type Props = {
  entityTitle: string;
  onSubmitForm: (values: TCloneToStepFormValues) => Promise<void>;
  onCancel: () => void;
};

const CloneToStepModal: FC<Props> = ({
  entityTitle,
  onSubmitForm,
  onCancel,
}) => {
  const messageApi = getMessageApi();
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm<TCloneToStepFormValues>();
  const [isLoading, setIsLoading] = useState<
    "submit" | "phase" | "step" | "createPhase" | boolean
  >(false);
  const [companyId, setCompanyId] = useState<number | undefined>(undefined);
  const campaignId = Form.useWatch<number | undefined>("campaignId", form);
  const phaseId = Form.useWatch<number | undefined>("phaseId", form);
  const [phaseOptions, setPhaseOptions] = useState<TSelectOption[]>([]);
  const [stepOptions, setStepOptions] = useState<TSelectOption[]>([]);
  const [isAddPhaseBtnVisible, setIsAddPhaseBtnVisible] = useState(false);
  const [newPhaseName, setNewPhaseName] = useState("");

  useSubmitFormOnEnter(() => onValidateForm(), { condition: !isLoading });

  const onValidateForm = async () => {
    if (companyId === undefined) {
      messageApi.error("Company ID not found");
      return;
    }

    try {
      setIsLoading("submit");

      const values = await form.validateFields();

      await onSubmitForm({ ...values, name: values.name.trim(), companyId });

      onCancel();
    } catch (e) {
      messageApi.error(
        "Please fill in all required fields of the form before sending",
      );
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getTargetSequence = (seq: number, nextSeq: number | undefined) => {
    return nextSeq !== undefined
      ? seq + Math.floor((nextSeq - seq) / 2)
      : seq + STEP_SEQUENCE_STEP;
  };

  const onSelectCampaign = async (
    campaignId: number | undefined,
    option: TSelectOptionWithData<TCampaignResponseData> | undefined,
  ) => {
    setPhaseOptions([]);
    setIsAddPhaseBtnVisible(false);

    setCompanyId(option ? option.data.companyId : undefined);
    form.setFieldsValue({
      campaignId,
      phaseId: undefined,
      targetStepSeq: undefined,
    });

    //prevent loading options if the clear value selection action was called
    if (campaignId === undefined) return;

    try {
      setIsLoading("phase");

      const { data } = await getPhasesApi({ campaignId });

      if (data.length) {
        const sortedPhases = sortBySequence(data);
        const options = sortedPhases.map(({ id, name }) => ({
          label: name,
          value: id,
        }));
        setPhaseOptions(options);
      } else {
        setIsAddPhaseBtnVisible(true);
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectPhase = async (phaseId: number) => {
    setStepOptions([]);

    form.setFieldsValue({
      phaseId,
      targetStepSeq: undefined,
    });

    try {
      setIsLoading("step");

      const { data } = await getStepsApi({ phaseId });
      let options: TSelectOption[] = [];

      if (data.length) {
        const sortedSteps = sortBySequence(data);
        options = sortedSteps.map(({ seq, name }, index) => ({
          label: name,
          value: getTargetSequence(seq, sortedSteps[index + 1]?.seq),
        }));
      } else {
        //if no phases in campaign - set seq=0 because it'll be the first phase
        form.setFieldValue("targetStepSeq", 0);
      }

      setStepOptions(options);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };
  const onAddNewPhase = async () => {
    try {
      const nameTrim = newPhaseName.trim();

      if (nameTrim.length < 1) {
        messageApi.error(
          "The name of the phase must include at least 1 character!",
        );
        return;
      }

      setIsLoading("createPhase");

      const { data } = await createPhaseApi({
        campaignId: campaignId!,
        name: nameTrim,
        hidden: false,
        microSiteContextFolder: "",
        addType: "AFTER",
      });
      const { name, id } = data;

      setPhaseOptions([{ value: id, label: name }]);
      setIsAddPhaseBtnVisible(false);
      form.setFieldsValue({ phaseId: id, targetStepSeq: 0 });

      messageApi.success("The phase was successfully created");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Clone Step To"
      open={true}
      onOk={onValidateForm}
      okButtonProps={{ loading: isLoading === "submit" }}
      okText="Clone"
      onCancel={onCancel}
      className="w-[700px]"
    >
      <Form
        form={form}
        layout="vertical"
        className="h-[365px]"
        name="clone_to_phase_form"
        initialValues={{
          resetOvr: true,
          resetResult: false,
          name: `Clone of ${entityTitle}`,
        }}
      >
        <div className="flex justify-between items-center">
          <Form.Item
            name="name"
            className="w-[330px]"
            label="Step Name"
            tooltip="Please enter a string up to 255 characters long"
            rules={[
              {
                type: "string",
                required: true,
                whitespace: true,
                message: "Required field!",
              },
              {
                max: 255,
                message: "Phase name must contain no more than 255 characters!",
              },
            ]}
          >
            <Input placeholder="Enter Phase name" ref={inputRef} />
          </Form.Item>
          <div className="flex flex-col gap-[6px]">
            <Form.Item name="resetOvr" valuePropName="checked" noStyle>
              <Checkbox>Reset override</Checkbox>
            </Form.Item>
            <Form.Item name="resetResult" valuePropName="checked" noStyle>
              <Checkbox>Reset result</Checkbox>
            </Form.Item>
          </div>
        </div>

        <Form.Item
          name="campaignId"
          label="Choose Campaign"
          rules={[{ required: true, message: "Required field!" }]}
        >
          <SelectWithHighlightSearch
            onSelect={onSelectCampaign}
            fetchOptions={fetchCampaignsOptions}
            allowClear={true}
            optionLabel="extCompanyName"
          />
        </Form.Item>
        <Spin spinning={isLoading === "phase"}>
          <Form.Item
            name="phaseId"
            hidden={!campaignId}
            label="Choose Phase"
            rules={[{ required: true, message: "Required field!" }]}
          >
            <Select
              placeholder="Click to select"
              className="w-full font-normal"
              options={phaseOptions}
              onChange={onSelectPhase}
            />
          </Form.Item>
        </Spin>
        <Spin spinning={isLoading === "step"}>
          <Form.Item
            name="targetStepSeq"
            hidden={!phaseId || !stepOptions.length}
            label="Choose Step"
            rules={[{ required: true, message: "Required field!" }]}
          >
            <Select
              placeholder="Select the Step after which to insert"
              className="w-full font-normal"
              options={stepOptions}
            />
          </Form.Item>
          {phaseId && !stepOptions.length && (
            <div className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400">
              <span>
                There are no steps in the campaign yet.
                <br />
                Therefore, the copied step will be inserted first.
                <br />
                Please continue by clicking the Clone button.
              </span>
            </div>
          )}
          {isAddPhaseBtnVisible && (
            <div className="flex flex-col">
              <span className="text-[10px]">
                There is not a single phase in the selected company.
                <br /> You can enter the name of the new phase below and create
                it.
              </span>
              <div className="flex gap-[12px]">
                <Input
                  placeholder="Phase name"
                  size="small"
                  value={newPhaseName}
                  onChange={(e) => setNewPhaseName(e.target.value)}
                />
                <Button
                  size="small"
                  onClick={onAddNewPhase}
                  loading={isLoading === "createPhase"}
                  disabled={!newPhaseName.length}
                >
                  Add new phase
                </Button>
              </div>
            </div>
          )}
        </Spin>
      </Form>
    </Modal>
  );
};

export default CloneToStepModal;
