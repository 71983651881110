import clsx from "clsx";
import React, { FC, useRef } from "react";
import { TFileTreeItem } from "../../../globalTypes";
import useHover from "../../../hooks/useHover";
import { StorageFileIcon } from "../../common/Icons";

type Props = {
  onSelectItem: () => void;
  isSelected: boolean;
  item: TFileTreeItem;
  renderMenu: (props: {
    isHovering: boolean;
    isSelected: boolean;
    item: TFileTreeItem;
  }) => React.ReactElement;
  columns: React.ReactElement | null;
};
const File: FC<Props> = ({
  onSelectItem,
  isSelected,
  renderMenu,
  item,
  columns,
}) => {
  const fileRef = useRef<HTMLDivElement | null>(null);
  const isHovering = useHover(fileRef);

  return (
    <div
      ref={fileRef}
      onClick={(e) => {
        e.stopPropagation();
        onSelectItem();
      }}
      className={clsx(
        "inline-flex items-center ml-[25px] cursor-pointer gap-[28px] rounded-[4px] py-[6px] px-[8px] transition-all",
        {
          "text-vePrimary bg-[#E0E7FF]": isSelected,
          "hover:bg-[#eee]": !isSelected,
        },
      )}
    >
      <div className="flex items-center gap-[8px]">
        <StorageFileIcon
          className={clsx("size-[20px] flex-shrink-0", {
            "text-green-500": item.isContext,
          })}
        />
        <div className="break-all">{item.title}</div>
      </div>

      <div className="flex-shrink-0">
        {renderMenu({ isHovering, isSelected, item })}
      </div>

      {columns}
    </div>
  );
};

export default File;
