import { Modal, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { editCampaignApi, getCampaignsApi } from "src/api/campaigns.api";
import {
  changeToDefaultSystemModelConfigApi,
  deleteModelConfigApi,
  getAiModelConfigOptions,
  getModelConfigsListApi,
} from "src/api/model-configs.api";
import { getMessageApi } from "src/store/slices/appSlice";
import { TCampaign } from "src/store/slices/campaignsSlice";
import { ModelConfig } from "src/types/modelConfigs";
import handleRequestError from "src/utils/handleRequestError";
import { getDynFormSelectOptions } from "../../../../utils/cm.utils";
import { Button } from "../../ui/button";
import { CampaignChangeModelListItem } from "./CampaignChangeModelListItem";

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  aiModelId: number;
  onDeleted?: () => void;
};

const DeleteModelConfigModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  aiModelId,
  onDeleted,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [campaignsList, setCampaignsList] = useState<TCampaign[]>([]);
  const [campaignsTotalItems, setCampaignsTotalItems] = useState(0);
  const [modelConfigs, setModalConfigs] = useState<
    { label: string; value: string | number | null }[]
  >([]);
  const [defaultModel, setDefaultModel] = useState<ModelConfig | null>(null);
  const messageApi = getMessageApi();
  const navigate = useNavigate();

  useEffect(() => {
    //TODO fix a bug - when not initialized successfully don't show delete dialog
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const [
        defaultModels,
        campaignsWithModelResponse,
        aiModelConfigOptionsResponse,
      ] = await Promise.all([
        getModelConfigsListApi({
          pageNumber: 1,
          pageSize: 1,
          sortBy: "systemDefault",
          orderBy: "DESC",
        }),
        getCampaignsApi({ aiModelId }),
        getAiModelConfigOptions(),
      ]);

      const defaultModelConfig = defaultModels.data.items[0];
      const campaignsWithModel = campaignsWithModelResponse.data;
      const { data } = aiModelConfigOptionsResponse;
      const options = getDynFormSelectOptions({ data });
      const filteredOptionsList = options.filter(
        (option) => option.value !== aiModelId,
      );

      setModalConfigs(filteredOptionsList);
      setCampaignsList(campaignsWithModel.items);
      setCampaignsTotalItems(campaignsWithModel.totalItems);
      setDefaultModel(defaultModelConfig);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsInitialized(true);
    }
  };

  const handleCampaignChangeAiModel = async (campaign: TCampaign) => {
    setIsLoading(true);
    try {
      await editCampaignApi({ campaignData: campaign });

      // remove campaign from list
      const newCampaignsList = campaignsList.filter(
        (item) => item.id !== campaign.id,
      );

      setCampaignsList(newCampaignsList);
      setCampaignsTotalItems(campaignsTotalItems - 1);

      messageApi.success("Campaign updated successfully");
    } catch (e) {
      const customError = handleRequestError(e as any);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);

      await Promise.all([
        changeToDefaultSystemModelConfigApi(aiModelId),
        deleteModelConfigApi(aiModelId),
      ]);

      setIsModalOpen(false);
      messageApi.success("Model config deleted successfully");
      onDeleted?.();
      navigate(`/model-configs`);
    } catch (e) {
      const customError = handleRequestError(e as any);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Delete Model Config"
      open={isModalOpen}
      width={800}
      onCancel={() => setIsModalOpen(false)}
      footer={() => {
        return (
          <Button
            variant={"destructive"}
            disabled={isLoading || !isInitialized}
            loading={isLoading}
            onClick={handleDelete}
          >
            Delete
          </Button>
        );
      }}
    >
      <Spin spinning={!isInitialized} tip="Initializing...">
        <div className="pb-3">
          You should change the model for the following campaigns before
          deleting, if you ignore this step, the campaigns will be set to
          default model.
        </div>

        <div className="max-h-[60vh] overflow-y-auto pr-2">
          {campaignsList.map((campaign) => (
            <CampaignChangeModelListItem
              key={campaign.id}
              campaign={campaign}
              modelConfigs={modelConfigs}
              onAiModelChange={handleCampaignChangeAiModel}
              defaultModel={defaultModel}
            />
          ))}
        </div>
      </Spin>
    </Modal>
  );
};

export default DeleteModelConfigModal;
