import { TCcVariable } from "../../ccVariablesSlice";
import {
  ComputationInfo,
  TEventMessagesMap,
  TSocketMsg,
  TSocketMsgAssetError,
  TSocketMsgCampaignError,
  TSocketMsgCIError,
  TSocketMsgCIUpdate,
  TSocketMsgComputationStatus,
  TSocketMsgMicrositeDeploy,
  TSocketMsgMicrositeDeployError,
  TSocketMsgPhaseError,
  TSocketMsgPromptPostProcessingResult,
  TSocketMsgStepError,
  TSocketMsgType,
  TSocketMsgZIPExportError,
  TSocketMsgZIPExportInfo,
  TSocketMsgZIPImportError,
} from "../types";
import { getProgressNumber } from "./helpers";
import { format } from "date-fns";

type TProcessEventMessagesProps = {
  messages: TSocketMsg<TSocketMsgType>[];
  currentStepId?: number;
};

type TProcessEventMessagesResponse = {
  eventMessagesMap: TEventMessagesMap | null;
  itemsToUpdate: TCcVariable[];
  computationInfo: ComputationInfo | null;
};

const processEventMessages = ({
  messages,
  currentStepId,
}: TProcessEventMessagesProps): TProcessEventMessagesResponse => {
  let eventMessagesMap: TEventMessagesMap = {}; // set in all types
  const itemsToUpdate: TCcVariable[] = []; // set in CI_UPDATED and CI_ERROR type
  let computationInfo: ComputationInfo | null = null; // set in COMPUTATION_STATUS type

  messages.forEach((message, index) => {
    const { type, timestamp } = message;
    const localTime = format(timestamp, "MMM d, yyyy HH:mm:ss");

    switch (type) {
      case "CI_UPDATED": {
        const { item, computationTime, path, phaseId } =
          message as TSocketMsgCIUpdate;
        const { key, stepId } = item.id;
        const eventMessageKey = `${stepId}_${key}`;

        //update only visible items in the grid (if certain step opened)
        if (currentStepId === stepId) {
          itemsToUpdate.push(item);
        }

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          phaseId,
          stepId,
          ccItemKey: item.id.key,
          status: item.state,
          computationTime,
          item,
        };
        break;
      }
      case "CI_ERROR": {
        const { key, stepId, errorMessage, item, path, phaseId } =
          message as TSocketMsgCIError;
        const eventMessageKey = `${stepId}_${key}`;

        //update only visible items in the grid (if certain step opened)
        if (currentStepId === stepId) {
          itemsToUpdate.push(item);
        }

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          phaseId,
          stepId,
          ccItemKey: item.id.key,
          status: item.state,
          errorMessage,
          item,
        };
        break;
      }
      case "STEP_ERROR": {
        const { stepId, errorMessage, path, phaseId, key } =
          message as TSocketMsgStepError;
        const eventMessageKey = `${type}_${stepId}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          phaseId,
          stepId,
          ccItemKey: key || undefined,
          status: "error",
          errorMessage,
        };
        break;
      }
      case "PHASE_ERROR": {
        const { phaseId, errorMessage, path } = message as TSocketMsgPhaseError;
        const eventMessageKey = `${type}_${phaseId}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          phaseId,
          status: "error",
          errorMessage,
        };
        break;
      }
      case "CAMPAIGN_ERROR": {
        const { errorMessage, campaignId } = message as TSocketMsgCampaignError;
        const eventMessageKey = `${type}_${campaignId}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path: "An error occurred during the execution of the campaign!",
          status: "error",
          errorMessage,
        };
        break;
      }
      case "COMPUTATION_STATUS": {
        const {
          total,
          onhold,
          state,
          computationType: compType,
          executionType: execType,
        } = message as TSocketMsgComputationStatus;

        computationInfo = {
          progress: getProgressNumber(total, onhold),
          status: state,
          type: compType,
          executionType: execType,
        };
        break;
      }

      case "POST_PROCESSING": {
        const { postProcessingResultMessage, path, item, phaseId } =
          message as TSocketMsgPromptPostProcessingResult;
        const { stepId, key } = item.id;
        const eventMessageKey = `${type}_${stepId}_${key}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          item,
          phaseId,
          stepId,
          ccItemKey: item.id.key,
          status: "finished",
          message: postProcessingResultMessage,
        };
        break;
      }

      case "ASSET_PUBLISH": {
        const {
          asset: { title, stepId },
          path,
        } = message as TSocketMsgMicrositeDeploy;
        const eventMessageKey = `${type}_${stepId}_${title}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          status: "finished",
        };
        break;
      }

      case "DEPLOY_ERROR": {
        const { stepId, errorMessage, phaseId, path } =
          message as TSocketMsgMicrositeDeployError;
        const eventMessageKey = `${type}_${phaseId}_${stepId}_${path}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          errorMessage,
          status: "error",
        };
        break;
      }

      case "ASSET_ERROR": {
        const { errorMessage, path } = message as TSocketMsgAssetError;
        const eventMessageKey = `${type}_${timestamp}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          errorMessage,
          status: "error",
        };
        break;
      }

      case "ZIP_IMPORT_ERROR": {
        const { errorMessage, path } = message as TSocketMsgZIPImportError;
        const eventMessageKey = `${type}_${path}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          errorMessage,
          status: "error",
        };
        break;
      }

      case "ZIP_EXPORT_ERROR": {
        const { errorMessage, path } = message as TSocketMsgZIPExportError;
        const eventMessageKey = `${type}_${path}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          errorMessage,
          status: "error",
        };
        break;
      }

      case "ZIP_EXPORT_INFO": {
        const { message: messageInfo, path } =
          message as TSocketMsgZIPExportInfo;
        const eventMessageKey = `${type}_${path}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          status: "finished",
          message: messageInfo,
        };
        break;
      }

      default: {
        const eventMessageKey = `${type}_${index}_${timestamp}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path: `${index}__Unhandled message type__${localTime}`,
        };
        console.warn("Unhandled message type", message);
        break;
      }
    }
  });

  return {
    eventMessagesMap: Object.keys(eventMessagesMap).length
      ? eventMessagesMap
      : null,
    itemsToUpdate,
    computationInfo,
  };
};

export default processEventMessages;
