import React from "react";
import {
  SecurityGroupList,
  SecurityGroupListHeader,
} from "../../components/securityGroups";

const SecurityGroupListPage: React.FC = () => {
  return (
    <div className="w-full flex flex-col gap-4">
      <SecurityGroupListHeader />
      <SecurityGroupList />
    </div>
  );
};

export default SecurityGroupListPage;
