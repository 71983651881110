import { EllipsisHorizontalIcon, SwatchIcon } from "@heroicons/react/16/solid";
import clsx from "clsx";
import { FC, useRef, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "src/components/common/ui/dropdown-menu";
import { useOutsideAlerter } from "src/hooks/useClickOutside";

export type MenuDropdownItem = {
  key: string;
  label: string;
  onClick: () => void;
  icon: any;
};

type Props = {
  children: (
    ref: React.RefObject<HTMLButtonElement>,
    setOpen: (is: boolean) => void,
    DropdownMenuTrigger: any
  ) => React.ReactNode;
  beforeViewsItems: MenuDropdownItem[];
  afterViewsItems: MenuDropdownItem[];
  moreItems: MenuDropdownItem[];
  viewItems: MenuDropdownItem[];
  dropdownClassName?: string;
  align?: "start" | "end";
  isCustomRef?: boolean;
};

const StepMenuDropdown: FC<Props> = ({
  children,
  beforeViewsItems,
  afterViewsItems,
  moreItems,
  dropdownClassName,
  viewItems,
  align,
}) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  useOutsideAlerter(menuRef, () => {
    setOpen(false);
  });

  const handleOpen = (is: boolean) => {
    setOpen(is);
  };

  const handleClose = (open: boolean) => {
    if (!open) {
      setOpen(false);
    }
  };

  return (
    <DropdownMenu open={open} onOpenChange={handleClose}>
      {children(triggerRef, handleOpen, DropdownMenuTrigger)}
      <DropdownMenuContent
        className={dropdownClassName}
        side={"bottom"}
        align={align || "start"}
        ref={menuRef}
      >
        {beforeViewsItems!.map((item: any) => {
          return (
            <DropdownMenuItem
              key={item.key}
              onClick={item.onClick}
              disabled={item.disabled}
              className={clsx(
                `p-[8px] cursor-pointer !text-slate-600 font-medium font-sans`,
                item.className
              )}
            >
              <item.icon className="w-5 h-5 mr-2" />
              <span className="text-sm font-medium font-sans w-[85%]">
                {item.label}
              </span>
            </DropdownMenuItem>
          );
        })}
        <SubMenuDropdown icon={SwatchIcon} label="Views" items={viewItems} />
        {afterViewsItems!.map((item: any) => {
          return (
            <DropdownMenuItem
              key={item.key}
              onClick={item.onClick}
              disabled={item.disabled}
              className={clsx(
                `p-[8px] cursor-pointer !text-slate-600 font-medium font-sans`,
                item.className
              )}
            >
              <item.icon className="w-5 h-5 mr-2" />
              <span className="text-sm font-medium font-sans w-[85%]">
                {item.label}
              </span>
            </DropdownMenuItem>
          );
        })}
        {!!moreItems?.length && (
          <SubMenuDropdown
            icon={EllipsisHorizontalIcon}
            label="More"
            items={moreItems}
          />
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export type SubMenuDropdownItem = {
  label: string;
  items: MenuDropdownItem[];
  icon?: any;
};

export const SubMenuDropdown: FC<SubMenuDropdownItem> = ({
  icon: Icon,
  label,
  items,
}) => {
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger
        className={clsx(
          `p-[8px] cursor-pointer !text-slate-600 font-medium font-sans`
        )}
      >
        {Icon && <Icon className="w-5 h-5 mr-2" />}
        <span className="font-sans !text-slate-600 font-medium">{label}</span>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          {items.map((item: any) => {
            return (
              <DropdownMenuItem
                key={item.key}
                onClick={item.onClick}
                disabled={item.disabled}
                className={clsx(
                  `p-[8px] cursor-pointer !text-slate-600 font-medium font-sans`,
                  item.className
                )}
              >
                <item.icon className="w-5 h-5 mr-2" />
                <span className="text-sm font-medium font-sans">
                  {item.label}
                </span>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};

export default StepMenuDropdown;
