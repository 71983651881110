import React, { FC, useState } from "react";
import { Button } from "../common/ui/button";
import { ChevronLeftIcon, Icons } from "../common/Icons";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import CompanyDrawer, { TCompanyForm } from "./CompanyDrawer/CompanyDrawer";
import { updateCompanyApi } from "../../api/companies.api";
import {
  getCurrentCompany,
  setCurrentCompany,
} from "../../store/slices/companiesSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../store/slices/appSlice";
import { AppDispatch } from "../../store/store";
import { useGoBack } from "../../hooks/useGoBack";
import handleRequestError from "../../utils/handleRequestError";

type Props = {
  error: string;
  isInitialized: boolean;
};
const CompanyDetailsHeader: FC<Props> = ({ error, isInitialized }) => {
  const { companyId } = useParams();
  const { hasCompanyUpdateRole } = useUserHasPermission({ companyId });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const currentCompany = getCurrentCompany();
  const messageApi = useSelector(selectMessageApi);
  const dispatch = useDispatch<AppDispatch>();
  const goBack = useGoBack("/companies");

  const onUpdateCompanyInfo = async ({
    values,
    rebuild,
  }: {
    values: TCompanyForm;
    rebuild: boolean;
  }) => {
    try {
      if (!currentCompany) {
        messageApi.error("Unable to get [companyData]");
        return;
      }

      const { data } = await updateCompanyApi({
        payload: values,
        companyId: currentCompany.id,
        rebuild,
      });

      dispatch(setCurrentCompany(data));

      setDrawerVisible(false);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mb-[20px]">
        <div
          onClick={goBack}
          className="flex gap-[4px] items-center font-medium text-[#475569] transition-colors text-base cursor-pointer hover:text-vePrimary"
        >
          <ChevronLeftIcon className="w-[20px] h-[20px]" />
          <span>Back</span>
        </div>

        {hasCompanyUpdateRole && (
          <Button
            icon={Icons.edit}
            variant="primaryOutline"
            className="rounded-full"
            disabled={!isInitialized || !!error || !currentCompany}
            onClick={() => setDrawerVisible(true)}
          >
            Edit Company
          </Button>
        )}
      </div>

      {hasCompanyUpdateRole && currentCompany && (
        <CompanyDrawer
          visible={drawerVisible}
          setVisible={setDrawerVisible}
          onUpdateCompany={onUpdateCompanyInfo}
          initialFormValues={{
            name: currentCompany.name,
            website: currentCompany.website || "",
          }}
        />
      )}
    </>
  );
};

export default CompanyDetailsHeader;
