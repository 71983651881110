import axiosInstance from "src/utils/axios/axiosInstance";
import { TDictionaryType } from "../globalTypes";

export const dictionaryApi = ({
  dictionaryType,
}: {
  dictionaryType: TDictionaryType;
}) => ({
  find: ({ wordMatch }: { wordMatch: string }) => {
    return axiosInstance.get<string[]>(
      `/api/secured/dictionary/find/${dictionaryType}`,
      {
        params: {
          wordMatch,
        },
      },
    );
  },
  add: ({ item }: { item: string }) => {
    return axiosInstance.post<string[]>(
      `/api/secured/dictionary/${dictionaryType}/`,
      {
        id: {
          type: dictionaryType,
          word: item,
        },
      },
    );
  },
  delete: ({ wordMatch }: { wordMatch: string }) => {
    return axiosInstance.delete<string[]>(
      `/api/secured/dictionary/${dictionaryType}`,
      {
        params: {
          wordMatch,
        },
      },
    );
  },
});
