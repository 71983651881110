import { Spin } from "antd";
import React from "react";
import AssetsList from "./AssetsList";
import ActionButtons from "./ActionButtons";
import useGetAssets from "../hooks/useGetAssets";

const Assets = () => {
  const { isFetching, loadAssets, assets } = useGetAssets();

  return (
    <div className="flex flex-col gap-4">
      <ActionButtons loadAndSetAssets={loadAssets} />

      <Spin spinning={isFetching}>
        <AssetsList assets={assets} loadAndSetAssets={loadAssets} />
      </Spin>
    </div>
  );
};

export default Assets;
