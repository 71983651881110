import { Typography } from "antd";
import React from "react";
import { TCcVarsFormOptionsValues } from "../../../../store/slices/ccVariablesSlice";

type Props = {
  options: TCcVarsFormOptionsValues | undefined;
};

const GridItemParameters: React.FC<Props> = (props) => {
  const { options } = props;

  if (!options) return <></>;

  const optionItems: Array<React.ReactElement> = [];

  for (const key in options) {
    const optionsKey = key as keyof TCcVarsFormOptionsValues;
    let content = options[optionsKey]
      ? JSON.stringify(options[optionsKey], null, 2)
      : options[optionsKey] + "";

    if (key === "aiModelId" && options[optionsKey] === null) {
      content = "--Use parent model--";
    }

    if (!content.length) {
      content = '""';
    }

    const item = (
      <div key={key}>
        <strong>{key}: </strong>
        {content && <Typography.Text code>{content}</Typography.Text>}
      </div>
    );

    optionItems.push(item);
  }

  return <>{optionItems}</>;
};

export default GridItemParameters;
