import React from "react";
import MicroSiteData from "../../../components/MicroSite/MicrositeData";
import { useSelector } from "react-redux";
import { selectCompanyStorageMicroSiteData } from "../../../store/slices/companyStorageSlice";
import { SftpUsers } from "../../../components/MicroSite/SftpUsers";
import { CardDescription, CardTitle } from "../../../components/common/ui/card";
import MicrositeStorage from "../../../components/storage/micrositeStorage/MicrositeStorage";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";

const CustomerSitePage = () => {
  const { companyId } = useParams();
  const microSite = useSelector(selectCompanyStorageMicroSiteData);
  const { hasCompanyMicrositeUserListRole, hasMsFsFileListRole } =
    useUserHasPermission({ companyId });

  return (
    <div className="flex flex-col gap-6">
      <MicroSiteData />

      {microSite && microSite.state === "CREATED" && (
        <>
          <div className="h-[1px] bg-[#E2E8F0]" />
          <div>
            <div className="flex flex-wrap justify-between gap-4">
              {hasCompanyMicrositeUserListRole && (
                <div className="w-full lg:w-[51%] mb-4">
                  <SftpUsers micrositeId={microSite.id} />
                </div>
              )}

              {hasMsFsFileListRole && (
                <div className="w-full lg:w-[47%]">
                  <div className="mb-4">
                    <CardTitle className="font-sans text-[#0F172A] mb-2">
                      Storage
                    </CardTitle>
                    <CardDescription className="font-geist text-[#475569]">
                      Manage microsite storage
                    </CardDescription>
                  </div>
                  <MicrositeStorage />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerSitePage;
