import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getMessageApi } from "../../store/slices/appSlice";
import {
  initCampaignsListFilters,
  loadCampaignsList,
} from "../../store/slices/campaignsListSlice";
import { AppDispatch } from "../../store/store";
import { ArchiveStatus, TCampaignListOptionType } from "src/globalTypes";
import { TCampaign } from "src/store/slices/campaignsSlice";
import { SortingDirection } from "src/types/docTemplates";
import { useCampaignsSearchParams } from "./useCampaignsSearchParams";

export const ALL_COMPANIES_ID = "ALL";
export const ALL_TEMPLATES_ID = "DRAFT_TEMPLATE,PUBLISHED_TEMPLATE";

export type CampaignsListRequestParams = {
  companyId: number | null;
  campaignType: TCampaignListOptionType;
  page: number;
  pageSize: number;
  archive: ArchiveStatus;
  sortBy?: keyof TCampaign;
  orderBy?: SortingDirection;
};

export type CampaignsListAdditionalParams = {
  backUrl?: string;
};

const useCampaignsList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isTemplatesPage = useLocation().pathname.includes("/templates");
  const messageApi = getMessageApi();
  const [fetching, setFetching] = useState(true);

  const { requestParams, updateSearchParams, additionalParams } =
    useCampaignsSearchParams();

  const init = () => {
    try {
      dispatch(initCampaignsListFilters());
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        "An error occurred while trying to init campaigns page:",
        e,
      );
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (
      requestParams.page &&
      requestParams.pageSize &&
      requestParams.campaignType
    ) {
      requestCampaignsList(requestParams);
    } else {
      updateSearchParams({
        ...requestParams,
        campaignType:
          requestParams.campaignType ||
          (isTemplatesPage ? ALL_TEMPLATES_ID : "CAMPAIGN"),
        page: requestParams.page || 1,
        pageSize: requestParams.pageSize || 50,
      });
    }
  }, [requestParams]);

  async function requestCampaignsList(
    requestParams: CampaignsListRequestParams,
  ) {
    try {
      setFetching(true);
      await dispatch(loadCampaignsList(requestParams)).unwrap();
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        "An error occurred while trying to init campaigns page:",
        e,
      );
    } finally {
      setFetching(false);
    }
  }

  const handleChangePage = (page: number) => {
    updateSearchParams(
      {
        ...requestParams,
        page,
      },
      additionalParams,
    );
  };

  const handleChangePageSize = (pageSize: number) => {
    updateSearchParams(
      {
        ...requestParams,
        pageSize,
        page: 1,
      },
      additionalParams,
    );
  };

  const handleChangeCompany = (companyId: string) => {
    updateSearchParams(
      {
        ...requestParams,
        companyId:
          companyId === ALL_COMPANIES_ID || !companyId
            ? null
            : Number(companyId),
        page: 1,
      },
      additionalParams,
    );
  };

  const handleChangeCampaignType = (campaignType: TCampaignListOptionType) => {
    updateSearchParams(
      {
        ...requestParams,
        campaignType,
        page: 1,
      },
      additionalParams,
    );
  };

  const handleChangeArchive = (archive: ArchiveStatus) => {
    updateSearchParams(
      { ...requestParams, archive, page: 1 },
      additionalParams,
    );
  };

  const updateSorting = (
    sortBy?: keyof TCampaign,
    orderBy?: SortingDirection,
  ) => {
    if (!sortBy || !orderBy) {
      updateSearchParams(
        { ...requestParams, sortBy: undefined, orderBy: undefined },
        additionalParams,
      );
      return;
    }
    updateSearchParams({ ...requestParams, sortBy, orderBy }, additionalParams);
  };

  return {
    fetching,
    requestParams,
    additionalParams,
    requestCampaignsList,
    handleChangePage,
    handleChangePageSize,
    handleChangeCompany,
    handleChangeCampaignType,
    handleChangeArchive,
    updateSorting,
  };
};
export default useCampaignsList;
