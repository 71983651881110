import { CopyFileParams } from "src/types";
import { TStorageFileItem } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";
import { replaceBrackets } from "../utils/cm.utils";

// PUBLIC
export const getGlobalPublicFilesListApi = ({ path }: { path: string }) => {
  return axiosInstance.get<TStorageFileItem[]>(`/api/secured/fs/public/list`, {
    params: {
      path: replaceBrackets(path),
    },
  });
};

export const uploadGlobalPublicFileApi = ({
  formData,
}: {
  formData: FormData;
}) => {
  return axiosInstance.post<TStorageFileItem>(
    `/api/secured/fs/public/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};

export const getGlobalPublicFileDataApi = ({ path }: { path: string }) => {
  return axiosInstance.get<string | Object>(`/api/secured/fs/public/file`, {
    params: {
      path: replaceBrackets(path),
    },
  });
};

export const deleteGlobalPublicFileApi = ({ path }: { path: string }) => {
  const form = new FormData();
  form.append("path", path);

  return axiosInstance.delete<void>(`/api/secured/fs/public/file`, {
    data: form,
  });
};

export const copyGlobalPublicFileApi = ({
  sourcePath,
  targetPath,
}: CopyFileParams) => {
  return axiosInstance.post<TStorageFileItem>(
    `/api/secured/fs/public/copy`,
    {},
    {
      params: {
        targetPath: replaceBrackets(targetPath),
        sourcePath: replaceBrackets(sourcePath),
      },
    },
  );
};

export const downloadGlobalPublicFileApi = ({ path }: { path: string }) => {
  return axiosInstance.get<ArrayBuffer>(`/api/secured/fs/public/download`, {
    responseType: "arraybuffer",
    params: {
      path,
    },
  });
};

export const downloadGlobalPublicFilePDFApi = ({ path }: { path: string }) => {
  return axiosInstance.get<ArrayBuffer>(`/api/secured/fs/public/html2Pdf`, {
    responseType: "arraybuffer",
    params: {
      path,
    },
  });
};

export const getGlobalPublicFileUrlApi = ({ path }: { path: string }) => {
  return axiosInstance.get<string>("/api/secured/fs/public/file-url", {
    params: {
      path,
    },
  });
};

// PRIVATE
export const getGlobalPrivateFilesListApi = ({ path }: { path: string }) => {
  return axiosInstance.get<TStorageFileItem[]>(`/api/secured/fs/private/list`, {
    params: {
      path: replaceBrackets(path),
    },
  });
};

export const uploadGlobalPrivateFileApi = ({
  formData,
}: {
  formData: FormData;
}) => {
  return axiosInstance.post<TStorageFileItem>(
    `/api/secured/fs/private/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};

export const getGlobalPrivateFileDataApi = ({ path }: { path: string }) => {
  return axiosInstance.get<string | Object>(`/api/secured/fs/private/file`, {
    params: {
      path: replaceBrackets(path),
    },
  });
};

export const deleteGlobalPrivateFileApi = ({ path }: { path: string }) => {
  const form = new FormData();
  form.append("path", path);

  return axiosInstance.delete<void>(`/api/secured/fs/private/file`, {
    data: form,
  });
};

export const copyGlobalPrivateFileApi = ({
  sourcePath,
  targetPath,
}: CopyFileParams) => {
  return axiosInstance.post<TStorageFileItem>(
    `/api/secured/fs/private/copy`,
    {},
    {
      params: {
        targetPath: replaceBrackets(targetPath),
        sourcePath: replaceBrackets(sourcePath),
      },
    },
  );
};

export const downloadGlobalPrivateFileApi = ({ path }: { path: string }) => {
  return axiosInstance.get<ArrayBuffer>(`/api/secured/fs/private/download`, {
    responseType: "arraybuffer",
    params: {
      path,
    },
  });
};

export const downloadGlobalPrivatePDFFileApi = ({ path }: { path: string }) => {
  return axiosInstance.get<ArrayBuffer>(`/api/secured/fs/private/html2Pdf`, {
    responseType: "arraybuffer",
    params: {
      path,
    },
  });
};
