import {
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Empty, Flex, message, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  exportEnvVarsToCSVThunk,
  getEnvVariables,
  getImportVarsDynItemsThunk,
  getIsVarEditorOpened,
  TEnvVarData,
  TEnvVariable,
  toggleIsEnvVarsEditorOpened,
} from "../../../store/slices/envVariablesSlice";
import { AppDispatch } from "../../../store/store";
import AddVariableModal from "../AddVariableModal/AddVariableModal";
import ImportVariablesModal from "../ImportVariables/ImportVariables";
import VariablesList from "../VariablesList/VariablesList";
import { useUserHasPermission } from "../../../store/slices/userData/hooks/useUserHasPermission";
import { useParams } from "react-router-dom";

const CampaignVariables = () => {
  const { companyId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const isOpened = getIsVarEditorOpened();
  const [isAddVarModalOpened, setIsAddVarModalOpened] = useState(false);
  const [isImportVarModalOpened, setIsImportVarModalOpened] = useState(false);
  const [editModeProps, setEditModeProps] = useState<TEnvVariable | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const envVariables = getEnvVariables();
  const [messageApi, contextHolder] = message.useMessage();
  const gridData: TEnvVarData[] = envVariables.map((variable) => {
    const { id, type, options, value } = variable;

    return {
      key: JSON.stringify(id), //campaignId + key,
      type,
      varKey: id.key,
      value,
      action: options,
      variable,
    };
  });
  const {
    hasCampaignEnvItemCreateRole,
    hasCampaignEnvItemImportRole,
    hasCampaignEnvItemExportRole,
    hasCampaignEnvItemUpdateRole,
  } = useUserHasPermission({ companyId });

  const onVarCreate = () => {
    setIsAddVarModalOpened(true);
    setEditModeProps(null);
  };

  const onVarsImport = async () => {
    try {
      const res = await dispatch(getImportVarsDynItemsThunk());

      if ("error" in res) {
        messageApi.error(
          `An error occurred while trying to get import env types: ${res.payload?.message}`,
        );
      } else {
        setIsImportVarModalOpened(true);
      }
    } catch (e: any) {
      messageApi.error(
        `An error occurred while trying to get import env types: ${e?.message}`,
      );
      console.error(
        "An error occurred while trying to get import env types:",
        e,
      );
    }
  };

  const onVarsExport = async () => {
    try {
      setIsFetching(true);
      const res = await dispatch(exportEnvVarsToCSVThunk());

      if ("error" in res) {
        messageApi.error(
          `An error occurred while trying to export to CSV: ${res.payload?.message}`,
        );
      } else {
        //TODO: add to utils after moving to module
        const { data, fileName } = res.payload;
        const csvUrl = URL.createObjectURL(data);
        const link = document.createElement("a");

        link.href = csvUrl;
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(csvUrl);
      }
    } catch (e: any) {
      messageApi.error(
        `An error occurred while trying to export to CSV: ${e?.message}`,
      );
      console.error("An error occurred while trying to export to CSV:", e);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Environment variables"
        open={isOpened}
        onCancel={() => {
          dispatch(toggleIsEnvVarsEditorOpened(false));
        }}
        centered={true}
        width={"80vw"}
        footer={null}
        destroyOnClose
      >
        <Flex vertical gap="middle" style={{ width: "100%", height: "80vh" }}>
          <Flex gap="middle">
            {hasCampaignEnvItemCreateRole && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={onVarCreate}
              >
                Add
              </Button>
            )}

            {hasCampaignEnvItemImportRole && (
              <Button icon={<ImportOutlined />} onClick={onVarsImport}>
                Import
              </Button>
            )}

            {hasCampaignEnvItemExportRole && (
              <Button
                loading={isFetching}
                icon={<ExportOutlined />}
                onClick={onVarsExport}
              >
                Export prompt variables to CSV
              </Button>
            )}
          </Flex>

          {!!gridData.length ? (
            <VariablesList
              setIsOpened={setIsAddVarModalOpened}
              setEditModeProps={setEditModeProps}
              gridData={gridData}
            />
          ) : (
            <Flex
              vertical
              style={{ height: "100%", marginBottom: "15vh" }}
              align="center"
              justify="center"
            >
              <Empty
                imageStyle={{ height: "30vh" }}
                description="There are no environment variables in this campaign."
              />
            </Flex>
          )}
          {(hasCampaignEnvItemCreateRole || hasCampaignEnvItemUpdateRole) &&
            isAddVarModalOpened && (
              <AddVariableModal
                isOpened={isAddVarModalOpened}
                setIsOpened={setIsAddVarModalOpened}
                setEditModeProps={setEditModeProps}
                envVarData={editModeProps}
                varKeysLowerCase={envVariables.map((variable) =>
                  variable.id.key.toLowerCase(),
                )}
              />
            )}
          {hasCampaignEnvItemImportRole && isImportVarModalOpened && (
            <ImportVariablesModal
              isOpened={isImportVarModalOpened}
              setIsOpened={setIsImportVarModalOpened}
            />
          )}
        </Flex>
      </Modal>
    </>
  );
};

export default CampaignVariables;
