import { Checkbox, Form, FormInstance, Input, Select } from "antd";
import TextAreaWithAltEnter from "../../common/TextAreaWithAltEnter";
import { TDynamicItem, TDynamicSelectProps, TDynamicTextareaProps } from "../../../globalTypes";

type PropsType = {
  isTypeSelected: boolean;
  form: FormInstance<any>;
  items: TDynamicItem[];
};

const ImportVarDynForms = (props: PropsType) => {
  const { isTypeSelected, form, items } = props;

  if (!items || !items.length) return <></>;

  const defaultFieldsValue: any = {};

  items.forEach((item) => {
    defaultFieldsValue[item.name] = item.defaultValue;
  });

  form.setFieldsValue(defaultFieldsValue);

  return (
    <>
      {items.map((item) => {
        let formItem: React.ReactElement = <></>;

        switch (item.type) {
          case "checkbox":
            {
              const { name } = item;

              formItem = (
                <div style={{ marginBottom: "16px" }} key={name}>
                  <Form.Item
                    hidden={!isTypeSelected}
                    valuePropName="checked"
                    name={name}
                    noStyle
                  >
                    <Checkbox>{name}</Checkbox>
                  </Form.Item>
                </div>
              );
            }
            break;
          case "textarea":
            {
              const textareaProps = item as TDynamicTextareaProps;
              const rows = +textareaProps.properties.rows;
              const name = textareaProps.name;

              formItem = (
                <Form.Item
                  key={name}
                  hidden={!isTypeSelected}
                  name={name}
                  label={name}
                  rules={[
                    {
                      required: textareaProps.required,
                      message: "Required field!",
                    },
                  ]}
                >
                  <TextAreaWithAltEnter
                    placeholder={`Enter ${name}`}
                    currentForm={form}
                    fieldName={name}
                    rows={rows}
                  />
                </Form.Item>
              );
            }
            break;
          case "text_input":
            {
              const { name, required } = item;

              formItem = (
                <Form.Item
                  key={name}
                  hidden={!isTypeSelected}
                  name={name}
                  label={name}
                  rules={[
                    {
                      required: required,
                      message: "Required field!",
                    },
                  ]}
                >
                  <Input placeholder={`Enter ${name}`} />
                </Form.Item>
              );
            }
            break;
          case "select":
            {
              const selectProps = item as TDynamicSelectProps;
              const name = selectProps.name;

              formItem = (
                <Form.Item
                  key={name}
                  hidden={!isTypeSelected}
                  name={name}
                  label={name}
                  rules={[
                    {
                      required: selectProps.required,
                      message: "Required field!",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 120 }}
                    placeholder={`Choose ${name}`}
                    options={selectProps.values.map((value) => ({
                      value,
                      label: value,
                    }))}
                  />
                </Form.Item>
              );
            }
            break;
        }
        return formItem;
      })}
    </>
  );
};

export default ImportVarDynForms;
