import { Tooltip } from "antd";
import clsx from "clsx";
import { FC } from "react";
import { useSelector } from "react-redux";
import { DocIcon, TableIcon } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import { selectDocVisibleSteps } from "src/store/slices/stepsSlice";
import { CampaignViewTypes } from "src/types";
import { useUserHasPermission } from "../../../../store/slices/userData/hooks/useUserHasPermission";
import { useCampaignParams } from "../CampaignEditorPage/useCampaignParams";

type TMenuItem = {
  viewType: CampaignViewTypes;
  icon: any;
  disabled: boolean;
  display: boolean;
};

const CampaignViewSelector: FC<{}> = () => {
  const { companyId, updateCampaignView, view } = useCampaignParams();
  const docVisibleList = useSelector(selectDocVisibleSteps);
  const { hasStepTemplatePreviewRole, hasCampaignCcListRole } =
    useUserHasPermission({ companyId });
  const isDocVisibleListEmpty = docVisibleList.length === 0;

  const items: TMenuItem[] = [
    {
      viewType: CampaignViewTypes.DOC,
      icon: DocIcon,
      disabled: !hasStepTemplatePreviewRole || isDocVisibleListEmpty,
      display: true,
    },
    {
      viewType: CampaignViewTypes.GRID,
      icon: TableIcon,
      disabled: !hasCampaignCcListRole,
      display: true,
    },
  ];

  return (
    <div className="flex flex-col border-r border-[#E2E8F0]">
      {items.map(({ viewType, icon: Icon, disabled, display }, i) => {
        const isActive = view === viewType;

        if (!display) return null;

        return (
          <div
            key={viewType}
            className={clsx(
              "bg-slate-50 h-[48px] items-center flex transition-colors text-[#334155] text-sm font-semibold font-sans leading-[14px] border-t-[3px] border-transparent p-[8px]",
              {
                "rounded-tl-[3px]": i === 0,
                "rounded-bl-[3px]": i === items.length - 1,
              },
            )}
          >
            <Tooltip
              title={
                disabled && isDocVisibleListEmpty
                  ? `You don't have visible steps in Doc view`
                  : ""
              }
            >
              <div>
                <Button
                  className="w-full h-full flex items-center justify-center size-[32px]"
                  size="icon"
                  disabled={disabled}
                  onClick={() => updateCampaignView(viewType)}
                  variant={isActive ? "primary" : "ghostPrimary2"}
                >
                  <Icon className="h-4 w-4" />
                </Button>
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default CampaignViewSelector;
