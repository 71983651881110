import {
  Button,
  Flex,
  FormInstance,
  Input,
  Popover,
  Segmented,
  Select,
  Typography,
} from "antd";
import clsx from "clsx";
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { TVarType, VarTypeEnum } from "src/types";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import SelectCCKey from "../../common/SelectCCKey";

type Props = {
  varKey: string | null;
  varType: TVarType;
  index: number;
  form: FormInstance<any>;
  formPromptArgsKey: string;
  gridItemSequence: number;
  localKeys: Array<TCcVariable>;
};

const TemplatePromptVar: FC<Props> = ({
  varKey,
  index,
  form,
  formPromptArgsKey,
  gridItemSequence,
  varType,
  localKeys,
}) => {
  const [inputText, setInputText] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const timeout: MutableRefObject<any> = useRef(null);
  const currentValue: MutableRefObject<string> = useRef("");
  const [open, setOpen] = useState(false);
  const inputTagRef = useRef<any>(null);
  const [argumentType, setArgumentType] = useState<TVarType>(varType);
  const [varKeyPlaceholder, setVarKeyPlaceholder] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    const inputTextDefaultValue =
      varType === VarTypeEnum.RAW
        ? varKey
          ? varKey.replace(/^"|"$/g, "")
          : varKey
        : null;

    let keyPlaceholder = varKey;

    if (varKey && varType === VarTypeEnum.CAMPAIGN_VAR) {
      const splitKey = varKey.split("/");

      keyPlaceholder = splitKey[splitKey.length - 1];
    }

    setInputText(inputTextDefaultValue);

    setSelectedOption(varType === VarTypeEnum.CAMPAIGN_VAR ? varKey : null);
    setVarKeyPlaceholder(keyPlaceholder);
  }, [varType, varKey, varKeyPlaceholder]);

  const onSelectKey = (value: string) => {
    setSelectedOption(value);

    const newKeys = form.getFieldValue(formPromptArgsKey);

    newKeys[index] = value;

    form.setFieldsValue({
      [formPromptArgsKey]: newKeys,
    });

    //need validateFields to trigger form change
    //needed for "form changed warning"
    form.validateFields([formPromptArgsKey]);

    setOpen(false);
    setInputText(null);
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    if (argumentType === VarTypeEnum.CAMPAIGN_VAR) {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }

      currentValue.current = text;
    } else {
      setInputText(text);
    }
  };

  const onSaveText = () => {
    const newKeys = form.getFieldValue(formPromptArgsKey);

    newKeys[index] = `"${inputText?.trim()}"`;

    form.setFieldsValue({
      [formPromptArgsKey]: newKeys,
    });

    //need validateFields to trigger form change
    //needed for "form changed warning"
    form.validateFields([formPromptArgsKey]);

    setOpen(false);
    setSelectedOption(null);
  };

  const getInputSection = () => {
    switch (argumentType) {
      case VarTypeEnum.CAMPAIGN_VAR:
        return (
          <div>
            <SelectCCKey
              gridItemSequence={gridItemSequence}
              onSelect={onSelectKey}
            />
            <div className="ml-2 mt-4 text-gray-700">{selectedOption}</div>
          </div>
        );
      case VarTypeEnum.LOCAL_VAR:
        return (
          <Select
            options={localKeys.map((variable) => ({
              label: variable.id.key,
              value: variable.id.key,
            }))}
            defaultOpen
            autoFocus
            showSearch
            onSelect={onSelectKey}
            placeholder="Choose local key"
            defaultValue={
              varType === VarTypeEnum.LOCAL_VAR ? varKey : undefined
            }
          />
        );
      case VarTypeEnum.RAW:
        return (
          <>
            <Input
              placeholder="Enter value"
              onChange={onTextChange}
              value={inputText || undefined}
              ref={inputTagRef}
            />
            <div>
              <Button
                type="primary"
                size="small"
                onClick={onSaveText}
                disabled={!inputText}
              >
                Choose
              </Button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Popover
      destroyTooltipOnHide
      content={
        <Flex
          vertical
          gap="small"
          justify="space-between"
          style={{ height: "76px" }}
        >
          {getInputSection()}
        </Flex>
      }
      title={
        <Flex justify="space-between" style={{ width: "500px" }}>
          Choose Argument
          <Segmented
            size="small"
            value={argumentType}
            onChange={(value) => {
              setArgumentType(value as TVarType);
            }}
            options={[
              VarTypeEnum.LOCAL_VAR,
              VarTypeEnum.CAMPAIGN_VAR,
              VarTypeEnum.RAW,
            ]}
          />
        </Flex>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Typography.Text
        code
        strong
        className={clsx(
          "font-bold text-[#ff761b] cursor-pointer hover:text-[#a54200] transition-colors",
          {
            "!hover:text-[#002c02] !text-[#007706]": varKey !== null,
          },
        )}
      >
        {varKeyPlaceholder !== null ? varKey : "var"}
      </Typography.Text>
    </Popover>
  );
};

export default TemplatePromptVar;
