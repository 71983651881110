import { Form } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTemplateByIdApi } from "../../../api/document-templates.api";
import { TemplateSettingForm } from "../../../components/docTemplates/TemplateSettingsForm";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { XSLTEditorEnum } from "../../../types/docTemplates";
import handleRequestError from "../../../utils/handleRequestError";

const useTemplateDataHook = () => {
  const messageApi = useSelector(selectMessageApi);
  const { templateId } = useParams();
  const [XSLTCode, setXSLTCode] = useState<string | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(true);
  const [form] = Form.useForm<TemplateSettingForm>();
  const [tab, setTab] = useState<XSLTEditorEnum>(XSLTEditorEnum.CODE);
  const [mediaType, setMediaType] = useState<string | undefined>(undefined);

  useEffect(() => {
    templateId ? getTemplateById(Number(templateId)) : setIsFetching(false);
  }, [templateId]);

  async function getTemplateById(id: number) {
    try {
      const { data } = await getTemplateByIdApi({ id });
      const {
        content,
        classes,
        name,
        state,
        includeCampaignStructure,
        mediaType,
      } = data;

      setMediaType(mediaType);
      setXSLTCode(content);
      form.setFieldsValue({
        name,
        state,
        classes,
        includeCampaignStructure,
      });
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  }

  return {
    XSLTCode,
    templateId,
    isFetching,
    setXSLTCode,
    form,
    setTab,
    tab,
    mediaType,
  };
};

export default useTemplateDataHook;
