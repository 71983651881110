import React, { FC, useState } from "react";
import { TableCell } from "../common/ui/table";
import MenuDropdown, { MenuDropdownItem } from "../common/MenuDropdown";
import { Button } from "../common/ui/button";
import { DeleteIcon, Icons } from "../common/Icons";
import { BoltIcon, BoltSlashIcon } from "@heroicons/react/16/solid";
import { useUserHasPermission } from "../../store/slices/userData/hooks/useUserHasPermission";
import useConfirm from "../../hooks/useConfirm";
import handleRequestError from "../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../store/slices/appSlice";
import DeleteWithTitleConfirmationModal from "../common/modals/DeleteWithTitleConfirmationModal/DeleteWithTitleConfirmationModal";
import { useParams } from "react-router-dom";

type Props = {
  userName: string;
  disabled: boolean;
  onNavigateToUserDetails: () => void;
  updateUsers: () => Promise<void>;
  deleteUser: () => Promise<void>;
  enableUser: () => Promise<void>;
  disableUser: () => Promise<void>;
};
const UserMenuTableCell: FC<Props> = ({
  onNavigateToUserDetails,
  userName,
  disabled,
  updateUsers,
  deleteUser,
  enableUser,
  disableUser,
}) => {
  const { companyId } = useParams();
  const {
    hasUserDeleteRole,
    hasUserReadRole,
    hasUserDisableRole,
    hasUserEnableRole,
  } = useUserHasPermission({ companyId });
  const confirm = useConfirm();
  const messageApi = useSelector(selectMessageApi);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onDelete = async () => {
    try {
      await deleteUser();
      await updateUsers();

      messageApi.success("User deleted successfully");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const toggleEnableUser = async (action: "enable" | "disable") => {
    try {
      let successMessage: string;

      if (action === "enable") {
        await enableUser();
        successMessage = "User enabled successfully";
      } else {
        await disableUser();
        successMessage = "User disabled successfully";
      }

      await updateUsers();

      messageApi.success(successMessage);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const menuItems = [
    hasUserReadRole && {
      key: "edit",
      label: "Edit",
      onClick: onNavigateToUserDetails,
      icon: Icons.edit,
    },
    !disabled &&
      hasUserDisableRole && {
        key: "disableUser",
        label: "Disable User",
        onClick: () =>
          confirm({
            action: () => toggleEnableUser("disable"),
            title: "Disable User",
          }),
        icon: BoltSlashIcon,
      },
    disabled &&
      hasUserEnableRole && {
        key: "enableUser",
        label: "Enable User",
        onClick: () =>
          confirm({
            action: () => toggleEnableUser("enable"),
            title: "Enable User",
          }),
        icon: BoltIcon,
      },
    hasUserDeleteRole && {
      key: "delete",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  if (!menuItems.length) return <TableCell />;

  return (
    <TableCell onClick={(e) => e.stopPropagation()}>
      <div className="flex justify-end items-center">
        <MenuDropdown
          items={menuItems}
          dropdownClassName="w-[190px]"
          align="end"
        >
          <Button variant={"ghostPrimary"} className="size-[40px] p-2">
            <Icons.MoreDots className="size-[24px]" />
          </Button>
        </MenuDropdown>
      </div>

      {hasUserDeleteRole && isDeleteModalOpen && (
        <DeleteWithTitleConfirmationModal
          entity="User"
          onDelete={onDelete}
          title={userName}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}
    </TableCell>
  );
};

export default UserMenuTableCell;
