import { Form } from "antd";
import { useEffect, useMemo, useState } from "react";
import { TEditModeProps } from "../components/CampaignGrid/Grid/CampaignGrid";
import {
  TCcDynamicFormItems,
  TCcItemProps,
  TCcResultType,
  TCcVariable,
  TCcVariableType,
  TCcVarsFormValues,
  useCcDynamicFormItems,
  useCcVarTypes,
} from "../store/slices/ccVariablesSlice";
import localStorageProvider from "../utils/localStorageProvider";

const useCCVarData = (ccVarData: TEditModeProps | null) => {
  const dynamicFormItems = useCcDynamicFormItems();
  const ccVarTypes = useCcVarTypes();
  const [form] = Form.useForm<TCcVarsFormValues>();
  const type = Form.useWatch<TCcVariableType | undefined | null>("type", form);
  const [dynamicData, setDynamicData] = useState<TCcItemProps<
    TCcResultType,
    TCcDynamicFormItems
  > | null>(null);
  const dynamicItems = useMemo(
    () =>
      dynamicData
        ? [...dynamicData.items].sort((a, b) => a.order - b.order)
        : [],
    [dynamicData],
  );

  //initial data loading
  useEffect(() => {
    if (!ccVarData) {
      let key = localStorageProvider.getCCType();

      if (key !== null) {
        const parsedKey: TCcVariableType = JSON.parse(key);

        if (ccVarTypes.includes(parsedKey)) {
          const dynData = dynamicFormItems[parsedKey];
          const defaultFieldsValue: any = {
            type: parsedKey,
          };

          dynData.items.forEach((item) => {
            defaultFieldsValue[item.name] = item.defaultValue;
          });

          form.setFieldsValue(defaultFieldsValue);

          setDynamicData(dynData);
        }
      }
    } else {
      setEditData(ccVarData);
    }
  }, []);

  useEffect(() => {
    if (type) {
      if (ccVarData) {
        setEditData(ccVarData);
      } else {
        const dynData = dynamicFormItems[type];
        const formValues: any = {
          type,
          key: form.getFieldValue("key"),
        };

        dynData.items.forEach((item) => {
          formValues[item.name] = item.defaultValue;
        });

        form.resetFields();
        form.setFieldsValue(formValues);

        setDynamicData(dynData);
        setCCVarTypeLS(type);
      }
    }
  }, [type]);

  const setEditData = (ccVarData: TEditModeProps) => {
    const ccVar = ccVarData.variableData;

    setFormValues(ccVar);

    setDynamicData(dynamicFormItems[ccVar.type]);
  };

  const setFormValues = (ccVar: TCcVariable) => {
    const { id, type, result, modifiedTime, options } = ccVar;

    form.setFieldsValue({
      key: id.key,
      type,
      result,
      modifiedTime,
      ...options,
    });
  };

  const setCCVarTypeLS = (item: TCcVariableType) => {
    localStorageProvider.setCCType(item);
  };

  return {
    dynamicFormItems,
    ccVarTypes,
    setCCVarTypeLS,
    form,
    dynamicData,
    setDynamicData,
    type,
    resultType: dynamicData?.resultType,
    dynamicItems,
    setFormValues,
  };
};

export default useCCVarData;
