import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  handleRequestError,
  TCustomError,
} from "../../../utils/handleRequestError";
import { RootState } from "../../store";
import { buildWebSocketUrl } from "./utils/helpers";
import {
  ComputationInfo,
  TComputationMessage,
  TEventMessagesMap,
} from "./types";
import { initializeWebsocket } from "./utils/initializeWebsocket";
import { wait } from "../../../utils";

type CampaignWebsocket = { campaignId: number; socket: WebSocket };

const initialState = {
  socketShouldReconnect: false,
  messagesStack: {} as TEventMessagesMap,
  computationInfo: null as ComputationInfo | null,
  sockets: [] as CampaignWebsocket[],
};

type InitialStateType = typeof initialState;

const slice = createSlice({
  name: "computationMessages",
  initialState,
  reducers: {
    addSocket: (
      state: InitialStateType,
      action: PayloadAction<CampaignWebsocket>,
    ) => {
      state.sockets.push(action.payload);
    },
    removeSocket: (state, action: PayloadAction<number>) => {
      const index = state.sockets.findIndex(
        (entry) => entry.campaignId === action.payload,
      );

      if (index !== -1) {
        state.sockets.splice(index, 1);
      }
    },
    setSocketShouldReconnect: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.socketShouldReconnect = action.payload;
    },
    resetComputationMessages: (state: InitialStateType) => {
      state.messagesStack = {};
    },
    addBulkComputationMessages: (
      state: InitialStateType,
      action: PayloadAction<TEventMessagesMap>,
    ) => {
      state.messagesStack = { ...state.messagesStack, ...action.payload };
    },
    setComputationInfo: (
      state: InitialStateType,
      action: PayloadAction<ComputationInfo | null>,
    ) => {
      state.computationInfo = action.payload;
    },
  },
});

export const {
  resetComputationMessages,
  setSocketShouldReconnect,
  addBulkComputationMessages,
  setComputationInfo,
  addSocket,
  removeSocket,
} = slice.actions;

export default slice.reducer;

export const selectEventMessages = createSelector(
  (state: RootState) => state.computationMessages.messagesStack,
  (messages) => Object.values(messages),
);
export const selectErrorMessagesCount = createSelector(
  selectEventMessages,
  (messages: TComputationMessage[]) => {
    return messages.filter((item) => item.type.toLowerCase().includes("error"))
      .length;
  },
);
export const selectSockets = (state: RootState) =>
  state.computationMessages.sockets;
export const selectSocketByCampaignId = createSelector(
  [selectSockets, (_, campaignId: number | null) => campaignId],
  (sockets, campaignId) =>
    sockets.find((entry) => entry.campaignId === campaignId),
);
export const selectSocketShouldReconnect = (state: RootState) =>
  state.computationMessages.socketShouldReconnect;
export const selectComputationInfo = (state: RootState) =>
  state.computationMessages.computationInfo;

export const connectSocketThunk = createAsyncThunk<
  undefined,
  // stepIdForCcItemsToUpdate - update cc items for the specified step in the grid
  {
    campaignId: number;
    shouldUpdateCurrentStepCcItems?: boolean;
  },
  { state: RootState; rejectValue: TCustomError }
>(
  "computationMessages/connectSocket",
  async (
    { campaignId, shouldUpdateCurrentStepCcItems },
    { getState, rejectWithValue, dispatch },
  ) => {
    const wsUrl = buildWebSocketUrl(campaignId);

    dispatch(setSocketShouldReconnect(false));

    try {
      const {
        computationMessages: { sockets },
      } = getState();

      if (sockets.length) {
        console.log("Socket connection found, closing...", sockets);
        sockets.forEach((entry) => entry.socket.close());

        await wait(300);
      }

      initializeWebsocket({
        url: wsUrl,
        shouldUpdateCurrentStepCcItems,
        dispatch,
        campaignId,
        getState,
      });
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to connect to WS:`, e);

      return rejectWithValue(customError);
    }
  },
);

export const disconnectSocketThunk = createAsyncThunk<
  undefined,
  { campaignId: number },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "computationMessages/disconnectSocket",
  async ({ campaignId }, { getState, rejectWithValue }) => {
    try {
      const {
        computationMessages: { sockets },
      } = getState();

      sockets.forEach((entry) => entry.socket.close());
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to disconnect from WS:`, e);

      return rejectWithValue(customError);
    }
  },
);
