import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getUserPermissionsApi,
  userLoginApi,
  userLoginWithGoogleApi,
  userLogoutApi,
} from "../../../api/user.api";
import handleRequestError, {
  TCustomError,
} from "../../../utils/handleRequestError";
import { RootState } from "../../store";
import { TUser } from "../../../globalTypes";
import { AuthThunkRes, UserMyPermissions } from "./types";

const initialState = {
  userData: null as TUser | null,
  isAuthenticated: false,
  permissions: {
    globalPermissions: [],
    companyPermissions: {},
    companyPermissionsDeny: {},
  } as UserMyPermissions,
};

type InitialStateType = typeof initialState;

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserPermissions: (
      state: InitialStateType,
      action: PayloadAction<UserMyPermissions>,
    ) => {
      state.permissions = action.payload;
    },
    setIsAuthenticated: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state: InitialStateType, action: PayloadAction<TUser>) => {
      state.userData = action.payload;
    },
  },
});

export const { setUserPermissions, setUser, setIsAuthenticated } =
  slice.actions;

export default slice.reducer;

export const selectIsAuthenticated = (state: RootState) =>
  state.userData.isAuthenticated;
export const selectUserData = (state: RootState) => state.userData.userData;
export const selectUserPermissions = (state: RootState) =>
  state.userData.permissions;

export const loginWithGoogleThunk = createAsyncThunk<
  AuthThunkRes,
  string,
  { rejectValue: TCustomError }
>(
  "users/loginWithGoogleThunk",
  async (googleCredentials, { rejectWithValue, dispatch }) => {
    try {
      const { data: user } = await userLoginWithGoogleApi({
        credential: googleCredentials,
      });
      const { userPermissions } = await dispatch(
        loadUserPermissions(),
      ).unwrap();

      dispatch(setUser(user));
      dispatch(setIsAuthenticated(true));

      return { userEmail: user.email, userPermissions };
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(e);

      return rejectWithValue(customError);
    }
  },
);

export const authenticateUserThunk = createAsyncThunk<
  AuthThunkRes,
  undefined,
  { rejectValue: TCustomError }
>("users/authenticateUserThunk", async (_, { rejectWithValue, dispatch }) => {
  try {
    const { data: user } = await userLoginApi();
    const { userPermissions } = await dispatch(loadUserPermissions()).unwrap();

    dispatch(setUser(user));
    dispatch(setIsAuthenticated(true));

    return { userEmail: user.email, userPermissions };
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(e);

    return rejectWithValue(customError);
  }
});

export const loadUserPermissions = createAsyncThunk<
  { userPermissions: UserMyPermissions },
  undefined,
  { rejectValue: TCustomError }
>("users/loadUserPermissions", async (_, { rejectWithValue, dispatch }) => {
  try {
    const { data: userPermissions } = await getUserPermissionsApi();

    dispatch(setUserPermissions(userPermissions));

    return { userPermissions };
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(e);

    return rejectWithValue(customError);
  }
});

export const logoutThunk = createAsyncThunk<
  undefined,
  { cleanLogout: boolean },
  { rejectValue: TCustomError }
>(
  "users/logoutThunk",
  async ({ cleanLogout }, { rejectWithValue, dispatch }) => {
    try {
      cleanLogout && (await userLogoutApi());

      dispatch(setIsAuthenticated(false));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(e);

      return rejectWithValue(customError);
    }
  },
);
