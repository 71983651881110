import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ModelConfig } from "src/types/modelConfigs";
import { RootState } from "../store";

type ModelConfigsSliceState = {
  list: ModelConfig[];
  pageNumber: number;
  pageSize: number;
  total: number;
};

const initialState: ModelConfigsSliceState = {
  list: [],
  pageNumber: 1,
  pageSize: 50,
  total: 0,
};

const modelConfigsSlice = createSlice({
  name: "modelConfigs",
  initialState,
  reducers: {
    setList: (
      state: ModelConfigsSliceState,
      action: PayloadAction<{ list: ModelConfig[]; total: number }>
    ) => {
      state.list = action.payload.list;
      state.total = action.payload.total;
    },
    setPageSize: (
      state: ModelConfigsSliceState,
      action: PayloadAction<number>
    ) => {
      state.pageSize = action.payload;
    },
    setPageNumber: (
      state: ModelConfigsSliceState,
      action: PayloadAction<number>
    ) => {
      state.pageNumber = action.payload;
    },
    setSystemDefault: (
      state: ModelConfigsSliceState,
      action: PayloadAction<number>
    ) => {
      state.list = state.list.map((item) => {
        if (item.id === action.payload) {
          return { ...item, systemDefault: true };
        }
        return { ...item, systemDefault: false };
      });
    },
  },
});

export const modelConfigsActions = modelConfigsSlice.actions;

export default modelConfigsSlice.reducer;

export const selectModelConfigsState = (state: RootState) => state.modelConfigs;
